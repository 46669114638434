import React, { useContext } from 'react';
import { useNavigate } from 'react-router';

import {
  Drawer, DrawerContent, DrawerItemProps, DrawerSelectEvent
} from '@progress/kendo-react-layout';

import * as Permissions from '../../permissions/permissions';
import { AppContext, IAppContext } from '../../services/AppContext';
import useSessionWrapper from '../../services/SessionWrapper';
import { Header } from '../Header';
import DrawerItemRenderer from './DrawerCustomItem';
import { IMenuItem, MenuItems } from './MenuItems';

const width: number = 1200;

const DrawerRouterContainer = (props: any) => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelected] = React.useState<string>("/");
  const [expandexMenu, setExpandexMenu] = React.useState<boolean>(true);
  const context = useContext<IAppContext>(AppContext);

  const sessionWrapper = useSessionWrapper();

  const onSelect = (e: DrawerSelectEvent) => {
    if (e.itemTarget.props.route === "#") return;
    navigate(e.itemTarget.props.route);
    unhideChilds(e);
  };

  const onHamburgerClick = () => {
    setExpandexMenu(!expandexMenu);
  };

  React.useEffect(() => {
    resetVisibilityOfMenuItems();
  }, [context.values.loggedIn]);

  React.useEffect(() => {
    const path = window.location.href.split("#").pop() ?? "/";
    if (selectedMenuItem !== path) {
      setSelected(path);
    }
  }, [window.location.href]);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= width) setExpandexMenu(true);
      if (window.innerWidth < width) setExpandexMenu(false);
    }

    window.addEventListener("resize", handleResize);
  }, []);

  const compareRouteWithLocation = (menuRoute: string, currentAddress: string): boolean => {
    if (menuRoute === "/" && currentAddress !== menuRoute) return false;

    const slicedCurrentAddress = currentAddress.slice(1);
    const slicedMenuRoute = menuRoute.slice(1);

    const currentAddressFirstSegment = slicedCurrentAddress.split('/')[0];
    const menuRouteFirstSegment = slicedMenuRoute.split('/')[0];

    if (currentAddressFirstSegment.length !== menuRouteFirstSegment.length) return false;

    return slicedCurrentAddress.indexOf("/") !== -1 ? slicedCurrentAddress.startsWith(slicedMenuRoute) : slicedCurrentAddress === slicedMenuRoute;
  };

  const resetVisibilityOfMenuItems = () => {
    MenuItems.forEach((x) => {
      x.hidden = x.parentid != null;
    });
  }

  const unhideChilds = (e: DrawerSelectEvent) => {
    const parentId: number = e.itemTarget.props.parentid ?? e.itemTarget.props.id;
    MenuItems.forEach((x) => {
      if (x.parentid == null || x.parentid === parentId) {
        x.hidden = false;
      } else {
        x.hidden = true;
      }
    });
  };

  return sessionWrapper.isLoggedIn() ? ( //context.values.loggedIn
    <div>
      <Header onHamburgerClick={onHamburgerClick} />
      <Drawer
        className="drawer-background"
        expanded={expandexMenu}
        mini={false}
        mode={"push"}
        items={MenuItems.filter((item: IMenuItem) => Permissions.hasPermission(item.permission, context.values.role))
          .map((item: IMenuItem): DrawerItemProps => {
            var drawerItem: DrawerItemProps = {
              ...item,
              text: `${item.name}`,
              selected: compareRouteWithLocation(item.route ?? "", selectedMenuItem),
            };

            return drawerItem;
          })}
        item={DrawerItemRenderer}
        onSelect={onSelect}
        width={251} //kendo subtracts 1 px (?)
      >
        <DrawerContent>{props.children}</DrawerContent>
      </Drawer>
    </div>
  ) : (
    <>{props.children}</>
  );
};

export default DrawerRouterContainer;
