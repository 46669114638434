import * as React from 'react';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';

import { apiClient } from '../../api/apiClient';
import { DEPARTMENTS_DELETE } from '../../api/apiEndpoints';
import EditDepartmentRequestDto from '../../models/dto/Departments/Requests/EditDepartmentRequestDto';
import NotificationEnum from '../../models/enums/NotificationEnum';
import DepartmentTreeItem from '../../models/local/Departments/DepartmentTreeItem';
import DepartmentUser from '../../models/local/Departments/DepartmentUser';
import useAppNotifications from '../../services/AppNotifications';
import { depNameValidator } from '../../validators/DepartmentsEditValidators';
import InfoDialog from '../Common/InfoDialog';
import PromptDialog from '../Common/PromptDialog';
import { FormComboBox, FormInput, FormTextArea } from '../Kendo/form-components';

const DepartmentsEdit = (props: {
  department: DepartmentTreeItem,
  users: DepartmentUser[],
  onSave: (dto: EditDepartmentRequestDto) => void,
  onCancel: () => void,
  onEmployeesManage: () => void | null,
}) => {
  const isGroupOnly = false;//props?.selectedDepartment?.groupOnly;
  const notifications = useAppNotifications();
  const [viewState, setViewState] = React.useState<{ promptDialogVisible: boolean, infoDialogVisible: boolean }>(
    {
      promptDialogVisible: false,
      infoDialogVisible: false,
    }
  );

  const handleSubmit = (dataItem: any) => {
    props.onSave(dataItem);
  };

  const onDeleteDepartment = () => {
    apiClient({
      method: "DELETE",
      url: `${DEPARTMENTS_DELETE(props.department.id)}`,
      data: {},
    }).then(async (resp) => {
      setViewState({ ...viewState, promptDialogVisible: false })
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        notifications.showNotification("Dział został usunięty", NotificationEnum.Success);
        props.onCancel()
      }
    });
  };

  const onDeleteButtonClick = () => {
    if (props.department.items.length > 0)
      setViewState({ ...viewState, infoDialogVisible: true })
    else
      setViewState({ ...viewState, promptDialogVisible: true })

  }

  const managersList = props.users.filter((user) => { return user.canBeManager == true; }).map((user) => { return { id: user.id, name: `${user.lastName} ${user.firstName}` } });

  return (
    <>
      <div className="belowToolbarWrapper">

        {viewState.promptDialogVisible === true && (
          <PromptDialog
            text={`<p>Czy na pewno chcesz usunąć <b>"${props.department.text}"</b> ?</p>` +
              `Operacja ta spowoduje także usunięcie wszystkich danych powiązanych z działem, tzn. informacje o przypisanych pracownikach, kierownikach itp.` +
              `<br/>Operacja ta jest nieodwracalna.`}
            onYes={() => { onDeleteDepartment(); }}
            onNo={() => { setViewState({ ...viewState, promptDialogVisible: false }) }}
          />
        )}

        {viewState.infoDialogVisible === true && (
          <InfoDialog
            text={`<p>Usunięcie działu <b>"${props.department.text}"</b> nie jest możliwe ponieważ zawiera on przypisane działy zależne.</p>` +
              `Przed usunięciem działu nadrzętnego należy najpierw usunąć wszystkie działy przypisane do niego.`}
            onClose={() => { setViewState({ ...viewState, infoDialogVisible: false }) }}
          />
        )}

        <Form
          onSubmit={handleSubmit}
          initialValues={{
            id: props?.department?.id,
            name: props?.department?.text,
            description: props?.department?.description,
            manager: props?.department?.managers != null ? managersList?.filter((m) => m.id === props?.department?.managers[0])[0] : {},
            assistantManager: props?.department?.assistantManagers != null ? managersList?.filter((m) => m.id === props?.department?.assistantManagers[0])[0] : {},
          }}
          render={(formRenderProps) => (
            <>
              <div className="mainToolbar">
                <div className="itemHeader">
                  {props.department.id !== undefined ? <>Edycja: {props?.department?.text}</> : <>Tworzenie nowego działu</>}
                  {isGroupOnly && <div>&nbsp;(dział grupujący)</div>}
                </div>

                <Toolbar>
                  <ToolbarItem>
                    <FormElement>
                      <Button
                        fillMode="outline"
                        icon="save"
                        id="submit"
                        type={"submit"}
                        className="k-button"
                        disabled={!formRenderProps.allowSubmit}
                      >
                        Zapisz
                      </Button>
                    </FormElement>
                  </ToolbarItem>

                  <ToolbarItem>
                    <Button
                      icon="close"
                      fillMode="outline"
                      onClick={props.onCancel}
                    >
                      Anuluj
                    </Button>
                  </ToolbarItem>

                  <ToolbarSeparator />

                  {props.department.id !== undefined &&
                    <>
                      <ToolbarItem >
                        <Button
                          icon="user"
                          fillMode="outline"
                          onClick={props.onEmployeesManage}
                        >
                          Zarządzanie pracownikami działu
                        </Button>
                      </ToolbarItem>

                      <ToolbarSeparator />

                      <ToolbarItem>
                        <Button
                          icon="trash"
                          themeColor="error"
                          fillMode="outline"
                          onClick={onDeleteButtonClick}>
                          Usuń dział
                        </Button>
                      </ToolbarItem>
                    </>
                  }


                </Toolbar>
              </div>

              <FormElement
                style={{
                  width: 400,
                }}
              >
                <Field
                  id={"name"}
                  name={"name"}
                  autoComplete="off"
                  label={"Nazwa działu"}
                  component={FormInput}
                  hint={"(np. Dział marketingu)"}
                  validator={depNameValidator}
                />

                <Field
                  id={"description"}
                  name={"description"}
                  label={"Opis"}
                  component={FormTextArea}
                  hint={
                    "(pole opcjonalne; np. Pracownicy działu marketingu, bud. 4, p. II)"
                  }
                />

                <Field
                  id={"manager"}
                  name={"manager"}
                  label={"Kierownik (osoba akceptująca wnioski w dziale)"}
                  component={FormComboBox}
                  textField={"name"}
                  data={managersList}
                  hint={
                    "(akceptuje wnioski i zarządza składem zespołu w dziale; na liście pojawiają się nazwiska osób z typem konta \"kierownik\")"
                  }
                  // validator={requiredValidator}
                  dataItemKey="id"
                  valueMap={(item: any) => item && item.id}
                />

                <Field
                  id={"assistantManager"}
                  name={"assistantManager"}
                  label={"Zastępca (druga osoba akceptująca wnioski w dziale)"}
                  component={FormComboBox}
                  textField={"name"}
                  data={managersList}
                  hint={
                    "(wybór opcjonalny; akceptuje wnioski i zarządza składem zespołu w dziale)"
                  }
                  dataItemKey="id"
                  valueMap={(item: any) => item && item.id}
                />
              </FormElement>
            </>
          )}
        />
      </div>
    </>);
};

export default DepartmentsEdit;
