export const getCurrentMonthBounds = (date: Date): { start: string, end: string } => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const formatLocalDate = (d: Date) => {
        return d.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2');
    }

    return {
        start: formatLocalDate(firstDayOfMonth),
        end: formatLocalDate(lastDayOfMonth)
    };
}
