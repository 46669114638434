import DropdownItemRequest from "../../Dropdown/DropdownItemRequest";
import StorageFile from "../../Storage/StorageFile";

export default class AddContractRequest {
    userId: string;
    contractType: DropdownItemRequest;
    contractPeriod: DropdownItemRequest;
    signDate: Date;
    startDate: Date;
    endDate: Date;
    note: string;
    files: StorageFile[];
}