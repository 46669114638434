import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import useRoutesBreadcrumb from '../helpers/routesBreadcrumb';
import BreadcrumbItem from '../models/local/Breadcrumb/BreadcrumbItem';
import { AppContext, IAppContext } from './AppContext';

const useAppBreadcrumb = () => {
  const location = useLocation();
  const routesBredcrumb = useRoutesBreadcrumb();
  const defItems: BreadcrumbItem[] = [
    {
      id: "0",
      text: "Pulpit",
      url: "/",
      path: "/",
      disabled: true,
      iconClass: "k-i-grid",
    },
  ];
  const context = useContext<IAppContext>(AppContext);

  const isNumber = (value: string | number): boolean => {
    return value != null && value !== "" && !isNaN(Number(value.toString()));
  };

  const refresh = () => {
    let items = context.values.breadcrumbs || [];
    items.length = 0;

    let segments = (location.pathname.split("#").pop() || "").split("/");
    let segment = "";
    let path = "";
    for (let index = 0; index < segments.length; index++) {
      let part = segments[index];
      if (part) {
        let currentPath = location.pathname.split("#").pop();
        // for guid/id values
        if (part.indexOf("-") > -1 || isNumber(part)) {
          segment += "/" + part;
          path += "/:id";
          let breadCrumbItem = routesBredcrumb.getItem(path);
          if (breadCrumbItem != null && breadCrumbItem.name) {
            if (breadCrumbItem.name !== items[items.length - 1].text) {
              items.push({
                id: index.toString(),
                text: breadCrumbItem.name,
                url: segment,
                path: path,
                disabled: currentPath === segment,
                iconClass: breadCrumbItem.icon,
              });
            } else {
              items[items.length - 1].path = path;
              items[items.length - 1].url = segment;
              items[items.length - 1].text = breadCrumbItem.name;
              items[items.length - 1].disabled = currentPath === segment;
              items[items.length - 1].iconClass = breadCrumbItem.icon;
            }
          }
        } else {
          segment += "/" + part;
          path += "/" + part;
          let breadCrumbItem = routesBredcrumb.getItem(path);
          if (breadCrumbItem != null && breadCrumbItem.name) {
            items.push({
              id: index.toString(),
              text: breadCrumbItem.name,
              url: segment,
              path: path,
              disabled: currentPath == segment,
              iconClass: breadCrumbItem.icon,
            });
          }
        }
      }
    }
    context.setValues({ ...context.values, breadcrumbs: items });
  };

  const setMenu = (items: BreadcrumbItem[]) => {
    context.setValues({ ...context.values, breadcrumbs: items });
  };

  const getData = (): BreadcrumbItem[] => {
    if (!context.values.breadcrumbs || context.values.breadcrumbs.length === 0) {
      //setMenu(defItems);
      return defItems;
    }
    return context.values.breadcrumbs;
  };

  return { getData, refresh, setMenu };
};

export default useAppBreadcrumb;
