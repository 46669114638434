import DropdownItemRequest from "../../Dropdown/DropdownItemRequest";
import StorageFile from "../../Storage/StorageFile";

export default class AddNotificationRequest {
    userId: string;
    notificationType: DropdownItemRequest;
    date: Date;
    validityDate?: Date;
    note: string;
    notifyHR: boolean;
    notifyEmployee: boolean;
    files: StorageFile[];
}