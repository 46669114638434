import React from 'react';

import { Field, Form, FormElement } from '@progress/kendo-react-form';

import Calendar from '../../../models/local/Calendars/Calendar';
import { requiredMinLength6Validator } from '../../../validators/CommonValidators';
import EditToolbar from '../../Common/EditToolbar';
import { FormCheckbox, FormInput, FormTextArea } from '../../Kendo/form-components';

const AddEditCalendar = (props: {
  data: Calendar | null,
  onSave: (entity: Calendar) => void,
  onCancel: () => void,
}) => {

  const handleSubmit = (dataItem: any) => {
    props.onSave(dataItem);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          id: props?.data?.id,
          name: props?.data?.name,
          description: props?.data?.description,
          default: props?.data?.default
        }}
        render={(formProps) => (
          <>
            <p>
              {props.data === null ? "Tworzenie nowego kalendarza dni wolnych" : "Edycja danych kalendarza dni wolnych"}
            </p>

            <p>
              <EditToolbar onCancelClick={props.onCancel} ></EditToolbar>
            </p>

            <FormElement
              style={{
                width: 400,
              }}
            >
              <Field
                id={"name"}
                name={"name"}
                autoComplete="off"
                label={"Nazwa kalendarza"}
                component={FormInput}
                hint={"(np. Kalendarz dla pracujących w sobotę)"}
                validator={requiredMinLength6Validator}
              />

              <Field
                id={"description"}
                name={"description"}
                label={"Opis"}
                component={FormTextArea}
                hint={"(pole opcjonalne)"}
              />

              <Field
                id={"default"}
                name={"default"}
                label={"Ustaw jako domyślny"}
                component={FormCheckbox}
                hint={"(domyślny kalendarz automatycznie przypisywany jest nowym pracownikom)"}
              />
            </FormElement>
          </>
        )
        }
      />
    </>
  );
};
export default AddEditCalendar;
