import * as React from 'react';

import { Button } from '@progress/kendo-react-buttons';
import { Field, FormElement } from '@progress/kendo-react-form';
import { Upload, UploadFileStatus, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';

import { baseUrl } from '../../../../../api/apiClient';
import {
  STORAGE_GET_avatarBase64, STORAGE_REMOVE, STORAGE_UPLOAD_avatar
} from '../../../../../api/apiEndpoints';
import userAvatar from '../../../../../assets/avatar.png';
import useUploadUtils from '../../../../../helpers/uploadUtils';
import StorageFile from '../../../../../models/dto/Storage/StorageFile';
import {
  emailValidator, lastNameValidator, nameValidator
} from '../../../../../validators/CommonValidators';
import ImgWithJWTToken from '../../../../Common/ImgWithJWTToken';
import { FormDatePicker, FormInput } from '../../../../Kendo/form-components';

const PersonalDataTab = (props: { data: any, onChange: (avatarId?: string) => void }) => {
  const [avatarId, setAvatarId] = React.useState<string | null>();
  const uploadUtils = useUploadUtils();

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    if (event.newState.find(x => x.status === UploadFileStatus.Uploaded) != null) {
      let data = event?.response?.response?.result as StorageFile[];
      if (data != null && data.length > 0) {
        data.forEach(f => {
          setAvatarId(f.fileId);
        });
      }
    }
  }

  // intialize current avatar
  React.useEffect(() => {
    setAvatarId(props.data.avatarId);
  }, []);

  React.useEffect(() => {
    props.onChange(avatarId!);
  }, [avatarId]);

  return (
    <div>
      <FormElement
        style={{
          width: 400,
        }}
      >
        <Field
          id={"email"}
          name={"email"}
          autoComplete="off"
          label={"Adres e-mail"}
          hint={"(adres ten wykorzystywany jest do logowania w systemie)"}
          component={FormInput}
          validator={emailValidator}
          markRequired={true}
        />

        <Field
          id={"firstName"}
          name={"firstName"}
          autoComplete="off"
          label={"Imię"}
          component={FormInput}
          validator={nameValidator}
          markRequired={true}
        />

        <Field
          id={"lastName"}
          name={"lastName"}
          autoComplete="off"
          label={"Nazwisko"}
          component={FormInput}
          validator={lastNameValidator}
          markRequired={true}
        />

        <Field
          id={"uniqueId"}
          name={"uniqueId"}
          autoComplete="off"
          label={"Unikatowy numer ewidencyjny"}
          hint={"(wewnętrzny identyfikator pracownika, niewymagany)"}
          component={FormInput}
        />

        {avatarId && //only for existings
          <div className="img-wrap">
            <ImgWithJWTToken src={baseUrl + STORAGE_GET_avatarBase64(avatarId)} className="avatar-preview" alt="User avatar" width="120px" height="auto" base64={true} />
            <Button
              fillMode="outline"
              icon="delete"
              id="avatarDelete"
              title="Usuń zdjęcie"
              type={"button"}
              className="k-button close"
              onClick={(e: any) => {
                props.onChange('');
                setAvatarId(null);
              }}
            >
            </Button>
          </div>
        }

        {!avatarId && //only for empty
          <img src={userAvatar} alt="User avatar" width="120px" height="auto" />
        }

        <Upload
          autoUpload={true}
          multiple={false}
          defaultFiles={[]}
          restrictions={{
            allowedExtensions: [".jpg", ".png", ".jpeg"],
            maxFileSize: 4000000,
          }}
          withCredentials={false}
          saveUrl={baseUrl + STORAGE_UPLOAD_avatar}
          removeUrl={baseUrl + STORAGE_REMOVE}
          saveHeaders={uploadUtils.setHeaders()}
          removeHeaders={uploadUtils.setHeaders()}
          showFileList={true}
          saveField="file"
          onAdd={uploadUtils.showOnAdd}
          onStatusChange={onStatusChange}
        />

        <Field
          id={"birthDate"}
          name={"birthDate"}
          autoComplete="off"
          label={"Data urodzenia"}
          component={FormDatePicker}
        />

        <Field
          id={"phoneNumber"}
          name={"phoneNumber"}
          autoComplete="off"
          label={"Numer telefonu"}
          component={FormInput}
        />
      </FormElement>
    </div>
  );
};

export default PersonalDataTab;
