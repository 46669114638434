import * as React from "react";

import {
  DictTypes,
  CellDictToName,
} from "../../../../../../scripts/dictProvider";

import PersonalFilesToolbar from "../PersonalFilesToolbar";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import MedicalDto from "../../../../../../models/dto/Employees/Tabs/MedicalDto";

const MedicalTab = (props: any) => {
  return (
    <>
      <PersonalFilesToolbar
        onNewItem={() => {
          props.onNew();
        }}
      />
      <Grid style={{ height: "100%" }} data={props.entities as MedicalDto[]}>
        <Column field="date" title="Data wykonania" format="{0:d}" />
        <Column
          field="medicalTestingType"
          title="Rodzaj badania"
          cell={(v: any) => CellDictToName(v, DictTypes.MedicalTestingTypes)}
        />
        <Column field="validityDate" title="Ważne do" format="{0:d}" />
        <Column field="note" title="Uwagi" />
        <Column
          title="Operacje"
          width="100px"
          cell={(c: any) => (
            <td>
              <Button
                fillMode="outline"
                icon="edit"

                onClick={() => {
                  props.onEdit(c.dataItem.id);
                }}
              ></Button>{" "}
              <Button
                fillMode="outline"
                icon="trash"
                onClick={() => {
                  props.onDelete(c.dataItem.id);
                }}
              ></Button>
            </td>
          )}
        />
      </Grid>
    </>
  );
};

export default MedicalTab;
