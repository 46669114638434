import { Fade } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PromoBanner from "../components/LoginRegister/PromoBanner";
import { apiClient } from "../api/apiClient";
import { USER_POST_useActivationCode } from "../api/apiEndpoints";
import moduleStyle from "../styles/LoginRegister.module.scss";
import useSessionWrapper from "../services/SessionWrapper";

const ActivateAccount = (props) => {
  const navigate = useNavigate();
  const { activationCode } = useParams();
  const sessionWrapper = useSessionWrapper();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccessNotificationVisible, setIsSuccessNotificationVisible] =
    useState(false);

  useEffect(() => {
    localStorage.clear();
    setTimeout(handleSubmit, 1000);
  }, []);

  const handleSubmit = async () => {
    clearTimeout(handleSubmit);

    apiClient({
      method: "POST",
      url: USER_POST_useActivationCode,
      data: {
        ActivationCode: activationCode,
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        setErrorMessage(resp.data.errors);
        return;
      }

      setIsSuccessNotificationVisible(true);
    });
  };

  return (
    <>


      <div className={moduleStyle.pageWrapper}>


        {errorMessage !== null && (
          <p>
            <NotificationGroup >
              <Notification
                key="error"
                closable={false}
                onClose={() => setErrorMessage(null)}
                type={{
                  style: "error",
                }}
              >
                Aktywacja nie powiodła się: {errorMessage}
              </Notification>
            </NotificationGroup>
          </p>
        )}

        <div className={moduleStyle.formColumn}>
          <p style={{ width: "400px", textAlign: "justify" }}>
            {errorMessage !== null && (
              <Fade>
                <p>
                  Wystąpił błąd podczas aktywacji konta. Możliwe, że konto zostało
                  już wcześniej aktywowane. Spróbuj zalogować się podając
                  prawidłowy login oraz hasło.
                  <p>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/");
                      }}
                    >
                      Przejdź do strony logowania
                    </Button>
                  </p>
                </p>
              </Fade>
            )}

            {!isSuccessNotificationVisible && !errorMessage && (
              <>
                <p>
                  Jeszcze chwila...
                  <br />
                  Aktywujemy Twoje konto.
                </p>
                <Loader type="infinite-spinner" />
              </>
            )}
            {isSuccessNotificationVisible && (
              <>
                <p
                  className={moduleStyle.loginFormHeader}
                  style={{ textAlign: "left" }}
                >
                  Dziękujemy!
                </p>
                <b>
                  Twoje konto zostało aktywowane. Kliknij w poniższy przycisk, aby ustawić hasło dostępu do swojego konta.
                </b>
                <br />
                <p>
                  {/* <Button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    Przejdź do strony logowania
                  </Button>
                  <br /> 
                  &nbsp;lub ustaw nowe hasło&nbsp; */}
                  <br />
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/resetPasswordRequest");
                    }}
                  >
                    Ustaw hasło
                  </Button>
                </p>
              </>
            )}
          </p>
        </div>
        <PromoBanner />
      </div>
    </>
  );
};

export default ActivateAccount;
