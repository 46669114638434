

export default class DepartmentUser {
  id: string;

  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  registrationNo: string;
  canBeManager: boolean;

  supervisor: string;
  supervisorId: string;
}