import '../../../styles/_dashboard.scss';

import React, { useContext, useEffect } from 'react';

import { apiClient } from '../../../api/apiClient';
import { CLIENTLICENCE_GET } from '../../../api/apiEndpoints';
import ClientLicenceDto from '../../../models/dto/Licence/ClientLicenceDto';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import UserRoleEnum from '../../../models/enums/UserRoleEnum';
import ClientLicence from '../../../models/local/Licences/ClientLicence';
import { DateToDateAsString, mapper } from '../../../models/mapper';
import { AppContext, IAppContext } from '../../../services/AppContext';
import useAppNotifications from '../../../services/AppNotifications';

const Licence = (props: {}) => {
  const notifications = useAppNotifications();
  const context = useContext<IAppContext>(AppContext);

  const [viewState, setViewState] = React.useState<{
    isLoading: boolean;
    licenceData: ClientLicence | undefined;
  }>({
    isLoading: true,
    licenceData: new ClientLicence(),
  });

  const getClientLicence = async () => {
    const resp = await apiClient({
      method: "GET",
      url: CLIENTLICENCE_GET,
      data: {},
    });

    if (resp.status !== 200) {
      notifications.showNotification(resp.data.errors, NotificationEnum.Error);
      return;
    }

    const result: ClientLicenceDto = resp.data.result as ClientLicenceDto;
    const mappedResult: ClientLicence = mapper.map(result, ClientLicence, ClientLicenceDto);

    return mappedResult;
  };

  useEffect(() => {
    Promise.all([getClientLicence()]).then(([resLicence]) => {
      setViewState({ ...viewState, isLoading: false, licenceData: resLicence });
    });
  }, []);

  return (
    <div id="contactWrapper" className="singleContactBrick brickframe">
      <img src="https://cdn-icons-png.flaticon.com/512/9080/9080464.png" className="bcgrImg" alt="Contact us" />
      <b>Informacje o licencji</b>
      <br />
      <br />
      {viewState.isLoading === false && (
        <>
          <div className={!viewState.licenceData?.isValid ? " errorText" : ""}>{viewState.licenceData?.isValid ? `Licencja na produkt aktywna.` : `Licencja wygasła z dniem ${viewState.licenceData?.validTo.toLocaleDateString()}`}</div>
          <span>Właściciel: {viewState.licenceData!.officeName}</span>
          <br />
          <br />
          {context.values.role === UserRoleEnum.HR &&
            (
              <>
                <span>
                  Obowiązuje od {DateToDateAsString(viewState.licenceData!.validFrom)} do {DateToDateAsString(viewState.licenceData!.validTo)}
                </span>
                <br />
                <span>Limit pracowników: {viewState.licenceData!.workersLimit}</span>
              </>
            )}
        </>
      )}
    </div>
  );
};

export default Licence;
