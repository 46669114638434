import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import { apiClient } from '../api/apiClient';
import { LEAVE_GET_getUserLeaveStats, LIMITS_GET_allForUser } from '../api/apiEndpoints';
import UserLimits from '../components/Leaves/UserLimits';
import UserLeaveStatsResponseDto from '../models/dto/Leaves/Responses/UserLeaveStatsResponseDto';
import AllForUserReponseDto from '../models/dto/Limits/Responses/AllForUserReponseDto';
import NotificationEnum from '../models/enums/NotificationEnum';
import UserLeaveStats from '../models/local/Leaves/UserLeaveStats';
import AllUserLimits from '../models/local/Limits/AllUserLimits';
import { mapper } from '../models/mapper';
import { AppContext, IAppContext } from '../services/AppContext';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';

const UserLimitsView = () => {
  const [selected, setSelected] = React.useState(0);
  const context = useContext<IAppContext>(AppContext);
  const navigate = useNavigate();
  const notifications = useAppNotifications();

  const [userId, setUserId] = React.useState<string>();

  const [userLimits, setUserLimits] = React.useState<AllUserLimits>(new AllUserLimits());
  const [selectedYear, setSelectedYear] = React.useState<number>(new Date().getFullYear());
  const [userLeaveStats, setUserLeaveStats] = React.useState<UserLeaveStats>(new UserLeaveStats());

  const loader = useAppLoader(true);

  const getUserLimitsData = async (year: number) => {
    const resp = await apiClient({
      method: "GET",
      url: LIMITS_GET_allForUser(userId!, year),
      data: {},
    });

    if (resp.status !== 200) {
      notifications.showNotification(resp.data.errors, NotificationEnum.Error);
      return;
    }

    const result: AllForUserReponseDto = resp.data.result as AllForUserReponseDto;
    const mappedResult: AllUserLimits = mapper.map(result, AllUserLimits, AllForUserReponseDto);

    setUserLimits(mappedResult);
  };

  const getUserLeaveStats = async (year: number) => {
    const resp = await apiClient({
      method: "GET",
      url: LEAVE_GET_getUserLeaveStats(userId!, year),
      data: {},
    });

    if (resp.status !== 200) {
      notifications.showNotification(resp.data.errors, NotificationEnum.Error);
      return;
    }

    const result: UserLeaveStatsResponseDto = resp.data.result as UserLeaveStatsResponseDto;

    const mappedResult: UserLeaveStats = mapper.map(result, UserLeaveStats, UserLeaveStatsResponseDto);

    setUserLeaveStats(mappedResult);
  };

  const refreshUserLimits = async (year: number) => {
    await getUserLimitsData(year);
    await setSelectedYear(year);
    await getUserLeaveStats(year);

    loader.showLoading(false);
  };

  useEffect(() => {
    setUserId(context.values.userId);
  }, []);

  useEffect(() => {
    if (userId) refreshUserLimits(selectedYear);
  }, [userId]);

  return (
    <>
      {!loader.isLoading() &&
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn" id="leaveList">
            <TabStrip selected={selected} onSelect={(e) => setSelected(e.selected)}>
              <TabStripTab title="Wykorzystanie Twojego limitu urlopowego" >
                <UserLimits userLimits={userLimits} year={selectedYear} userLeaveStats={userLeaveStats} onSetYear={refreshUserLimits} yearReadonly={false}></UserLimits>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      }
    </>
  );
};

export default UserLimitsView;
