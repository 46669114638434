import React, { useEffect, useState } from 'react';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';

import CalendarItem from '../../../models/local/Calendars/CalendarItem';
import { requiredMinLength6Validator } from '../../../validators/CommonValidators';
import { FormDateInput, FormInput, FormTextArea } from '../../Kendo/form-components';

const AddEditCalendarItemDialog = (props: {
  onAdd: (entity: CalendarItem) => void
  onCancel: () => void
  date: Date
}) => {
  const [calendarItem, setCalendarItem] = useState<CalendarItem>(new CalendarItem());

  const submitForm = (form: any) => {
    props.onAdd(form as CalendarItem);
  }

  useEffect(() => {
    setCalendarItem({ ...calendarItem, date: props.date })
  }, []);


  return (
    <Dialog title={"Oznaczanie dnia wolnego"} onClose={props.onCancel}>
      <div style={{ margin: "10px", textAlign: "left" }}>

        {calendarItem?.date != null && (
          <Form
            onSubmit={submitForm}
            initialValues={calendarItem}
            render={(formRenderProps: FormRenderProps) => (

              <FormElement
                style={{
                  maxWidth: 650,
                }}
              >

                <div className="k-form">
                  <Field
                    id={"date"}
                    name={"date"}
                    label={"Data"}
                    component={FormDateInput}
                    disabled={true}
                  />

                  <Field
                    id={"name"}
                    name={"name"}
                    autoComplete="off"
                    label={"Nazwa"}
                    hint={"(krótka nazwa opisująca dzień wolny)"}
                    component={FormInput}
                    validator={requiredMinLength6Validator}
                  />

                  <Field
                    id={"description"}
                    name={"description"}
                    label={"Opis"}
                    component={FormTextArea}
                    hint={
                      "(pole opcjonalne)"
                    }
                  />

                  <div style={{ marginTop: "50px" }}></div>

                  <DialogActionsBar >
                    <button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      type={"submit"}
                    //onClick={() => { props.onAdd(calendarItem) }}
                    >
                      Zapisz
                    </button>

                    <button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={props.onCancel}
                    >
                      Anuluj
                    </button>

                  </DialogActionsBar>
                </div>
              </FormElement>
            )}
          />
        )}

      </div>


    </Dialog>
  );
};

export default AddEditCalendarItemDialog;
