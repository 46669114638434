export default class GetContractsRequest {
    ContractTypeId: string;

    SignDateFrom: Date;
    SignDateTo: Date;
    StartDateFrom: Date;
    StartDateTo: Date;
    EndDateFrom: Date;
    EndDateTo: Date;

    Firstname: string;
    Lastname: string;
}
