import StorageFile from '../../dto/Storage/StorageFile';
import IsNewBaseMarker from './IsNewBaseMarker';

export default class Training extends IsNewBaseMarker {
    id: string;
    trainingType: string;
    date: Date;
    validityDate: Date;
    note: string;
    files: StorageFile[];
}
