import React from "react";
import LegendItem from "../../models/local/Calendars/LegendItem"

const Legend = (props: {
    items: LegendItem[]
  }) => { 
    return (
        <>
            <ul className="legend">
                {props.items.map((value, index) => {
                     return <li><span style={{ backgroundColor: value.color}}></span>{value.text}</li>
                })}
            </ul>
        </>
    )

  }
  
  export default Legend;