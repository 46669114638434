import '../styles/_dashboard.scss';

import React, { useEffect } from 'react';

import { apiClient } from '../api/apiClient';
import { CLIENTLICENCE_GET, DASHBOARD_GET } from '../api/apiEndpoints';
import AwaitingLeaves from '../components/Dashboard/Bricks/DailyNews/AwaitingLeaves';
import DailyNews from '../components/Dashboard/Bricks/DailyNews/DailyNews';
import LicenceWaring from '../components/Dashboard/Bricks/DailyNews/LicenceWaring';
import Onboarding from '../components/Dashboard/Bricks/DailyNews/Onboarding';
import SystemNews from '../components/Dashboard/Bricks/DailyNews/SystemNews';
import YourLeaves from '../components/Dashboard/Bricks/DailyNews/YourLeaves';
import YourTeamLeaves from '../components/Dashboard/Bricks/DailyNews/YourTeamLeaves';
import Quote from '../components/Dashboard2/Quote';
import Contact from '../components/Dashboard2/TextInfoBlocks/Contact';
import Help from '../components/Dashboard2/TextInfoBlocks/Help';
import Licence from '../components/Dashboard2/TextInfoBlocks/Licence';
import UserInfo from '../components/Dashboard2/UserInfo';
import DashboardDataResponseDto from '../models/dto/Dashboard/Responses/DashboardDataResponseDto';
import NotificationEnum from '../models/enums/NotificationEnum';
import DashboardData from '../models/local/Dashboard/DashboardData';
import { mapper } from '../models/mapper';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';

const Dashboard = () => {
  const notifications = useAppNotifications();
  const loader = useAppLoader(true);
  const [dashboardData, setDashboardData] = React.useState<DashboardData>();

  const getDashboardData = async () => {
    try {
      loader.showLoading(true);

      const resp = await apiClient({
        method: "GET",
        url: DASHBOARD_GET,
        data: {},
      });

      const mappedResult: DashboardData = mapper.map(resp.data.result, DashboardData, DashboardDataResponseDto);

      setDashboardData(mappedResult);
    } catch (error: any) {
      notifications.showNotification(error.message, NotificationEnum.Error);
      console.log(error);
    } finally {
      loader.showLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    // <div>
    //   <img src="https://i.postimg.cc/MpYMh9hX/screen.png" alt="screen" style={{ width: "95%", height: "95%", padding: "15px" }} />
    // </div>

    <>
      {!loader.isLoading() && (
        <div className="dashboardContent">
          <Onboarding></Onboarding>

          <div className="col colLeft" style={{ minWidth: "470px" }} >
            <div className="row" style={{ minHeight: "280px", height: "280px" }} >
              <UserInfo data={dashboardData!.todayInformation}></UserInfo>
            </div>
            <div className="row">
              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>   {/*className="brickframe" */}
                <div style={{ display: "flex", color: "#000", width: "100%" }}>  {/* padding: "15px", border: "1px dotted red" */}
                </div>
              </div>
            </div>
            <div className="row" style={{ height: "auto" }}>
              <Quote data={dashboardData!.todayInformation}></Quote>
            </div>
          </div>
          <div className="col" style={{ minWidth: "450px" }}>
            <div className="row" style={{ minHeight: "300px" }}>
              <div className={"brickframe"} style={{ width: "100%" }}>
                <iframe src="https://synerdi.pl/inappblog/" title="Aktualności" style={{ border: "none", width: "100%", height: "100%" }} className='iframe-placeholder' />
              </div>
            </div>
            <div className="row" style={{ maxHeight: "250px" }}>
              <div className="col">
                <Licence></Licence>
              </div>
              <div className="col">
                <Help></Help>
              </div>
              <div className="col">
                <Contact></Contact>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
