
import StorageFile from '../../dto/Storage/StorageFile';
import IsNewBaseMarker from './IsNewBaseMarker';

export default class Resource extends IsNewBaseMarker {
    id: string;
    resourceType: string;
    date: Date;
    returnUntilDate: Date;
    returnDate?: Date;
    isReturned: boolean;
    note: string;
    files: StorageFile[];
}

