import DropdownItemRequest from "../../Dropdown/DropdownItemRequest";
import StorageFile from "../../Storage/StorageFile";

export default class AddWorkexperienceRequest {
    userId: string;
    name: string;
    date: Date;
    validityDate?: Date;
    note: string;
    files: StorageFile[];
}