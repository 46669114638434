import React, { useContext, useState } from 'react';

import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { Upload, UploadFileStatus, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';

import { apiClient, baseUrl } from '../api/apiClient';
import {
  PROFILE_GET_info, PROFILE_POST_avatar, PROFILE_POST_password, STORAGE_GET_avatarBase64,
  STORAGE_REMOVE, STORAGE_UPLOAD_avatar
} from '../api/apiEndpoints';
import userAvatar from '../assets/avatar.png';
import EditToolbar from '../components/Common/EditToolbar';
import ErrorMessage from '../components/Common/ErrorMessage';
import ImgWithJWTToken from '../components/Common/ImgWithJWTToken';
import { FormInput } from '../components/Kendo/form-components';
import useUploadUtils from '../helpers/uploadUtils';
import GetProfileUserInfo from '../models/dto/Profile/GetProfileUserInfo';
import StorageFile from '../models/dto/Storage/StorageFile';
import NotificationEnum from '../models/enums/NotificationEnum';
import { AppContext, IAppContext } from '../services/AppContext';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';
import { requiredValidator } from '../validators/CommonValidators';
import Loading from './Loading';

const MyProfile = () => {
  const context = useContext<IAppContext>(AppContext);
  const notifications = useAppNotifications();
  const uploadUtils = useUploadUtils();
  const loader = useAppLoader(true);

  const [tempAvatarId, setTempAvatarId] = React.useState<string | null>();
  const [selectedTabIdx, setSelectedTabIdx] = React.useState(0);
  const [resultData, setResultData] = React.useState<GetProfileUserInfo>();

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelectedTabIdx(e.selected);
  };

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    if (event.newState.find(x => x.status === UploadFileStatus.Uploaded) != null) {
      let data = event?.response?.response?.result as StorageFile[];
      if (data != null && data.length > 0) {
        data.forEach(f => {
          setTempAvatarId(f.fileId);
        });
      }
    }
  }

  const handleSubmit = async (myProfileForm: any) => {
    apiClient({
      method: "POST",
      url: PROFILE_POST_password,
      data: {
        oldPassword: myProfileForm.oldPassword,
        newPassword: myProfileForm.newPassword,
        repeatedNewPassword: myProfileForm.repeatedNewPassword,
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      notifications.showNotification("Zmiany zostały zapisane", NotificationEnum.Success);
    });
  };

  const handleAvatarSubmit = async () => {
    apiClient({
      method: "POST",
      url: PROFILE_POST_avatar,
      data: {
        avatarId: tempAvatarId
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }
      notifications.showNotification("Dane zostały poprawnie zapisane.", NotificationEnum.Success);
      getMyProfileData();
    });
  };

  const getMyProfileData = () => {
    loader.showLoading(true);

    apiClient({
      method: "GET",
      url: PROFILE_GET_info,
    }).then(async (resp) => {
      loader.showLoading(false);

      if (resp.status === 200) {
        const result = resp?.data?.result as GetProfileUserInfo;
        setResultData(result);
        setTempAvatarId(result.avatarId);
        context.setValues({ ...context.values, avatarId: result.avatarId })
      } else {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }
    });
  };

  React.useEffect(() => {
  }, [context]);

  React.useEffect(() => {
    getMyProfileData();
  }, []);

  return (
    <>
      <div className="fullPageContentWrapper">
        <div className="fullWidthColumn">
          <TabStrip selected={selectedTabIdx} onSelect={handleSelect}>
            {/* TAB1 */}
            <TabStripTab title="Twoje dane">
              <EditToolbar onSubmitClick={(handleAvatarSubmit)}></EditToolbar>

              <div className="k-form" style={{ width: "400px", marginTop: "15px" }}>
                <div className="k-form-field">
                  <Label>Adres e-mail (login)</Label>
                  <Input
                    id={"email"}
                    name={"email"}
                    value={resultData?.email}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
                <br />
                <div className="k-form-field">
                  <Label>Imię</Label>
                  <Input
                    id={"firstName"}
                    name={"firstName"}
                    value={resultData?.firstName}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
                <div className="k-form-field">
                  <Label>Nazwisko</Label>
                  <Input
                    id={"lastName"}
                    name={"lastName"}
                    value={resultData?.lastName}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
                <br />

                {tempAvatarId ? (
                  <div className="img-wrap">
                    <ImgWithJWTToken
                      src={baseUrl + STORAGE_GET_avatarBase64(tempAvatarId)}
                      className="avatar-preview"
                      alt="User avatar"
                      width="120px"
                      height="auto"
                      base64={true}
                    />
                    <Button
                      fillMode="outline"
                      icon="delete"
                      id="avatarDelete"
                      title="Usuń zdjęcie"
                      type="button"
                      className="k-button close"
                      onClick={(e: any) => {
                        setTempAvatarId(null);
                      }}
                    >
                      Usuń zdjęcie
                    </Button>
                  </div>
                ) : (
                  <div className="img-wrap">
                    <img src={userAvatar} alt="Zdjęcie pracownika" width="150px" height="auto" />
                  </div>
                )}

                <Upload
                  autoUpload={true}
                  multiple={false}
                  defaultFiles={[]}
                  restrictions={{
                    allowedExtensions: [".jpg", ".png", ".jpeg"],
                    maxFileSize: 1000000,
                  }}
                  withCredentials={false}
                  saveUrl={baseUrl + STORAGE_UPLOAD_avatar}
                  removeUrl={baseUrl + STORAGE_REMOVE}
                  saveHeaders={uploadUtils.setHeaders()}
                  removeHeaders={uploadUtils.setHeaders()}
                  showFileList={true}
                  saveField="file"
                  onAdd={uploadUtils.showOnAdd}
                  onStatusChange={onStatusChange}
                />

              </div>
            </TabStripTab>

            {/* TAB2 */}
            <TabStripTab title="Zmiana hasła">
              <Form onSubmit={handleSubmit}
                initialValues={{ email: "", password: "" }}
                render={() => (
                  <div style={{ width: "400px" }}>
                    <FormElement>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Field
                          id={"oldPassword"}
                          name={"oldPassword"}
                          autoComplete="Podaj aktualne hasło"
                          label={"Aktualne hasło"}
                          type="password"
                          component={FormInput}
                          validator={requiredValidator}
                        />
                        <Field
                          id={"newPassword"}
                          name={"newPassword"}
                          autoComplete="Podaj nowe hasło"
                          label={"Nowe hasło"}
                          type="password"
                          component={FormInput}
                          validator={requiredValidator}
                        />
                        <Field
                          id={"repeatedNewPassword"}
                          name={"repeatedNewPassword"}
                          autoComplete="Powtórz nowe hasło"
                          label={"Powtórz nowe hasło"}
                          type="password"
                          component={FormInput}
                          validator={requiredValidator}
                        />
                      </div>
                      <div style={{ display: "flex", marginTop: "30px" }}>
                        <div style={{ width: "50%" }}>

                        </div>
                        <div style={{ width: "50%", textAlign: "right" }}>
                          <Button type="submit">Zmień hasło</Button>
                        </div>
                      </div>
                    </FormElement>
                  </div>
                )}
              />
            </TabStripTab>
          </TabStrip>
        </div>
      </div>
    </>
  )
};

export default MyProfile;
