import {
  Button, Toolbar,
  ToolbarItem,
  ToolbarSeparator
} from "@progress/kendo-react-buttons";
import * as React from "react";

const EditToolbar = (props: any) => {

  return (
    <Toolbar>
      <ToolbarItem>
        <Button
          fillMode="outline"
          icon="save"
          id="submitButton"
          className="k-button"
          onClick={() => {
            props.onSave();
          }}
        >
          Zapisz
        </Button>
      </ToolbarItem>

      <ToolbarItem>
        <Button
          icon="close"
          fillMode="outline"
          onClick={() => {
            props.onCancel();
          }}
        >
          Anuluj
        </Button>
      </ToolbarItem>

      <ToolbarSeparator />
    </Toolbar>
  );
};

export default EditToolbar;
