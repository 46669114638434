import React, { useEffect } from 'react';

import {
  MonthView, Scheduler, SchedulerItem, SchedulerItemProps, SchedulerViewItem,
  SchedulerViewItemProps, SchedulerViewSlot, SchedulerViewSlotProps
} from '@progress/kendo-react-scheduler';
import {
  SchedulerModelFields
} from '@progress/kendo-react-scheduler/dist/npm/models/SchedulerModelFields';

import LeaveStatusEnum from '../../../models/enums/LeaveStatusEnum';
import MonthViewItem from '../../../models/local/Leaves/MonthViewItem';
import CalendarItemPreview from './CalendarItemPreview';

const modelFields: SchedulerModelFields = {
  id: "id",
  start: "startDate",
  end: "endDate",
  isAllDay: "isAllDay",
};

const CalendarMonthView = (props: {
  displayDate: Date,
  calendarItems: MonthViewItem[]
}) => {
  const [previewDialog, setPreviewDialog] = React.useState<{ item: MonthViewItem | undefined, visible: boolean }>({ item: undefined, visible: false });


  const CustomItem = (props: SchedulerItemProps) => {
    const dataItem = props.dataItem as MonthViewItem;

    return (
      <SchedulerItem
        {...props}
        style={{
          ...props.style,
          backgroundColor: props.dataItem.color,
          color: dataItem.status === LeaveStatusEnum.AWAITING ? "gray" : "black",
          background: dataItem.status === LeaveStatusEnum.AWAITING ?
            `repeating-linear-gradient(-45deg, rgba(0,0,0,0.05), rgba(0,0,0,0.05) 10px, rgba(0,0,0,0.1) 10px, rgba(0,0,0,0.1) 20px)` : props.dataItem.color
        }}
      >{`${dataItem.firstName[0]}.${dataItem.lastName}`}</SchedulerItem>
    );
  }

  const CustomViewItem = (props: SchedulerViewItemProps) => {
    return (
      <SchedulerViewItem
        {...props}
        onClick={() => { setPreviewDialog({ visible: true, item: props.dataItem as MonthViewItem }) }}
        style={{
          ...props.style,
          height: "auto",
        }}
      />
    );
  }

  const CustomViewSlot = (props: SchedulerViewSlotProps) => {
    return (
      <SchedulerViewSlot
        {...props}
        style={{
          ...props.style,
          minHeight: 120,
          border: props.start.toDateString() === (new Date()).toDateString() ? "1px solid #292351" : ""
        }}
      />
    )
  }

  useEffect(() => {
    console.log("CALENDAR DATA:", props.calendarItems);
  }, []);

  // const handleDateChange = useCallback(
  //   (event: SchedulerDateChangeEvent) => {
  //     setDisplayDate(event.value);
  //   },
  //   [displayDate]
  // );

  return (
    <>
      {previewDialog.visible && (<CalendarItemPreview item={previewDialog.item as MonthViewItem} onClose={() => { setPreviewDialog({ item: undefined, visible: false }) }} />)}

      <Scheduler
        item={CustomItem}
        viewItem={CustomViewItem}
        height="100%"
        style={{ padding: "15px", overflowX: "hidden" }}
        data={props.calendarItems}
        modelFields={modelFields}
        // onDateChange={handleDateChange}
        viewSlot={CustomViewSlot}
        date={props.displayDate}

        footer={(props) =>
          <React.Fragment />
        }
        header={(props) =>
          <React.Fragment />
        }
      >
        <MonthView
          title="Pokaż kalendarz"
          selectedDateFormat="{0:M}"
          selectedShortDateFormat="{0:M}"
          itemsPerSlot={50}
        />

      </Scheduler>

    </>
  );
};

export default CalendarMonthView;
