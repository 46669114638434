import React from 'react';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import DepartmentTreeItem from '../../models/local/Departments/DepartmentTreeItem';
import DepartmentUser from '../../models/local/Departments/DepartmentUser';

const localStyle = {
  description: {
    fontWeight: "400",
    fontSize: "0.9em",
    width: "100%",
    paddingBottom: "10px"
  },
}

const DepartmentsEmplList = (props: {
  employed: DepartmentUser[],
  department: DepartmentTreeItem,
  onEditDepartment: () => void,
  onNewDepartment: () => void,
}) => {

  const manager = props.employed.find(e => e.id === props.department.managers[0]);
  const assManager = props.employed.find(e => e.id === props.department.assistantManagers[0]);


  return (
    <>
      <div className="mainToolbar">
        <div className="itemHeader">
          Wybrany dział:&nbsp;<b>{props?.department.text}</b>
          <br />
          <div style={localStyle.description}>{props.department.description}</div>
        </div>

        <Toolbar>
          <ToolbarItem>
            <Button
              fillMode="outline"
              icon="page-properties"
              onClick={props.onEditDepartment}
            >
              Edytuj dane działu
            </Button>
          </ToolbarItem>

          <ToolbarSeparator />

          <ToolbarItem>
            <Button
              icon="plus"
              fillMode="outline"
              onClick={props.onNewDepartment}
            >
              Dodaj podległy dział
            </Button>
          </ToolbarItem>

        </Toolbar>
      </div>

      <>
        <div className="itemHeader">
          Uprawnienia do akceptacji wniosków w dziale:
        </div>

        <p style={{ marginTop: "-0.5em" }}>
          {manager != null && <>{manager?.firstName} {manager?.lastName}<br /></>}
          {assManager != null && <>{assManager?.firstName} {assManager?.lastName}<br /></>}

          {manager == null && assManager == null && <>Nie wskazano</>}
        </p>

        <div className="itemHeader">Lista pracowników przypisanych do działu</div>
        <Grid
          className="belowToolbarWrapper"
          data={props.employed}
        >
          <Column field="firstName" title="Imię" />
          <Column field="lastName" title="Nazwisko" />
          <Column field="email" title="Email" />
          <Column field="department" title="Dział"
            cell={(_: any) => (
              <td>
                {props.department.text}
              </td>
            )} />
          <Column field="registrationNo" title="Nr ewid." />
        </Grid>
      </>
    </>
  );
};

export default DepartmentsEmplList;
