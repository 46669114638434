import React, { ReactNode, useContext } from 'react';

import UserRoleEnum from '../models/enums/UserRoleEnum';
import { AppContext, IAppContext } from '../services/AppContext';

export enum Rights {
    full = "full",
    partial = "partial",
};

export enum Scopes {
    Dashboard,
    AddApp,
    UserLimits,
    Calendar,
    AppsList,
    ApplicationDetail,
    ApplicationConsider,
    ExportData,
    Licences,
    LicenceEdit,

    Briefcases,
    Employees,
    ArchivedEmployees,
    EmployeeEdit,
    Bc,
    Departments,
    Settings,
    MyProfile,
    ClientLicence,
    PromoCodes,
    PromoCodeEdit,

    WorkTime,
    WorkTimeRegister,
    WorkTimeBrowse,

    IRRELEVANT
};

export const Permissions = {
    [UserRoleEnum.HR]:
    {
        [Scopes.Dashboard]: [Rights.full],
        [Scopes.AddApp]: [Rights.full],
        [Scopes.UserLimits]: [Rights.full],
        [Scopes.Calendar]: [Rights.full],
        [Scopes.AppsList]: [Rights.full],
        [Scopes.ApplicationDetail]: [Rights.full],
        [Scopes.ApplicationConsider]: [Rights.full],
        [Scopes.ExportData]: [Rights.full],
        [Scopes.Briefcases]: [Rights.full],
        [Scopes.Employees]: [Rights.full],
        [Scopes.ArchivedEmployees]: [Rights.full],
        [Scopes.EmployeeEdit]: [Rights.full],
        [Scopes.Bc]: [Rights.full],
        [Scopes.Departments]: [Rights.full],
        [Scopes.Settings]: [Rights.full],
        [Scopes.MyProfile]: [Rights.full],
        [Scopes.ClientLicence]: [Rights.full],
        [Scopes.WorkTime]: [Rights.full],
        [Scopes.WorkTimeRegister]: [Rights.full],
        [Scopes.WorkTimeBrowse]: [Rights.full],
    },

    [UserRoleEnum.EMPLOYEE]:
    {
        [Scopes.Dashboard]: [Rights.full],
        [Scopes.AddApp]: [Rights.full],
        [Scopes.UserLimits]: [Rights.full],
        [Scopes.Calendar]: [Rights.partial],
        [Scopes.AppsList]: [Rights.partial],
        [Scopes.ApplicationDetail]: [Rights.partial],
        [Scopes.MyProfile]: [Rights.full],
        [Scopes.WorkTime]: [Rights.full],
        [Scopes.WorkTimeRegister]: [Rights.full],
    },

    [UserRoleEnum.MANAGER]:
    {
        [Scopes.Dashboard]: [Rights.full],
        [Scopes.AddApp]: [Rights.full],
        [Scopes.UserLimits]: [Rights.full],
        [Scopes.Calendar]: [Rights.partial],
        [Scopes.AppsList]: [Rights.partial],
        [Scopes.ApplicationDetail]: [Rights.partial],
        [Scopes.ApplicationConsider]: [Rights.full],
        [Scopes.Briefcases]: [Rights.full],
        [Scopes.Employees]: [Rights.partial],
        [Scopes.MyProfile]: [Rights.full],
        [Scopes.WorkTime]: [Rights.full],
        [Scopes.WorkTimeRegister]: [Rights.full],
        [Scopes.WorkTimeBrowse]: [Rights.full],
    },

    [UserRoleEnum.ADMINISTRATOR]:
    {
        [Scopes.MyProfile]: [Rights.full],
        [Scopes.Licences]: [Rights.full],
        [Scopes.LicenceEdit]: [Rights.full],
        [Scopes.PromoCodes]: [Rights.full],
        [Scopes.PromoCodeEdit]: [Rights.full],
    },

    [UserRoleEnum.UNKNOWN]:
    {
    }
};

export const hasPermission = (permissionScope: Scopes | undefined, role: string | null): boolean => {
    if (role === null)
        return false;

    const permissions: { [scope: string]: Rights[]; }
        = Permissions[role as keyof typeof UserRoleEnum];

    if (permissionScope === undefined)
        return true;

    return permissions[permissionScope] ? true : false;
}

export const PermissionsGate = (props: { children: ReactNode, scope: Scopes }) => {
    const context = useContext<IAppContext>(AppContext);

    const permissionGranted =
        props.scope === Scopes.IRRELEVANT || hasPermission(props.scope, context.values.role);

    if (!permissionGranted)
        return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>NIE MASZ UPRAWNIEŃ DO WYŚWIETLENIA TEJ STRONY</div>

    return <>{props.children}</>;
}