import WorkPlanTypeEnum from '../../../../enums/WorkPlanTypeEnum';
import WorkPlanItemIdDto from '../../../WorkPlans/Responses/WorkPlanItemIdDto';

export default class UserWorkPlanResponseDto {
    id: string;

    name: string;
    workPlanId: string;

    validFromDate: Date;
    userId: string;

    type: WorkPlanTypeEnum;
    items: WorkPlanItemIdDto[];
}