import PromoCodeDto from "../../dto/PromoCode/PromoCodeDto";
import DropdownItem from "../Dropdown/DropdownItem";
import PromoCode from "../PromoCode/PromoCode";

export default class Licence {
    id: string;
    office: DropdownItem;
    validFrom: Date;
    validTo: Date;
    workersLimit: number;
    // read - only.
    promoCode: PromoCode;
}