import '../../../../styles/pdfExportUnicodeSupport.scss';

import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { faAddressCard, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { TextArea } from '@progress/kendo-react-inputs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { GridPDFExport } from '@progress/kendo-react-pdf';

import { apiClient } from '../../../../api/apiClient';
import {
    WORKTIME_DELETE_WORKPLANS, WORKTIME_GET_FORCURRENTUSER, WORKTIME_GET_FORUSER,
    WORKTIME_POST_UPDATEDESCRIPTION
} from '../../../../api/apiEndpoints';
import { getCurrentMonthBounds } from '../../../../helpers/dateUtils';
import WorkTimeItemTypeEnum from '../../../../models/dto/WorkTime/Common/WorkTimeItemTypeEnum';
import WorkTimeWithUserResponseDto from '../../../../models/dto/WorkTime/Responses/WorkTimeWithUserResponseDto';
import NotificationEnum from '../../../../models/enums/NotificationEnum';
import DropdownItem from '../../../../models/local/Dropdown/DropdownItem';
import WorkTimePersonalGridItem from '../../../../models/local/WorkTime/Personal/WorkTimePersonalGridItem';
import WorkTimePersonalTimeLogGridViewState from '../../../../models/local/WorkTime/Personal/WorkTimePersonalTimeLogGridViewState';
import { dateToHHMM, mapper } from '../../../../models/mapper';
import useAppLoader from '../../../../services/AppLoader';
import useAppNotifications from '../../../../services/AppNotifications';
import PromptDialog from '../../../Common/PromptDialog';
import WorkTimeMonthlyTimeLogGridToolbar from '../WorkTimeGridSimpleToolbar';

type UserIdParams = {
    id: string;
};

const WorkTimePersonalTimeLogGrid = forwardRef((props: { forCurrentUser: boolean, cachedOnBehalfUser: DropdownItem | undefined, isIndividualView: boolean }, ref: Ref<{ forceReloadPersonalLogGrid: () => void }>) => {
    const loader = useAppLoader(false);
    const notifications = useAppNotifications();

    const userId = useParams<UserIdParams>()?.id ?? props.cachedOnBehalfUser?.id;

    const [viewState, setViewState] = useState<WorkTimePersonalTimeLogGridViewState>(new WorkTimePersonalTimeLogGridViewState());
    const editDescriptionFormRef = useRef(null);

    const descriptionColWidth = 360;

    // aggregates
    // make groups & aggregates (sum/day)
    const groupsInGrid = [{ field: "date", }];
    const processGroups = (data: WorkTimePersonalGridItem[], groups: any) => {

        const processResult = process(data,
            {
                sort: [{ field: "date", dir: "desc" }],
                group: [{
                    field: 'date',
                    dir: 'desc',
                    aggregates: [
                        // { aggregate: "sum", field: "hours" },
                    ]
                }],
            });


        // count sum of hours in group/day
        processResult.data.forEach((group: any) => {
            const totalMinutes = (group.items as WorkTimePersonalGridItem[]).reduce((acc, item) => {
                const date = item.hours;

                if (item.isDeleted)
                    return acc;

                return acc + date.getHours() * 60 + date.getMinutes();
            }, 0);

            const summedHours = new Date();
            summedHours.setHours(Math.floor(totalMinutes / 60));
            summedHours.setMinutes(totalMinutes % 60);
            summedHours.setSeconds(0);

            group.aggregates = { sum: summedHours };
        });

        return processResult;
    };

    // actions
    const onChangeDate = (shift: number) => {
        const date = new Date(viewState.Date);
        date.setMonth(date.getMonth() + shift);
        date.setDate(1); // first day

        setViewState(prevState => ({ ...prevState, Date: new Date(date) }));
    }

    const onResetDate = () => {
        const date = new Date();
        date.setDate(1); // first day
        setViewState(prevState => ({ ...prevState, Date: new Date(date) }));
    }

    const onExportPDF = () => {
        exportGridToPDF();
    }

    const onAskDeleteDialog = (id: string) => {
        setViewState(prevState => ({ ...prevState, idItemToDelete: id, deleteDialogVisible: true }));
    }

    useImperativeHandle(ref, () => ({
        forceReloadPersonalLogGrid: async () => {
            await getUserMonthlyTimeLog();
        }
    }));

    const onShowEditDescriptionDialog = (id: string) => {
        setViewState(prevState => ({ ...prevState, editDescriptionDialogVisible: true, editDescriptionId: id }));
    }

    const onCancelDescriptionDialog = () => {
        setViewState(prevState => ({ ...prevState, editDescriptionDialogVisible: false, editDescriptionId: undefined }));
    }

    const handleSaveDescriptionDialogSubmit = (event: any) => {
        event.preventDefault();

        const form = editDescriptionFormRef.current;
        const formData = new FormData(form!);
        const description = formData.get('description');

        apiClient({
            method: "POST",
            url: WORKTIME_POST_UPDATEDESCRIPTION,
            data: {
                id: viewState.editDescriptionId,
                description: description
            },
        }).then(async (resp) => {
            if (resp.status !== 200) {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error)
                return;
            }

            await getUserMonthlyTimeLog();
            notifications.showNotification("Wskazana pozycja została zaktualizowana, a zarejestrowany czas pracy odpowiednio przeliczony.")
            onCancelDescriptionDialog();
            setViewState(prevState => ({ ...prevState, idItemToDelete: undefined, deleteDialogVisible: false }));
        });

        // alert(`Wartość TextArea: ${textAreaValue} Wartość ID ${viewState.editDescriptionId}`);
    };

    const cellRender = (tdElement: React.ReactElement<HTMLTableCellElement> | null, cellProps: GridCellProps, isForPrint: boolean) => {
        // (cannot be moved to column attribute cell = {(d: GridCellProps) => ...})
        const isDeleted: boolean = (cellProps.dataItem as WorkTimePersonalGridItem).isDeleted;
        const cellClass = isDeleted ? cellProps.className + " striketrough" : cellProps.className;

        const isQrCode = WorkTimeItemTypeEnum[(cellProps.dataItem as WorkTimePersonalGridItem).itemType] === WorkTimeItemTypeEnum.QrCode;

        // GROUP HEADER (date)
        if (tdElement?.props.children && cellProps.rowType === "groupHeader") {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const cellDate = new Date(cellProps.dataItem.value);
            cellDate.setHours(0, 0, 0, 0);
            const isToday = cellDate.getTime() === today.getTime();

            const children = (
                <span>
                    {cellProps.dataItem.value}
                    {isToday ? " (dzisiaj)" : ""}
                </span>
            );

            // return tdElement;
            return React.cloneElement(tdElement, tdElement.props, children);
        }

        // GROUP FOOTER
        if (cellProps.rowType === "groupFooter") {
            if (cellProps.field === "hours") {
                const data = cellProps.dataItem.aggregates["sum"];
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {dateToHHMM(data)}
                    </td>
                );
            }

            if (cellProps.field === "hourTo") {
                return <td style={{ textAlign: "right" }}><span className="k-icon k-i-sum" /></td>;
            }
        }
        const qrCodeInfo = isQrCode ? <><FontAwesomeIcon icon={faAddressCard} title='Wpis zarejestrowano z użyciem identyfikatora' color='darkblue' /><br /></> : <></>;

        // OTHER 
        if (cellProps.rowType === "data" && cellProps.field === 'Opcje') {


            if (isDeleted)
                return <td>Usunięto</td>
            else
                return <td><Button fillMode="outline" icon="trash" onClick={() => {
                    onAskDeleteDialog((cellProps.dataItem as WorkTimePersonalGridItem).id);
                }} >Usuń</Button></td>;
        }

        if (cellProps.rowType === "data" && (cellProps.field === 'description')) {
            return <td className={cellClass} style={{ whiteSpace: 'pre-line' }}>

                {qrCodeInfo}
                {(cellProps.dataItem as WorkTimePersonalGridItem).description}
                {isQrCode && !(cellProps.dataItem as WorkTimePersonalGridItem).description && !isForPrint &&
                    <Button fillMode="outline" size='small' style={{ opacity: "0.25" }} onClick={() => {
                        onShowEditDescriptionDialog((cellProps.dataItem as WorkTimePersonalGridItem).id);
                    }} >uzupełnij opis</Button>
                }
            </td>;
        }

        if (cellProps.rowType === "data" && (cellProps.field === 'hourTo')) {
            const hourTo = (cellProps.dataItem as WorkTimePersonalGridItem).hourTo;
            return <td className={cellClass} style={{ textAlign: "center" }}>{hourTo === null ? <span>--:--</span> : <>{dateToHHMM(hourTo)}</>}</td>;
        }

        if (cellProps.rowType === "data" && (cellProps.field === 'hours')) {
            const hourTo = (cellProps.dataItem as WorkTimePersonalGridItem).hourTo;
            const hours = (cellProps.dataItem as WorkTimePersonalGridItem).hours;
            return <td className={cellClass} style={{ textAlign: "center" }}>{hourTo === null ? <FontAwesomeIcon icon={faTriangleExclamation} title='Nie zarejestrowano końca pracy' color='red' /> : <>{dateToHHMM(hours)}</>}</td>;
        }

        if (cellProps.rowType !== "groupFooter" && cellProps.field === "hours") {
            const data = cellProps.dataItem.hours as Date;
            if (!data) return <></>;

            return <td className={cellClass} style={{ textDecoration: "underline" }}> {dateToHHMM(data)}</td>;
        }

        return tdElement;
    };


    // api
    const getUserMonthlyTimeLog = async () => {
        loader.showLoading(true);

        const monthBounds = getCurrentMonthBounds(viewState.Date);

        if (props.forCurrentUser || (!props.forCurrentUser && userId !== undefined))
            await apiClient({
                method: "GET",
                url: (props.forCurrentUser ? WORKTIME_GET_FORCURRENTUSER(monthBounds.start, monthBounds.end) : WORKTIME_GET_FORUSER(userId!, monthBounds.start, monthBounds.end))
            }).then(async (resp) => {
                if (resp.status !== 200) {
                    notifications.showNotification(resp.data.errors, NotificationEnum.Error);
                    return;
                }

                const mappedResult: WorkTimePersonalGridItem[] = mapper.mapArray(resp?.data?.result.items, WorkTimePersonalGridItem, WorkTimeWithUserResponseDto);

                setViewState(prevState => ({ ...prevState, GridItems: mappedResult, GroupedGridItems: processGroups(mappedResult, groupsInGrid) }));
            });

        loader.showLoading(false);
    }

    const deleteItem = async (id: string) => {
        apiClient({
            method: "DELETE",
            url: WORKTIME_DELETE_WORKPLANS(id),
            data: {
                DeleteReason: "---" //todo
            },
        }).then(async (resp) => {
            if (resp.status !== 200) {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error)
                return;
            }

            await getUserMonthlyTimeLog();

            notifications.showNotification("Wskazana pozycja została usunięta, a zarejestrowany czas pracy odpowiednio przeliczony.")
            setViewState(prevState => ({ ...prevState, idItemToDelete: undefined, deleteDialogVisible: false }));
        });
    }

    const gridLocalComponent = (isForPrint: boolean) => {
        return <Grid
            className="contentWrapperFullHeight--withTopToolbar"
            data={viewState.GroupedGridItems}
            group={groupsInGrid}
            groupable={{
                enabled: false,
                footer: "visible",
            }}
            cellRender={(tdElement: React.ReactElement<HTMLTableCellElement> | null, cellProps: GridCellProps) => { return cellRender(tdElement, cellProps, isForPrint) }} // something like cell='...' but more advanced operations allowed 
            rowRender={(row, rowProps) => {
                return row;
            }}
        >
            <GridColumn field="hourFrom" title="Od" width={60} format='{0:HH:mm}' className='pdfExportFonts' />
            <GridColumn field="hourTo" title="Do" width={60} format='{0:HH:mm}' className='pdfExportFonts' />
            <GridColumn field="hours" title="Godz." width={60} className='pdfExportFonts' />
            <GridColumn field="description" title="Opis" width={descriptionColWidth} className='pdfExportFonts' />
            {(isForPrint ? <></> : <GridColumn field="Opcje" width={100} />)}
        </Grid >;
    }

    // extracts unique user name from grid/array list
    const getUserNameFromGrid = (viewState: WorkTimePersonalTimeLogGridViewState): string | Error => {

        const uniqueUserNames = new Set(viewState.GridItems.map(item => item.userName));

        if (uniqueUserNames.size > 1) {
            throw new Error("Wielokrotny userName w GridItems");
        } else {
            return Array.from(uniqueUserNames)[0] ?? "";
        }
    }

    let gridPDFExport: GridPDFExport;
    const exportGridToPDF = () => {
        if (gridPDFExport !== null) {
            gridPDFExport.save();
        }
    };

    // effects
    useEffect(() => {
        getUserMonthlyTimeLog();
    }, [viewState.Date]);



    return (
        <>
            {viewState.editDescriptionDialogVisible &&
                <Dialog title={"Edycja opisu wykonanej pracy"} width={650} height={320} >
                    Wprowadź opis wykonanej pracy zarejestrowanej z użyciem identyfikatora:
                    <form ref={editDescriptionFormRef} onSubmit={handleSaveDescriptionDialogSubmit}>
                        <p>
                            <input id="itemId" name="itemId" type="hidden" value={"12345"}></input>
                            <TextArea
                                id={"description"}
                                name={"description"}
                                autoComplete="off"
                                rows={6}
                            />
                        </p>
                        <DialogActionsBar>
                            <Button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                fillMode="outline"
                                icon="save"
                                type='submit'
                            >
                                Zapisz
                            </Button>
                            <Button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={onCancelDescriptionDialog}
                                fillMode="outline"
                            >
                                Anuluj
                            </Button>
                        </DialogActionsBar>
                    </form>
                </Dialog>}

            <div className="fullPageContentWrapper">
                <div className="fullWidthColumn" id="leaveList">
                    <TabStrip selected={0} >
                        <TabStripTab title={props.forCurrentUser ? `Twoja miesięczna karta pracy` : `Miesięczna karta pracy ${getUserNameFromGrid(viewState)}`} >
                            {!loader.isLoading() && (
                                <>
                                    {viewState.deleteDialogVisible === true && (
                                        <PromptDialog
                                            text={`<p>Czy na pewno chcesz wykreślić wskazaną pozycję ?`}
                                            onYes={() => { deleteItem(viewState.idItemToDelete!); }}
                                            onNo={() => { setViewState({ ...viewState, deleteDialogVisible: false }) }}
                                        />
                                    )}

                                    <WorkTimeMonthlyTimeLogGridToolbar
                                        date={viewState.Date}
                                        changeMonth={onChangeDate}
                                        resetMonth={onResetDate}
                                        exportPDF={onExportPDF}
                                        showFilters={false}
                                        showPrintTemplateButton={props.isIndividualView}
                                        showBackToListButton={props.isIndividualView}
                                        exportAttendanceList={() => { }}
                                    />

                                    <div id="itemsGridWrapper">
                                        {gridLocalComponent(false)}
                                    </div>

                                    <GridPDFExport
                                        ref={(pdfExport: GridPDFExport) => (gridPDFExport = pdfExport)}
                                        paperSize="A4"
                                        scale={0.5}
                                        margin={{ top: "25mm", right: "15mm", left: "10mm", bottom: "15mm" }}
                                        pageTemplate={(props: any) => {
                                            return (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "60px",
                                                        left: "40px",
                                                    }}
                                                    className='pdfExportFonts'
                                                >
                                                    <p>MIESIĘCZNA KARTA EWIDENCJI CZASU PRACY<br />
                                                        <>{getUserNameFromGrid(viewState)}</>&nbsp;|&nbsp;
                                                        {viewState.Date.toISOString().slice(0, 7)}
                                                    </p>
                                                </div>
                                            )
                                        }}
                                    >
                                        {gridLocalComponent(true)}
                                    </GridPDFExport >
                                </>
                            )}

                        </TabStripTab>
                    </TabStrip>
                </div>
            </div >

        </>)
});



export default WorkTimePersonalTimeLogGrid;
