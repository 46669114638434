import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";

export default class TrainingEditForm {
    id: string;
    trainingType: DictItem;
    date: Date;
    validityDate: Date;
    note: string;
    files: StorageFile[];
}