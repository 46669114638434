import LeaveResponseListItemDto from './LeaveResponseListItemDto';
import SummaryLeaveItemResponseDto from './SummaryLeaveItemResponseDto';
import TodayInformationResponseDto from './TodayInformationResponseDto';

export default class DashboardDataResponseDto {
  todayInformation: TodayInformationResponseDto;
  plannedLeaves: SummaryLeaveItemResponseDto[];
  actualLeaves: SummaryLeaveItemResponseDto[];
  forConsiderationLeaves: LeaveResponseListItemDto[];
}
