import * as React from "react";

import {
  Button,
  Toolbar,
  ToolbarItem,
  ToolbarSeparator,
} from "@progress/kendo-react-buttons";
import { FormElement } from "@progress/kendo-react-form";
import { useNavigate } from "react-router";

const LicenceEditToolbar = (props: any) => {
  const navigate = useNavigate();

  return (
    <Toolbar>
      <ToolbarItem>
        <FormElement>
          <Button
            fillMode="outline"
            icon="save"
            id="submit"
            type={"button"}
            className="k-button"
            onClick={() => {
              props.onSubmitClick();
            }}
            // disabled={!formRenderProps.allowSubmit}
          >
            Zapisz
          </Button>
        </FormElement>
      </ToolbarItem>

      <ToolbarItem>
        <Button
          icon="close"
          fillMode="outline"
          onClick={() => {
            navigate(-1);
          }}
        >
          Anuluj
        </Button>
      </ToolbarItem>

      <ToolbarSeparator />
    </Toolbar>
  );
};

export default LicenceEditToolbar;
