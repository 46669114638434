import * as React from 'react';

import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import { apiClient } from '../../api/apiClient';
import { USER_PUT_MoveToUsers } from '../../api/apiEndpoints';
import NotificationEnum from '../../models/enums/NotificationEnum';
import ArchivedUserListItem from '../../models/local/Employees/ArchivedUserListItem';
import Loading from '../../pages/Loading';
import useAppLoader from '../../services/AppLoader';
import useAppNotifications from '../../services/AppNotifications';
import PromptDialog from '../Common/PromptDialog';

const ArchivedEmployeesList = (props: {
  users: ArchivedUserListItem[] | undefined,
  reloadData: () => void
}) => {
  const notifications = useAppNotifications();
  const [viewState, setViewState] = React.useState<{ moveToUsersUserId: string, moveToUsersDialogVisible: boolean }>(
    {
      moveToUsersUserId: "",
      moveToUsersDialogVisible: false
    });

  const loader = useAppLoader(false);

  const onMoveToUsers = (entityId: string) => {
    setViewState({ ...viewState, moveToUsersUserId: entityId, moveToUsersDialogVisible: true });
  };

  const onMoveToUsersPUT = () => {
    loader.showLoading(true);
    apiClient({
      method: "PUT",
      url: USER_PUT_MoveToUsers,
      data: {
        userId: viewState.moveToUsersUserId
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }
      loader.showLoading(false);
      props.reloadData();
      setViewState({ ...viewState, moveToUsersDialogVisible: false });
      notifications.showNotification("Pracownik został przywróconyy z archiwum", NotificationEnum.Success);
    });
  };

  return (
    <>
      {viewState.moveToUsersDialogVisible === true && (
        <PromptDialog
          text="Czy na pewno cofnąć pracownika z archiwum?"
          onYes={() => {
            onMoveToUsersPUT();
          }}
          onNo={() => { setViewState({ ...viewState, moveToUsersDialogVisible: false }); }}
        />
      )}

      <Grid className="belowToolbarWrapper" data={props?.users}>
        <Column field="firstName" title="Imię" />
        <Column field="lastName" title="Nazwisko" />
        <Column field="email" title="Email" />
        <Column field="currentDepartmentName" title="Dział(y)" />
        <Column field="uniqueId" title="Nr ewidencyjny" />
        <Column
          title="Operacje"
          width="100px"
          cell={(props: any) => (
            <td>
              <Button
                fillMode="outline"
                icon="undo"
                onClick={() => {
                  onMoveToUsers(props.dataItem.id);
                }}
              />
            </td>
          )}
        />
      </Grid>
    </>
  );
};
export default ArchivedEmployeesList;
