import LimitTypeOverrideItemDto from './LimitTypeOverrideItemDto';

export default class LimitTypeOverrideDto {
    id: string;
    name: string;
    limitScope: string;
    limitUnit: string;

    parentId: string;
    userId: string;

    year: number;
    visible: boolean;

    items: LimitTypeOverrideItemDto[];

    sum: number | null;
}