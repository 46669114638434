import React from 'react';

import {
  CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor
} from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import {
  Grid, GridCellProps, GridColumn as Column, GridFilterOperators, GridRowProps,
  GridSortChangeEvent
} from '@progress/kendo-react-grid';

import LeaveStatusEnum, {
  LeaveStatusEnumToColor, LeaveStatusEnumTranslation
} from '../../../models/enums/LeaveStatusEnum';
import DropdownItem from '../../../models/local/Dropdown/DropdownItem';
import LeaveListItem from '../../../models/local/Leaves/LeaveListItem';

const ApplicationGridTab = (props:
  {
    data:
    LeaveListItem[] | undefined,
    icon: string,
    buttonLabel: string,
    buttonAction: (id: string) => void
  }) => {
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();

  const filterOperators: GridFilterOperators = {
    text: [{ text: "grid.filterContainsOperator", operator: "zawiera" }],
    numeric: [{ text: "grid.filterEqOperator", operator: "eq" }],
    date: [{ text: "grid.filterEqOperator", operator: "eq" }],
    boolean: [{ text: "grid.filterEqOperator", operator: "eq" }],
  };

  const initialSort: Array<SortDescriptor> = [
  ];
  const [sort, setSort] = React.useState(initialSort);


  const rowRender = (trElement: any, props: GridRowProps) => {
    const element = React.cloneElement(
      trElement,
      { style: { color: LeaveStatusEnumToColor(props.dataItem.status) }, },
      trElement.props.children
    );

    return element;
  }

  return (
    <>
      <Grid style={{ maxHeight: "30%" }} data={props?.data != null ? orderBy(filterBy(props.data!, filter!), sort) : undefined} rowRender={rowRender} filterable={true} filter={filter} onFilterChange={(e) => setFilter(e.filter)}
        sortable={true}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
        }}>
        <Column field="id" title="Nr wniosku" cell={(d: GridCellProps) => (<td>{d.dataItem.id.substr(0, 8)}</td>)} width="100px" />
        <Column field="limitType" title="Nieobecność" width="180px" />
        <Column field="ownerName" title="Dotyczy" />
        <Column field="replacement" title="Zastępstwo" cell={(d: GridCellProps) => (<td>{d.dataItem.replacementUsers.length > 0 ? d.dataItem.replacementUsers.map((u: DropdownItem) => u.text).join(", ") : "(brak)"}</td>)} filterable={false} />
        <Column field="startDate" title="Rozpoczęcie" format="{0:d}" width="110px" filterable={false} />
        <Column field="endDate" title="Zakończenie" format="{0:d}" width="110px" filterable={false} />
        <Column field="calculations.workingDaysSum" cell={(d: GridCellProps) => (<td>{(d.dataItem as LeaveListItem)?.calculations?.workingDaysSum ?? "-"}</td>)} title="Ilość dni" width="110px" filterable={false} />
        <Column field="accepted" title="Status" width="140px" cell={(d: GridCellProps) => (<td><b>{LeaveStatusEnumTranslation(d.dataItem.status)}</b></td>)} filterable={false} />
        <Column
          title="Operacje"
          width="110px"
          cell={(c: any) => (
            <td>
              <Button
                fillMode="outline"
                onClick={() => {
                  props.buttonAction(c.dataItem.id)
                }}
              >{props.buttonLabel}</Button>
            </td>
          )}
          filterable={false}
        />
      </Grid>
    </>
  );
};

export default ApplicationGridTab;
