import React, { useEffect, useRef, useState } from 'react';

import WorkTimePersonalTimeLogGrid from '../components/WorkTime/MonthlyLog/Personal/WorkTimePersonalTimeLogGrid';
import WorkTimeRegisterFormWrapper from '../components/WorkTime/Registration/WorkTimeRegisterFormWrapper';
import DropdownItem from '../models/local/Dropdown/DropdownItem';
import useAppLoader from '../services/AppLoader';

const WorkTimeRegisterPage = () => {
  const loader = useAppLoader(false);

  const logGridRef = useRef<{ forceReloadPersonalLogGrid: () => void }>(null);

  const [onBehalfUserCached, setOnBehalfUserCache] = useState<DropdownItem | undefined>(undefined);
  const [tabIdx, setTabIdx] = useState<number | undefined>(0);
  const [shouldReloadLogGrid, setShouldReloadLogGrid] = useState<boolean>(false);

  const handleOnBehalfUserChanged = (behalfUser: DropdownItem | undefined) => {
    setShouldReloadLogGrid(!shouldReloadLogGrid);
    setOnBehalfUserCache(behalfUser);
    console.log("----------------------------------------handleOnBehalfUserChanged:", { tabIdx, behalfUser, onBehalfUserCached: onBehalfUserCached?.text })
  }

  const handleFormDataSaved = () => {
    setShouldReloadLogGrid(!shouldReloadLogGrid);
    console.log("----------------------------------------handleFormDataSaved", { tabIdx, onBehalfUserCached: onBehalfUserCached?.text })
  }

  const handleTabIdxChange = (tabIdx: number) => {
    setTabIdx(tabIdx);

    if (tabIdx === 0)
      setOnBehalfUserCache(undefined);
    console.log("----------------------------------------handleWorkTimeRegFormTabIdxChanged:", { tabIdx, onBehalfUserCached: "set to: undefined" })
  }

  useEffect(() => {
    console.log("----------------------------------------[onBehalfUserCached, shouldReloadLogGrid]", { tabIdx, onBehalfUserCached: onBehalfUserCached?.text })

    if (tabIdx === 0 || onBehalfUserCached !== undefined)
      logGridRef.current!.forceReloadPersonalLogGrid();
  }, [onBehalfUserCached, shouldReloadLogGrid]);

  return (
    <>
      {!loader.isLoading() && (
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn">
            <div className="columnsWrapper">
              <div className="leftColumn">
                <WorkTimeRegisterFormWrapper onBehalfUserChanged={handleOnBehalfUserChanged} onDataSaved={handleFormDataSaved} onTabIdxChange={handleTabIdxChange} cachedOnBehalfUser={onBehalfUserCached}></WorkTimeRegisterFormWrapper>
              </div>
              <div className="rightColumn">
                {/* current user work time grid log */}
                {(tabIdx === 0) && <WorkTimePersonalTimeLogGrid ref={logGridRef} forCurrentUser={true} isIndividualView={false} cachedOnBehalfUser={undefined}></WorkTimePersonalTimeLogGrid>}
                {/* behalf of other user time grid log */}
                {(tabIdx === 1 && onBehalfUserCached !== undefined) && <WorkTimePersonalTimeLogGrid ref={logGridRef} forCurrentUser={false} isIndividualView={false} cachedOnBehalfUser={onBehalfUserCached}></WorkTimePersonalTimeLogGrid>}
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default WorkTimeRegisterPage;