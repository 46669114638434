import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@progress/kendo-react-buttons';
import { QrScanner } from '@yudiel/react-qr-scanner';

import { apiClient } from '../api/apiClient';
import { USER_GET_bySpecialId, WORKTIME_POST_REGISTERBYQRCODE } from '../api/apiEndpoints';
import SynerdiLogoImg from '../assets/synerdi-logo-dark.png';
import UserBySpecialIdResponse from '../models/dto/User/UserBySpecialIdResponse';
import WorkTimeStartOrEndOfWorkEnum from '../models/dto/WorkTime/Common/WorkTimeStartOrEndOfWorkEnum';
import useAppLoader from '../services/AppLoader';
import useSessionWrapper from '../services/SessionWrapper';

enum step {
  scanning = 1,
  loading = 2,
  startOrStop = 3,
  info = 4,
  screenSaver = 1000,
}

interface CachedApiResponse {
  nameSurname: string;
  startOrEnd: WorkTimeStartOrEndOfWorkEnum;
  hourFromToClose: string | null;
  currentDateTime: Date | null;
  token: string | null;
}

interface ViewState {
  currentViewStep: step;
  cacheDecodedQrValue: null | string;

  apiResponse: CachedApiResponse | null;
}

const defaultViewState = (): ViewState => ({
  currentViewStep: step.screenSaver,
  cacheDecodedQrValue: null,
  apiResponse: null,
});

const ssTimeout = 60;

const LoginQrCode = () => {

  const loader = useAppLoader(false);
  const navigate = useNavigate();
  const sessionWrapper = useSessionWrapper();

  const [viewState, setViewState] = useState<ViewState>(defaultViewState());
  const [counter, setCounter] = useState(0);
  const [ssTimer, setSSTimer] = useState(ssTimeout); //seconds

  useEffect(() => {
    sessionWrapper.logout(true); //logout on page load
  }, [])

  // action timer
  useEffect(() => {
    let intervalId: NodeJS.Timeout;;

    if (counter > 0) {
      intervalId = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else {
      setViewState({ ...defaultViewState() });
    }

    return () => clearInterval(intervalId);
  }, [counter]);

  // screensaver timer
  useEffect(() => {
    const intervalId = setInterval(() => {
      setSSTimer((prevTimer) => prevTimer > 0 ? prevTimer - 1 : 0);
    }, 1000);

    return () => { clearInterval(intervalId); };
  }, []);

  const resetSSTimer = () => {
    console.log('kliknięto, resetuję timer');
    setSSTimer(ssTimeout);
    setViewState({ ...defaultViewState(), currentViewStep: step.scanning });
  };

  useEffect(() => {
    if (ssTimer === 0) {
      console.log('czas minął');
      setViewState(defaultViewState());
    } else {
      console.log(`Pozostały czas: ${ssTimer} sekund`);
    }
  }, [ssTimer]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 3600000); // 3600000 ms = 1 godzina

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    if (viewState.currentViewStep === step.loading) {
      apiClient({
        method: "GET",
        url: USER_GET_bySpecialId(viewState.cacheDecodedQrValue),
      }, true).then(async (resp) => {

        // error
        if (resp?.data?.errors?.length > 0) {
          var errors = resp?.data?.errors;

          if (errors && Array.isArray(errors) && errors.length > 0)
            alert("Wystąpił błąd: " + errors[0]);
          else
            showError(100014)

          setCounter(2);
          return;
        }

        // ok
        if (resp?.status === 200) {
          const result: UserBySpecialIdResponse = resp.data.result as UserBySpecialIdResponse;
          console.log("RESULT", result);

          const mappedResult: CachedApiResponse =
            { nameSurname: `${result.firstName} ${result.lastName}`, hourFromToClose: result.hourFromToClose, startOrEnd: result.startOrEnd, token: result.token, currentDateTime: result.currentDateTime };
          console.log(mappedResult)

          setViewState({
            ...viewState,
            currentViewStep: step.startOrStop,
            apiResponse: mappedResult,
            cacheDecodedQrValue: null
          });

          setCounter(5);
          return;
        }

        showError(100015)
        setViewState(defaultViewState());
      });
    }
  }, [viewState.currentViewStep])

  const handleStartEndButtonClick = async () => {
    apiClient({
      method: "POST",
      url: WORKTIME_POST_REGISTERBYQRCODE,
    }, true, viewState.apiResponse?.token).then(async (resp) => {

      if (resp?.status === 200) {
        setCounter(2);

        return;
      }
      showError(100016)
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: "#292351" }} onClick={resetSSTimer}>
      <div style={{
        width: 400,
        height: '100vh',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        textAlign: "center",
        position: 'relative',
        backgroundColor: "#292351",
      }}>

        <div style={{ position: "absolute", top: "25px" }}><img alt="Synerdi.pl" src={SynerdiLogoImg} style={{ width: "200px", marginBottom: "25px" }} /></div>

        {viewState.currentViewStep === step.screenSaver &&
          <div>
            <p><span className="k-icon k-icon-xxxl k-i-information" style={{ color: "white" }} /></p>
            <p style={{ fontSize: '2em' }}>Dotknij ekranu, aby rozpocząć.</p>
          </div>
        }

        {viewState.currentViewStep === step.scanning &&
          <div style={{ width: "100%" }}>
            <QrScanner
              onDecode={(result) => { console.log(result); setViewState({ ...viewState, currentViewStep: step.loading, cacheDecodedQrValue: result }) }}
              onError={(error) => console.log(error?.message)}
              tracker={true}
              viewFinderBorder={25}
              constraints={{ facingMode: 'user' }}
            />

            <div style={{
              height: "80px",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              textAlign: "center",
              position: 'relative',
              fontSize: '2em'
            }}>
              Zeskanuj kartę
            </div>
          </div>
        }

        {viewState.currentViewStep === step.loading &&
          <div>
            <p><span className="k-icon k-icon-xxxl k-i-check-circle" style={{ color: "lightgreen" }} /></p>
            <p>Identyfikator rozpoznany<br />
              Wczytywanie danych...</p>
          </div>
        }

        {viewState.currentViewStep === step.startOrStop &&
          <div>
            <p>
              <span style={{ fontSize: '3.5em' }}>{viewState.apiResponse?.nameSurname}<br /></span>
              {viewState.apiResponse?.startOrEnd === WorkTimeStartOrEndOfWorkEnum.End && <>START: {viewState.apiResponse?.hourFromToClose}</>}
            </p>

            <p style={{ fontSize: "2em" }}>
              {counter > 0 && <span style={{ fontSize: '1.5em' }}>{counter}</span>}
            </p>

            {viewState.apiResponse?.startOrEnd === WorkTimeStartOrEndOfWorkEnum.Start &&
              <p>
                <Button size='large' themeColor='success' style={{ height: "125px", width: "300px", fontSize: '2em' }}
                  onClick={() => { setViewState({ ...defaultViewState(), currentViewStep: step.info }); handleStartEndButtonClick(); }}>
                  <span style={{ fontSize: "1.5em" }}>START</span><br /><span style={{ fontSize: "0.5em", padding: "0px" }}>DOTKNIJ PRZYCISK</span>
                </Button>
              </p>
            }

            {viewState.apiResponse?.startOrEnd === WorkTimeStartOrEndOfWorkEnum.End &&
              <p>
                <Button size='large' themeColor='error' style={{ height: "125px", width: "300px", fontSize: '2em' }}
                  onClick={() => { setViewState({ ...defaultViewState(), currentViewStep: step.info }); handleStartEndButtonClick(); }} >
                  <span style={{ fontSize: "1.5em" }}>ZAKOŃCZ</span><br /><span style={{ fontSize: "0.5em", padding: "0px" }}>DOTKNIJ PRZYCISK</span>
                </Button>
              </p>
            }
          </div>
        }

        {viewState.currentViewStep === step.info &&
          <div>
            <p style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "lightgreen", fontSize: '4em' }}>
              <span className="k-icon  k-i-check-outline" style={{ fontSize: '2em' }} />
              <span>&nbsp;ZAPISANO</span>
            </p>
            {/* <p style={{ fontSize: "3em" }}>
              {counter > 0 && <span style={{ fontSize: '1.5em' }}>{counter}</span>}
            </p> */}
          </div>
        }

      </div>
    </div>
  );
};

const showError = (id: number) => {
  alert(`Połączenie z serwerem nie powiodło się i dane nie zostały zapisane. Sprawdź połączenie z internetem. ${id}`)
}

export default LoginQrCode;
