import LeaveStatusEnum from "../../enums/LeaveStatusEnum";

export default class LeaveHistory
{
    date: Date;
    // changed by
    userId: string
    firstName: string;
    lastName: string;
    status: LeaveStatusEnum;
}