import '../../../styles/_dashboard.scss';

import React from 'react';

import RegulaminSynerdi from '../../../_documents/RegulaminSynerdi202309.pdf';
import PomocPdf from '../../../_documents/Synerdi - jak rozpocząć pracę w systemie - Dokumenty Google.pdf';

function Help() {
  return (
    <div id="contactWrapper" className="singleContactBrick brickframe">
      <img src="https://cdn-icons-png.flaticon.com/512/3018/3018864.png" className="bcgrImg" alt="Contact us" />
      <b>Więcej o Synerdi.pl</b><br /><br />
      <span className="k-icon k-i-attachment-45" /> <a href={PomocPdf}>Przewodnik po programie</a><br />
      <span className="k-icon k-i-aggregate-fields" /> <a href="https://synerdi.pl/blog/">Portal wiedzy dla pracownika i HR</a><br />
      <span className="k-icon k-i-information" /> <a href="https://synerdi.pl/">Informacje o Synerdi.pl</a><br />
      <span className="k-icon k-i-dictionary-add" /> <a href={RegulaminSynerdi}>Regulamin usługi</a><br />
      <span className="k-icon k-i-comment" /> <a href="https://synerdi.pl/kontakt-synerdi/">Formularz kontaktowy</a><br />
      {/* <br />
      <br />
      <a href="#">Od czego zacząć pracę ?</a> <br />
      <a href="#">Zakładanie kont</a> <br />
      <a href="#">Definiowanie kalendarzy</a> <br />
      <a href="#">Ustalanie planów pracy</a> <br />
      <a href="#">Konfigurowanie limitów</a> <br />
      <a href="#">Składanie wniosków</a>
      <br />
      <a href="#">Szczegółowa pomoc</a> <br /> */}
    </div >
  );
}

export default Help;
