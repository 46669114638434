
export default class UserListItem {
  id: string;
  personalDataId: string;

  email: string;
  firstName: string;
  lastName: string;
  uniqueId: string;
  birthDate?: Date;
  phoneNumber: string;

  corAddressSameAsAddress: boolean;

  addrId: string;
  addrCity: string;
  addrStreet: string;
  addrStreetNumber: string;
  addrHouseNumber: string;
  addrPostalCode: string;

  addrCorespId: string;
  addrCorespCity: string;
  addrCorespStreet: string;
  addrCorespStreetNumber: string;
  addrCorespHouseNumber: string;
  addrCorespPostalCode: string;

  briefcaseId: string;
  role: string;

  currentDepartmentName: string;

  isActive: boolean;
  isBlocked: boolean;
}
