import LeaveStatusEnum from '../../enums/LeaveStatusEnum';

export default class MonthViewItem {
  id: string;

  userId: string;
  firstName: string;
  lastName: string;

  departmentsIds: string[];
  departments: string[];

  limitTypeId: string;
  limitType: string;

  startDate: Date;
  endDate: Date;

  // title: string;
  color: string;

  status: LeaveStatusEnum;

  isAllDay: boolean;
}