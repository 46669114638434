import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { StepType, useTour } from '@reactour/tour';

// https://codesandbox.io/s/6z56m8x18k?file=/App.js
// https://stackoverflow.com/questions/67132278/reactour-how-to-disable-next-step-until-correct-user-action

const Onboarding = () => {
  const { setIsOpen, setSteps, setCurrentStep } = useTour();
  const navigate = useNavigate();
  const steps: StepType[] = [
    {
      selector: "#welcome",
      content: () => (
        <div style={{ textAlign: "left" }}>
          <p>
            Witaj w systemie <b>Synerdi.pl</b><br /><br />
            Dzięki Synerdi zarządzanie nieobecnościami w firmie, rejestracją czasu pracy oraz ewidencją pracowników stanie się nie tylko bardziej efektywne, ale także przyjemne i bezproblemowe.<br />
          </p>
          <p>Chcemy, aby Twoje doświadczenie z korzystania z Synerdi było jak najbardziej satysfakcjonujące. Dlatego przygotowaliśmy dla Ciebie prostą i przejrzystą instrukcję, która pomoże Ci w pełni wykorzystać potencjał naszego systemu. Zacznijmy od początku...</p>
          <p>Kliknij strzałkę w prawo, aby przejść do kolejnego slajdu. W każdej chwili możesz wrócić do poprzedniej lekcji klikając strzałkę w lewo.</p>
        </div>
      ),
      position: "center",
    },
    {
      // left menu
      selector: ".k-drawer-items",
      content: () => (
        <div style={{ textAlign: "justify" }}>
          <p>Po lewej stronie ekranu znajduje się menu, dzięki któremu możesz przełączać się pomiędzy poszczególnymi funkcjami programu.</p>

        </div>
      ),
    },
    {
      // left menu - employee
      selector: ".k-drawer-item[name=Pracownicy]",
      content: ({ setCurrentStep }: { setCurrentStep: (step: number) => void }) => {
        const elements = document.getElementsByName("Pracownicy");
        if (elements.length > 0) {
          const menuButton = elements[0] as HTMLElement;
          menuButton.addEventListener("click", () => setCurrentStep(3));
        }
        return (
          <div className="hideNextButton" style={{ textAlign: "justify" }}>
            <p>
              W pierwszej kolejności zapoznam Cię z modułem ewidencji danych o pracownikach, w którym utworzysz konta swoim pracownikom oraz wprowadzisz ważne informacje kadrowe, np. informacje o umowach, badaniach lekarskich, szkoleniach BHP itp.
            </p>
            <p>
              Aby przejść do modułu z listą kartotek pracowników i kontynuować lekcje kliknij teraz we wskazany przycisk <b>'Pracownicy'</b>.
            </p>
          </div>
        );
      },
    },

    {
      // left menu - employee
      selector: "#fullPageContentWrapper",
      content: ({ setCurrentStep }: { setCurrentStep: (step: number) => void }) => {
        const elements = document.getElementsByName("Pracownicy");
        if (elements.length > 0) {
          const menuButton = elements[0] as HTMLElement;
          menuButton.addEventListener("click", () => setCurrentStep(4));
        }
        return (
          <div className="hideNextButton" style={{ textAlign: "justify" }}>
            <p>
              123
            </p>
          </div>
        );
      },
      position: "center",
    },


    // {
    //   selector: "#btnNewEmployee",
    //   content: ({ setCurrentStep }: { setCurrentStep: (step: number) => void }) => {
    //     const button = document.getElementById("btnNewEmployee");
    //     if (button) {
    //       button.addEventListener("click", () => {
    //         setTimeout(() => {
    //           const tabKontaktoweVisible = document.querySelectorAll("#root > div > div > div > div.k-drawer-content > div > div > div.k-tabstrip.k-pos-relative.k-tabstrip-top > div.k-tabstrip-items-wrapper.k-hstack > ul > li:nth-child(2)").length > 0;
    //           if (tabKontaktoweVisible) setCurrentStep(4)
    //         }, 500);
    //       });
    //     }
    //     return (
    //       <div className="hidePrevButton hideNextButton" style={{ textAlign: "justify" }}>
    //         <p>
    //           W obecnym widoku <b>Kartoteki osobowe pracowników</b> widzisz aktualną listę pracowników z ich podstawowymi danymi takimi jak Imię, Nazwisko, Email itd.
    //         </p>
    //         <p>
    //           Kliknij teraz we wskazany przycisk <b>Nowy pracownik</b>, aby przejść do kolejnej lekcji
    //         </p>
    //       </div>
    //     );
    //   },
    // },

    {
      selector: "#btnNewEmployee",
      content: ({ setCurrentStep }: { setCurrentStep: (step: number) => void }) => {
        const button = document.getElementById("btnNewEmployee");
        if (button) {
          button.addEventListener("click", () => {
            setTimeout(() => {
              const tabKontaktoweVisible = document.querySelectorAll("#root > div > div > div > div.k-drawer-content > div > div > div.k-tabstrip.k-pos-relative.k-tabstrip-top > div.k-tabstrip-items-wrapper.k-hstack > ul > li:nth-child(2)").length > 0;
              if (tabKontaktoweVisible) setCurrentStep(4)
            }, 500);
          });
        }
        return (
          <div className="hidePrevButton hideNextButton" style={{ textAlign: "justify" }}>
            <p>
              W obecnym widoku <b>Kartoteki osobowe pracowników</b> widzisz aktualną listę pracowników z ich podstawowymi danymi takimi jak Imię, Nazwisko, Email itd.
            </p>
            <p>
              Kliknij teraz we wskazany przycisk <b>Nowy pracownik</b>, aby przejść do kolejnej lekcji
            </p>
          </div>
        );
      },
    },



    {
      selector: "#root > div > div > div > div.k-drawer-content > div > div > div.k-tabstrip.k-pos-relative.k-tabstrip-top > div.k-tabstrip-items-wrapper.k-hstack > ul > li:nth-child(2)",
      content: ({ setCurrentStep }: { setCurrentStep: (step: number) => void }) => {
        const elements = document.querySelectorAll("#root > div > div > div > div.k-drawer-content > div > div > div.k-tabstrip.k-pos-relative.k-tabstrip-top > div.k-tabstrip-items-wrapper.k-hstack > ul > li:nth-child(2)");
        if (elements.length > 0) {
          elements[0].addEventListener("click", () => setCurrentStep(5));
        }
        return (
          <div className="hidePrevButton hideNextButton" style={{ textAlign: "justify" }}>
            <p>
              Jesteś na widoku <b>Tworzenie nowej kartoteki pracownika</b>. Widok ten umożliwia wprowadzenie do Systemu kompletu danych pracownika.
            </p>
            <p>Domyślnie widoczna jest zakładka z danymi podstawowymi, które można wprowadzić w odpowiednie pola: Adres e-mail, Imię, Nazwisko, Unikatowy numer ewidencyjny, Data urodzenia, Numer telefonu.</p>
            <p>
              Kliknij teraz we wskazaną zakładkę <b>Kontaktowe</b>, aby przejść do kolejnej lekcji
            </p>
          </div>
        );
      },
    },
    {
      selector: "ul.k-tabstrip-items li:nth-child(3)",
      content: ({ setCurrentStep }: { setCurrentStep: (step: number) => void }) => {
        const elements = document.querySelectorAll("ul.k-tabstrip-items li:nth-child(3)");
        if (elements.length > 0) {
          elements[0].addEventListener("click", () => setCurrentStep(6));
        }
        return (
          <div className="hidePrevButton hideNextButton" style={{ textAlign: "justify" }}>
            <p>
              Cały czas jesteś na widoku <b>Tworzenie nowej kartoteki pracownika</b>. Teraz widzisz zakładkę <b>Kontaktowe</b> tutaj możesz wprowadzić dane adresu zameldowania i adresu do korespondencji.
            </p>
            <p>
              Kliknij teraz we wskazaną zakładkę <b>Kartoteka osobowa</b>, aby przejść do kolejnej lekcji
            </p>
          </div>
        );
      },
    },
    {
      selector: "ul.k-tabstrip-items li:nth-child(4)",
      content: ({ setCurrentStep }: { setCurrentStep: (step: number) => void }) => {
        const elements = document.querySelectorAll("ul.k-tabstrip-items li:nth-child(4)");
        if (elements.length > 0) {
          elements[0].addEventListener("click", () => setCurrentStep(6));
        }
        return (
          <div className="hidePrevButton hideNextButton" style={{ textAlign: "justify" }}>
            <p>Wprowadzając do systemu dane nowego pracownika możesz od razu wprowadzić kompleksowe informacje związane z jego: umowami, badaniami, szkoleniami, powiadomieniami, doświadczeniami, kompetencjami, powierzonymi zasobami, dokumentami i notatki.</p>
            <p>
              Wszystko to jest dostępne na widocznej właśnie zakładce <b>Kartoteka osobowa</b>.
            </p>
            {/* <p>
              Kliknij teraz we wskazaną zakładkę <b>Limity urlopowe</b>, aby przejść do kolejnej lekcji
            </p> */}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setSteps!(steps);
  }, []);

  return <></>;
  // <div className="brick coloredBookmarkWarning" style={{ display: "block" }}>
  //   <div className="itemHeader">Onboarding</div>

  //   <div style={{ fontSize: "0.9em" }}>
  //     <button onClick={() => { setCurrentStep(0); setIsOpen(true); }}>Pokaż samouczek</button>
  //   </div>

  // </div>
};

export default Onboarding;
