import React, { useEffect, useRef, useState } from 'react';

import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';

import NotificationEnum from '../../../../../../models/enums/NotificationEnum';
import LimitTypeOverride from '../../../../../../models/local/Employees/LimitTypeOverride';
import LimitTypeOverrideItem from '../../../../../../models/local/Employees/LimitTypeOverrideItem';
import LimitTypeOverrideItemInitialEditForm from '../../../../../../models/local/Employees/LimitTypeOverrideItemInitialEditForm';
import { DateToDateAsString, mapper } from '../../../../../../models/mapper';
import useAppNotifications from '../../../../../../services/AppNotifications';
import {
  number0to100Validator, requiredValidator
} from '../../../../../../validators/CommonValidators';
import { FormDatePicker, FormInput } from '../../../../../Kendo/form-components';
import EditToolbar from '../../PersonalFiles/LeftSideTabs/Edit/EditToolbar';

const LimitsEdit = (props: { entity: LimitTypeOverride, item: LimitTypeOverrideItem, onCancel: () => void, onSave: (item: LimitTypeOverrideItem) => void }) => {
  const [entity, setEntity] = useState<LimitTypeOverrideItem>(props.item);
  const formRef = useRef<Form>(null);
  const notifications = useAppNotifications();

  const handleChange = (e: any) => {
    if (e.value?.id) //todo: ech...
      e.value = e.value.id;

    setEntity({ ...entity, [e.target.name as string]: e.value });
  };

  const onSave = () => {
    const itemWithSaveValidFromDate = (props.entity.items.find(lti => DateToDateAsString(lti.validFromDate) === DateToDateAsString(entity.validFromDate) && lti.id !== entity.id))
    if (itemWithSaveValidFromDate !== undefined) {
      notifications.showNotification(`Nie można zapisać zmian ponieważ w tabeli istnieje już pozycja, która definiuje limit obowiązujacy od wskazanej daty ${DateToDateAsString(entity.validFromDate)}. Zmień datę, od której obowiązuje limit lub wyedytuj wartość limitu pozycji z tą datą.`, NotificationEnum.Warning);
      return;
    }

    if (formRef?.current?.isValid()) {
      props.onSave(entity);
      notifications.showNotification("Zmiany wprowadzone. Pamiętaj o ich zapisaniu.", NotificationEnum.Info);
    }
  }

  const initValue: LimitTypeOverrideItemInitialEditForm =
    mapper.map(props.item, LimitTypeOverrideItemInitialEditForm, LimitTypeOverrideItem);

  useEffect(() => {
  }, []);

  return (
    <>
      <Form
        ref={formRef}
        initialValues={initValue}
        render={(formRenderProps: FormRenderProps) => (
          <>
            <FormElement style={{ width: 700, }}>

              <div className="mainToolbar">
                <EditToolbar
                  onCancel={props.onCancel}
                  onSave={onSave}
                />
                <div className="itemHeader">
                  {!props.item.isNew &&
                    <p>Zmiana ilości przysługujących pracownikowi urlopu od dnia <b>{new Date(props.item.validFromDate).toISOString().slice(0, 10)}</b> w ramach pozycji "<b>{props.entity.name}</b>" na rok <b>{props.entity.year}</b></p>
                  }

                  {props.item.isNew &&
                    <p>Dodawanie limitu urlopu pracownika w ramach pozycji "<b>{props.entity.name}</b>" na rok <b>{props.entity.year}</b></p>
                  }
                </div>
              </div>

              {props.item.isNew &&
                <Field
                  label={"Data od której obowiązuje limit"}
                  id={"validFromDate"}
                  name={"validFromDate"}
                  component={FormDatePicker}
                  validator={requiredValidator}
                  onChange={handleChange}
                  min={new Date(props.entity.year, 0, 1)}
                  max={new Date(props.entity.year, 11, 31)}
                />}

              <Field
                id={"limit"}
                name={"limit"}
                label={"Limit"}
                component={FormInput}
                validator={number0to100Validator}
                onChange={handleChange}
              />

            </FormElement>
          </>
        )}
      />

    </>);
};
export default LimitsEdit;
