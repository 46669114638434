import React, { useEffect } from 'react';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import { apiClient } from '../api/apiClient';
import { LEAVE_GET_getUserLeaveStats, LIMITS_GET_allForUser } from '../api/apiEndpoints';
import { AddAppForm } from '../components/Leaves/AddApplication/AddAppForm';
import AddAppRangeDetails from '../components/Leaves/AddApplication/AddAppRangeDetails';
import UserLimits from '../components/Leaves/UserLimits';
import UserLeaveStatsResponseDto from '../models/dto/Leaves/Responses/UserLeaveStatsResponseDto';
import AllForUserReponseDto from '../models/dto/Limits/Responses/AllForUserReponseDto';
import NotificationEnum from '../models/enums/NotificationEnum';
import CalculateAppItem from '../models/local/AddLeave/CalculateAppItem';
import UserLeaveStats from '../models/local/Leaves/UserLeaveStats';
import AllUserLimits from '../models/local/Limits/AllUserLimits';
import { mapper } from '../models/mapper';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';

const AddApp = () => {
  const notifications = useAppNotifications();
  const loader = useAppLoader(false);

  const [userId, setUserId] = React.useState<string>();
  const [userLimits, setUserLimits] = React.useState<AllUserLimits>(new AllUserLimits());
  const [userLeaveStats, setUserLeaveStats] = React.useState<UserLeaveStats>(new UserLeaveStats());
  const [selectedYear, setSelectedYear] = React.useState<number>(new Date().getFullYear());

  const [appItems, setAppItems] = React.useState<CalculateAppItem[]>([]);
  const [selected, setSelected] = React.useState(0);

  const onUserIdChanged = (id?: string) => {
    setUserId(id);
  };

  const onAppDetailsChanged = (items: CalculateAppItem[]) => {
    setAppItems(items);
  };



  const getAllData = async (year: number) => {
    loader.showLoading(true);

    const getUserLimitsData = async (year: number): Promise<AllUserLimits | undefined> => {
      const resp = await apiClient({
        method: "GET",
        url: LIMITS_GET_allForUser(userId!, year),
        data: {},
      });

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      const result: AllForUserReponseDto = resp.data.result as AllForUserReponseDto;
      const mappedResult: AllUserLimits = mapper.map(result, AllUserLimits, AllForUserReponseDto);

      return mappedResult;
    };

    const getUserLeaveStats = async (year: number): Promise<UserLeaveStats | undefined> => {
      const resp = await apiClient({
        method: "GET",
        url: LEAVE_GET_getUserLeaveStats(userId!, year),
        data: {},
      });

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      const result: UserLeaveStatsResponseDto = resp.data.result as UserLeaveStatsResponseDto;
      const mappedResult: UserLeaveStats = mapper.map(result, UserLeaveStats, UserLeaveStatsResponseDto);

      return mappedResult;
    };

    Promise
      .all([
        getUserLimitsData(year),
        setSelectedYear(year),
        getUserLeaveStats(year)
      ])
      .then(([userLimits, _, userLeaveStats]) => {
        if (userLimits && userLeaveStats) {
          setUserLimits(userLimits);
          setUserLeaveStats(userLeaveStats);
        } else {
          notifications.showNotification("Wystąpił nieoczekiwany błąd.", NotificationEnum.Error);
        }

        loader.showLoading(false);
      });
  };

  useEffect(() => {
    if (userId) getAllData(selectedYear);
  }, [userId]);

  return (
    <>
      <div className="fullPageContentWrapper">
        <div className="fullWidthColumn">
          <div className="columnsWrapper">
            <div className="leftColumn">
              <TabStrip selected={0}>
                <TabStripTab title="Formularz wniosku o urlop">
                  <AddAppForm year={selectedYear} userLimits={userLimits} userLeaveStats={userLeaveStats} onSetUserId={onUserIdChanged} onAppDaysDetailsChanged={onAppDetailsChanged}></AddAppForm>
                </TabStripTab>
              </TabStrip>
            </div>
            <div className="rightColumn">
              <TabStrip selected={selected} onSelect={(e) => setSelected(e.selected)} >
                <TabStripTab title="Szczegóły składanego wniosku">
                  <AddAppRangeDetails items={appItems}></AddAppRangeDetails>
                </TabStripTab>
                <TabStripTab title="Pozostały urlop">
                  <UserLimits year={selectedYear} userLimits={userLimits} userLeaveStats={userLeaveStats} onSetYear={getAllData} yearReadonly={true}></UserLimits>
                </TabStripTab>
              </TabStrip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddApp;
