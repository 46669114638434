import React from 'react';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';

import Calendar from '../../../models/local/Calendars/Calendar';
import { BooleanToIcon } from '../../../scripts/dictProvider';

const CalendarsList = (props: {
  calendars: Calendar[],
  onEdit: (entityId: string) => void,
  onAdd: () => void,
  onEmployeesAssign: (entityId: string) => void,
  onDelete: (entityId: string) => void,
  onPreview: (entityId: string) => void,
}) => {



  return (
    <>
      {
        <>
          <Toolbar>
            <ToolbarItem>
              <Button fillMode="outline" icon="calendar" onClick={props.onAdd}>
                Utwórz kalendarz
              </Button>
            </ToolbarItem>
            <ToolbarSeparator />
          </Toolbar>

          <p style={{ fontWeight: 400 }}>Dostępne kalendarze dni wolnych</p>

          <Grid style={{ height: "100%" }} data={props.calendars}>
            <Column field="name" title="Nazwa kalendarza" />
            <Column
              field="description"
              title="Opis"
            />
            <Column
              title="Domyślny"
              width="120px"
              cell={(v: GridCellProps) => BooleanToIcon((v.dataItem as Calendar).default)}
            />
            <Column
              title="Operacje"
              width="400px"
              cell={(v: any) => (
                <td>
                  <Button fillMode="outline" icon="calendar" onClick={() => {
                    props.onPreview((v.dataItem as Calendar).id);
                  }} >Podgląd</Button>&nbsp;


                  <Button fillMode="outline" icon="user" onClick={() => {
                    props.onEmployeesAssign((v.dataItem as Calendar).id);
                  }} >Przypisz</Button>&nbsp;

                  <Button fillMode="outline" icon="pencil" onClick={() => {
                    props.onEdit((v.dataItem as Calendar).id);
                  }} >Edytuj</Button>&nbsp;

                  <Button fillMode="outline" icon="trash" onClick={() => {
                    props.onDelete((v.dataItem as Calendar).id);
                  }} >Usuń</Button>
                </td>
              )}
            />
          </Grid>
        </>
      }

    </>
  );
};
export default CalendarsList;
