import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";

export default class ContractEdit {
    id: string;
    contractType: DictItem;
    contractPeriod: DictItem;
    signDate: Date;
    startDate: Date;
    endDate?: Date;
    note: string;
    files: StorageFile[];
}