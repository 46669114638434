import React, { useEffect } from 'react';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import CompetencyDto from '../../../../../models/dto/Employees/Tabs/CompetencyDto';
import ContractDto from '../../../../../models/dto/Employees/Tabs/ContractDto';
import DocDto from '../../../../../models/dto/Employees/Tabs/DocDto';
import MedicalDto from '../../../../../models/dto/Employees/Tabs/MedicalDto';
import NoteDto from '../../../../../models/dto/Employees/Tabs/NoteDto';
import NotificationDto from '../../../../../models/dto/Employees/Tabs/NotificationDto';
import ResourceDto from '../../../../../models/dto/Employees/Tabs/ResourceDto';
import TrainingDto from '../../../../../models/dto/Employees/Tabs/TrainingDto';
import WorkExperienceDto from '../../../../../models/dto/Employees/Tabs/WorkExperienceDto';
import User from '../../../../../models/local/Employees/User';
import { EmployeeSubViewsTypeEnum } from '../../../EmployeeEdit';
import CompetenciesTab from './LeftSideTabs/CompetenciesTab';
import ContractsTab from './LeftSideTabs/ContractsTab';
import DocumentsTab from './LeftSideTabs/DocumentsTab';
import CompetenciesEdit from './LeftSideTabs/Edit/CompetenciesEdit';
import ContractsEdit from './LeftSideTabs/Edit/ContractsEdit';
import DocumentsEdit from './LeftSideTabs/Edit/DocumentsEdit';
import MedicalEdit from './LeftSideTabs/Edit/MedicalEdit';
import NotesEdit from './LeftSideTabs/Edit/NotesEdit';
import NotificationsEdit from './LeftSideTabs/Edit/NotificationsEdit';
import ResourcesEdit from './LeftSideTabs/Edit/ResourcesEdit';
import TrainingEdit from './LeftSideTabs/Edit/TrainingEdit';
import WorkExprerienceEdit from './LeftSideTabs/Edit/WorkExprerienceEdit';
import MedicalTab from './LeftSideTabs/MedicalTab';
import NotesTab from './LeftSideTabs/NotesTab';
import NotificationsTab from './LeftSideTabs/NotificationsTab';
import ResourcesTab from './LeftSideTabs/ResourcesTab';
import TrainingTab from './LeftSideTabs/TrainingTab';
import WorkExprerienceTab from './LeftSideTabs/WorkExprerienceTab';

const PersonalFilesTab = (
  props: {
    user: User,
    onSave: (entity: any, entityId: string, entityType: EmployeeSubViewsTypeEnum) => void,
    onDelete: (entityId: string, entityType: EmployeeSubViewsTypeEnum) => void,
    activeTab: number | undefined,
    setDisableParentSubmit: (value: boolean) => void
  }
) => {
  const [viewState, setViewState] =
    React.useState<{ tabIdx: number, inEditMode: boolean, editedEntityId: string, isNew: boolean }>(
      {
        tabIdx: 0,
        inEditMode: false,
        editedEntityId: "",
        isNew: false
      });

  // events
  const onLeftSideTabSelect = (e: { selected: number }) => {
    props.setDisableParentSubmit(false);
    setViewState({ ...viewState, tabIdx: e.selected, inEditMode: false, editedEntityId: "", isNew: false });
  };

  const onSave = (entity: any, entityId: string) => {
    props.onSave(entity, entityId, viewState.tabIdx); // save item on parent list (form)
    onCancel();
  }

  const onNew = () => {
    props.setDisableParentSubmit(true);
    setViewState({ ...viewState, inEditMode: true, editedEntityId: "", isNew: true });
  };

  const onCancel = () => {
    props.setDisableParentSubmit(false);
    setViewState({ ...viewState, inEditMode: false, editedEntityId: "", isNew: false });
  };

  const onEdit = (entityId: string) => {
    props.setDisableParentSubmit(true);
    setViewState({ ...viewState, inEditMode: true, editedEntityId: entityId, isNew: false });
  };

  // hooks
  useEffect(() => {
    if (props.activeTab !== undefined)
      viewState.tabIdx = props.activeTab;
  }, []);

  return (
    <TabStrip
      selected={viewState.tabIdx}
      onSelect={onLeftSideTabSelect}
      tabPosition="left"
    >
      <TabStripTab title="Umowy">
        {viewState.tabIdx === 0 && viewState.inEditMode ? (
          <ContractsEdit
            entity={viewState.isNew ? new ContractDto() : props.user.contracts.filter(d => d.id === viewState.editedEntityId).at(0)}
            onSave={(entityToSave: ContractDto) => { onSave(entityToSave, viewState.editedEntityId as string) }}
            onCancel={onCancel}
            setDisableParentSubmit={props.setDisableParentSubmit}
          />
        ) : (
          <ContractsTab
            entities={props?.user.contracts}
            onEdit={(entityId: string) => {
              onEdit(entityId);
            }}
            onDelete={(entityId: string) => {
              props.onDelete(entityId, viewState.tabIdx);
            }}
            onNew={onNew}
          />
        )}
      </TabStripTab>
      <TabStripTab title="Badania">
        {viewState.tabIdx === 1 && viewState.inEditMode ? (
          <MedicalEdit
            entity={viewState.isNew ? new MedicalDto() : props.user.medicals.filter(d => d.id === viewState.editedEntityId).at(0)}
            onSave={(entityToSave: MedicalDto) => { onSave(entityToSave, viewState.editedEntityId as string) }}
            onCancel={onCancel}
            setDisableParentSubmit={props.setDisableParentSubmit}
          />
        ) : (
          <MedicalTab
            entities={props?.user.medicals}
            onEdit={(entityId: string) => {
              onEdit(entityId);
            }}
            onDelete={(entityId: string) => {
              props.onDelete(entityId, viewState.tabIdx);
            }}
            onNew={onNew}
          />
        )}
      </TabStripTab>
      <TabStripTab title="Szkolenia">
        {viewState.tabIdx === 2 && viewState.inEditMode ? (
          <TrainingEdit
            entity={viewState.isNew ? new TrainingDto() : props.user.trainings.filter(d => d.id === viewState.editedEntityId).at(0)}
            onSave={(entityToSave: TrainingDto) => { onSave(entityToSave, viewState.editedEntityId as string) }}
            onCancel={onCancel}
            setDisableParentSubmit={props.setDisableParentSubmit}
          />
        ) : (
          <TrainingTab
            entities={props?.user.trainings}
            onEdit={(entityId: string) => {
              onEdit(entityId);
            }}
            onDelete={(entityId: string) => {
              props.onDelete(entityId, viewState.tabIdx);
            }}
            onNew={onNew}
          />
        )}
      </TabStripTab>
      <TabStripTab title="Powiadomienia">
        {viewState.tabIdx === 3 && viewState.inEditMode ? (
          <NotificationsEdit
            entity={viewState.isNew ? new NotificationDto() : props.user.notifications.filter(d => d.id === viewState.editedEntityId).at(0)}
            onSave={(entityToSave: NotificationDto) => { onSave(entityToSave, viewState.editedEntityId as string) }}
            onCancel={onCancel}
            setDisableParentSubmit={props.setDisableParentSubmit} />
        ) : (
          <NotificationsTab
            entities={props?.user.notifications}
            onEdit={(entityId: string) => {
              onEdit(entityId);
            }}
            onDelete={(entityId: string) => {
              props.onDelete(entityId, viewState.tabIdx);
            }}
            onNew={onNew}
          />
        )}
      </TabStripTab>
      <TabStripTab title="Doświadczenie">
        {viewState.tabIdx === 4 && viewState.inEditMode ? (
          <WorkExprerienceEdit entity={viewState.isNew ? new WorkExperienceDto() : props.user.workExperiences.filter(d => d.id === viewState.editedEntityId).at(0)}
            onSave={(entityToSave: WorkExperienceDto) => { onSave(entityToSave, viewState.editedEntityId as string) }}
            onCancel={onCancel}
            setDisableParentSubmit={props.setDisableParentSubmit}
          />
        ) : (
          <WorkExprerienceTab
            entities={props?.user.workExperiences}
            onEdit={(entityId: string) => {
              onEdit(entityId);
            }}
            onDelete={(entityId: string) => {
              props.onDelete(entityId, viewState.tabIdx);
            }}
            onNew={onNew}
          />
        )}
      </TabStripTab>
      <TabStripTab title="Kompetencje">
        {viewState.tabIdx === 5 && viewState.inEditMode ? (
          <CompetenciesEdit entity={viewState.isNew ? new CompetencyDto() : props.user.competencies.filter(d => d.id === viewState.editedEntityId).at(0)}
            onSave={(entityToSave: CompetencyDto) => { onSave(entityToSave, viewState.editedEntityId as string) }}
            onCancel={onCancel}
            setDisableParentSubmit={props.setDisableParentSubmit}
          />
        ) : (
          <CompetenciesTab
            entities={props?.user.competencies}
            onEdit={(entityId: string) => {
              onEdit(entityId);
            }}
            onDelete={(entityId: string) => {
              props.onDelete(entityId, viewState.tabIdx);
            }}
            onNew={onNew}
          />
        )}
      </TabStripTab>
      <TabStripTab title="Zasoby">
        {viewState.tabIdx === 6 && viewState.inEditMode ? (
          <ResourcesEdit entity={viewState.isNew ? new ResourceDto() : props.user.resources.filter(d => d.id === viewState.editedEntityId).at(0)}
            onSave={(entityToSave: ResourceDto) => { onSave(entityToSave, viewState.editedEntityId as string) }}
            onCancel={onCancel}
            setDisableParentSubmit={props.setDisableParentSubmit}
          />
        ) : (
          <ResourcesTab
            entities={props?.user.resources}
            onEdit={(entityId: string) => {
              onEdit(entityId);
            }}
            onDelete={(entityId: string) => {
              props.onDelete(entityId, viewState.tabIdx);
            }}
            onNew={onNew}
          />
        )}
      </TabStripTab>
      <TabStripTab title="Dokumenty">
        {viewState.tabIdx === 7 && viewState.inEditMode ? (
          <DocumentsEdit
            entity={viewState.isNew ? new DocDto() : props.user.docs.filter(d => d.id === viewState.editedEntityId).at(0)}
            onSave={(entityToSave: DocDto) => { onSave(entityToSave, viewState.editedEntityId as string) }}
            onCancel={onCancel}
            setDisableParentSubmit={props.setDisableParentSubmit}
          />
        ) : (
          <DocumentsTab
            entities={props?.user.docs}
            onEdit={(entityId: string) => {
              onEdit(entityId);
            }}
            onDelete={(entityId: string) => {
              props.onDelete(entityId, viewState.tabIdx);
            }}
            onNew={onNew}
          />
        )}
      </TabStripTab>
      {/* <TabStripTab title="Uprawnienia">
        <PermissionsTab></PermissionsTab>
      </TabStripTab> */}
      <TabStripTab title="Notatki">
        {viewState.tabIdx === 8 && viewState.inEditMode ? (
          <NotesEdit
            entity={viewState.isNew ? new NoteDto() : props.user.notes.filter(d => d.id === viewState.editedEntityId).at(0)}
            onSave={(entityToSave: NoteDto) => { onSave(entityToSave, viewState.editedEntityId as string) }}
            onCancel={onCancel}
            setDisableParentSubmit={props.setDisableParentSubmit}
          />
        ) : (
          <NotesTab
            entities={props?.user.notes}
            onEdit={(entityId: string) => {
              onEdit(entityId);
            }}
            onDelete={(entityId: string) => {
              props.onDelete(entityId, viewState.tabIdx);
            }}
            onNew={onNew}
          />
        )}
      </TabStripTab>
    </TabStrip>
  );
};

export default PersonalFilesTab;
