import React, { useContext } from 'react';
import { useNavigate } from 'react-router';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import LeaveStatusEnum, { LeaveStatusEnumTranslation } from '../../../models/enums/LeaveStatusEnum';
import UserRoleEnum from '../../../models/enums/UserRoleEnum';
import MonthViewItem from '../../../models/local/Leaves/MonthViewItem';
import { AppContext, IAppContext } from '../../../services/AppContext';

const CalendarItemPreview = (props: {
  item: MonthViewItem,
  onClose: () => void
}) => {
  const navigate = useNavigate();
  const context = useContext<IAppContext>(AppContext);

  const goToDetailsView = (id: string) => {
    navigate(`/appslist/${id}`);
  }

  return (

    <Dialog title={"Dodatkowe informacje"} onClose={props.onClose}>
      <p style={{ margin: "15px", textAlign: "left" }}>
        <>
          <u>{props.item.firstName} {props.item.lastName}</u><br />
          ({props.item.departments.join(", ")})<br /><br />
          Okres: <br />{props.item.startDate.toISOString().slice(0, 10)} - {props.item.endDate.toISOString().slice(0, 10)}<br /><br />
          Nieobecność: <br />{props.item.limitType.toLowerCase()}<br /><br />
          Status: <br />{LeaveStatusEnumTranslation(props.item.status as LeaveStatusEnum)}<br />
        </>
      </p>
      <DialogActionsBar>
        {(props.item.userId === context.values.userId || context.values.role === UserRoleEnum.MANAGER || context.values.role === UserRoleEnum.HR) &&
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() => { goToDetailsView(props.item.id); }}
          >
            Zobacz szczegóły...
          </button>
        }

        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={props.onClose}
        >
          Zamknij
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default CalendarItemPreview;
