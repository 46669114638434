import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";
import DropdownItem from "../Dropdown/DropdownItem";

export default class AddTrainingForm {
    user: DropdownItem;
    trainingType: DictItem;
    date: Date;
    validityDate?: Date;
    note: string;
    files: StorageFile[];
}