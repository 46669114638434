import LimitTypeOverrideItem from './LimitTypeOverrideItem';

export default class LimitTypeOverride {
    id: string;
    name: string;
    limitScope: string;
    limitUnit: string;

    parentId: string;
    userId: string;

    year: number;
    visible: boolean;

    items: LimitTypeOverrideItem[];
    expanded: boolean;

    sum: number | null;
}