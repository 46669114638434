import React from 'react';

import { classes } from '@automapper/classes';
import {
    CamelCaseNamingConvention, createMapper, ignore, mapFrom, mapWith
} from '@automapper/core';

import { addLeadingZeros } from '../helpers/timeUtils';
import { DictItem, DictTypes, IdToDropDownValue } from '../scripts/dictProvider';
import { AppContext, IAppContext } from '../services/AppContext';
import AddCompetencyRequest from './dto/Briefcases/Requests/AddCompetencyRequest';
import AddContractRequest from './dto/Briefcases/Requests/AddContractRequest';
import AddDocRequest from './dto/Briefcases/Requests/AddDocRequest';
import AddMedicalRequest from './dto/Briefcases/Requests/AddMedicalRequest';
import AddNotificationRequest from './dto/Briefcases/Requests/AddNotificationRequest';
import AddResourceRequest from './dto/Briefcases/Requests/AddResourceRequest';
import AddTrainingRequest from './dto/Briefcases/Requests/AddTrainingRequest';
import AddWorkexperienceRequest from './dto/Briefcases/Requests/AddWorkexperienceRequest';
import CompetencyItemResponse from './dto/Briefcases/Responses/CompetencyItemResponse';
import ContractItemResponse from './dto/Briefcases/Responses/ContractItemResponse';
import DocItemResponse from './dto/Briefcases/Responses/DocItemResponse';
import MedicalItemResponse from './dto/Briefcases/Responses/MedicalItemResponse';
import NotificationItemResponse from './dto/Briefcases/Responses/NotificationItemResponse';
import ResourceItemResponse from './dto/Briefcases/Responses/ResourceItemResponse';
import TrainingItemResponse from './dto/Briefcases/Responses/TrainingItemResponse';
import WorkExperienceItemResponse from './dto/Briefcases/Responses/WorkExperienceItemResponse';
import CalendarItemDto from './dto/Calendars/CalendarItemDto';
import AddCalendarItemRequestDto from './dto/Calendars/Requests/AddCalendarItemRequestDto';
import AddCalendarRequestDto from './dto/Calendars/Requests/AddCalendarRequestDto';
import EditCalendarRequestDto from './dto/Calendars/Requests/EditCalendarRequestDto';
import CalendarResponseDto from './dto/Calendars/Responses/CalendarResponseDto';
import CalendarWithItemsResponse from './dto/Calendars/Responses/CalendarWithItemsResponse';
import UserWithCalendarResponseDto from './dto/Calendars/Responses/UserWithCalendarResponseDto';
import DashboardDataResponseDto from './dto/Dashboard/Responses/DashboardDataResponseDto';
import EditDepartmentRequestDto from './dto/Departments/Requests/EditDepartmentRequestDto';
import NewDepartmentRequestDto from './dto/Departments/Requests/NewDepartmentRequestDto';
import AssignedUnassignedUsersResponseDto from './dto/Departments/Responses/AssignedUnassignedUsersResponseDto';
import DepartmentTreeResponseDto from './dto/Departments/Responses/DepartmentTreeResponseDto';
import UserListItemResponseDto from './dto/Departments/Responses/UserListItemResponseDto';
import DropdownItemRequest from './dto/Dropdown/DropdownItemRequest';
import DropdownItemResponse from './dto/Dropdown/DropdownItemResponse';
import DropdownTreeItemResponse from './dto/Dropdown/DropdownTreeItemResponse';
import ArchivedUserListDto from './dto/Employees/ArchivedUserListDto';
import UserWorkPlanIdDto from './dto/Employees/Requests/UserWorkPlanIdDto';
import UserWorkPlanResponseDto from './dto/Employees/Responses/UserWorkPlan/UserWorkPlanResponseDto';
import StorageFileDto from './dto/Employees/StorageFileDto';
import AddressDto from './dto/Employees/Tabs/AddressDto';
import CompetencyDto from './dto/Employees/Tabs/CompetencyDto';
import ContractDto from './dto/Employees/Tabs/ContractDto';
import CorrAddressDto from './dto/Employees/Tabs/CorrAddressDto';
import DocDto from './dto/Employees/Tabs/DocDto';
import LimitTypeDto from './dto/Employees/Tabs/LimitTypeDto';
import LimitTypeOverrideDto from './dto/Employees/Tabs/LimitTypeOverrideDto';
import LimitTypeOverrideItemDto from './dto/Employees/Tabs/LimitTypeOverrideItemDto';
import MedicalDto from './dto/Employees/Tabs/MedicalDto';
import NoteDto from './dto/Employees/Tabs/NoteDto';
import NotificationDto from './dto/Employees/Tabs/NotificationDto';
import PersonalDataDto from './dto/Employees/Tabs/PersonalDataDto';
import ResourceDto from './dto/Employees/Tabs/ResourceDto';
import TrainingDto from './dto/Employees/Tabs/TrainingDto';
import UserBriefcaseDto from './dto/Employees/Tabs/UserBriefcaseDto';
import WorkExperienceDto from './dto/Employees/Tabs/WorkExperienceDto';
import UserDto from './dto/Employees/UserDto';
import UserListDto from './dto/Employees/UserListDto';
import ExportDataListItemDto from './dto/Export/ExportDataListItemDto';
import LeaveDto from './dto/Leaves/LeaveDto';
import LeaveHistoryDto from './dto/Leaves/LeaveHistoryDto';
import LeaveListItemDto from './dto/Leaves/LeaveListItemDto';
import AddLeaveRequestDto from './dto/Leaves/Requests/AddLeaveRequestDto';
import CalculateAppItemResponseDto from './dto/Leaves/Responses/CalculateAppItemResponseDto';
import CalculateAppResponseDto from './dto/Leaves/Responses/CalculateAppResponseDto';
import LeaveDetailsResponseDto from './dto/Leaves/Responses/LeaveDetailsResponseDto';
import LeavesGroupedByTypeResponseDto from './dto/Leaves/Responses/LeavesGroupedByTypeResponseDto';
import MonthViewItemResponseDto from './dto/Leaves/Responses/MonthViewItemResponseDto';
import SummaryLeaveItemDto from './dto/Leaves/Responses/SummaryLeaveItemDto';
import UserLeaveStatsItemResponseDto from './dto/Leaves/Responses/UserLeaveStatsItemResponseDto';
import UserLeaveStatsResponseDto from './dto/Leaves/Responses/UserLeaveStatsResponseDto';
import ClientLicenceDto from './dto/Licence/ClientLicenceDto';
import LicenceDto from './dto/Licence/LicenceDto';
import LicenceEditRequest from './dto/Licence/LicenceEditRequest';
import LicenceListDto from './dto/Licence/LicenceListDto';
import AllForUserReponseDto from './dto/Limits/Responses/AllForUserReponseDto';
import OfficeDto from './dto/Office/OfficeDto';
import PromoCodeDto from './dto/PromoCode/PromoCodeDto';
import PromoCodeEditRequest from './dto/PromoCode/PromoCodeEditRequest';
import PromoCodeListDto from './dto/PromoCode/PromoCodeListDto';
import EditOfficeLimitsRequest from './dto/Settings/Requests/LimitTypes/EditOfficeLimitsRequest';
import SettingsParametersRequest from './dto/Settings/Requests/SettingsParametersRequest';
import SettingsParametersResponse from './dto/Settings/Responses/SettingsParametersResponse';
import StorageFile from './dto/Storage/StorageFile';
import WorkPlanItemRequestDto from './dto/WorkPlans/Requests/WorkPlanItemRequestDto';
import WorkPlanRequestDto from './dto/WorkPlans/Requests/WorkPlanRequestDto';
import WorkPlanItemIdDto from './dto/WorkPlans/Responses/WorkPlanItemIdDto';
import WorkPlanListResponseDto from './dto/WorkPlans/Responses/WorkPlanListResponseDto';
import WorkPlanResponseDto from './dto/WorkPlans/Responses/WorkPlanResponseDto';
import RegisterWorkTimeRequestDto from './dto/WorkTime/Requests/RegisterWorkTimeRequestDto';
import WorkTimeListResponseDto from './dto/WorkTime/Responses/Personal/WorkTimeListResponseDto';
import WorkTimeGroupItemResponseDto from './dto/WorkTime/Responses/Summary/WorkTimeGroupItemResponseDto';
import WorkTimeGroupListResponseDto from './dto/WorkTime/Responses/Summary/WorkTimeGroupListResponseDto';
import WorkTimeGroupUserItemResponseDto from './dto/WorkTime/Responses/Summary/WorkTimeGroupUserItemResponseDto';
import WorkTimeGroupUserResponseDto from './dto/WorkTime/Responses/Summary/WorkTimeGroupUserResponseDto';
import WorkTimeWithUserResponseDto from './dto/WorkTime/Responses/WorkTimeWithUserResponseDto';
import LeaveStatusEnum from './enums/LeaveStatusEnum';
import AddLeaveForm from './local/AddLeave/AddLeaveForm';
import CalculateApp from './local/AddLeave/CalculateApp';
import CalculateAppItem from './local/AddLeave/CalculateAppItem';
import AddCompetencyForm from './local/Briefcases/AddCompetencyForm';
import AddContractForm from './local/Briefcases/AddContractForm';
import AddDocForm from './local/Briefcases/AddDocForm';
import AddMedicalForm from './local/Briefcases/AddMedicalForm';
import AddNotificationForm from './local/Briefcases/AddNotificationForm';
import AddResourceForm from './local/Briefcases/AddResourceForm';
import AddTrainingForm from './local/Briefcases/AddTrainingForm';
import AddWorkexperienceForm from './local/Briefcases/AddWorkexperienceForm';
import CompetencyEditForm from './local/Briefcases/CompetencyEditForm';
import CompetencyItem from './local/Briefcases/CompetencyItem';
import ContractEdit from './local/Briefcases/ContractEdit';
import ContractItem from './local/Briefcases/ContractItem';
import DocEditForm from './local/Briefcases/DocEditForm';
import DocItem from './local/Briefcases/DocItem';
import MedicalEditForm from './local/Briefcases/MedicalEditForm';
import MedicalItem from './local/Briefcases/MedicalItem';
import NoteEditForm from './local/Briefcases/NoteEditForm';
import NotificationEditForm from './local/Briefcases/NotificationEditForm';
import NotificationItem from './local/Briefcases/NotificationItem';
import ResourceEditForm from './local/Briefcases/ResourceEditForm';
import ResourceItem from './local/Briefcases/ResourceItem';
import TrainingEditForm from './local/Briefcases/TrainingEditForm';
import TrainingItem from './local/Briefcases/TrainingItem';
import WorkExperienceEditForm from './local/Briefcases/WorkExperienceEditForm';
import WorkExperienceItem from './local/Briefcases/WorkExperienceItem';
import Calendar from './local/Calendars/Calendar';
import CalendarItem from './local/Calendars/CalendarItem';
import CalendarUsersListboxItem from './local/Calendars/CalendarUsers';
import CalendarWithItems from './local/Calendars/CalendarWithItems';
import DashboardData from './local/Dashboard/DashboardData';
import DepartmentsEditForm from './local/Departments/DepartmentsEditForm';
import DepartmentsNewForm from './local/Departments/DepartmentsNewForm';
import DepartmentTreeItem from './local/Departments/DepartmentTreeItem';
import DepartmentUser from './local/Departments/DepartmentUser';
import DepartmentUserListboxItem from './local/Departments/DepartmentUserListboxItem';
import DropdownItem from './local/Dropdown/DropdownItem';
import DropdownTreeItem from './local/Dropdown/DropdownITreetem';
import ArchivedUserListItem from './local/Employees/ArchivedUserListItem';
import Competency from './local/Employees/Competency';
import Contract from './local/Employees/Contract';
import Doc from './local/Employees/Doc';
import LimitType from './local/Employees/LimitType';
import LimitTypeOverride from './local/Employees/LimitTypeOverride';
import LimitTypeOverrideItem from './local/Employees/LimitTypeOverrideItem';
import LimitTypeOverrideItemInitialEditForm from './local/Employees/LimitTypeOverrideItemInitialEditForm';
import Medical from './local/Employees/Medical';
import Note from './local/Employees/Note';
import Notification from './local/Employees/Notification';
import Resource from './local/Employees/Resource';
import Training from './local/Employees/Training';
import User from './local/Employees/User';
import UserListItem from './local/Employees/UserListItem';
import UserWorkPlan from './local/Employees/UserWorkPlan';
import WorkExperience from './local/Employees/WorkExperience';
import ExportDataListItem from './local/ExportData/ExportDataListItem';
import Leave from './local/Leaves/Leave';
import LeaveDetails from './local/Leaves/LeaveDetails';
import LeaveHistory from './local/Leaves/LeaveHistory';
import LeaveListItem from './local/Leaves/LeaveListItem';
import LeavesGroupedByType from './local/Leaves/LeavesGroupedByType';
import MonthViewItem from './local/Leaves/MonthViewItem';
import SummaryLeaveItem from './local/Leaves/SummaryLeaveItem';
import UserLeaveStats from './local/Leaves/UserLeaveStats';
import UserLeaveStatsItem from './local/Leaves/UserLeaveStatsItem';
import ClientLicence from './local/Licences/ClientLicence';
import Licence from './local/Licences/Licence';
import LicenceList from './local/Licences/LicenceList';
import AllUserLimits from './local/Limits/AllUserLimits';
import PromoCode from './local/PromoCode/PromoCode';
import PromoCodeList from './local/PromoCode/PromoCodeList';
import SettingsParameters from './local/Settings/Responses/SettingsParameters';
import WorkPlan from './local/WorkPlan/WorkPlan';
import WorkPlanItem from './local/WorkPlan/WorkPlanItem';
import WorkPlanListItem from './local/WorkPlan/WorkPlanListItem';
import WorkTimePersonalGridItem from './local/WorkTime/Personal/WorkTimePersonalGridItem';
import WorkTimePersonalTimeLogGridViewState from './local/WorkTime/Personal/WorkTimePersonalTimeLogGridViewState';
import WorkTimeRegisterFrom from './local/WorkTime/Register/WorkTimeRegisterFrom';
import WorkTimeGroupUser from './local/WorkTime/Summary/WorkTimeGroupUser';
import WorkTimeGroupUserItem from './local/WorkTime/Summary/WorkTimeGroupUserItem';
import WorkTimeSummaryGrid from './local/WorkTime/Summary/WorkTimeSummaryGrid';

export const mapper = createMapper({
    name: 'someName',
    pluginInitializer: classes,
    namingConventions: new CamelCaseNamingConvention()
});

//dto -> local/form
mapper.createMap(ContractDto, Contract)
    .forMember((dest) => dest.isNew, mapFrom((src) => false))
    .forMember((dest) => dest.signDate, mapFrom((src) => new Date(src.signDate)))
    .forMember((dest) => dest.startDate, mapFrom((src) => new Date(src.startDate)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .forMember((dest) => dest.endDate, mapFrom((src) => src.endDate != null ? new Date(src.endDate) : null));

mapper.createMap(MedicalDto, Medical)
    .forMember((dest) => dest.isNew, mapFrom((src) => false))
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .forMember((dest) => dest.validityDate, mapFrom((src) => new Date(src.validityDate)));

mapper.createMap(TrainingDto, Training)
    .forMember((dest) => dest.isNew, mapFrom((src) => false))
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .forMember((dest) => dest.validityDate, mapFrom((src) => new Date(src.validityDate)));

mapper.createMap(NotificationDto, Notification)
    .forMember((dest) => dest.isNew, mapFrom((src) => false))
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .forMember((dest) => dest.validityDate, mapFrom((src) => new Date(src.validityDate)));

mapper.createMap(WorkExperienceDto, WorkExperience)
    .forMember((dest) => dest.isNew, mapFrom((src) => false))
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .forMember((dest) => dest.validityDate, mapFrom((src) => new Date(src.validityDate)));

mapper.createMap(CompetencyDto, Competency)
    .forMember((dest) => dest.isNew, mapFrom((src) => false))
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .forMember((dest) => dest.validityDate, mapFrom((src) => src.validityDate ? new Date(src.validityDate) : null));

mapper.createMap(ResourceDto, Resource)
    .forMember((dest) => dest.isNew, mapFrom((src) => false))
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)))
    .forMember((dest) => dest.returnUntilDate, mapFrom((src) => src.returnUntilDate ? new Date(src.returnUntilDate) : null))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .forMember((dest) => dest.returnDate, mapFrom((src) => src.returnDate ? new Date(src.returnDate) : null));

mapper.createMap(DocDto, Doc)
    .forMember((dest) => dest.isNew, mapFrom((src) => false))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)));

mapper.createMap(NoteDto, Note)
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .forMember((dest) => dest.isNew, mapFrom((src) => false));

mapper.createMap(LimitTypeDto, LimitTypeDto);
mapper.createMap(LimitTypeDto, LimitType)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.limit, mapFrom((src) => src.limit))
    .forMember((dest) => dest.limitScope, mapFrom((src) => src.limitScope))
    .forMember((dest) => dest.limitUnit, mapFrom((src) => src.limitUnit))
    .forMember((dest) => dest.name, mapFrom((src) => src.name))
    .forMember((dest) => dest.visible, mapFrom((src) => src.visible))
    .forMember((dest) => dest.year, mapFrom((src) => src.year))
    .forMember((dest) => dest.sum, mapFrom((src) => src.sum));

mapper.createMap(UserWorkPlanResponseDto, UserWorkPlan)
    .forMember((dest) => dest.isNew, mapFrom((src) => false))
    .forMember((dest) => dest.workPlanId, mapFrom((src) => src.workPlanId))
    .forMember((dest) => dest.userId, mapFrom((src) => src.userId))
    .forMember((dest) => dest.name, mapFrom((src) => src.name))
    .forMember((dest) => dest.type, mapFrom((src) => src.type))
    .forMember((dest) => dest.validFromDate, mapFrom((src) => new Date(src.validFromDate)))
    .forMember((dest) => dest.items, mapWith(WorkPlanItem, WorkPlanItemIdDto, (src) => src.items));

mapper.createMap(LimitTypeOverrideItemDto, LimitTypeOverrideItem)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.limit, mapFrom((src) => src.limit))
    .forMember((dest) => dest.parentId, mapFrom((src) => src.parentId))
    .forMember((dest) => dest.validFromDate, mapFrom((src) => new Date(src.validFromDate)))
    .forMember((dest) => dest.isNew, mapFrom((src) => false));

mapper.createMap(LimitTypeOverrideDto, LimitTypeOverride)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.limitScope, mapFrom((src) => src.limitScope))
    .forMember((dest) => dest.limitUnit, mapFrom((src) => src.limitUnit))
    .forMember((dest) => dest.name, mapFrom((src) => src.name))
    .forMember((dest) => dest.parentId, mapFrom((src) => src.parentId))
    .forMember((dest) => dest.userId, mapFrom((src) => src.userId))
    .forMember((dest) => dest.visible, mapFrom((src) => src.visible))
    .forMember((dest) => dest.year, mapFrom((src) => src.year))
    .forMember((dest) => dest.expanded, mapFrom(() => true))
    .forMember((dest) => dest.items, mapWith(LimitTypeOverrideItem, LimitTypeOverrideItemDto, (src) => src.items))
    .forMember((dest) => dest.sum, mapFrom((src) => src.sum));

mapper.createMap(AllForUserReponseDto, AllUserLimits)
    .forMember((dest) => dest.global, mapWith(LimitType, LimitTypeDto, (src) => src.global))
    .forMember((dest) => dest.individual, mapWith(LimitTypeOverride, LimitTypeOverrideDto, (src) => src.individual));

mapper.createMap(StorageFileDto, StorageFile);
mapper.createMap(StorageFile, StorageFileDto);

mapper.createMap(DashboardDataResponseDto, DashboardData)
    .forMember((dest) => dest.todayInformation, mapFrom((src) => src.todayInformation))
    .forMember((dest) => dest.actualLeaves, ignore())
    .forMember((dest) => dest.forConsiderationLeaves, ignore())
    .forMember((dest) => dest.plannedLeaves, ignore());

mapper.createMap(UserDto, User)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))

    .forMember((dest) => dest.briefcaseId, mapFrom((src) => src.userBriefcase.id))
    .forMember((dest) => dest.contracts, mapWith(Contract, ContractDto, (s) => s.userBriefcase.contracts))
    .forMember((dest) => dest.medicals, mapWith(Medical, MedicalDto, (s) => s.userBriefcase.medicals))
    .forMember((dest) => dest.trainings, mapWith(Training, TrainingDto, (s) => s.userBriefcase.trainings))
    .forMember((dest) => dest.notifications, mapWith(Notification, NotificationDto, (s) => s.userBriefcase.notifications))
    .forMember((dest) => dest.workExperiences, mapWith(WorkExperience, WorkExperienceDto, (s) => s.userBriefcase.workExperiences))
    .forMember((dest) => dest.competencies, mapWith(Competency, CompetencyDto, (s) => s.userBriefcase.competencies))
    .forMember((dest) => dest.resources, mapWith(Resource, ResourceDto, (s) => s.userBriefcase.resources))
    .forMember((dest) => dest.docs, mapWith(Doc, DocDto, (s) => s.userBriefcase.docs))
    .forMember((dest) => dest.notes, mapWith(Note, NoteDto, (s) => s.userBriefcase.notes))

    // .forSelf(PersonalDataDto, (s) => s.personalData) //
    .forMember((dest) => dest.personalDataId, mapFrom((src) => src.personalData?.id))
    .forMember((dest) => dest.firstName, mapFrom((src) => src.personalData?.firstName))
    .forMember((dest) => dest.lastName, mapFrom((src) => src.personalData?.lastName))
    .forMember((dest) => dest.phoneNumber, mapFrom((src) => src.personalData?.phoneNumber))
    .forMember((dest) => dest.uniqueId, mapFrom((src) => src.personalData?.uniqueId))
    .forMember((dest) => dest.avatarId, mapFrom((src) => src.avatarId))
    .forMember((dest) => dest.birthDate, mapFrom((src) => src.personalData?.birthDate != null ? new Date(src.personalData?.birthDate) : null))

    .forMember((dest) => dest.corAddressSameAsAddress, mapFrom((src) => src.userBriefcase.corAddressSameAsAddress ?? false))

    .forMember((dest) => dest.addrId, mapFrom((src) => src.userBriefcase.address?.id))
    .forMember((dest) => dest.addrCity, mapFrom((src) => src.userBriefcase.address?.city))
    .forMember((dest) => dest.addrStreet, mapFrom((src) => src.userBriefcase.address?.street))
    .forMember((dest) => dest.addrStreetNumber, mapFrom((src) => src.userBriefcase.address?.streetNumber))
    .forMember((dest) => dest.addrHouseNumber, mapFrom((src) => src.userBriefcase.address?.houseNumber))
    .forMember((dest) => dest.addrPostalCode, mapFrom((src) => src.userBriefcase.address?.postalCode))

    .forMember((dest) => dest.addrCorespId, mapFrom((src) => src.userBriefcase.correspondenceAddress?.id))
    .forMember((dest) => dest.addrCorespCity, mapFrom((src) => src.userBriefcase.correspondenceAddress?.city))
    .forMember((dest) => dest.addrCorespStreet, mapFrom((src) => src.userBriefcase.correspondenceAddress?.street))
    .forMember((dest) => dest.addrCorespStreetNumber, mapFrom((src) => src.userBriefcase.correspondenceAddress?.streetNumber))
    .forMember((dest) => dest.addrCorespHouseNumber, mapFrom((src) => src.userBriefcase.correspondenceAddress?.houseNumber))
    .forMember((dest) => dest.addrCorespPostalCode, mapFrom((src) => src.userBriefcase.correspondenceAddress?.postalCode))

    .forMember((dest) => dest.supervisorsNames, mapFrom((src) => src.supervisorsNames))
    .forMember((dest) => dest.departmentNames, mapFrom((src) => src.departmentNames))
    .forMember((dest) => dest.accountCreateDate, mapFrom((src) => new Date(src.accountCreateDate)))
    .forMember((dest) => dest.lastLoginDate, mapFrom((src) => new Date(src.lastLoginDate)))

    .forMember((dest) => dest.canBeManager, mapFrom((src) => src.userBriefcase.canBeManager))
    .forMember((dest) => dest.isHR, mapFrom((src) => src.userBriefcase.isHR))
    .forMember((dest) => dest.jobPosition, mapFrom((src) => src.userBriefcase.jobPosition))

    .forMember((dest) => dest.limitTypes, mapWith(LimitType, LimitTypeDto, (src) => src.limitTypes))
    .forMember((dest) => dest.limitTypesOverrides, mapWith(LimitTypeOverride, LimitTypeOverrideDto, (src) => src.limitTypesOverrides))
    .forMember((dest) => dest.userWorkPlans, mapWith(UserWorkPlan, UserWorkPlanResponseDto, (src) => src.userWorkPlans))

    .forMember((dest) => dest.isBlocked, mapFrom((src) => src.isBlocked))
    .forMember((dest) => dest.isActive, mapFrom((src) => src.isActive))
    .forMember((dest) => dest.specialId, mapFrom((src) => src.specialId))

    .beforeMap((a) => {
        //usefull when mapping "new UserDto()" to User

        if (a.personalData == null)
            a.personalData = new PersonalDataDto();

        if (a.userBriefcase == null)
            a.userBriefcase = new UserBriefcaseDto();
        if (a.userBriefcase.address == null)
            a.userBriefcase.address = new AddressDto();
        if (a.userBriefcase.correspondenceAddress == null)
            a.userBriefcase.correspondenceAddress = new AddressDto();

        if (a.userBriefcase.competencies == null)
            a.userBriefcase.competencies = new Array<CompetencyDto>();
        if (a.userBriefcase.contracts == null)
            a.userBriefcase.contracts = new Array<ContractDto>();
        if (a.userBriefcase.docs == null)
            a.userBriefcase.docs = new Array<DocDto>();
        if (a.userBriefcase.medicals == null)
            a.userBriefcase.medicals = new Array<MedicalDto>();
        if (a.userBriefcase.notes == null)
            a.userBriefcase.notes = new Array<NoteDto>();
        if (a.userBriefcase.notifications == null)
            a.userBriefcase.notifications = new Array<NotificationDto>();
        if (a.userBriefcase.resources == null)
            a.userBriefcase.resources = new Array<ResourceDto>();
        if (a.userBriefcase.trainings == null)
            a.userBriefcase.trainings = new Array<TrainingDto>();
        if (a.userBriefcase.workExperiences == null)
            a.userBriefcase.workExperiences = new Array<WorkExperienceDto>();

        if (a.limitTypes == null)
            a.limitTypes = new Array<LimitTypeDto>();
    })

mapper.createMap(UserListDto, UserListItem)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))

    .forMember((dest) => dest.currentDepartmentName, mapFrom((src) => src.currentDepartmentName))
    .forMember((dest) => dest.briefcaseId, mapFrom((src) => src.userBriefcase.id))

    .forMember((dest) => dest.personalDataId, mapFrom((src) => src.personalData?.id))
    .forMember((dest) => dest.firstName, mapFrom((src) => src.personalData?.firstName))
    .forMember((dest) => dest.lastName, mapFrom((src) => src.personalData?.lastName))
    .forMember((dest) => dest.phoneNumber, mapFrom((src) => src.personalData?.phoneNumber))
    .forMember((dest) => dest.uniqueId, mapFrom((src) => src.personalData?.uniqueId))
    .forMember((dest) => dest.birthDate, mapFrom((src) => src.personalData?.birthDate != null ? new Date(src.personalData?.birthDate) : null))

    .forMember((dest) => dest.corAddressSameAsAddress, mapFrom((src) => src.userBriefcase.corAddressSameAsAddress ?? false))

    .forMember((dest) => dest.addrId, mapFrom((src) => src.userBriefcase.address?.id))
    .forMember((dest) => dest.addrCity, mapFrom((src) => src.userBriefcase.address?.city))
    .forMember((dest) => dest.addrStreet, mapFrom((src) => src.userBriefcase.address?.street))
    .forMember((dest) => dest.addrStreetNumber, mapFrom((src) => src.userBriefcase.address?.streetNumber))
    .forMember((dest) => dest.addrHouseNumber, mapFrom((src) => src.userBriefcase.address?.houseNumber))
    .forMember((dest) => dest.addrPostalCode, mapFrom((src) => src.userBriefcase.address?.postalCode))

    .forMember((dest) => dest.addrCorespId, mapFrom((src) => src.userBriefcase.correspondenceAddress?.id))
    .forMember((dest) => dest.addrCorespCity, mapFrom((src) => src.userBriefcase.correspondenceAddress?.city))
    .forMember((dest) => dest.addrCorespStreet, mapFrom((src) => src.userBriefcase.correspondenceAddress?.street))
    .forMember((dest) => dest.addrCorespStreetNumber, mapFrom((src) => src.userBriefcase.correspondenceAddress?.streetNumber))
    .forMember((dest) => dest.addrCorespHouseNumber, mapFrom((src) => src.userBriefcase.correspondenceAddress?.houseNumber))
    .forMember((dest) => dest.addrCorespPostalCode, mapFrom((src) => src.userBriefcase.correspondenceAddress?.postalCode))
    .forMember((dest) => dest.role, mapFrom((src) => src.role))

    .forMember((dest) => dest.isActive, mapFrom((src) => src.isActive))
    .forMember((dest) => dest.isBlocked, mapFrom((src) => src.isBlocked))


    .beforeMap((a) => {
        //usefull when mapping "new UserDto()" to User

        if (a.personalData == null)
            a.personalData = new PersonalDataDto();

        if (a.userBriefcase == null)
            a.userBriefcase = new UserBriefcaseDto();
        if (a.userBriefcase.address == null)
            a.userBriefcase.address = new AddressDto();
        if (a.userBriefcase.correspondenceAddress == null)
            a.userBriefcase.correspondenceAddress = new AddressDto();
    })

mapper.createMap(ArchivedUserListDto, ArchivedUserListItem)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))

    .forMember((dest) => dest.currentDepartmentName, mapFrom((src) => src.currentDepartmentName))

    .forMember((dest) => dest.personalDataId, mapFrom((src) => src.personalData?.id))
    .forMember((dest) => dest.firstName, mapFrom((src) => src.personalData?.firstName))
    .forMember((dest) => dest.lastName, mapFrom((src) => src.personalData?.lastName))
    .forMember((dest) => dest.phoneNumber, mapFrom((src) => src.personalData?.phoneNumber))
    .forMember((dest) => dest.uniqueId, mapFrom((src) => src.personalData?.uniqueId))
    .forMember((dest) => dest.birthDate, mapFrom((src) => src.personalData?.birthDate != null ? new Date(src.personalData?.birthDate) : null))

    .forMember((dest) => dest.corAddressSameAsAddress, mapFrom((src) => src.userBriefcase?.corAddressSameAsAddress ?? false))

    .forMember((dest) => dest.addrId, mapFrom((src) => src.userBriefcase?.address?.id))
    .forMember((dest) => dest.addrCity, mapFrom((src) => src.userBriefcase?.address?.city))
    .forMember((dest) => dest.addrStreet, mapFrom((src) => src.userBriefcase?.address?.street))
    .forMember((dest) => dest.addrStreetNumber, mapFrom((src) => src.userBriefcase?.address?.streetNumber))
    .forMember((dest) => dest.addrHouseNumber, mapFrom((src) => src.userBriefcase?.address?.houseNumber))
    .forMember((dest) => dest.addrPostalCode, mapFrom((src) => src.userBriefcase?.address?.postalCode))

    .forMember((dest) => dest.addrCorespId, mapFrom((src) => src.userBriefcase?.correspondenceAddress?.id))
    .forMember((dest) => dest.addrCorespCity, mapFrom((src) => src.userBriefcase?.correspondenceAddress?.city))
    .forMember((dest) => dest.addrCorespStreet, mapFrom((src) => src.userBriefcase?.correspondenceAddress?.street))
    .forMember((dest) => dest.addrCorespStreetNumber, mapFrom((src) => src.userBriefcase?.correspondenceAddress?.streetNumber))
    .forMember((dest) => dest.addrCorespHouseNumber, mapFrom((src) => src.userBriefcase?.correspondenceAddress?.houseNumber))
    .forMember((dest) => dest.addrCorespPostalCode, mapFrom((src) => src.userBriefcase?.correspondenceAddress?.postalCode))

    .beforeMap((a) => {
        //usefull when mapping "new UserDto()" to User

        if (a.personalData == null)
            a.personalData = new PersonalDataDto();

        if (a.userBriefcase == null)
            a.userBriefcase = new UserBriefcaseDto();
        if (a.userBriefcase.address == null)
            a.userBriefcase.address = new AddressDto();
        if (a.userBriefcase.correspondenceAddress == null)
            a.userBriefcase.correspondenceAddress = new AddressDto();
    })

mapper.createMap(LeaveDto, Leave)
    .forMember((dest) => dest.replacementUsers, mapWith(DropdownItem, DropdownItemResponse, (s) => s.replacementUsers))
    .forMember((dest) => dest.leavesHistory, mapWith(LeaveHistory, LeaveHistoryDto, (s) => s.leavesHistory))
    .forMember((dest) => dest.status, mapFrom((src) => LeaveStatusEnum[src.status.toString().toUpperCase() as keyof typeof LeaveStatusEnum]))
    .forMember((dest) => dest.startDate, mapFrom((src) => new Date(src.startDate)))
    .forMember((dest) => dest.endDate, mapFrom((src) => new Date(src.endDate)))
    .forMember((dest) => dest.isLeaveBeConsideredByUser, mapFrom((src) => src.isLeaveBeConsideredByUser));

mapper.createMap(LeaveListItemDto, LeaveListItem)
    .forMember((dest) => dest.replacementUsers, mapWith(DropdownItem, DropdownItemResponse, (s) => s.replacementUsers))
    .forMember((dest) => dest.status, mapFrom((src) => LeaveStatusEnum[src.status.toString().toUpperCase() as keyof typeof LeaveStatusEnum]))
    .forMember((dest) => dest.startDate, mapFrom((src) => new Date(src.startDate)))
    .forMember((dest) => dest.endDate, mapFrom((src) => new Date(src.endDate)))

    .forMember((dest) => dest.calculations, mapWith(CalculateApp, CalculateAppResponseDto, (s) => s.calculations));

mapper.createMap(LeaveDetailsResponseDto, LeaveDetails)
    .forMember((dest) => dest.replacementUsers, mapWith(DropdownItem, DropdownItemResponse, (s) => s.replacementUsers))
    .forMember((dest) => dest.leavesHistory, mapWith(LeaveHistory, LeaveHistoryDto, (s) => s.leavesHistory))
    .forMember((dest) => dest.status, mapFrom((src) => LeaveStatusEnum[src.status.toString().toUpperCase() as keyof typeof LeaveStatusEnum]))
    .forMember((dest) => dest.startDate, mapFrom((src) => new Date(src.startDate)))
    .forMember((dest) => dest.endDate, mapFrom((src) => new Date(src.endDate)))
    .forMember((dest) => dest.isLeaveBeConsideredByUser, mapFrom((src) => src.isLeaveBeConsideredByUser))
    .forMember((dest) => dest.calculations, mapWith(CalculateApp, CalculateAppResponseDto, (s) => s.calculations));

mapper.createMap(LeaveHistoryDto, LeaveHistory)
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)))
    .forMember((dest) => dest.status, mapFrom((src) => LeaveStatusEnum[src.status.toString().toUpperCase() as keyof typeof LeaveStatusEnum]))
    .forMember((dest) => dest.firstName, mapFrom((src) => src.firstName))
    .forMember((dest) => dest.lastName, mapFrom((src) => src.lastName))
    .forMember((dest) => dest.userId, mapFrom((src) => src.userId));

mapper.createMap(UserLeaveStatsItemResponseDto, UserLeaveStatsItem)
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)))
    .forMember((dest) => dest.leaveTypeId, mapFrom(src => src.leaveTypeId))
    .forMember((dest) => dest.leaveStatus, mapFrom((src) => LeaveStatusEnum[src.leaveStatus.toString().toUpperCase() as keyof typeof LeaveStatusEnum]));

mapper.createMap(LeavesGroupedByTypeResponseDto, LeavesGroupedByType)
    .forMember((dest) => dest.count, mapFrom((src) => src.count))
    .forMember((dest) => dest.leaveTypeId, mapFrom(src => src.leaveTypeId));

mapper.createMap(UserLeaveStatsResponseDto, UserLeaveStats)
    .forMember((dest) => dest.year, mapFrom((src) => src.year))
    .forMember((dest) => dest.detailsList, mapWith(UserLeaveStatsItem, UserLeaveStatsItemResponseDto, (s) => s.detailsList))
    .forMember((dest) => dest.leavesGroupedByType, mapWith(LeavesGroupedByType, LeavesGroupedByTypeResponseDto, (s) => s.leavesGroupedByType));

mapper.createMap(CalculateAppResponseDto, CalculateApp)
    .forMember((dest) => dest.isCalculated, mapFrom((_) => false))
    .forMember((dest) => dest.dates, mapWith(CalculateAppItem, CalculateAppItemResponseDto, (src) => src.dates));

mapper.createMap(CalculateAppItemResponseDto, CalculateAppItem)
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date)));


mapper.createMap(ExportDataListItemDto, ExportDataListItem)
    .forMember((dest) => dest.firstName, mapFrom((src) => src.firstName))
    .forMember((dest) => dest.lastName, mapFrom((src) => src.lastName))
    .forMember((dest) => dest.leaveStatus, mapFrom((src) => LeaveStatusEnum[src.leaveStatus.toString().toUpperCase() as keyof typeof LeaveStatusEnum]))
    .forMember((dest) => dest.startDate, mapFrom((src) => new Date(src.startDate)))
    .forMember((dest) => dest.endDate, mapFrom((src) => new Date(src.endDate)));

mapper.createMap(LicenceListDto, LicenceList)
    .forMember((dest) => dest.validFrom, mapFrom((src) => new Date(src.validFrom)))
    .forMember((dest) => dest.validTo, mapFrom((src) => new Date(src.validTo)))
    .forMember((dest) => dest.promoCode, mapFrom((src) => src.promoCode?.code));

mapper.createMap(PromoCodeListDto, PromoCodeList)
    .forMember((dest) => dest.validFromUtc, mapFrom((src) => new Date(src.validFromUtc)))
    .forMember((dest) => dest.validToUtc, mapFrom((src) => new Date(src.validToUtc)));

mapper.createMap(OfficeDto, DropdownItem)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.text, mapFrom((src) => src.name));

mapper.createMap(LicenceDto, Licence)
    .forMember((dest) => dest.promoCode, mapFrom((src) => src.promoCode))
    .forMember((dest) => dest.validFrom, mapFrom((src) => new Date(src.validFrom)))
    .forMember((dest) => dest.validTo, mapFrom((src) => new Date(src.validTo)))
    .forMember((dest) => dest.office, mapWith(DropdownItem, OfficeDto, (src) => src.office));

mapper.createMap(ClientLicenceDto, ClientLicence)
    .forMember((dest) => dest.validFrom, mapFrom((src) => new Date(src.validFrom)))
    .forMember((dest) => dest.validTo, mapFrom((src) => new Date(src.validTo)));

mapper.createMap(PromoCodeDto, PromoCode)
    .forMember((dest) => dest.validFromUtc, mapFrom((src) => new Date(src.validFromUtc)))
    .forMember((dest) => dest.validToUtc, mapFrom((src) => new Date(src.validToUtc)));;

mapper.createMap(DepartmentTreeResponseDto, DepartmentTreeItem)
    .forMember((dest) => dest.items, mapWith(DepartmentTreeItem, DepartmentTreeResponseDto, (s) => s.items))
    .forMember((dest) => dest.text, mapFrom((src) => src.name))
    .forMember((dest) => dest.expanded, mapFrom((_) => true))
    .forMember((dest) => dest.disabled, mapFrom((_) => false))
    .forMember((dest) => dest.selected, ignore());

mapper.createMap(UserListItemResponseDto, DepartmentUser);
mapper.createMap(AssignedUnassignedUsersResponseDto, DepartmentUserListboxItem)
    .forMember((dest) => dest.selected, mapFrom((_) => false));

mapper.createMap(CalendarResponseDto, Calendar);
mapper.createMap(CalendarItemDto, CalendarItem)
    .forMember((dest) => dest.hasParent, mapFrom((src) => src.hasParent));
mapper.createMap(CalendarWithItemsResponse, CalendarWithItems)
    .forMember((dest) => dest.calendarItems, mapWith(CalendarItem, CalendarItemDto, (s) => s.calendarItems));

mapper.createMap(UserWithCalendarResponseDto, CalendarUsersListboxItem);

mapper.createMap(CompetencyItemResponse, CompetencyItem)
    .forMember((dest) => dest.date, mapFrom((src) => toSafeDate(src.date)));
mapper.createMap(ContractItemResponse, ContractItem)
    .forMember((dest) => dest.signDate, mapFrom((src) => toSafeDate(src.signDate)))
    .forMember((dest) => dest.startDate, mapFrom((src) => toSafeDate(src.startDate)));
mapper.createMap(DocItemResponse, DocItem)
    .forMember((dest) => dest.date, mapFrom((src) => toSafeDate(src.date)));
mapper.createMap(MedicalItemResponse, MedicalItem)
    .forMember((dest) => dest.date, mapFrom((src) => toSafeDate(src.date)));
mapper.createMap(NotificationItemResponse, NotificationItem)
    .forMember((dest) => dest.date, mapFrom((src) => toSafeDate(src.date)))
    .forMember((dest) => dest.validityDate, mapFrom((src) => toSafeDate(src.validityDate)));
mapper.createMap(ResourceItemResponse, ResourceItem)
    .forMember((dest) => dest.returnDate, mapFrom((src) => toSafeDate(src.returnDate)))
    .forMember((dest) => dest.returnUntilDate, mapFrom((src) => toSafeDate(src.returnUntilDate)))
    .forMember((dest) => dest.date, mapFrom((src) => toSafeDate(src.date)));
mapper.createMap(TrainingItemResponse, TrainingItem)
    .forMember((dest) => dest.date, mapFrom((src) => toSafeDate(src.date)))
    .forMember((dest) => dest.validityDate, mapFrom((src) => toSafeDate(src.validityDate)));
mapper.createMap(WorkExperienceItemResponse, WorkExperienceItem)
    .forMember((dest) => dest.date, mapFrom((src) => toSafeDate(src.date)))
    .forMember((dest) => dest.validityDate, mapFrom((src) => toSafeDate(src.validityDate)));

mapper.createMap(SettingsParametersResponse, SettingsParameters);

// ticket: https://www.telerik.com/account/support-center/view-ticket/1627677
mapper.createMap(MonthViewItemResponseDto, MonthViewItem)
    .forMember((dest) => dest.isAllDay, mapFrom((src) => true))
    .forMember((dest) => dest.color, mapFrom((src) => stringToColor(src.firstName + src.lastName)))
    .forMember((dest) => dest.startDate, mapFrom((src) => new Date(src.startDate)))
    .forMember((dest) => dest.endDate, mapFrom((src) => {
        const endDateWithAddedSecond = new Date(src.endDate);
        if (src.endDate === src.startDate)
            endDateWithAddedSecond.setSeconds(endDateWithAddedSecond.getSeconds() + 1);
        return endDateWithAddedSecond;
    }));
// .forMember((dest) => dest.title, mapFrom((src) => `
//     ${(src.status as LeaveStatusEnum) === LeaveStatusEnum.AWAITING ? "[?] " : ""}
//     ${new Date(src.startDate).toISOString().slice(5, 10).replace("-", ".")}-${new Date(src.endDate).toISOString().slice(5, 10).replace("-", ".")}
//     ${src.firstName[0]}. ${src.lastName} [${src.limitType.toLowerCase()}]`))

mapper.createMap(WorkPlanItemIdDto, WorkPlanItem)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.day, mapFrom((src) => src.day))
    .forMember((dest) => dest.hourFrom, mapFrom((src) => TimeOnlyToTime(src.hourFrom.toString())))
    .forMember((dest) => dest.hourTo, mapFrom((src) => TimeOnlyToTime(src.hourTo?.toString())))
    .forMember((dest) => dest.hoursSum, mapFrom((src) => { const source = src.hoursSum.split(":"); return `${source[0]}:${source[1]}`; }))
    .forMember((dest) => dest.enabled, mapFrom((src) => src.enabled));
mapper.createMap(WorkPlanResponseDto, WorkPlan)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.name, mapFrom((src) => src.name))
    .forMember((dest) => dest.isDefault, mapFrom((src) => src.isDefault))
    .forMember((dest) => dest.type, mapFrom((src) => src.type))
    .forMember((dest) => dest.items, mapWith(WorkPlanItem, WorkPlanItemIdDto, (s) => s.items));
mapper.createMap(WorkPlanListResponseDto, WorkPlanListItem)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.name, mapFrom((src) => src.name))
    .forMember((dest) => dest.isDefault, mapFrom((src) => src.isDefault))
    .forMember((dest) => dest.type, mapFrom((src) => src.type));

// dropdowns
mapper.createMap(DropdownItemResponse, DropdownItem);
mapper.createMap(DropdownTreeItemResponse, DropdownTreeItem)
    .forMember((dest) => dest.items, mapWith(DropdownTreeItem, DropdownTreeItemResponse, (s) => s.items))
    .forMember((dest) => dest.expanded, mapFrom((src) => true));
mapper.createMap(WorkPlanListResponseDto, DropdownItem)
    .forMember((dest) => dest.text, mapFrom((src) => src.name))
    .forMember((dest) => dest.id, mapFrom((src) => src.id));

mapper.createMap(WorkTimeWithUserResponseDto, WorkTimePersonalGridItem)
    // .forMember((dest) => dest.departmentsNames, mapFrom((src) => src.departmentsNames))
    // .forMember((dest) => dest.departmentsIds, mapFrom((src) => src.departmentsIds))
    .forMember((dest) => dest.hours, mapFrom((src) => TimeOnlyToTime(src.hours)))
    .forMember((dest) => dest.hourFrom, mapFrom((src) => TimeOnlyToTime(src.hourFrom)))
    .forMember((dest) => dest.hourTo, mapFrom((src) => TimeOnlyToTime(src.hourTo)));

mapper.createMap(WorkTimeListResponseDto, WorkTimePersonalTimeLogGridViewState)
    .forMember((dest) => dest.GroupedGridItems, mapWith(WorkTimePersonalGridItem, WorkTimeWithUserResponseDto, (s) => s.items));

mapper.createMap(WorkTimeGroupUserResponseDto, WorkTimeGroupUser)
    .forMember(d => d.userId, mapFrom(s => s.userId))
    .forMember(d => d.userName, mapFrom(s => s.userName))
    .forMember(d => d.hours, mapFrom(s => s.hours))
    .forMember(d => d.minutes, mapFrom(s => s.minutes))
    .forMember(d => d.hoursQr, mapFrom(s => s.hoursQr))
    .forMember(d => d.minutesQr, mapFrom(s => s.minutesQr))
    // .forMember(d => d.items, mapWith(WorkTimeGroupUserItem, WorkTimeGroupUserItemResponseDto, s => s.items))
    .forMember(d => d.expanded, mapFrom((src) => true));

mapper.createMap(WorkTimeGroupUserItemResponseDto, WorkTimeGroupUserItem)
    .forMember(d => d.userId, mapFrom(s => s.userId))
    .forMember(d => d.date, mapFrom(s => new Date(s.date)))
    .forMember(d => d.hours, mapFrom(s => s.hours));

mapper.createMap(WorkTimeGroupItemResponseDto, WorkTimeSummaryGrid)
    .forMember(d => d.departmentId, mapFrom(s => s.departmentId))
    .forMember(d => d.departmentName, mapFrom(s => s.departmentName))
    .forMember(d => d.userWorkSummaries, mapWith(WorkTimeGroupUser, WorkTimeGroupUserResponseDto, s => s.userWorkSummaries))
    .forMember(d => d.expanded, mapFrom((src) => true));



// ----------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------
// local/form -> dto
// ----------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------

mapper.createMap(User, PersonalDataDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.personalDataId)));

mapper.createMap(DropdownItem, DropdownItemRequest);
mapper.createMap(DictItem, DropdownItemRequest)
    .forMember((dest) => dest.text, mapFrom((src) => (src.name)));

mapper.createMap(LimitType, LimitTypeDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.id)))
    .forMember((dest) => dest.limit, mapFrom((src) => (src.limit)))
    .forMember((dest) => dest.limitScope, mapFrom((src) => (src.limitScope)))
    .forMember((dest) => dest.limitUnit, mapFrom((src) => (src.limitUnit)))
    .forMember((dest) => dest.name, mapFrom((src) => (src.name)))
    .forMember((dest) => dest.visible, mapFrom((src) => (src.visible)))
    .forMember((dest) => dest.sum, ignore());

mapper.createMap(LimitType, EditOfficeLimitsRequest)
    .forMember((dest) => dest.id, mapFrom((src) => (src.id)))
    .forMember((dest) => dest.limit, mapFrom((src) => (src.limit)))
    .forMember((dest) => dest.limitScope, mapFrom((src) => (src.limitScope)))
    .forMember((dest) => dest.limitUnit, mapFrom((src) => (src.limitUnit)))
    .forMember((dest) => dest.visible, mapFrom((src) => (src.visible)))
    .forMember((dest) => dest.forceEdit, ignore());

mapper.createMap(User, AddressDto)
    .forMember((dest) => dest.addressName, ignore())
    .forMember((dest) => dest.id, mapFrom((src) => src.addrId))
    .forMember((dest) => dest.city, mapFrom((src) => src.addrCity))
    .forMember((dest) => dest.houseNumber, mapFrom((src) => src.addrHouseNumber))
    .forMember((dest) => dest.postalCode, mapFrom((src) => src.addrPostalCode))
    .forMember((dest) => dest.street, mapFrom((src) => src.addrStreet))
    .forMember((dest) => dest.streetNumber, mapFrom((src) => src.addrStreetNumber));

mapper.createMap(User, CorrAddressDto)
    .forMember((dest) => dest.addressName, ignore())
    .forMember((dest) => dest.id, mapFrom((src) => src.addrCorespId))
    .forMember((dest) => dest.city, mapFrom((src) => src.addrCorespCity))
    .forMember((dest) => dest.houseNumber, mapFrom((src) => src.addrCorespHouseNumber))
    .forMember((dest) => dest.postalCode, mapFrom((src) => src.addrCorespPostalCode))
    .forMember((dest) => dest.street, mapFrom((src) => src.addrCorespStreet))
    .forMember((dest) => dest.streetNumber, mapFrom((src) => src.addrCorespStreetNumber));

mapper.createMap(Competency, CompetencyDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));
mapper.createMap(Contract, ContractDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));
mapper.createMap(Doc, DocDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));
mapper.createMap(Medical, MedicalDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));
mapper.createMap(Notification, NotificationDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));
mapper.createMap(Resource, ResourceDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));
mapper.createMap(Training, TrainingDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));
mapper.createMap(WorkExperience, WorkExperienceDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));
mapper.createMap(Note, NoteDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(User, UserBriefcaseDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.briefcaseId)))
    .forMember((dest) => dest.contracts, mapWith(ContractDto, Contract, (s) => s.contracts))
    .forMember((dest) => dest.medicals, mapWith(MedicalDto, Medical, (s) => s.medicals))
    .forMember((dest) => dest.trainings, mapWith(TrainingDto, Training, (s) => s.trainings))
    .forMember((dest) => dest.notifications, mapWith(NotificationDto, Notification, (s) => s.notifications))
    .forMember((dest) => dest.workExperiences, mapWith(WorkExperienceDto, WorkExperience, (s) => s.workExperiences))
    .forMember((dest) => dest.competencies, mapWith(CompetencyDto, Competency, (s) => s.competencies))
    .forMember((dest) => dest.resources, mapWith(ResourceDto, Resource, (s) => s.resources))
    .forMember((dest) => dest.docs, mapWith(DocDto, Doc, (s) => s.docs))
    .forMember((dest) => dest.notes, mapWith(NoteDto, Note, (s) => s.notes))

    .forMember((dest) => dest.address, mapFrom((src) => mapper.map(src, AddressDto, User)))
    .forMember((dest) => dest.correspondenceAddress, mapFrom((src) => mapper.map(src, CorrAddressDto, User)));

mapper.createMap(LimitTypeOverrideItem, LimitTypeOverrideItemDto)
    .forMember((dest) => dest.id, mapFrom((src) => (src.isNew === true ? null : src.id)))
    .forMember((dest) => dest.limit, mapFrom((src) => src.limit))
    .forMember((dest) => dest.parentId, mapFrom((src) => src.parentId))
    .forMember((dest) => dest.validFromDate, mapFrom((src) => DateToDateAsString(src.validFromDate)));

mapper.createMap(LimitTypeOverride, LimitTypeOverrideDto)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.limitScope, mapFrom((src) => src.limitScope))
    .forMember((dest) => dest.limitUnit, mapFrom((src) => src.limitUnit))
    .forMember((dest) => dest.name, mapFrom((src) => src.name))
    .forMember((dest) => dest.parentId, mapFrom((src) => src.parentId))
    .forMember((dest) => dest.userId, mapFrom((src) => src.userId))
    .forMember((dest) => dest.visible, mapFrom((src) => src.visible))
    .forMember((dest) => dest.year, mapFrom((src) => src.year))
    .forMember((dest) => dest.items, mapWith(LimitTypeOverrideItemDto, LimitTypeOverrideItem, (src) => src.items))
    .forMember((dest) => dest.sum, ignore());

mapper.createMap(UserWorkPlan, UserWorkPlanIdDto)
    .forMember((dest) => dest.validFromDate, mapFrom((src) => DateToDateAsString(src.validFromDate)))
    .forMember((dest) => dest.workPlanId, mapFrom((src) => src.workPlanId));

mapper.createMap(User, UserDto)
    .forMember((dest) => dest.supervisorsNames, ignore())
    .forMember((dest) => dest.departmentNames, ignore())
    .forMember((dest) => dest.accountCreateDate, ignore())
    .forMember((dest) => dest.limitTypes, mapWith(LimitTypeDto, LimitType, (s) => s.limitTypes))
    .forMember((dest) => dest.personalData, mapFrom((src) => mapper.map(src, PersonalDataDto, User)))
    .forMember((dest) => dest.userBriefcase, mapFrom((src) => mapper.map(src, UserBriefcaseDto, User)))

    .forMember((dest) => dest.limitTypes, ignore())
    .forMember((dest) => dest.limitTypesOverrides, mapWith(LimitTypeOverrideDto, LimitTypeOverride, (src) => src.limitTypesOverrides))

    .forMember((dest) => dest.userWorkPlans, mapWith(UserWorkPlanIdDto, UserWorkPlan, (src) => src.userWorkPlans))

    .forMember((dest) => dest.specialId, ignore());

mapper.createMap(AddLeaveForm, AddLeaveRequestDto)
    .forMember((dest) => dest.limitTypeId, mapFrom((src) => (src.limitType.parentId || src.limitType.id)))
    .forMember((dest) => dest.replacementUsers, mapWith(DropdownItemRequest, DropdownItem, (s) => s.replacementUsers))
    .forMember((dest) => dest.onBehalfOfUserId, mapFrom((src) => src.onBehalfOf?.id))
    .forMember((dest) => dest.startDate, mapFrom((src) => new Date(src.startDate.getTime() - src.startDate.getTimezoneOffset() * 60000).toISOString()))
    .forMember((dest) => dest.endDate, mapFrom((src) => new Date(src.endDate.getTime() - src.endDate.getTimezoneOffset() * 60000).toISOString()));

mapper.createMap(Licence, LicenceEditRequest)
    .forMember((dest) => dest.officeId, mapFrom((src) => (src.office.id)));

mapper.createMap(PromoCode, PromoCodeEditRequest);

//.forMember((dest) => dest.limitType, mapFrom((src) => (GuidGenerator())));

mapper.createMap(DepartmentsEditForm, EditDepartmentRequestDto)
    .forMember((dest) => dest.managers, mapFrom((src) => src.manager == null ? [] : [src.manager?.id]))
    .forMember((dest) => dest.assistantManagers, mapFrom((src) => src.assistantManager == null ? [] : [src.assistantManager?.id]));

mapper.createMap(DepartmentsNewForm, NewDepartmentRequestDto);

mapper.createMap(Calendar, AddCalendarRequestDto);
mapper.createMap(Calendar, EditCalendarRequestDto);
mapper.createMap(CalendarItemDto, AddCalendarItemRequestDto)
    // .beforeMap((a) => { a.date.setUTCHours(0, 0, 0, 0); })
    .forMember((dest) => dest.date, mapFrom((src) => new Date(src.date.getTime() - src.date.getTimezoneOffset() * 60000).toISOString()))

mapper.createMap(AddContractForm, AddContractRequest)
    .forMember((dest) => dest.userId, mapFrom((src) => src.user?.id))
    .forMember((dest) => dest.contractType, mapWith(DropdownItemRequest, DictItem, (s) => s.contractType))
    .forMember((dest) => dest.contractPeriod, mapWith(DropdownItemRequest, DictItem, (s) => s.contractPeriod))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(AddMedicalForm, AddMedicalRequest)
    .forMember((dest) => dest.userId, mapFrom((src) => src.user?.id))
    .forMember((dest) => dest.medicalTestingType, mapWith(DropdownItemRequest, DictItem, (s) => s.medicalTestingType))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(AddTrainingForm, AddTrainingRequest)
    .forMember((dest) => dest.userId, mapFrom((src) => src.user?.id))
    .forMember((dest) => dest.trainingType, mapWith(DropdownItemRequest, DictItem, (s) => s.trainingType))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(AddNotificationForm, AddNotificationRequest)
    .forMember((dest) => dest.userId, mapFrom((src) => src.user?.id))
    .forMember((dest) => dest.notificationType, mapWith(DropdownItemRequest, DictItem, (s) => s.notificationType))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(AddWorkexperienceForm, AddWorkexperienceRequest)
    .forMember((dest) => dest.userId, mapFrom((src) => src.user?.id))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(AddCompetencyForm, AddCompetencyRequest)
    .forMember((dest) => dest.userId, mapFrom((src) => src.user?.id))
    .forMember((dest) => dest.competencyType, mapWith(DropdownItemRequest, DictItem, (s) => s.competencyType))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(AddResourceForm, AddResourceRequest)
    .forMember((dest) => dest.userId, mapFrom((src) => src.user?.id))
    .forMember((dest) => dest.resourceType, mapWith(DropdownItemRequest, DictItem, (s) => s.resourceType))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(AddDocForm, AddDocRequest)
    .forMember((dest) => dest.userId, mapFrom((src) => src.user?.id))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(Doc, DocEditForm)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.name, mapFrom((src) => src.name))
    .forMember((dest) => dest.internalId, mapFrom((src) => src.internalId))
    .forMember((dest) => dest.date, mapFrom((src) => src.date))
    .forMember((dest) => dest.note, mapFrom((src) => src.note))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(Note, NoteEditForm)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.text, mapFrom((src) => src.text))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(WorkExperience, WorkExperienceEditForm)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.name, mapFrom((src) => src.name))
    .forMember((dest) => dest.validityDate, mapFrom((src) => src.validityDate))
    .forMember((dest) => dest.date, mapFrom((src) => src.date))
    .forMember((dest) => dest.note, mapFrom((src) => src.note))
    .forMember((dest) => dest.files, mapFrom((src) => src.files));

mapper.createMap(Medical, MedicalEditForm)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.medicalTestingType, ignore())
    .forMember((dest) => dest.date, mapFrom((src) => src.date))
    .forMember((dest) => dest.validityDate, mapFrom((src) => src.validityDate))
    .forMember((dest) => dest.note, mapFrom((src) => src.note))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .afterMap((src, dest) => {
        const context = React.useContext<IAppContext>(AppContext);
        dest.medicalTestingType = IdToDropDownValue(DictTypes.MedicalTestingTypes, src.medicalTestingType, context.values.dicts);
    });

mapper.createMap(Contract, ContractEdit)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.contractPeriod, ignore())
    .forMember((dest) => dest.contractType, ignore())
    .forMember((dest) => dest.endDate, mapFrom((src) => src.endDate))
    .forMember((dest) => dest.signDate, mapFrom((src) => src.signDate))
    .forMember((dest) => dest.startDate, mapFrom((src) => src.startDate))
    .forMember((dest) => dest.note, mapFrom((src) => src.note))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .afterMap((src, dest) => {
        const context = React.useContext<IAppContext>(AppContext);
        dest.contractPeriod = IdToDropDownValue(DictTypes.ContractPeriods, src.contractPeriod, context.values.dicts);
        dest.contractType = IdToDropDownValue(DictTypes.ContractTypes, src.contractType, context.values.dicts);
    });

mapper.createMap(Notification, NotificationEditForm)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.notificationType, ignore())
    .forMember((dest) => dest.date, mapFrom((src) => src.date))
    .forMember((dest) => dest.validityDate, mapFrom((src) => src.validityDate))
    .forMember((dest) => dest.note, mapFrom((src) => src.note))
    .forMember((dest) => dest.notifyHR, mapFrom((src) => src.notifyHR))
    .forMember((dest) => dest.notifyEmployee, mapFrom((src) => src.notifyEmployee))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .afterMap((src, dest) => {
        const context = React.useContext<IAppContext>(AppContext);
        dest.notificationType = IdToDropDownValue(DictTypes.NotificationTypes, src.notificationType, context.values.dicts);
    });

mapper.createMap(Resource, ResourceEditForm)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.resourceType, ignore())
    .forMember((dest) => dest.date, mapFrom((src) => src.date))
    .forMember((dest) => dest.returnUntilDate, mapFrom((src) => src.returnUntilDate))
    .forMember((dest) => dest.returnDate, mapFrom((src) => src.returnDate))
    .forMember((dest) => dest.note, mapFrom((src) => src.note))
    .forMember((dest) => dest.isReturned, mapFrom((src) => src.isReturned))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .afterMap((src, dest) => {
        const context = React.useContext<IAppContext>(AppContext);
        dest.resourceType = IdToDropDownValue(DictTypes.ResourceTypes, src.resourceType, context.values.dicts);
    });

mapper.createMap(Training, TrainingEditForm)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.trainingType, ignore())
    .forMember((dest) => dest.date, mapFrom((src) => src.date))
    .forMember((dest) => dest.validityDate, mapFrom((src) => src.validityDate))
    .forMember((dest) => dest.note, mapFrom((src) => src.note))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .afterMap((src, dest) => {
        const context = React.useContext<IAppContext>(AppContext);
        dest.trainingType = IdToDropDownValue(DictTypes.TrainingTypes, src.trainingType, context.values.dicts);
    });

mapper.createMap(Competency, CompetencyEditForm)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.competencyType, ignore())
    .forMember((dest) => dest.date, mapFrom((src) => src.date))
    .forMember((dest) => dest.validityDate, mapFrom((src) => src.validityDate))
    .forMember((dest) => dest.note, mapFrom((src) => src.note))
    .forMember((dest) => dest.files, mapFrom((src) => src.files))
    .afterMap((src, dest) => {
        const context = React.useContext<IAppContext>(AppContext);
        dest.competencyType = IdToDropDownValue(DictTypes.CompetencyTypes, src.competencyType, context.values.dicts);
    });

mapper.createMap(SettingsParameters, SettingsParametersRequest);

mapper.createMap(WorkPlanItem, WorkPlanItemRequestDto)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.day, mapFrom((src) => src.day))
    .forMember((dest) => dest.hourFrom, mapFrom((src) => TimeToTimeOnly(src.hourFrom)))
    .forMember((dest) => dest.hourTo, mapFrom((src) => TimeToTimeOnly(src.hourTo)))
    .forMember((dest) => dest.hoursSum, mapFrom((src) => `${src.hoursSum && src.hoursSum !== "" ? src.hoursSum : "00:00"}:00`))
    .forMember((dest) => dest.enabled, mapFrom((src) => src.enabled));
mapper.createMap(WorkPlan, WorkPlanRequestDto)
    .forMember((dest) => dest.id, mapFrom((src) => src.id))
    .forMember((dest) => dest.name, mapFrom((src) => src.name))
    .forMember((dest) => dest.isDefault, mapFrom((src) => src.isDefault))
    .forMember((dest) => dest.type, mapFrom((src) => src.type))
    .forMember((dest) => dest.items, mapWith(WorkPlanItemRequestDto, WorkPlanItem, (s) => s.items));

mapper.createMap(SummaryLeaveItemDto, SummaryLeaveItem)

mapper.createMap(WorkTimeRegisterFrom, RegisterWorkTimeRequestDto)
    .forMember((dest) => dest.description, mapFrom((src) => src.description))
    .forMember((dest) => dest.isDeleted, ignore())
    .forMember((dest) => dest.deleteReason, ignore())
    .forMember((dest) => dest.date, mapFrom((src) => DateToDateAsString(src.date)))
    .forMember((dest) => dest.hourFrom, mapFrom((src) => TimeToTimeOnly(src.hourFrom)))
    .forMember((dest) => dest.hourTo, mapFrom((src) => TimeToTimeOnly(src.hourTo)))
    .forMember((dest) => dest.behalfOfUserId, mapFrom((src) => src.behalfOfUser?.id))
    .forMember((dest) => dest.itemType, ignore());

// local -> initial form values
mapper.createMap(LimitTypeOverrideItem, LimitTypeOverrideItemInitialEditForm)
    .forMember((dest) => dest.validFromDate, mapFrom((src) => new Date(src.validFromDate)))
    .forMember((dest) => dest.limit, mapFrom((src) => src.limit));

mapper.createMap(WorkPlanItem, WorkPlanItem);


// ---------------------------------------------------------------------------------------------------------------

const stringToColor = (str: string) => {
    const pastelColors = [
        "#FFB3BA", "#FFDFBA", "#FFFFBA", "#BAFFC9", "#BAE1FF",
        "#FFBAD2", "#FFD3BA", "#FFF5BA", "#D5FFBA", "#BAD7FF",
        "#FFBACD", "#FFCEBA", "#FFFFBA", "#C9FFBA", "#BABAFF",
    ];

    let hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const colorIndex = Math.abs(hash) % pastelColors.length;
    return pastelColors[colorIndex];
}

export const DateToDateAsString = (src: Date): string => {
    const res = new Date(src.getTime() - src.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
    return res;
}

export const TimeOnlyToTime = (str: string | null): Date | null =>
    str !== null ?
        new Date(0, 0, 0, parseInt(str.split(":")[0]), parseInt(str.split(":")[1]), parseInt(str.split(":")[2]), 0)
        : null;
export const TimeToTimeOnly = (time: Date | null): string =>
    time !== null ?
        `${addLeadingZeros(time.getHours())}:${addLeadingZeros(time.getMinutes())}:${addLeadingZeros(time.getSeconds())}`
        : "00:00:00";

export const dateToHHMM = (date: Date): string => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const toSafeDate = (dateString: Date | null) => dateString === null ? null : new Date(dateString);
