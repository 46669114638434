import React, { useEffect, useState } from 'react';

import { apiClient } from '../../../../../api/apiClient';
import { LEAVE_GET_getUserLeaveStats } from '../../../../../api/apiEndpoints';
import UserLeaveStatsResponseDto from '../../../../../models/dto/Leaves/Responses/UserLeaveStatsResponseDto';
import NotificationEnum from '../../../../../models/enums/NotificationEnum';
import LimitTypeOverride from '../../../../../models/local/Employees/LimitTypeOverride';
import LimitTypeOverrideItem from '../../../../../models/local/Employees/LimitTypeOverrideItem';
import User from '../../../../../models/local/Employees/User';
import UserLeaveStats from '../../../../../models/local/Leaves/UserLeaveStats';
import { mapper } from '../../../../../models/mapper';
import useAppLoader from '../../../../../services/AppLoader';
import useAppNotifications from '../../../../../services/AppNotifications';
import LimitsEdit from './LimitsTab/LimitsEdit';
import LimitsList from './LimitsTab/LimitsList';

const LimitsTab = (props: { data: User; onSave: (item: LimitTypeOverrideItem) => void; onDeleteItem: (item: LimitTypeOverrideItem) => void; onVisibilityChange: (entityId: string, visibility: boolean) => void; setDisableParentSubmit: (value: boolean) => void }) => {
  const notifications = useAppNotifications();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [userLeaveStats, setUserLeaveStats] = useState<UserLeaveStats>(new UserLeaveStats());
  const loader = useAppLoader(true);
  const [viewState, setViewState] = useState<{
    inEditMode: boolean;
    editedItem?: LimitTypeOverrideItem;
  }>({
    inEditMode: false,
    editedItem: undefined,
  });

  const onEdit = (item: LimitTypeOverrideItem) => {
    setViewState({ ...viewState, inEditMode: true, editedItem: item });
    props.setDisableParentSubmit(true);
  };

  const onAdd = (item: LimitTypeOverride) => {
    const newItem = new LimitTypeOverrideItem();
    newItem.isNew = true;
    newItem.parentId = item.id;
    newItem.validFromDate = new Date(item.year, 0, 1);

    setViewState({ ...viewState, inEditMode: true, editedItem: newItem });
    props.setDisableParentSubmit(true);
  };

  const onCancel = () => {
    setViewState({ ...viewState, inEditMode: false, editedItem: undefined });
    props.setDisableParentSubmit(false);
  };

  const getUserLeaveStats = async (year: number) => {
    loader.showLoading(true);
    const resp = await apiClient({
      method: "GET",
      url: LEAVE_GET_getUserLeaveStats(props.data.id, year),
      data: {},
    });

    loader.showLoading(false);
    if (resp.status !== 200) {
      notifications.showNotification(resp.data.errors, NotificationEnum.Error);
      return;
    }

    const result: UserLeaveStatsResponseDto = resp.data.result as UserLeaveStatsResponseDto;
    const mappedResult: UserLeaveStats = mapper.map(result, UserLeaveStats, UserLeaveStatsResponseDto);
    setUserLeaveStats(mappedResult);
  };

  useEffect(() => {
    getUserLeaveStats(year);
  }, [year]);

  return (
    <>
      {viewState.inEditMode ? (
        <LimitsEdit
          entity={props.data.limitTypesOverrides.filter((lto) => lto.id === viewState.editedItem!.parentId).at(0)!}
          item={
            props.data.limitTypesOverrides
              .filter((lto) => lto.id === viewState.editedItem!.parentId)
              .at(0)!
              .items.filter((i) => i.id === viewState.editedItem!.id)
              .at(0)! ?? viewState.editedItem
          }
          onCancel={onCancel}
          onSave={(item) => {
            props.onSave(item);
            onCancel();
          }}
        />
      ) : (
        <LimitsList
          year={year}
          setYear={setYear}
          globalLimits={props.data.limitTypes}
          individualLimits={props.data.limitTypesOverrides}
          leaveStats={userLeaveStats}
          onAddItem={onAdd}
          onEditItem={onEdit}
          onDeleteItem={props.onDeleteItem}
          onVisibilityChange={props.onVisibilityChange}
          readonly={false}
          yearReadonly={false}
        />
      )}
    </>
  );
};

export default LimitsTab;
