import React, { useEffect, useState } from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Hint, Label } from '@progress/kendo-react-labels';

import { LimitTypeScopeEnum } from '../../../models/enums/LimitTypeScopeEnum';
import LimitType from '../../../models/local/Employees/LimitType';
import { DictByType, DictTypes, IdToDropDownValue } from '../../../scripts/dictProvider';
import { AppContext, IAppContext } from '../../../services/AppContext';
import EditToolbar from '../../Employees/EmployeeEdit/Tabs/PersonalFiles/LeftSideTabs/Edit/EditToolbar';

const TypesOfLeaveEdit = (props: { entity: LimitType, onCancel: () => void, onSave: (entity: LimitType) => void }) => {
  const context = React.useContext<IAppContext>(AppContext);
  const [localEntity, setLocalEntity] = useState<LimitType>(props.entity);

  const handleChange = (e: any) => {
    if (e.value?.id) //todo: ech...
      e.value = e.value.id;

    setLocalEntity({ ...localEntity, [e.target.name as string]: e.value });
  };

  const handleLimitTypeChange = (e: any) => {
    if (e.value.id !== LimitTypeScopeEnum.Global)
      localEntity.limit = 0;
    handleChange(e);
  }

  const isLimitValueDisabled = (): boolean => {
    return localEntity.limitScope !== LimitTypeScopeEnum.Global;
  }

  useEffect(() => {
  });

  return (
    <>
      <div className="mainToolbar">
        <div className="itemHeader">Zmiana ilości przysługujących pracownikom dni urlopu w ramach "{props?.entity?.name}"</div>
        <EditToolbar
          onCancel={props.onCancel}
          onSave={() => { props.onSave(localEntity); }}
        ></EditToolbar>
      </div>

      <div className="k-form"
        style={{
          width: 400,
        }}
      >
        <div className="k-form-field">
          <Label></Label>
        </div>

        <div className="k-form-field">
          <Label>Liczba dni dostępnych dla pracownika w ramach limitu</Label>
          <Input
            id={"limit"}
            name={"limit"}
            value={localEntity.limit}
            onChange={handleChange}
            disabled={isLimitValueDisabled()}
          />
        </div>

        <div className="k-form-field">
          <Label>Rodzaj limitu</Label>
          <DropDownList
            name="limitScope"
            value={IdToDropDownValue(DictTypes.LimitTypeScopeEnum, localEntity.limitScope, context.values.dicts)}
            onChange={handleLimitTypeChange}
            data={DictByType(DictTypes.LimitTypeScopeEnum, context.values.dicts)}
            textField="name"
            dataItemKey="id"
          //valueMap={DropDownValueMapper}
          />
          <Hint>
            Limit globalny - wszyscy pracownicy będą mieli narzuconą tą samą ilość dni do wykorzystania,<br /><br />
            Limit indywidualny - limit musi zostać ustalony indywidualnie każdemu pracownikowi w widoku edycji pracownika,<br /><br />
            Bez ograniczen - w ramach tego rodzaju wniosku nie będą obowiązywały ograniczenia w ilości dni do wykorzystania<br />
          </Hint>
        </div>

        {/* <div className="k-form-field">
          <Label>Okres</Label>
          <DropDownList
            name="limitUnit"
            value={IdToDropDownValue(DictTypes.LimitUnitEnum, localEntity.limitUnit, context.session.dicts)}
            onChange={handleChange}
            data={DictByType(DictTypes.LimitUnitEnum, context.session.dicts)}
            textField="name"
            dataItemKey="id"
          //valueMap={DropDownValueMapper}
          />
          <Hint>
            Rok - limit dotyczy jednego roku kalendarzowego<br />
            Miesiąc - limit obowiązuje w ciągu miesiąca
          </Hint>
        </div> */}

        <div className="k-form-field">
          <Checkbox
            name="visible"
            value={localEntity.visible}
            onChange={handleChange}
            label="Aktywny"
          />
          <Hint>Zaznacz jeżeli chcesz aby wszyscy pracownicy widzieli ten rodzaj wniosku w formularzu składania wniosku o urlop.</Hint>
        </div>

      </div>
    </>
  );
};

export default TypesOfLeaveEdit;
