import React from 'react';
import { useNavigate } from 'react-router';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';

import { WorkPlanTypeEnumTranslation } from '../../../models/enums/WorkPlanTypeEnum';
import WorkPlanListItem from '../../../models/local/WorkPlan/WorkPlanListItem';
import { BooleanToIcon } from '../../../scripts/dictProvider';
import PromptDialog from '../../Common/PromptDialog';

const WorkPlansList = (props: {
  workPlans: WorkPlanListItem[],
  onAdd: () => void,
  onEmployeesAssign: (entityId: string) => void,
  onDelete: (entityId: string) => void,
  onPreview: (entityId: string) => void,
}) => {
  const navigate = useNavigate();
  const [viewState, setViewState] = React.useState<{ deleteDialogVisible: boolean, deleteDialogItem?: WorkPlanListItem }>({ deleteDialogVisible: false });

  const onDeleteWorkPlan = () => {
    props.onDelete(viewState.deleteDialogItem!.id);
    setViewState({ ...viewState, deleteDialogVisible: false, deleteDialogItem: undefined })
  };

  return (
    <>
      {viewState.deleteDialogVisible === true && (
        <PromptDialog
          text={`Czy na pewno chcesz usunąć plan "<b>${viewState.deleteDialogItem!.name}</b>" ?`}
          onYes={() => { onDeleteWorkPlan(); }}
          onNo={() => { setViewState({ ...viewState, deleteDialogVisible: false, deleteDialogItem: undefined }) }}
        />
      )}

      <Toolbar>
        <ToolbarItem>
          <Button fillMode="outline" icon="calendar" onClick={props.onAdd}>
            Utwórz plan pracy
          </Button>
        </ToolbarItem>
        <ToolbarSeparator />
      </Toolbar>

      <Grid className="belowToolbarWrapper" data={props.workPlans}>
        <Column field="name" title="Nazwa" />
        <Column field="type" title="Tryb pracy" cell={(v: GridCellProps) => <td>{WorkPlanTypeEnumTranslation((v.dataItem as WorkPlanListItem).type)}</td>} />
        <Column title="Domyślny" width="110px" cell={(v: GridCellProps) => BooleanToIcon((v.dataItem as WorkPlanListItem).isDefault)} />
        <Column
          title="Operacje"
          width="100px"
          cell={(cellProps: any) => (
            <td>
              <Button
                fillMode="outline"
                icon="edit"
                onClick={() => {
                  navigate(`/workPlans/edit/${cellProps.dataItem.id}`);
                }}
              />{" "}
              <Button
                fillMode="outline"
                icon="trash"

                onClick={() => { setViewState({ ...viewState, deleteDialogVisible: true, deleteDialogItem: cellProps.dataItem as WorkPlanListItem }) }} //props.onDelete(cellProps.dataItem.id);
              />
            </td>
          )}
        />
      </Grid>

    </>
  );
};
export default WorkPlansList;