import React, { useCallback, useEffect, useState } from 'react';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';

import { apiClient } from '../../../api/apiClient';
import {
  CALENDAR_DELETE_item_Id, CALENDAR_GET_Id_items, CALENDAR_POST_item,
  CALENDAR_PUT_batchAddDaysOff, CALENDAR_PUT_batchRemoveDaysOff,
  CALENDAR_PUT_restoreDefaultHolidays_Id
} from '../../../api/apiEndpoints';
import CalendarItemDto from '../../../models/dto/Calendars/CalendarItemDto';
import AddCalendarItemRequestDto from '../../../models/dto/Calendars/Requests/AddCalendarItemRequestDto';
import BatchDaysOffRequestBase from '../../../models/dto/Calendars/Requests/BatchDaysOffRequestBase';
import CalendarWithItemsResponse from '../../../models/dto/Calendars/Responses/CalendarWithItemsResponse';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import Calendar from '../../../models/local/Calendars/Calendar';
import CalendarItem from '../../../models/local/Calendars/CalendarItem';
import CalendarWithItems from '../../../models/local/Calendars/CalendarWithItems';
import LegendItem from '../../../models/local/Calendars/LegendItem';
import { mapper } from '../../../models/mapper';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';
import Legend from '../../Common/Legend';
import PromptDialog from '../../Common/PromptDialog';
import YearSelector from '../../Common/YearSelector';
import AddCalendarItemDialog from './AddCalendarItemDialog';
import MemoizedCalendarComponent from './CalendarComponent';
import InfoCalendarItemDialog from './InfoCalendarItemDialog';
import SetDaysDialog from './SetDaysDialog';

const CalendarView = (props: {
  data: Calendar,
  goBack: () => void
}) => {
  const [calendarDetails, setCalendarDetails] = useState<CalendarWithItems>(new CalendarWithItems());
  //const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const loader = useAppLoader(true);
  const notifications = useAppNotifications();
  const [addCalItemDialogState, setAddCalItemDialogState] = React.useState<{ visible: boolean, date: Date | null }>(
    {
      visible: false,
      date: null
    });
  const [infoDialogState, setInfoDialogState] = React.useState<{ visible: boolean, data: CalendarItem | null }>(
    {
      visible: false,
      data: null
    });
  const [setDaysDialogState, setSetDaysDialogState] = React.useState<{ visible: boolean }>({ visible: false, });
  const [selectedYear, setSelectedYear] = React.useState<{ value: number }>({ value: new Date().getFullYear() });


  const [promptDialogVisible, setPromptDialogVisible] = React.useState<boolean>(false);

  const getLegendItems = (): LegendItem[] => {
    return [
      { text: "dzień roboczy", color: "rgba(127, 186, 0, 0.2)" },
      { text: "dzień wolny", color: "rgb(255, 185, 0)" },
      { text: "dzień ustawowo wolny", color: "rgba(242, 80, 34, 0.6)" },
    ]
  }

  const getCalendarDetails = async (year: number) => {
    const getCalendarDetailsAsync = async (year: number) => {
      loader.showLoading(true);
      //setIsLoading(true);
      await apiClient({
        method: "GET",
        url: CALENDAR_GET_Id_items(props.data.id, year),
        data: {},
      }).then((resp) => {
        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }

        const mappedResult: CalendarWithItems =
          mapper.map(resp?.data?.result, CalendarWithItems, CalendarWithItemsResponse);

        setCalendarDetails(mappedResult);

        //setIsLoading(false);
        loader.showLoading(false);
      });
    };

    await getCalendarDetailsAsync(year);
  }

  const deleteCalendarItem = (id: string) => {
    const deleteCalendarItemAsync = async (id: string) => {
      //setIsLoading(true);
      loader.showLoading(true);
      apiClient({
        method: "DELETE",
        url: CALENDAR_DELETE_item_Id(id),
        data: {},
      }).then((resp) => {
        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }
        notifications.showNotification("Wpis został usunięty", NotificationEnum.Success);
        toggleInfoDialog(null, false);
        getCalendarDetails(selectedYear.value);
      });
    };

    deleteCalendarItemAsync(id);
  }

  const addCalendarItem = (entity: CalendarItem) => {
    const addCalendarItemAsync = async (entity: AddCalendarItemRequestDto) => {
      //setIsLoading(true);
      loader.showLoading(true);
      apiClient({
        method: "POST",
        url: CALENDAR_POST_item,
        data: entity,
      }).then((resp) => {
        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }
        notifications.showNotification("Wpis został dodany", NotificationEnum.Success);
        toggleAddDialog(null, false);
        getCalendarDetails(selectedYear.value);
      });
    };

    entity.calendarId = props.data.id;
    const addCalendarItemRequestDto: AddCalendarItemRequestDto =
      mapper.map(entity, AddCalendarItemRequestDto, CalendarItemDto);

    addCalendarItemAsync(addCalendarItemRequestDto);
  }

  const toggleInfoDialog = (data: CalendarItem | null, visible: boolean) => {
    setInfoDialogState({ ...infoDialogState, data: data, visible: visible })
  }

  const toggleAddDialog = (date: Date | null, visible: boolean) => {
    setAddCalItemDialogState({ ...addCalItemDialogState, date: date, visible: visible })
  }

  const toggleSetDaysDialog = (visible: boolean) => {
    setSetDaysDialogState({ ...setDaysDialogState, visible: visible })
  }

  const handleYearChange = async (year: number) => {
    setSelectedYear({ ...selectedYear, value: year });
    await getCalendarDetails(year);
  };

  const onRestoreDefaultHolidays = () => {
    const restoreDefaultHolidaysAsync = async () => {
      //setIsLoading(true);
      loader.showLoading(true);
      apiClient({
        method: "PUT",
        url: CALENDAR_PUT_restoreDefaultHolidays_Id(props.data.id),
        data: {},
      }).then((resp) => {
        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }
        notifications.showNotification("Przywrócono domyślne dni ustawowo wolne", NotificationEnum.Success);
        setPromptDialogVisible(false);
        getCalendarDetails(selectedYear.value);
      });
    };

    restoreDefaultHolidaysAsync();
  }


  const onBatchSetDaysOff = (days: number[], operation: string) => {
    const batchSetDaysOffRequestAsync = async () => {
      //setIsLoading(true);
      loader.showLoading(true);
      setSetDaysDialogState({ visible: false });

      const requestData: BatchDaysOffRequestBase =
      {
        calendarId: props.data.id,
        year: selectedYear.value,
        days: days
      };

      await apiClient({
        method: "PUT",
        url: operation === "add" ? CALENDAR_PUT_batchAddDaysOff : CALENDAR_PUT_batchRemoveDaysOff,
        data: requestData,
      }).then((resp) => {
        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }

        getCalendarDetails(selectedYear.value);
      });
    };

    batchSetDaysOffRequestAsync();
  }

  useEffect(() => {
    getCalendarDetails(selectedYear.value);
  }, []);

  const memoizedToggleAddDialog = useCallback(toggleAddDialog, []);
  const memoizedToggleInfoDialog = useCallback(toggleInfoDialog, []);

  return (
    <>
      {infoDialogState.visible === true && (
        <InfoCalendarItemDialog
          onClose={() => { toggleInfoDialog(null, false); }}
          onDelete={(id: string) => { deleteCalendarItem(id) }}
          calendarItem={infoDialogState.data as CalendarItem}
        />
      )}

      {promptDialogVisible === true && (
        <PromptDialog
          text="Czy na pewno chcesz przywrócić domyślne dni wolne ?"
          onYes={() => { onRestoreDefaultHolidays(); }}
          onNo={() => { setPromptDialogVisible(false); }}
        />
      )}

      {addCalItemDialogState.visible === true && (
        <AddCalendarItemDialog
          onAdd={(entity: CalendarItem) => { addCalendarItem(entity) }}
          onCancel={() => { toggleAddDialog(null, false); }}
          date={new Date(addCalItemDialogState?.date as Date)}
        />
      )}

      {setDaysDialogState.visible === true && (
        <SetDaysDialog
          onCancel={() => { setSetDaysDialogState({ visible: false }); }}
          onSave={onBatchSetDaysOff}
        />
      )}

      <p>
        <Toolbar>
          <ToolbarItem>
            <Button fillMode="outline" icon="undo" onClick={props.goBack}>
              Powrót
            </Button>&nbsp;
          </ToolbarItem>
          <ToolbarSeparator />
          <ToolbarItem>
            <Button fillMode="outline" icon="edit" onClick={() => { setPromptDialogVisible(true); }}>
              Przywróć ustawowe dni wolne
            </Button>&nbsp;
          </ToolbarItem>
          <ToolbarItem>
            <Button fillMode="outline" icon="calendar" onClick={() => { toggleSetDaysDialog(true); }}>
              Hurtowe oznaczanie dni
            </Button>
          </ToolbarItem>
        </Toolbar>
      </p>

      <p style={{ fontWeight: 400 }}>
        Kalendarz "{props.data.name}"
        na rok <YearSelector initialYear={new Date().getFullYear()} onValueChanged={handleYearChange} readonly={false} />
        <Legend items={getLegendItems()}></Legend>
      </p>

      {!loader.isLoading() &&
        <MemoizedCalendarComponent year={selectedYear.value} calendarItems={calendarDetails.calendarItems}
          toggleAddDialog={memoizedToggleAddDialog} toggleInfoDialog={memoizedToggleInfoDialog} />
      }
    </>
  );
};
export default CalendarView;
