import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Button } from '@progress/kendo-react-buttons';
import { Avatar } from '@progress/kendo-react-layout';

import { baseUrl } from '../../api/apiClient';
import { STORAGE_GET_avatar } from '../../api/apiEndpoints';
import defaultAvatar from '../../assets/avatar2.jpg';
import profileBg from '../../assets/profile-bg.png';
import UserRoleEnum, { UserRoleEnumTranslation } from '../../models/enums/UserRoleEnum';
import TodayInformation from '../../models/local/Dashboard/TodayInformation';
import { AppContext, IAppContext } from '../../services/AppContext';
import ImgWithJWTToken from '../Common/ImgWithJWTToken';

const UserInfo = (props: { data: TodayInformation }) => {
  const context = useContext<IAppContext>(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("UserInfo CTX:", context)
  }, [])


  return (
    <div id="userInfo" style={{ display: "flex", width: "100%", flexDirection: "column" }} className="brickframe">
      <div style={{ display: "flex", color: "#fff", backgroundColor: "#272962", fontWeight: "400", height: "120px" }}>
        <div style={{ width: "100%", padding: "10px" }}>Witaj ponownie,<br />{context.values.userName}</div>
        <div style={{ width: "100%", textAlign: "right", padding: "10px" }}>
          {UserRoleEnumTranslation(context.values.role as UserRoleEnum)}
        </div>
      </div>
      <div style={{ backgroundColor: "#fff", width: "100%", height: "60%" }}>
        <div style={{ position: "relative", textAlign: "right", right: "10px", top: "10px" }}>
          <Button fillMode="outline" size="small" icon="user" style={{ color: "#1c66d6" }} onClick={() => { navigate("/userinfo"); }} >Zobacz profil</Button>
        </div>
        <div style={{ width: "75px", height: "75px", border: "5px solid #fff", marginTop: "-80px", marginLeft: "25px", marginBottom: "15px", borderRadius: "25%", overflow: "hidden" }}>
          {context.values.avatarId && <ImgWithJWTToken src={baseUrl + STORAGE_GET_avatar(context.values.avatarId)} alt="User avatar" width="100%" />}
          {!context.values.avatarId && <img src={defaultAvatar} alt="user-avatar" style={{ width: "100%" }} />}

          {/* {context.session.avatarId && <ImgWithJWTToken src={baseUrl + STORAGE_GET_avatar(context.session.avatarId)} className="" alt="User avatar" width="" height="" base64={false} />}
          {!context.session.avatarId && <img src={userAvatar} alt="user-avatar" />}

          <img src={userAvatar} alt="user-avatar" style={{ width: "100%" }} /> */}
        </div>
        <div style={{ marginLeft: "25px", marginRight: "25px", padding: "10px", minHeight: "60px", textAlign: "justify" }}>
          Dzisiaj jest <b>{props.data.dayOfWeek}, {props.data.dateInText} r.</b><br />Jest to {props.data.dayOfYear} dzień roku. Do końca zostało jeszcze {props.data.daysRemaining} dni.
          <br />Imieniny {props.data.namedays.replace(" ", ", ")}.
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
