import * as React from 'react';
import { useNavigate } from 'react-router';

import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';

import { apiClient } from '../../api/apiClient';
import { USER_PUT_MoveToArchive } from '../../api/apiEndpoints';
import NotificationEnum from '../../models/enums/NotificationEnum';
import UserRoleEnum, { UserRoleEnumTranslation } from '../../models/enums/UserRoleEnum';
import UserListItem from '../../models/local/Employees/UserListItem';
import Loading from '../../pages/Loading';
import { AppContext, IAppContext } from '../../services/AppContext';
import useAppLoader from '../../services/AppLoader';
import useAppNotifications from '../../services/AppNotifications';
import PromptDialog from '../Common/PromptDialog';

const EmployeesList = (props: {
  users: UserListItem[] | undefined,
  reloadData: () => void
}) => {
  const navigate = useNavigate();
  const loader = useAppLoader(false);
  const notifications = useAppNotifications();
  const context = React.useContext<IAppContext>(AppContext);

  const [viewState, setViewState] = React.useState<{ moveToArchiveUserId: string, moveToArchiveDialogVisible: boolean }>(
    {
      moveToArchiveUserId: "",
      moveToArchiveDialogVisible: false
    });


  const onMoveToArchive = (entityId: string) => {
    setViewState({ ...viewState, moveToArchiveUserId: entityId, moveToArchiveDialogVisible: true });
  };

  const onMoveToArchivePUT = () => {
    loader.showLoading(true);
    apiClient({
      method: "PUT",
      url: USER_PUT_MoveToArchive,
      data: {
        userId: viewState.moveToArchiveUserId
      },
    }).then(async (resp) => {
      loader.showLoading(false);
      setViewState({ ...viewState, moveToArchiveDialogVisible: false });
      if (resp.status === 200) {
        notifications.showNotification("Pracownik został przeniesiony do archiwum", NotificationEnum.Success);
        props.reloadData();
      }
      else
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
    });
  };

  return (
    <>
      {viewState.moveToArchiveDialogVisible === true && (
        <PromptDialog
          text="Czy na pewno przenieść pracownika do archiwum?"
          onYes={() => { onMoveToArchivePUT(); }}
          onNo={() => { setViewState({ ...viewState, moveToArchiveDialogVisible: false }); }}
        />
      )}

      <Grid className="belowToolbarWrapper" data={props?.users}>
        <Column field="firstName" title="Imię" />
        <Column field="lastName" title="Nazwisko" />
        <Column field="email" title="Email" />
        <Column field="currentDepartmentName" title="Dział(y)" cell={(d: GridCellProps) => (<td style={!(d.dataItem as UserListItem).currentDepartmentName ? { color: "red" } : { color: "black" }}>{d.dataItem.currentDepartmentName ?? "nieprzypisany"}</td>)} />
        <Column field="uniqueId" title="Nr ewidencyjny" />
        <Column title="Rola" cell={(d: GridCellProps) => (<td>{UserRoleEnumTranslation((d.dataItem as UserListItem).role.toUpperCase() as UserRoleEnum)}</td>)} />
        <Column title="Aktywny" cell={(d: GridCellProps) => (<td>{(d.dataItem as UserListItem).isActive && (d.dataItem as UserListItem).isBlocked === false ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon style={{ color: "red" }} icon={faXmark} />}</td>)} />
        {context.values.role === UserRoleEnum.HR &&
          (
            <Column
              title="Operacje"
              width="100px"
              cell={(props: any) => (
                <td>
                  <Button
                    fillMode="outline"
                    icon="edit"
                    onClick={() => {
                      navigate(`/employees/edit/${props.dataItem.id}`);
                    }}
                  />{" "}
                  <Button
                    fillMode="outline"
                    icon="trash"
                    onClick={() => {
                      onMoveToArchive(props.dataItem.id);
                    }}
                  />
                </td>
              )}
            />
          )}
      </Grid>

    </>
  );
};
export default EmployeesList;
