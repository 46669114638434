enum LeaveDaySourceEnum {
  WORKINGDAY = "WorkingDay",
  WORKPLAN = "WorkPlan",
  CALENDARSTATUTORY = "CalendarStatutory",
  CALENDARCUSTOM = "CalendarCustom",
};

export const LeaveDaySourceEnumTranslation = (value: LeaveDaySourceEnum | undefined) => {
  let translation: string = "";

  switch (value) {
    case undefined:
      translation = "***ERROR***";
      break;
    case LeaveDaySourceEnum.WORKINGDAY:
      translation = "pracujący";
      break;
    case LeaveDaySourceEnum.WORKPLAN:
      translation = "wolny (plan pracy)";
      break;
    case LeaveDaySourceEnum.CALENDARSTATUTORY:
      translation = "wolny (kalendarz, święto)";
      break;
    case LeaveDaySourceEnum.CALENDARCUSTOM:
      translation = "wolny (kalendarz)";
      break;
  }

  return translation;
}

export default LeaveDaySourceEnum;