import * as React from "react";

import {
  Toolbar,
  ToolbarItem,
  ToolbarSeparator,
  Button,
} from "@progress/kendo-react-buttons";

const PersonalFilesToolbar = (props1) => {
  return (
    <Toolbar>
      <ToolbarItem>
        <Button
          fillMode="outline"
          icon="save"
          id="submit"
          className="k-button"
          onClick={() => {
            props1.onNewItem();
          }}
          // disabled={!formRenderProps.allowSubmit}
        >
          Dodaj pozycję
        </Button>
      </ToolbarItem>
      <ToolbarSeparator />
    </Toolbar>
  );
};

export default PersonalFilesToolbar;
