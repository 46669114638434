import DropdownItem from '../../Dropdown/DropdownItem';

export default class WorkTimeRegisterFrom {
    date: Date;
    hourFrom: Date;
    hourTo: Date;
    hours: string;
    isInEditMode: boolean;
    description?: string;
    behalfOfUser?: DropdownItem;

    constructor(date: Date | any, hourFrom: Date | any, hourTo: Date | any, hours: string | any, isInEditMode: boolean | any, description?: string | any, behalfOfUser?: DropdownItem | any) {
        this.date = date;
        this.hourFrom = hourFrom;
        this.hourTo = hourTo;
        this.hours = hours ?? "--:--";
        this.isInEditMode = isInEditMode;
        this.description = description;
        this.behalfOfUser = behalfOfUser;
    }
}