import * as React from 'react';

import worriedImg from '../assets/worried.png';
import useSessionWrapper from '../services/SessionWrapper';

const containerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
};

const childStyle: React.CSSProperties = {
  width: "400px",
  height: "300px",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  justifyContent: "center",
};

const ErrorPage = () => {
  // const sessionWrapper = useSessionWrapper();

  React.useEffect(() => {
    console.log("ErrorPage / loggin out.");
    localStorage.clear();
  }, []);

  return (
    <div style={containerStyle}>
      <div style={childStyle}>
        <p>
          Oooops!
          <br />
          Mamy problem. <br />
          Serwer nie odpowiada.
        </p>

        <p>
          <a href="/">Kliknij tutaj, aby wrócić na stronę logowania.</a>
        </p>
      </div>
      <div style={childStyle}>
        <img
          src={worriedImg}
          alt="Coś poszło nie tak..."
          style={{ width: "75%" }}
        />
      </div>
    </div>
  );
};

export default ErrorPage;
