import { getter } from "@progress/kendo-react-common";
import {} from "./CommonValidators";

export const codeValidator = (value: string) =>
  !value
    ? "Kod jest wymagany"
    : value.length < 2
    ? "Kod musi mieć przynajmniej 2 znaki"
    : "";
const codeGetter = getter("code");

export const formValidator = (values: any) => {
  const code = codeGetter(values);

  if (code) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: "Uzupełnij wszystkie wymagane pola.",
  };
};
