import React, { useEffect, useState } from 'react';

import { apiClient } from '../../../api/apiClient';
import {
  CALENDAR_DELETE_calendar, CALENDAR_GET_calendars, CALENDAR_POST_calendar, CALENDAR_PUT_calendar
} from '../../../api/apiEndpoints';
import EditCalendarRequestDto from '../../../models/dto/Calendars/Requests/EditCalendarRequestDto';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import Calendar from '../../../models/local/Calendars/Calendar';
import { mapper } from '../../../models/mapper';
import Loading from '../../../pages/Loading';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';
import ErrorMessage from '../../Common/ErrorMessage';
import PromptDialog from '../../Common/PromptDialog';
import AddEditCalendar from './AddEditCalendar';
import CalendarsList from './CalendarsList';
import CalendarView from './CalendarView';
import EmployeesAssign from './EmployeesAssign';

enum EditState {
  CalendarsList,
  CalendarAdd,
  CalendarEdit,
  CalendarPreview,
  EmployeeAssign,
}

const SettingsCalendarComponent = () => {
  const [viewState, setViewState] = React.useState<{ editState: EditState, editedEntityId: string }>(
    {
      editState: EditState.CalendarsList,
      editedEntityId: "",
    });

  const [deleteDialogState, setDeleteDialogState] = React.useState<{ visible: boolean, id: string }>(
    {
      visible: false,
      id: ""
    });

  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const loader = useAppLoader(true);
  const notifications = useAppNotifications();

  const loadData = async () => {
    const getOfficeCalendars = async () => {
      //setIsLoading(true);
      loader.showLoading(true);
      await apiClient({
        method: "GET",
        url: CALENDAR_GET_calendars,
        data: {},
      }).then((resp) => {
        setCalendars(resp?.data?.result);
        //setIsLoading(false);
        loader.showLoading(false);
        setViewState({ ...viewState, editState: EditState.CalendarsList, editedEntityId: "" });
      });
    };

    // Promise.all([getTemplates(), getForOffice()]).then(() => {
    Promise.all([getOfficeCalendars()]).then(() => {
    });
  }

  const onAddCalendar = () => {
    setViewState({ ...viewState, editState: EditState.CalendarAdd, editedEntityId: "" });
  };

  const onEditCalendar = (entityId: string) => {
    setViewState({ ...viewState, editState: EditState.CalendarEdit, editedEntityId: entityId });
  };

  const onPreviewCalendar = (entityId: string) => {
    setViewState({ ...viewState, editState: EditState.CalendarPreview, editedEntityId: entityId });
  };

  const showCalendarsList = () => {
    setViewState({ ...viewState, editState: EditState.CalendarsList, editedEntityId: "" });
  };

  const onEmployeesAssign = (entityId: string) => {
    setViewState({ ...viewState, editState: EditState.EmployeeAssign, editedEntityId: entityId });
  };


  const onSaveCalendar = (entity: Calendar) => {
    const dtoToSend: Calendar = mapper.map(entity, EditCalendarRequestDto, Calendar);

    apiClient({
      method: dtoToSend.id ? "PUT" : "POST",
      url: dtoToSend.id ? CALENDAR_PUT_calendar : CALENDAR_POST_calendar,
      data: dtoToSend,
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        notifications.showNotification("Zmiany zostały zapisane", NotificationEnum.Success);
        loadData();
      }
    });
  };

  const onDeleteCalendarDialog = (id: string) => {
    setDeleteDialogState({ ...deleteDialogState, visible: true, id: id });
  };

  const onDeleteCalendar = (id: string) => {
    apiClient({
      method: "DELETE",
      url: `${CALENDAR_DELETE_calendar}\\${id}`,
      data: {},
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        setDeleteDialogState({ ...deleteDialogState, visible: false });
        return;
      } else {
        setDeleteDialogState({ ...deleteDialogState, visible: false });
        loadData();
      }
    });
  };

  const onCancel = () => {
    setViewState({ ...viewState, editState: EditState.CalendarsList, editedEntityId: "" });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>

      {loader.isLoading() ? <Loading /> : (

        <>
          {deleteDialogState.visible === true && (
            <PromptDialog
              text="Czy na pewno usunąć wskazany element ?"
              onYes={() => { onDeleteCalendar(deleteDialogState.id); }}
              onNo={() => { setDeleteDialogState({ ...deleteDialogState, visible: false }) }}
            />
          )}

          {viewState.editState === EditState.CalendarsList && (
            <CalendarsList
              calendars={calendars}
              onEdit={onEditCalendar}
              onAdd={onAddCalendar}
              onDelete={onDeleteCalendarDialog}
              onPreview={onPreviewCalendar}
              onEmployeesAssign={onEmployeesAssign}
            />
          )}

          {viewState.editState === EditState.CalendarAdd && (
            <AddEditCalendar
              data={null}
              onSave={(entity: Calendar) => {
                onSaveCalendar(entity);
              }}
              onCancel={onCancel}
            />
          )}

          {viewState.editState === EditState.CalendarEdit && (
            <AddEditCalendar
              data={calendars.find(c => c.id === viewState.editedEntityId) as Calendar}
              onSave={(entity: Calendar) => {
                onSaveCalendar(entity);
              }}
              onCancel={onCancel}
            />
          )}

          {viewState.editState === EditState.CalendarPreview && (
            <CalendarView
              data={calendars.find(c => c.id === viewState.editedEntityId) as Calendar}
              goBack={showCalendarsList}
            />
          )}

          {viewState.editState === EditState.EmployeeAssign && (
            <EmployeesAssign
              calendar={calendars.find(c => c.id === viewState.editedEntityId) as Calendar}
              onAssign={() => { loadData(); }}
              onCancel={onCancel}
            />
          )}

        </>
      )}
    </>
  )
};

export default SettingsCalendarComponent;
