import WorkTimeGroupUserItem from './WorkTimeGroupUserItem';

class WorkTimeGroupUser {
    userId: string;
    userName: string;
    hours: number;
    minutes: number;
    hoursQr: number;
    minutesQr: number;
    items: WorkTimeGroupUserItem[];

    expanded: boolean;
}
export default WorkTimeGroupUser;
