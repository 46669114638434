import React from 'react';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';

import LeaveStatusEnum, { LeaveStatusEnumTranslation } from '../../../models/enums/LeaveStatusEnum';
import StatusChange from '../../../models/local/Leaves/StatusChange';
import StatusChangeOut from '../../../models/local/Leaves/StatusChangeOut';
import { DictByType, DictItem, DictTypes, IdToDropDownValue } from '../../../scripts/dictProvider';
import { AppContext, IAppContext } from '../../../services/AppContext';
import { requiredValidator } from '../../../validators/CommonValidators';
import { FormDropDownList } from '../../Kendo/form-components';

const ChangeStatusDialog = (props: {
  onChange: (statusChange: StatusChangeOut) => void
  onCancel: () => void
  statusChange: StatusChange
}) => {

  const context = React.useContext<IAppContext>(AppContext);
  const submitForm = (form: any) => {
    props.onChange(form as StatusChangeOut);
  }
  const changeStatusToList = (): DictItem[] => {
    // exclude
    const dictByType = DictByType(DictTypes.LeaveStatusEnum, context.values.dicts);
    const result = dictByType
      .filter(x => x.id !== 'Unknown' && x.id !== 'Accepted')
      .map(x => new DictItem(x.id, LeaveStatusEnumTranslation(x.name as LeaveStatusEnum)));

    return result;
  }

  return (
    <Dialog title={"Zmiana statusu"} onClose={props.onCancel}>
      <div style={{ margin: "10px", textAlign: "left" }}>

        {props.statusChange.oldStatus === LeaveStatusEnum.ACCEPTED && (
          <Form
            onSubmit={submitForm}
            initialValues={props.statusChange}
            render={(formRenderProps: FormRenderProps) => (

              <FormElement
                style={{
                  maxWidth: 650,
                }}
              >

                <div className="k-form">
                  <label>Aktualny status:</label>
                  <div><b>{LeaveStatusEnumTranslation(props.statusChange.oldStatus)}</b></div>
                  <Field
                    id={"newStatus"}
                    name={"newStatus"}
                    label={"Nowy status"}
                    component={FormDropDownList}
                    data={changeStatusToList()}
                    value={IdToDropDownValue(DictTypes.LeaveStatusEnum, props.statusChange.newStatus, context.values.dicts)}
                    validator={requiredValidator}
                    textField="name"
                    dataItemKey="id"
                  />

                  <div style={{ marginTop: "50px" }}></div>

                  <DialogActionsBar >
                    <button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      type={"submit"}
                    >
                      Zmień status
                    </button>

                    <button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={props.onCancel}
                    >
                      Anuluj
                    </button>

                  </DialogActionsBar>
                </div>
              </FormElement>
            )}
          />
        )}

      </div>


    </Dialog>
  );
};

export default ChangeStatusDialog;
