import axios, { AxiosRequestConfig } from 'axios';

let baseUrl = "https://app.synerdi.pl/api/";
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "") baseUrl = "http://localhost:5000/api/";

const apiClient = async (options: AxiosRequestConfig, doNotRedirectOnError: boolean = false, customToken: string | null = null) => {
  const token = customToken ?? localStorage.getItem("access-token");

  const defaultConfig = {
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    validateStatus: (status: any) => status >= 200 && status <= 400,
  };

  const defaultConfigUnauthorized = {
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: "None",
    },
    validateStatus: (status: any) => status >= 200 && status <= 400,
  };

  const client = axios.create(token === null ? defaultConfigUnauthorized : defaultConfig);

  //console.debug("Send request: ", options);

  const onSuccess = (response: { data: any, status: number }) => {
    //console.group("request");
    //console.log("Request successful. Returning data: ");
    //console.table(response.data);

    if (response.status !== undefined && response.status !== 200) {
      // console.log("Returning error status", response)
      return { data: { errors: response.data.errors }, status: response.status };
    }

    //console.table(response.data?.result);
    //console.groupEnd();

    return response;
  };

  const onError = (error: any) => {
    console.error("Request Failed ! :", error.config);

    if (doNotRedirectOnError)
      return;

    if (error.response) {
      // response other than 2xx && 400
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);

      if (error.response.status === 404) {
        // not found
        window.location.href = "/#/error";
      }

      if (error.response.status === 401) {
        // unauthorized (token expired)
        localStorage.clear();
        window.location.href = "/";
      }
    } else {
      // unexpected error
      console.error("Error Message -> ", error.message);
      window.location.href = "/#/error";
      window.location.reload();
    }

    return error.response || { data: { errors: error.message } };
  };

  return await client(options).then(r => onSuccess({ data: r.data, status: r.status })).catch(onError);
};

const apiClientDownload = async (config: FileDownloadRequestConfig) => {
  const params = new URLSearchParams(config.data);

  const onError = (error: any) => {
    if (error.response) {
      // response other than 2xx && 400
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      // unexpected error
      window.location.href = "/#/error";
      console.error("Error Message:", error.message);
    }

    return error.response || { data: { errors: error.message } };
  };

  const token = localStorage.getItem("access-token");

  const result = await fetch(`${baseUrl}${config.path}?${params}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(async (res) => {
      const blobResponse = await res.blob();
      const link = document.createElement("a");
      const objectUrl = window.URL.createObjectURL(new Blob([blobResponse]));
      link.href = objectUrl;
      document.body.appendChild(link);
      link.setAttribute("download", encodeURI(config.fileName ?? "unknown.file"));
      link.click();
      if (link.parentNode) link.parentNode.removeChild(link);
    })
    .catch(onError);

  return result;
};

class FileDownloadRequestConfig {
  path: string;
  fileName: string;
  data?: any;
  contentType?: string;
}

export { apiClient, apiClientDownload, FileDownloadRequestConfig, baseUrl };
