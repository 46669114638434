import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';

import { apiClient } from '../api/apiClient';
import { PROFILE_POST_passwordchange } from '../api/apiEndpoints';
import { FormInput } from '../components/Kendo/form-components';
import PromoBanner from '../components/LoginRegister/PromoBanner';
import NotificationEnum from '../models/enums/NotificationEnum';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';
import useSessionWrapper from '../services/SessionWrapper';
import moduleStyle from '../styles/LoginRegister.module.scss';
import { requiredMinLength6Validator } from '../validators/CommonValidators';

const ResetPassword = () => {
  const loader = useAppLoader(false);
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);
  const { token } = useParams<{ token: string }>();
  const notifications = useAppNotifications();
  const sessionWrapper = useSessionWrapper();

  React.useEffect(() => {
  }, []);

  const handleSubmit = async (resetPasswordForm: any) => {
    loader.showLoading(true);

    apiClient({
      method: "POST",
      url: PROFILE_POST_passwordchange,
      data: {
        token: token,
        newPassword: resetPasswordForm.pass1,
        newPasswordRepeated: resetPasswordForm.pass2,
      },
    }).then(async (resp) => {
      loader.showLoading(false);

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        setShowConfirmation(true);
      }
    });
  };

  return (
    <div className={moduleStyle.pageWrapper}>
      <div className={moduleStyle.formColumn}>

        {showConfirmation ?
          <div style={{ width: "400px" }}>
            Twoje hasło zostało poprawnie zaktualizowane.

            <p style={{ textAlign: "center", paddingTop: "15px" }}>
              <Link to="/">
                Powrót do strony logowania
              </Link>
            </p>
          </div> :
          <Form
            onSubmit={handleSubmit}
            initialValues={{ email: "" }}
            render={() => (
              <div style={{ width: "400px" }}>
                <p className={moduleStyle.formHeader}>Ustawianie hasła</p>
                <FormElement
                  style={{
                    width: 400,
                  }}
                >
                  <div>
                    <Field
                      id={"pass1"}
                      name={"pass1"}
                      autoComplete="off"
                      type="password"
                      label={"Nowe hasło:"}
                      component={FormInput}
                      validator={requiredMinLength6Validator}
                    />

                    <Field
                      id={"pass2"}
                      name={"pass2"}
                      autoComplete="off"
                      type="password"
                      label={"Powtórz nowe hasło:"}
                      component={FormInput}
                      validator={requiredMinLength6Validator}
                    />
                  </div>
                  <div style={{ display: "flex", marginTop: "30px" }}>
                    <div style={{ width: "50%" }}>
                      <Button type="submit" disabled={loader.isLoading()}>Zapisz</Button>
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <Link to="/">
                        Anuluj
                      </Link>
                    </div>
                  </div>
                </FormElement>
              </div>
            )}
          />}
      </div>
      <PromoBanner />
    </div>
  );
};

export default ResetPassword;
