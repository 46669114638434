import CompetencyDto from '../../dto/Employees/Tabs/CompetencyDto';
import ContractDto from '../../dto/Employees/Tabs/ContractDto';
import DocDto from '../../dto/Employees/Tabs/DocDto';
import MedicalDto from '../../dto/Employees/Tabs/MedicalDto';
import NotificationDto from '../../dto/Employees/Tabs/NotificationDto';
import ResourceDto from '../../dto/Employees/Tabs/ResourceDto';
import TrainingDto from '../../dto/Employees/Tabs/TrainingDto';
import WorkExperienceDto from '../../dto/Employees/Tabs/WorkExperienceDto';
import StorageFile from '../../dto/Storage/StorageFile';
import LimitType from './LimitType';
import LimitTypeOverride from './LimitTypeOverride';
import Note from './Note';
import UserWorkPlan from './UserWorkPlan';

export default class User {
  id: string;
  personalDataId: string;

  email: string;
  firstName: string;
  lastName: string;
  uniqueId: string;
  birthDate?: Date;
  phoneNumber: string;
  avatarId?: string;

  corAddressSameAsAddress: boolean;

  addrId: string;
  addrCity: string;
  addrStreet: string;
  addrStreetNumber: string;
  addrHouseNumber: string;
  addrPostalCode: string;

  addrCorespId: string;
  addrCorespCity: string;
  addrCorespStreet: string;
  addrCorespStreetNumber: string;
  addrCorespHouseNumber: string;
  addrCorespPostalCode: string;

  briefcaseId: string;
  contracts: ContractDto[] = [];
  medicals: MedicalDto[] = [];
  trainings: TrainingDto[] = [];
  notifications: NotificationDto[] = [];
  workExperiences: WorkExperienceDto[] = [];
  competencies: CompetencyDto[] = [];
  resources: ResourceDto[] = [];
  docs: DocDto[] = [];
  notes: Note[] = [];

  supervisorsNames: string;
  departmentNames: string;

  accountCreateDate: Date;
  lastLoginDate: Date;

  canBeManager: boolean;
  isHR: boolean;
  isBlocked: boolean;
  isActive: boolean;

  jobPosition: string;

  limitTypes: LimitType[];
  limitTypesOverrides: LimitTypeOverride[];
  userWorkPlans: UserWorkPlan[];

  specialId: string;
}