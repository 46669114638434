import React from 'react';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SettingsCalendarComponent from '../components/Settings/Calendars/SettingsCalendarComponent';

const SettingsCalendars = () => {
  return (
    <div className="fullPageContentWrapper">
      <div className="fullWidthColumn">
        <div className="mainToolbar" style={{ minHeight: "3em" }}>
          <SettingsCalendarComponent></SettingsCalendarComponent>
        </div>
      </div>
    </div >
  );
};

export default SettingsCalendars;
