import StorageFileDto from "../StorageFileDto";


export default class ResourceDto {
    id: string;
    resourceType: string;
    date: Date;
    returnUntilDate: Date;
    returnDate?: Date;
    isReturned: boolean;
    note: string;
    files: StorageFileDto[] = [];
}

