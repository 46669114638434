import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { GridColumn as Column } from '@progress/kendo-react-grid';

import { apiClient } from '../../api/apiClient';
import { LICENCE_DELETE } from '../../api/apiEndpoints';
import LicenceListDto from '../../models/dto/Licence/LicenceListDto';
import LicenceList from '../../models/local/Licences/LicenceList';
import { mapper } from '../../models/mapper';
import useAppNotifications from '../../services/AppNotifications';
import PromptDialog from '../Common/PromptDialog';
import { StatefullGrid } from '../Kendo/StatefullGrid';

const LicencesList = (props: {
  url: string
}) => {

  const navigate = useNavigate();
  const notifications = useAppNotifications();
  const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [
      { field: "officeName", operator: "contains", value: "" }
    ],
  };
  const initialSort: Array<SortDescriptor> = [
    { field: "officeName", dir: "asc" },
  ];

  const [viewState, setViewState] = React.useState<{
    promptDialogVisible: boolean,
    selectedId: string | null,
    selectedName: string | null,
    errorMessage: string | null,
    filter: CompositeFilterDescriptor,
    sort: Array<SortDescriptor>
  }>(
    {
      promptDialogVisible: false,
      selectedId: null,
      selectedName: null,
      errorMessage: null,
      filter: initialFilter,
      sort: initialSort
    }
  );

  const onDeleteButtonClick = (id: string, name: string) => {
    setViewState({ ...viewState, selectedId: id, selectedName: name, promptDialogVisible: true })
  }

  const callbackMapping = (dtoData: LicenceListDto[]) => {
    return mapper.mapArray(dtoData, LicenceList, LicenceListDto);
  };

  // const DATA_ITEM_KEY = "id";
  // const onSelectionChange = (event: GridSelectionChangeEvent) => {
  //     console.log(event);
  //     const newSelectedState = getSelectedState({
  //       event,
  //       selectedState: viewState.selectedState,
  //       dataItemKey: DATA_ITEM_KEY,
  //     });
  //     setViewState({ ...viewState, selectedState: newSelectedState })
  //   };

  const onDeleteLicence = () => {
    apiClient({
      method: "DELETE",
      url: `${LICENCE_DELETE(viewState.selectedId)}`,
      data: {},
    }).then(async (resp) => {
      setViewState({ ...viewState, promptDialogVisible: false })
      if (resp.status !== 200) {
        setViewState({ ...viewState, errorMessage: resp.data.errors })
        return;
      } else {
        notifications.showNotification("Licencja została usunięta.");
        navigate("/");
      }
    });
  };

  return (
    <>
      {viewState.promptDialogVisible === true && (
        <PromptDialog
          text={`<p>Czy na pewno chcesz usunąć licencję dla <b>"${viewState.selectedName}"</b> ?</p>` +
            `Operacja ta spowoduje zablokowanie możliwości korzystania z aplikacji.`}
          onYes={() => { onDeleteLicence(); }}
          onNo={() => { setViewState({ ...viewState, promptDialogVisible: false }) }}
        />
      )}

      <Toolbar>
        <ToolbarItem>
          <Button
            icon="plus"
            fillMode="outline"
            onClick={() => {
              navigate(`/licences/edit`);
            }}
          >
            Dodaj
          </Button>
        </ToolbarItem>
      </Toolbar>

      <StatefullGrid style={{ height: "100%" }}
        url={props.url}
        filterable={true}
        filter={viewState.filter}
        sortable={true}
        sort={viewState.sort}
        callbackMapping={callbackMapping}
      //onSelectionChange = {onSelectionChange}
      >
        <Column field="officeName" title="Biuro" filter='text' />
        <Column field="workersLimit" title="Limit pracowników" />
        <Column field="validFrom" title="Ważny od" format="{0:d}" filter="date" />
        <Column field="validTo" title="Ważny do" format="{0:d}" filter="date" />
        <Column field="promoCode" title="Kod promocyjny" />
        <Column title="Operacje"
          filterable={false}
          sortable={false}
          width="100px"
          cell={(props: any) => (
            <td>
              <Button
                fillMode="outline"
                icon="edit"
                onClick={() => {
                  navigate(`/licences/edit/${props.dataItem.id}`);
                }}
              />{" "}
              <Button
                fillMode="outline"
                icon="trash"
                themeColor="error"
                onClick={() => {
                  onDeleteButtonClick(props.dataItem.id, props.dataItem.officeName);
                }}
              />
            </td>
          )}
        />
      </StatefullGrid>
    </>
  );
};
export default LicencesList;