import WorkTimeGroupUser from './WorkTimeGroupUser';

class WorkTimeSummaryGrid {
    departmentId: string;
    departmentName: string;
    userWorkSummaries: WorkTimeGroupUser[];

    expanded: boolean;
}
export default WorkTimeSummaryGrid;
