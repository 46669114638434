export default class MonthViewItemResponseDto {
  id: string;

  userId: string;
  firstName: string;
  lastName: string;

  departmentsIds: string[];
  departments: string[];

  limitTypeId: string;
  limitType: string;

  startDate: Date;
  endDate: Date;

  status: string;
}