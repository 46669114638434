import * as React from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

import CalendarItem from "../../../models/local/Calendars/CalendarItem";

const InfoCalendarItemDialog = (props: {
  calendarItem: CalendarItem
  onClose: () => void
  onDelete: (id: string) => void
}) => {

  return (
    <Dialog title={"Dzień wolny"} onClose={props.onClose} minWidth="400px">
      <p style={{ margin: "25px", textAlign: "left" }}>
        <b>{props.calendarItem?.name}</b><br />
        {new Date(props.calendarItem?.date).toLocaleDateString("pl-PL")}<br /><br />
        Dodatkowe informacje:<br />
        {props.calendarItem?.description ?? "brak"}<br />
      </p>
      <DialogActionsBar>
        <Button
          fillMode="outline"
          icon="check"
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={props.onClose}
        >
          Zamknij
        </Button>

        <Button
          fillMode="outline"
          icon="close"

          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={() => { props.onDelete(props.calendarItem.id) }}
        >
          Usuń
        </Button>

      </DialogActionsBar>
    </Dialog>
  );
};

export default InfoCalendarItemDialog;
