import React from 'react';

import { faKeyboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ParametersComponent from '../components/Settings/Parameters/ParametersComponent';

const SettingsParameters = () => {

  return (
    <div className="fullPageContentWrapper">
      <div className="fullWidthColumn">
        <div className="mainToolbar" style={{ minHeight: "3em" }}>
          <ParametersComponent></ParametersComponent>
        </div>
      </div>
    </div >
  );
};

export default SettingsParameters;
