import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { Form, FormRenderProps } from '@progress/kendo-react-form';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import WorkTimeGroupTimeLogGrid from '../components/WorkTime/MonthlyLog/Summary/WorkTimeSummaryGrid';
import NotificationEnum from '../models/enums/NotificationEnum';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';

const WorkTimeReportsBrowser = () => {
  const loader = useAppLoader(false);
  const notifications = useAppNotifications();
  const formRef = useRef<Form>(null);
  const navigate = useNavigate();

  const [displayDate, setDisplayDate] = useState<Date>(new Date());

  const [viewState, setViewState] = React.useState<any>(() => {
  });


  const handleInputChange = (e: any) => {
    setViewState({ ...viewState, [e.target.name as string]: e.value });
  }

  const onFormSubmit = (form: any) => {
  }

  const onSubmitButtonClick = (formProps: FormRenderProps, e: any) => {
    if (!formRef.current?.isValid()) {
      formProps.onSubmit(e); //force execute validation
      notifications.showNotification("Zapisanie danych nie udało się ponieważ formularz zawiera błędy.", NotificationEnum.Error);
    }

    formProps.onSubmit(e);
  };

  const onDepartmentIdChanged = (departmentId: string | undefined) => {
    notifications.showNotification("ID:" + departmentId)
  }

  const onChangeDate = (shift: number) => {
    var date = displayDate;
    date.setMonth(date.getMonth() + shift);

    loader.showLoading(true);
    setDisplayDate(new Date(date));
    notifications.showNotification("date:" + date.toISOString())
    loader.showLoading(false);
  }

  useEffect(() => {
  }, []);

  return (
    <>
      {!loader.isLoading() &&
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn" id="leaveList">
            <TabStrip selected={0} >
              <TabStripTab title="Karty pracy pracowników" >
                <WorkTimeGroupTimeLogGrid showFilters={false} />
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      }

    </>
  );
};

export default WorkTimeReportsBrowser;
