import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';

import { apiClient } from '../api/apiClient';
import { BRIEFCASES_GET_DOCS } from '../api/apiEndpoints';
import DocsList from '../components/Briefcases/DocsList';
import ErrorMessage from '../components/Common/ErrorMessage';
import GetDocsRequest from '../models/dto/Briefcases/Requests/GetDocsRequest';
import DocItemResponse from '../models/dto/Briefcases/Responses/DocItemResponse';
import NotificationEnum from '../models/enums/NotificationEnum';
import DocItem from '../models/local/Briefcases/DocItem';
import { mapper } from '../models/mapper';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';
import Loading from './Loading';

const BcDocs = () => {
  const navigate = useNavigate();
  const [gridItems, setGridItems] = useState<DocItem[]>([]);
  const loader = useAppLoader(true);
  const notifications = useAppNotifications();

  const refreshGridItems = () => {
    loader.showLoading(true);
    let request: GetDocsRequest = new GetDocsRequest();

    apiClient({
      method: "GET",
      url: BRIEFCASES_GET_DOCS,
      data: request,
    }).then((resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        loader.showLoading(false);
        return;
      }

      loader.showLoading(false);

      const mappedResult: DocItem[] = mapper.mapArray(resp?.data?.result, DocItem, DocItemResponse);
      setGridItems(mappedResult);
    });

  }

  useEffect(() => {
    refreshGridItems();
  }, []);

  return (
    <>
      {!loader.isLoading() &&
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn">
            <div className="mainToolbar" style={{ minHeight: "3em", marginBottom: "10px" }}>
              <Toolbar>
                <ToolbarItem>
                  <Button
                    icon="plus"
                    fillMode="outline"
                    onClick={() => {
                      navigate(`/briefcases/docs/add`);
                    }}
                  >
                    Dodaj dokument
                  </Button>
                </ToolbarItem>
              </Toolbar>
            </div>
            <DocsList gridItems={gridItems} ></DocsList>
          </div>
        </div >
      }

    </>
  )
};

export default BcDocs;
