import React, { useEffect, useState } from 'react';

import { apiClient } from '../../../api/apiClient';
import {
  LIMITS_GET_forOffice, LIMITS_POST_initializeYear, LIMITS_PUT_editOfficeLimits
} from '../../../api/apiEndpoints';
import LimitTypeDto from '../../../models/dto/Employees/Tabs/LimitTypeDto';
import EditOfficeLimitsRequest from '../../../models/dto/Settings/Requests/LimitTypes/EditOfficeLimitsRequest';
import InitializeYearRequest from '../../../models/dto/Settings/Requests/LimitTypes/InitializeYearRequest';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import LimitType from '../../../models/local/Employees/LimitType';
import { mapper } from '../../../models/mapper';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';
import PromptDialog from '../../Common/PromptDialog';
import TypesOfLeaveEdit from './TypesOfLeaveEdit';
import TypesOfLeaveList from './TypesOfLeaveList';

const TypesOfLeaveComponent = () => {
  const [viewState, setViewState] =
    React.useState<{
      inEditMode: boolean,
      editedEntityId: string,
      importDialogVisible: boolean,
      forceLimitScopeEditDialogVisible: boolean,
      entityToSave?: LimitTypeDto
    }>(
      {
        inEditMode: false,
        editedEntityId: "",
        importDialogVisible: false,
        forceLimitScopeEditDialogVisible: false,
        entityToSave: undefined,
      });

  const [officeLimits, setOfficeLimits] = useState<LimitType[]>([]);
  const loader = useAppLoader(true);
  const notifications = useAppNotifications();
  const [year, setYear] = useState<number>((new Date()).getFullYear());

  const loadData = async () => {
    const getForOffice = async () => {
      await apiClient({
        method: "GET",
        url: LIMITS_GET_forOffice(year),
        data: {},
      }).then((resp) => {
        // var visibleItems = resp?.data?.result.filter((r: any) => r.visible === true);
        setOfficeLimits(resp?.data?.result);
        loader.showLoading(false);

        if (resp?.data?.result?.length === 0)
          setViewState({ ...viewState, importDialogVisible: true });
      });
    };

    // Promise.all([getTemplates(), getForOffice()]).then(() => {
    await getForOffice();
  }


  const onEdit = (entityId: string) => {
    setViewState({ ...viewState, inEditMode: true, editedEntityId: entityId });
  };

  const onCancel = () => {
    setViewState({ ...viewState, inEditMode: false, editedEntityId: "", entityToSave: undefined, forceLimitScopeEditDialogVisible: false, importDialogVisible: false });
  };

  const onSave = (entity: LimitType, forceLimitTypeChange: boolean) => {
    const submitData = async () => {
      const dtoToSend: EditOfficeLimitsRequest = mapper.map(entity, EditOfficeLimitsRequest, LimitType);
      dtoToSend.forceEdit = forceLimitTypeChange;

      apiClient({
        method: "PUT",
        url: LIMITS_PUT_editOfficeLimits,
        data: dtoToSend,
      }).then(async (resp) => {

        if (resp.status === 409) {
          setViewState({ ...viewState, entityToSave: entity, forceLimitScopeEditDialogVisible: true });
          return;
        }

        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }

        loadData().then(() => {
          notifications.showNotification("Zmiany zostały zapisane", NotificationEnum.Success);
          onCancel();
        });
      });
    };

    submitData();
  };


  const onInitializeYear = (year: number) => {
    const dtoToSend = new InitializeYearRequest();
    dtoToSend.year = year;

    const submitData = async () => {
      apiClient({
        method: "POST",
        url: LIMITS_POST_initializeYear,
        data: dtoToSend,
      }).then(async (resp) => {
        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }

        setViewState({ ...viewState, importDialogVisible: false });
        loadData();
      });
    };

    submitData();
  };

  const onYearChange = (year: number) => {
    setYear(year);
  }

  useEffect(() => {
    console.log("TypeOfLeaveComponent useEffect")

    loadData();
  }, [year]);

  return (
    <>

      {viewState.importDialogVisible === true && (
        <PromptDialog
          text={`Rok ${year} nie został zainicjowany i nie ma ustawionych jeszcze żadnych limitów urlopowych.</p>` +
            `Możesz zaimportować teraz i przypisać do tego roku domyślną listę limitów, a następnie ręcznie ustawić przysługującą ilość dni każdego typu urlopu.` +
            `<p>Czy chcesz teraz zainicjować rok ${year} wartościami domyślnymi ?</p>`}
          onYes={() => { onInitializeYear(year); }}
          onNo={() => { setViewState({ ...viewState, importDialogVisible: false }) }}
          justifyText={true}
        />
      )}

      {viewState.forceLimitScopeEditDialogVisible === true && (
        <PromptDialog
          text={`Uwaga! Wprowadzona zmiana spowoduje, że <b>indywidualne limity pracowników dla tego typu urlopu zostaną wyzerowane</b>.<br/>
          <p>Przykład: Jan Kowalski miał do tej pory ustalony indywidualny limit na 10 dni w roku. 
          Zapisanie zmiany (kliknięcie w przycisk Tak) spowoduje, że limit 10 dni w roku zostanie zresetowany i przestawiony na limit globalny, 
          wspólny dla wszystkich pracowników.</p>
          
          <p>Jest to operacja nieodwracalna. <b>Czy na pewno chcesz wprowadzić zmianę typu limitu z indywidualnego na globalny?<br/></b></p>`}
          onYes={() => { onSave(viewState.entityToSave!, true); }}
          onNo={() => { setViewState({ ...viewState, forceLimitScopeEditDialogVisible: false }) }}
          justifyText={true}
        />
      )}

      {!loader.isLoading() && <>
        {!viewState.inEditMode ? (
          <TypesOfLeaveList
            limitTypes={officeLimits}
            onEdit={(entityId: string) => { onEdit(entityId); }}
            onYearChange={onYearChange}
          />) : (
          <TypesOfLeaveEdit
            entity={officeLimits.filter(lt => lt.id === viewState.editedEntityId).at(0) ?? new LimitType()}
            onCancel={onCancel}
            onSave={(entity) => { onSave(entity, false); }}
          />)
        }
      </>}
    </>
  )
};

export default TypesOfLeaveComponent;
