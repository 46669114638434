import React, { useState } from 'react';

import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';

import ExportDataListItemDto from '../../models/dto/Export/ExportDataListItemDto';
import ExportTypeEnum from '../../models/enums/ExportTypeEnum';
import { LeaveStatusEnumTranslation } from '../../models/enums/LeaveStatusEnum';
import ExportDataListItem from '../../models/local/ExportData/ExportDataListItem';
import { mapper } from '../../models/mapper';
import { StatefullGrid } from '../Kendo/StatefullGrid';

const ExportDataList = (props: {
  url: string,
  exportType: ExportTypeEnum
}) => {
  //const StatefullGrid = statefullGrid(props.url);
  const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [
      { field: "firstName", operator: "startswith", value: "" },
      { field: "lastName", operator: "startswith", value: "" },
      // { field: "leaveStatus", operator: "eq", value: null },
    ],
  };
  const [filter, setFilter] = useState(initialFilter);
  const initialSort: Array<SortDescriptor> = [
    { field: "lastName", dir: "asc" },
  ];
  const [sort, setSort] = useState(initialSort);

  const callbackMapping = (dtoData: ExportDataListItemDto[]) => {
    return mapper.mapArray(dtoData, ExportDataListItem, ExportDataListItemDto);
  };

  return (
    <>
      <StatefullGrid style={{ height: "100%" }}
        url={props.url}
        filterable={true}
        filter={filter}
        sortable={true}
        sort={sort}
        callbackMapping={callbackMapping}
      >
        <Column field="firstName" title="Imię" />
        <Column field="lastName" title="Nazwisko" />
        <Column field="startDate" title="Data rozpoczęcia" format="{0:d}" filter="date" />
        <Column field="endDate" title="Data zakończenia" format="{0:d}" filter="date" />
        <Column field="leaveStatus" title="Status" cell={(d: GridCellProps) => (<td>{LeaveStatusEnumTranslation(d.dataItem.leaveStatus)}</td>)} filterable={false} />
      </StatefullGrid>
    </>
  );
};
export default ExportDataList;