import React, { useEffect, useState } from 'react';

import { apiClient } from '../api/apiClient';
import {
  DEPARTMENTS_GET, DEPARTMENTS_GET_users, DEPARTMENTS_POST, DEPARTMENTS_PUT,
  DEPARTMENTS_PUT_users_assignUsers
} from '../api/apiEndpoints';
import DepartmentsEdit from '../components/Departments/DepartmentsEdit';
import DepartmentsEmplList from '../components/Departments/DepartmentsEmplList';
import DepartmentsList from '../components/Departments/DepartmentsList';
import EmployeesManage from '../components/Departments/EmployeesManage';
import AssignUsersToDepRequestDto from '../models/dto/Departments/Requests/AssignUsersToDepRequestDto';
import EditDepartmentRequestDto from '../models/dto/Departments/Requests/EditDepartmentRequestDto';
import NewDepartmentRequestDto from '../models/dto/Departments/Requests/NewDepartmentRequestDto';
import DepartmentTreeResponseDto from '../models/dto/Departments/Responses/DepartmentTreeResponseDto';
import UserListItemResponseDto from '../models/dto/Departments/Responses/UserListItemResponseDto';
import NotificationEnum from '../models/enums/NotificationEnum';
import DepartmentsEditForm from '../models/local/Departments/DepartmentsEditForm';
import DepartmentsNewForm from '../models/local/Departments/DepartmentsNewForm';
import DepartmentTreeItem from '../models/local/Departments/DepartmentTreeItem';
import DepartmentUser from '../models/local/Departments/DepartmentUser';
import DepartmentUserListboxItem from '../models/local/Departments/DepartmentUserListboxItem';
import { mapper } from '../models/mapper';
import useAppNotifications from '../services/AppNotifications';
import Loading from './Loading';

enum EditState {
  ListView,
  DepartmentsNew,
  DepartmentsEdit,
  EmployeesManage,
}

const Departments = () => {
  const [selectedDepartment, setSelectedDepartment] = useState<DepartmentTreeItem>(new DepartmentTreeItem());
  const [departments, setDepartments] = useState<DepartmentTreeItem[]>([]);
  const [empoyed, setEmpleyed] = useState<DepartmentUser[]>([]);

  const [isUsersLoading, setIsUsersLoading] = React.useState<boolean>(true);
  const [isDepartmentsLoading, setIsDepartmentsLoading] = React.useState<boolean>(true);
  const [inEditMode, setInEditMode] = useState<EditState>(EditState.ListView);
  const notifications = useAppNotifications();
  const treeLoop = (list: DepartmentTreeItem[], selected: DepartmentTreeItem) => {
    list.forEach(x => {
      if (x.id === selected.id) {
        x.selected = true;
        reloadUsersData(x.id);
        setSelectedDepartment(x);
        return;
      }
      if (x?.items?.length > 0) {
        treeLoop(x.items, selected);
      }
    });
  }

  const reloadDepartmentData = async (init: boolean) => {
    apiClient({
      method: "GET",
      url: DEPARTMENTS_GET,
      data: {},
    }).then((resp) => {
      const mappedResult: DepartmentTreeItem[] = mapper.mapArray(resp?.data?.result, DepartmentTreeItem, DepartmentTreeResponseDto);
      setDepartments(mappedResult);
      handleMappedResult(mappedResult, init);
    });
  }

  const handleMappedResult = (mappedResult: DepartmentTreeItem[], init: boolean) => {
    if (mappedResult.length > 0) {
      if (init) {
        mappedResult[0].selected = true;
        reloadUsersData(mappedResult[0].id);
        setSelectedDepartment(mappedResult[0]);
      }
      else {
        treeLoop(mappedResult, selectedDepartment);
      }
    }

    setIsDepartmentsLoading(false);
  }


  const reloadUsersData = async (departmentId: string) => {
    apiClient({
      method: "GET",
      url: DEPARTMENTS_GET_users(departmentId),
      data: {},
    }).then((resp) => {
      const mappedResult: DepartmentUser[] = mapper.mapArray(resp?.data?.result, DepartmentUser, UserListItemResponseDto);
      setEmpleyed(mappedResult);

      setIsUsersLoading(false);
    });
  }

  useEffect(() => {
    reloadDepartmentData(true);
  }, []);

  const onSelectDepartment = (event: any) => {
    setIsUsersLoading(true);
    if (selectedDepartment) {
      selectedDepartment.selected = false;
    }

    event.item.selected = true;
    reloadUsersData(event.item.id);
    setSelectedDepartment(event.item);
  };

  const onEditDepartment = () => {
    setInEditMode(EditState.DepartmentsEdit);
  };

  const onNewDepartment = () => {
    setInEditMode(EditState.DepartmentsNew);
  };

  const onEditDepartmentEditSave = (dataItem: any) => {
    const dtoToSend: EditDepartmentRequestDto = mapper.map(dataItem, EditDepartmentRequestDto, DepartmentsEditForm);

    apiClient({
      method: "PUT",
      url: DEPARTMENTS_PUT,
      data: dtoToSend,
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        setInEditMode(EditState.ListView);
        reloadDepartmentData(false);
        reloadUsersData(selectedDepartment.id);
        notifications.showNotification("Zmiany zostały zapisane");
      }
    });
  };

  const onEditDepartmentNewSave = (dataItem: any) => {
    const dtoToSend: NewDepartmentRequestDto = mapper.map(dataItem, NewDepartmentRequestDto, DepartmentsNewForm);
    dtoToSend.parentDepartmentId = selectedDepartment.id;

    apiClient({
      method: "POST",
      url: DEPARTMENTS_POST,
      data: dtoToSend,
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        setInEditMode(EditState.ListView);
        reloadDepartmentData(false);
        notifications.showNotification("Dział został dodany", NotificationEnum.Success);
      }
    });
  };

  const onEmployeesManage = () => {
    setInEditMode(EditState.EmployeesManage);
  };

  const onEmployeesSave = (items: DepartmentUserListboxItem[]) => {
    setInEditMode(EditState.ListView);
    setIsUsersLoading(true);

    const dtoToSend: AssignUsersToDepRequestDto = new AssignUsersToDepRequestDto();
    dtoToSend.departmentId = selectedDepartment.id;
    dtoToSend.usersIds = items.map((item) => item.id);

    apiClient({
      method: "PUT",
      url: DEPARTMENTS_PUT_users_assignUsers,
      data: dtoToSend,
    }).then(async (resp) => {

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        reloadUsersData(selectedDepartment.id);
        return;
      }

      reloadUsersData(selectedDepartment.id);
      notifications.showNotification("Zmiany na liście pracowników przypisanych do działu zostały zapisane!");
    });
  };

  const onCancel = () => {
    reloadDepartmentData(false);
    setInEditMode(EditState.ListView);
  };


  return (
    <div className="fullPageContentWrapper">
      <div className="fullWidthColumn">
        <div className="columnsWrapper">

          {isDepartmentsLoading ? <Loading /> :
            <>
              <div className="leftColumn" style={{ flexBasis: "250px", minWidth: "initial" }}>
                <DepartmentsList
                  departments={departments}
                  onSelectDepartment={onSelectDepartment}
                  disabled={inEditMode !== EditState.ListView}
                />
              </div>
              <div className="rightColumn" style={{ flexGrow: 1 }}>
                {isUsersLoading ? <Loading /> :
                  <>
                    {inEditMode === EditState.ListView && (
                      <DepartmentsEmplList
                        employed={empoyed}
                        department={selectedDepartment}

                        onEditDepartment={onEditDepartment}
                        onNewDepartment={onNewDepartment}
                      />
                    )}

                    {inEditMode === EditState.DepartmentsEdit &&
                      (
                        <DepartmentsEdit
                          department={selectedDepartment}
                          users={empoyed}

                          onSave={onEditDepartmentEditSave}
                          onCancel={onCancel}
                          onEmployeesManage={onEmployeesManage} />
                      )}

                    {inEditMode === EditState.DepartmentsNew &&
                      (
                        <DepartmentsEdit
                          department={new DepartmentTreeItem()}
                          users={empoyed}

                          onSave={onEditDepartmentNewSave}
                          onCancel={onCancel}
                          onEmployeesManage={() => null} />
                      )}

                    {inEditMode === EditState.EmployeesManage &&
                      (
                        <EmployeesManage
                          departmentName={selectedDepartment.text}
                          departmentId={selectedDepartment.id}
                          onSave={onEmployeesSave}
                          onCancel={onCancel} />
                      )}
                  </>}
              </div>
            </>}
        </div>
      </div>
    </div>
  );
};

export default Departments;
