import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form, FormRenderProps } from '@progress/kendo-react-form';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';

import { apiClient } from '../../api/apiClient';
import { USER_GET_byId, USER_GET_newUser, USER_POST, USER_PUT } from '../../api/apiEndpoints';
import UserDto from '../../models/dto/Employees/UserDto';
import NotificationEnum from '../../models/enums/NotificationEnum';
import LimitTypeOverrideItem from '../../models/local/Employees/LimitTypeOverrideItem';
import User from '../../models/local/Employees/User';
import UserWorkPlan from '../../models/local/Employees/UserWorkPlan';
import { mapper } from '../../models/mapper';
import { GuidGenerator } from '../../scripts/guid';
import useAppLoader from '../../services/AppLoader';
import useAppNotifications from '../../services/AppNotifications';
import PromptDialog from '../Common/PromptDialog';
import EmployeeEditToolbar from './EmployeeEdit/EmployeeEditToolbar';
import PersonalDataTab from './EmployeeEdit/Tabs/BasicInformations/PersonalDataTab';
import PersonalContactTab from './EmployeeEdit/Tabs/Contacts/PersonalContactTab';
import LimitsTab from './EmployeeEdit/Tabs/Limits/LimitsTab';
import PersonalFilesTab from './EmployeeEdit/Tabs/PersonalFiles/PersonalFilesTab';
import SystemConfigurationTab from './EmployeeEdit/Tabs/System/SystemConfigurationTab';
import EmployeeWorkPlansList from './EmployeeEdit/Tabs/WorkPlans/EmployeeWorkPlansList';

type EmployeeEditParams = {
  id: string;
};

export enum EmployeeViewsTypeEnum {
  PERSONALDATA = 0,
  CONTACT = 1,
  PERSONALFILES = 2,
  LIMITS = 3,
  WORKPLANS = 4,
  SYSTEMCONFIGURATION = 5,
}

export enum EmployeeSubViewsTypeEnum {
  CONTRACTS = 0,
  MEDICALS = 1,
  TRAININGS = 2,
  NOTIFICATIONS = 3,
  WORKEXPERIENCES = 4,
  COMPETENCIES = 5,
  RESOURCES = 6,
  DOCS = 7,
  NOTES = 8,
  USERWORKPLANS = 9,
}

const EmployeeEdit = (props: { forceSwitchToPersonalFilesTab: EmployeeSubViewsTypeEnum | undefined }) => {
  const formRef = useRef<Form>(null);
  const navigate = useNavigate();
  const employeeId = useParams<EmployeeEditParams>()?.id;

  const notifications = useAppNotifications();
  const loader = useAppLoader(true);
  const [selectedTab, setSelectedTab] = React.useState<EmployeeViewsTypeEnum>(EmployeeViewsTypeEnum.PERSONALDATA);
  const [personalFilesSelectedTab, setPersonalFilesSelectedTab] = React.useState<number>(EmployeeSubViewsTypeEnum.CONTRACTS);
  const [employeeData, setEmployeeData] = React.useState<User>(new User());
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [viewState, setViewState] = React.useState<{
    promptDialogVisible: boolean;
    entityId: string;
    entityType: EmployeeSubViewsTypeEnum;
  }>({
    promptDialogVisible: false,
    entityId: "",
    entityType: EmployeeSubViewsTypeEnum.CONTRACTS,
  });

  const getEmployeeData = async () => {
    loader.showLoading(true);

    const resp = await apiClient({
      method: "GET",
      url: employeeId === undefined ? USER_GET_newUser() : USER_GET_byId(employeeId),
      data: {},
    }).then((resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      const result: UserDto = resp.data.result as UserDto;
      const mappedResult: User = mapper.map(result, User, UserDto);

      setEmployeeData(mappedResult);

      if (props.forceSwitchToPersonalFilesTab !== undefined) {
        setSelectedTab(EmployeeViewsTypeEnum.PERSONALFILES);
        setPersonalFilesSelectedTab(props.forceSwitchToPersonalFilesTab);
      }
    });
  };

  useEffect(() => {
    getEmployeeData().then(() => {
      loader.showLoading(false);
      setDataLoaded(true);
    });
  }, []);

  // --- LIMITS ---

  const Limits_onEntitySave = (item: LimitTypeOverrideItem) => {
    if (formRef?.current?.values != null) {
      const currentValues = formRef.current.values as User;
      const limitTypesOverrides = currentValues.limitTypesOverrides[currentValues.limitTypesOverrides.findIndex((e) => e.id === item.parentId)];

      if (item.isNew) {
        item.id = GuidGenerator();
        limitTypesOverrides.items.push(item);
      } else limitTypesOverrides.items[limitTypesOverrides.items.findIndex((i) => i.id === item.id)] = item;

      setEmployeeData(currentValues);
      markKendoFormAsChanged();
    }
  };
  const Limits_onVisibilityChange = (entityId: string, visibility: boolean) => {
    if (formRef?.current?.values != null) {
      const currentValues = formRef.current.values as User;
      const userLimitOverridesValues = currentValues.limitTypesOverrides;
      userLimitOverridesValues[userLimitOverridesValues.findIndex((e) => e.id === entityId)].visible = visibility;

      setEmployeeData(currentValues);
      markKendoFormAsChanged();
    }
  };
  const Limits_onDeleteItem = (item: LimitTypeOverrideItem) => {
    if (formRef?.current?.values != null) {
      const currentValues = formRef.current.values as User;
      const limitToDelete = currentValues.limitTypesOverrides.find((lt) => lt.id === item.parentId);
      const itemToDeleteIdx = limitToDelete!.items.findIndex((i) => i.id === item.id);

      limitToDelete!.items.splice(itemToDeleteIdx, 1);

      setEmployeeData(currentValues);
      markKendoFormAsChanged();
    }
  };

  // ---- PERSONAL DATA ---
  const PData_onChange = (avatarId?: string) => {

    if (formRef?.current?.values != null) {
      let currentValues = formRef.current.values as User;
      currentValues.avatarId = avatarId;
      markKendoFormAsChanged();
    }
  }

  // --- PERSONAL FILES ---

  const PFiles_onEntitySave = (entity: any, entityId: string, entityType: EmployeeSubViewsTypeEnum) => {
    const addOrUpdatePFilesEntity = (list: any[], entity: any, entityId: string) => {
      console.log("addOrUpdatePFilesEntity NEW ?", entity);

      if (entityId && entityId !== "") {
        list[list.findIndex((e) => e.id === entityId)] = entity;
      } else {
        entity.id = GuidGenerator();
        entity.isNew = true;

        list.push(entity);
        console.log("new list NEW ?", list);
      }
    };

    if (formRef?.current?.values != null) {
      let currentValues = formRef.current.values as User;

      switch (entityType) {
        case EmployeeSubViewsTypeEnum.CONTRACTS:
          addOrUpdatePFilesEntity(currentValues.contracts, entity, entityId);
          break;
        case EmployeeSubViewsTypeEnum.MEDICALS:
          addOrUpdatePFilesEntity(currentValues.medicals, entity, entityId);
          break;
        case EmployeeSubViewsTypeEnum.TRAININGS:
          addOrUpdatePFilesEntity(currentValues.trainings, entity, entityId);
          break;
        case EmployeeSubViewsTypeEnum.NOTIFICATIONS:
          addOrUpdatePFilesEntity(currentValues.notifications, entity, entityId);
          break;
        case EmployeeSubViewsTypeEnum.WORKEXPERIENCES:
          addOrUpdatePFilesEntity(currentValues.workExperiences, entity, entityId);
          break;
        case EmployeeSubViewsTypeEnum.COMPETENCIES:
          addOrUpdatePFilesEntity(currentValues.competencies, entity, entityId);
          break;
        case EmployeeSubViewsTypeEnum.RESOURCES:
          addOrUpdatePFilesEntity(currentValues.resources, entity, entityId);
          break;
        case EmployeeSubViewsTypeEnum.DOCS:
          addOrUpdatePFilesEntity(currentValues.docs, entity, entityId);
          break;
        case EmployeeSubViewsTypeEnum.NOTES:
          addOrUpdatePFilesEntity(currentValues.notes, entity, entityId);
          break;
        case EmployeeSubViewsTypeEnum.USERWORKPLANS:
          addOrUpdatePFilesEntity(currentValues.userWorkPlans, entity as UserWorkPlan, entityId);
          break;
      }

      setEmployeeData(currentValues);
      markKendoFormAsChanged();
    }
  };

  const PFiles_onPromptDelete = (entityId: string, entityType: EmployeeSubViewsTypeEnum) => {
    setViewState({
      ...viewState,
      promptDialogVisible: true,
      entityId: entityId,
      entityType: entityType,
    });
  };

  const PFiles_onEntityDelete = (entityId: string, entityType: EmployeeSubViewsTypeEnum) => {
    if (!entityId) throw new Error("EntityId cannot be undefined!");

    if (formRef?.current?.values != null) {
      let currentValues = formRef.current.values as User;
      switch (entityType) {
        case EmployeeSubViewsTypeEnum.CONTRACTS:
          currentValues.contracts.splice(
            currentValues.contracts.findIndex((e) => e.id === entityId),
            1
          );
          break;
        case EmployeeSubViewsTypeEnum.MEDICALS:
          currentValues.medicals.splice(
            currentValues.medicals.findIndex((e) => e.id === entityId),
            1
          );
          break;
        case EmployeeSubViewsTypeEnum.TRAININGS:
          currentValues.trainings.splice(
            currentValues.trainings.findIndex((e) => e.id === entityId),
            1
          );
          break;
        case EmployeeSubViewsTypeEnum.NOTIFICATIONS:
          currentValues.notifications.splice(
            currentValues.notifications.findIndex((e) => e.id === entityId),
            1
          );
          break;
        case EmployeeSubViewsTypeEnum.WORKEXPERIENCES:
          currentValues.workExperiences.splice(
            currentValues.workExperiences.findIndex((e) => e.id === entityId),
            1
          );
          break;
        case EmployeeSubViewsTypeEnum.COMPETENCIES:
          currentValues.competencies.splice(
            currentValues.competencies.findIndex((e) => e.id === entityId),
            1
          );
          break;
        case EmployeeSubViewsTypeEnum.RESOURCES:
          currentValues.resources.splice(
            currentValues.resources.findIndex((e) => e.id === entityId),
            1
          );
          break;
        case EmployeeSubViewsTypeEnum.DOCS:
          currentValues.docs.splice(
            currentValues.docs.findIndex((e) => e.id === entityId),
            1
          );
          break;
        case EmployeeSubViewsTypeEnum.NOTES:
          currentValues.notes.splice(
            currentValues.notes.findIndex((e) => e.id === entityId),
            1
          );
          break;
        case EmployeeSubViewsTypeEnum.USERWORKPLANS:
          currentValues.userWorkPlans.splice(
            currentValues.userWorkPlans.findIndex((e) => e.id === entityId),
            1
          );
          break;
      }

      setEmployeeData(currentValues);
      // hide prompt
      setViewState({ ...viewState, promptDialogVisible: false });

      markKendoFormAsChanged();
    }
  };

  // --- MAIN ---

  const isTabStripEnabled = (tab: EmployeeViewsTypeEnum): boolean => {
    return disableSubmit && selectedTab !== tab;
  };

  const markKendoFormAsChanged = () => {
    formRef.current!.onChange("personalFiles", { value: true }); //temporary ??? workaround - force fire onSubmit in kendo form --->>> SOLUTION ignoreModified={true}
  };

  const onSubmitButtonClick = (formProps: FormRenderProps, e: any) => {
    // data was not changed in edit mode - go back to list
    if (employeeId !== undefined && formRef?.current != null && Object.keys(formRef.current.modified).length === 0) navigate("/employees");

    if (!formRef.current?.isValid()) {
      formProps.onSubmit(e); //force execute validation
      notifications.showNotification("Zapisanie danych nie udało się ponieważ formularz zawiera błędy.", NotificationEnum.Error);
    }
    else {


      formProps.onSubmit(e);
    }
  };

  const submitForm = (form: any) => {
    console.log("submitForm()/form", form);

    const dtoToSend: UserDto = mapper.map(form as User, UserDto, User);
    loader.showLoading(true);

    console.log("submitForm()/dtoToSend", dtoToSend);

    apiClient({
      method: dtoToSend.isNewUser() ? "POST" : "PUT",
      url: dtoToSend.isNewUser() ? USER_POST : USER_PUT,
      data: dtoToSend,
    }).then(async (resp) => {
      loader.showLoading(false);

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        navigate("/employees");
        notifications.showNotification(dtoToSend.isNewUser() ? "Pracownik został dodany" : "Zmiany zostały zapisane", NotificationEnum.Success);
      }
    });
  };

  return (
    <div className="fullPageContentWrapper">
      <div className="fullWidthColumn">
        {viewState.promptDialogVisible === true && (
          <PromptDialog
            text={`<p>Czy na pewno usunąć wpis?</p>`}
            onYes={() => {
              PFiles_onEntityDelete(viewState.entityId, viewState.entityType);
            }}
            onNo={() => {
              setViewState({ ...viewState, promptDialogVisible: false });
            }}
          />
        )}

        {/* used local variable 'dataLoaded', because on 400 error loader hides form and force reloading initialValues and reloading info about modified fields*/}
        {dataLoaded && (
          <Form
            onSubmit={submitForm}
            initialValues={employeeData}
            ref={formRef}
            render={(formRenderProps: FormRenderProps) => (
              <>
                <div className="mainToolbar">
                  <div className="itemHeader">
                    {employeeId === undefined
                      ? "Tworzenie nowej kartoteki pracownika"
                      : `Edycja danych pracownika ${employeeData.firstName} ${employeeData.lastName}`}
                  </div>
                  <EmployeeEditToolbar
                    disableSubmitBtn={disableSubmit}
                    onSubmitClick={(e: any) => {
                      onSubmitButtonClick(formRenderProps, e);
                    }}
                  />
                </div>

                <TabStrip
                  selected={selectedTab}
                  animation={false}
                  onSelect={(e: TabStripSelectEventArguments) => {
                    setSelectedTab(e.selected);
                  }}
                  keepTabsMounted={true}
                >
                  <TabStripTab title="Podstawowe" disabled={isTabStripEnabled(EmployeeViewsTypeEnum.PERSONALDATA)}>
                    <PersonalDataTab data={employeeData} onChange={PData_onChange} />
                  </TabStripTab>
                  <TabStripTab title="Kontaktowe" disabled={isTabStripEnabled(EmployeeViewsTypeEnum.CONTACT)}>
                    <PersonalContactTab data={employeeData} />
                  </TabStripTab>
                  <TabStripTab title="Kartoteka osobowa" disabled={isTabStripEnabled(EmployeeViewsTypeEnum.PERSONALFILES)}>
                    <PersonalFilesTab
                      setDisableParentSubmit={setDisableSubmit}
                      user={employeeData}
                      onSave={PFiles_onEntitySave}
                      onDelete={PFiles_onPromptDelete}
                      activeTab={personalFilesSelectedTab}
                    />
                  </TabStripTab>
                  <TabStripTab title="Limity urlopowe" disabled={isTabStripEnabled(EmployeeViewsTypeEnum.LIMITS)}>
                    <LimitsTab
                      data={employeeData}
                      onSave={Limits_onEntitySave}
                      onDeleteItem={Limits_onDeleteItem}
                      onVisibilityChange={Limits_onVisibilityChange}
                      setDisableParentSubmit={setDisableSubmit}
                    />
                  </TabStripTab>
                  <TabStripTab title="Plany pracy" disabled={isTabStripEnabled(EmployeeViewsTypeEnum.WORKPLANS)}>
                    <EmployeeWorkPlansList
                      data={employeeData}
                      onDeleteItem={(item) => {
                        PFiles_onEntityDelete(item.id, 9);
                      }}
                      OnSave={PFiles_onEntitySave}
                      setDisableParentSubmit={setDisableSubmit}
                    />
                  </TabStripTab>
                  <TabStripTab title="Dodatkowe ustawienia" disabled={isTabStripEnabled(EmployeeViewsTypeEnum.SYSTEMCONFIGURATION)}>
                    <SystemConfigurationTab data={employeeData} />
                  </TabStripTab>

                  {/* <TabStripTab title="Zastępstwa" /> */}
                </TabStrip>
              </>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeEdit;
