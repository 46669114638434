export default class LimitType {
    id: string;
    name: string;
    limitScope: string;
    limitUnit: string;
    limit: number;
    year: number;
    visible: boolean;

    sum: number | null;
}
