import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import TodayInformation from '../../models/local/Dashboard/TodayInformation';
import { AppContext, IAppContext } from '../../services/AppContext';

const Quote = (props: { data: TodayInformation }) => {
  const context = useContext<IAppContext>(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
  }, [])


  return (
    <div id="Quote" style={{ display: "flex", flexDirection: "row", width: "100%", alignSelf: "end" }} className="brickframe">
      <div style={{ display: "flex", color: "#000", padding: "15px" }}>
        <i>"{props.data.quote}"</i>
      </div>
    </div >
  );
};

export default Quote;
