
import React, { useRef } from 'react';

import { Field, Form, FormRenderProps } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';

import { apiClient } from '../../../api/apiClient';
import { LEAVE_GET_subordinateUsers, WORKTIME_POST_REGISTER } from '../../../api/apiEndpoints';
import { calculateTimeDifference } from '../../../helpers/timeUtils';
import DropdownItemResponse from '../../../models/dto/Dropdown/DropdownItemResponse';
import RegisterWorkTimeRequestDto from '../../../models/dto/WorkTime/Requests/RegisterWorkTimeRequestDto';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import DropdownItem from '../../../models/local/Dropdown/DropdownItem';
import WorkTimeRegisterFrom from '../../../models/local/WorkTime/Register/WorkTimeRegisterFrom';
import { mapper } from '../../../models/mapper';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';
import { requiredValidator } from '../../../validators/CommonValidators';
import EditToolbar from '../../Common/EditToolbar';
import {
    FormComboBox, FormDatePicker, FormTextArea, FormTimePicker
} from '../../Kendo/form-components';

const WorkTimeRegisterForm = (
    props: {
        onBehalfUserChanged: (onBehalfUser: DropdownItem | undefined) => void;
        onDataSaved: () => void,
        onBehalf: boolean,
        behalfUser: DropdownItem | undefined
    }) => {
    const formRef = useRef<Form>(null);
    const notifications = useAppNotifications();
    const loader = useAppLoader(false);

    const [viewState, setViewState] = React.useState<WorkTimeRegisterFrom>(() => {
        return new WorkTimeRegisterFrom(new Date(), undefined, undefined, undefined, false, "", props.behalfUser);
    });

    const [subordinateUsers, setSubordinateUsers] = React.useState<DropdownItem[]>([]);

    // ---

    const hourFromToValidator = (value: any) => value == null || viewState.hourFrom >= viewState.hourTo ? "Data rozpoczęcia musi być większa od daty zakończenia." : "";

    const handleInputChange = (e: { target: { name: string, value: Date | string } }) => {
        console.log("input change")

        const { name, value } = e.target;
        let newState = { ...viewState, [name]: value };

        if ((name === "hourFrom" && viewState.hourTo) || (name === "hourTo" && viewState.hourFrom)) {
            const fromTime = name === "hourFrom" ? value as Date : viewState.hourFrom;
            const toTime = name === "hourTo" ? value as Date : viewState.hourTo;

            const difference = calculateTimeDifference(fromTime, toTime);
            newState.hours = difference.includes('-') ? "--:--" : difference;
        }

        setViewState(newState);
    }

    const getSubordinateUsers = async () => {
        try {
            const resp = await apiClient({
                method: "GET",
                url: LEAVE_GET_subordinateUsers,
                data: {},
            });

            if (resp.status !== 200) {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error);
                return;
            }

            const result: DropdownItemResponse[] = resp.data.result as DropdownItemResponse[];
            const mappedResult: DropdownItem[] = mapper.mapArray(result, DropdownItem, DropdownItemResponse);

            setSubordinateUsers(mappedResult);
        } catch (error) {
            notifications.showNotification("Wystąpił nieoczekiwany błąd.", NotificationEnum.Error);
            console.error("Error fetching subordinate users:", error);
        }
    }

    const onSubmitButtonClick = (formProps: FormRenderProps, e: any) => {
        if (!formRef.current?.isValid()) {
            formProps.onSubmit(e); //force execute validation
            notifications.showNotification("Zapisanie danych nie udało się ponieważ formularz zawiera błędy.", NotificationEnum.Error);
        }

        formProps.onSubmit(e);
    };

    const onFormSubmit = (form: any) => {

        const dtoToSend: RegisterWorkTimeRequestDto = mapper.map(form as WorkTimeRegisterFrom, RegisterWorkTimeRequestDto, WorkTimeRegisterFrom);

        loader.showLoading(true);
        apiClient({
            method: "POST",
            url: WORKTIME_POST_REGISTER,
            data: dtoToSend,
        }).then(async (resp) => {
            loader.showLoading(false);
            setViewState(new WorkTimeRegisterFrom(viewState.date, undefined, undefined, undefined, false, "", props.behalfUser));

            props.onDataSaved();

            if (resp.status === 200) {
                // props.onBehalfUserChanged(viewState?.behalfOfUser);
                notifications.showNotification("Wprowadzone godziny pracy zostały poprawnie zapisane.", NotificationEnum.Success);
            } else {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error);
                return;
            }
        });
    }

    React.useEffect(() => {
        const fetchData = async () => {
            await getSubordinateUsers();
        };

        // if (props.onBehalfUser !== undefined)
        //     props.onBehalfUserChanged(viewState?.behalfOfUser);

        fetchData();

    }, []);

    return (
        <>
            {!loader.isLoading() && (
                <div>
                    <Form
                        onSubmit={onFormSubmit}
                        initialValues={{ ...viewState }}
                        ref={formRef}
                        render={(formRenderProps: FormRenderProps) => (
                            <>
                                <div className="k-form-field" style={{ width: "45%" }}>
                                    {subordinateUsers.length > 0 && props.onBehalf && (
                                        <Field
                                            id={"behalfOfUser"}
                                            name={"behalfOfUser"}
                                            label={"Pracownik:"}
                                            textField="text"
                                            component={FormComboBox}
                                            data={subordinateUsers}
                                            hint={
                                                "(rejestracja godzin pracy za wskazanego pracownika)"
                                            }
                                            dataItemKey="id"
                                            onChange={(e) => { props.onBehalfUserChanged(e?.value) }}
                                            // wrapperClass={moduleStyle.formField}
                                            markRequired={true}
                                            validator={requiredValidator}
                                        />)}
                                </div>

                                <p></p>

                                <div className="k-form-field" style={{ width: "45%" }}>
                                    <Field
                                        id={"date"}
                                        name={"date"}
                                        label={"Data"}
                                        component={FormDatePicker}
                                        validator={requiredValidator}
                                        onChange={handleInputChange}
                                        markRequired={true}
                                    />
                                </div>

                                <p></p>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="k-form-field" style={{ width: "45%" }}>
                                        <Field
                                            id={"hourFrom"}
                                            name={"hourFrom"}
                                            label={"Godzina rozpoczęcia pracy"}
                                            component={FormTimePicker}
                                            onChange={handleInputChange}
                                            markRequired={true}
                                            validator={hourFromToValidator}
                                        />
                                    </div>

                                    <div className="k-form-field" style={{ width: "45%" }}>
                                        <Field
                                            id={"hourTo"}
                                            name={"hourTo"}
                                            label={"Godzina zakończenia"}
                                            component={FormTimePicker}
                                            onChange={handleInputChange}
                                            markRequired={true}
                                            validator={hourFromToValidator}
                                        />

                                        <p></p>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginRight: '8px' }}>Przepracowano:</span>
                                            <Input
                                                id={"hours"}
                                                name={"hours"}
                                                value={viewState.hours}
                                                readOnly={true}
                                            />
                                        </div>

                                    </div>
                                </div>

                                <p></p>

                                <Field
                                    label={"Opis wykonanej pracy:"}
                                    id={"description"}
                                    name={"description"}
                                    autoComplete="off"
                                    component={FormTextArea}
                                    hint={"(opcjonalny)"}
                                    rows={6}
                                />

                                <p></p>

                                <EditToolbar onSaveLabel={props.onBehalf ? "Zarejestruj godziny innego pracownika" : "Zarejestruj godziny pracy"} onSubmitClick={(e: any) => { onSubmitButtonClick(formRenderProps, e); }} useSpacer={true} useColors={true} />
                            </>
                        )}
                    />

                </div>)}
        </>
    )
};

export default WorkTimeRegisterForm;