import WorkTimeSummaryGrid from './WorkTimeSummaryGrid';

export default class WorkTimeSummaryViewState {
    GridItems: WorkTimeSummaryGrid[] = [];
    Date: Date = new Date();

    deleteDialogVisible: boolean;
    idItemToDelete: string | undefined;

    expanded: boolean;

    attendanceListDateDialogVisible: boolean;

}