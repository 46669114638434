import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";

export default class NotificationEditForm {
    id: string;
    notificationType: DictItem;
    date: Date;
    validityDate: Date;
    note: string;
    notifyHR: boolean;
    notifyEmployee: boolean;
    files: StorageFile[];
}