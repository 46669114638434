import StorageFileDto from "../StorageFileDto";


export default class ContractDto {
    id: string;
    contractType: string;
    contractPeriod: string;
    signDate: Date;
    startDate: Date;
    endDate?: Date;
    note: string;
    files: StorageFileDto[] = [];
}