export default class CalculateAppRequestDto {
  userId: string;
  startDate: string;
  endDate: string;

  public constructor(userId: string | any, startDate: string | any, endDate: string | any) { // | any -> workaround for automapper casting problem
    this.userId = userId;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}