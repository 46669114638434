import React from 'react';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

const PromptDialog = (props: {
  text: string,
  onYes: () => void
  onNo: () => void,
  justifyText?: boolean
}) => {

  return (
    <Dialog title={"Potwierdzenie"} onClose={props.onNo} width={"50%"} >
      <p style={{ margin: "25px", textAlign: props.justifyText ? "justify" : "left", }} dangerouslySetInnerHTML={{ __html: props.text }}>
      </p>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={props.onYes}
        >
          Tak
        </button>

        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={props.onNo}
        >
          Nie
        </button>

      </DialogActionsBar>
    </Dialog>
  );
};

export default PromptDialog;
