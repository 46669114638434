import { useContext, useEffect, useState } from 'react';

import { AppContext, IAppContext } from './AppContext';

const useAppLoader = (initialState: boolean) => {
    const context = useContext<IAppContext>(AppContext);
    const [loading, setLoading] = useState<boolean>(initialState);

    const showLoading = (show: boolean) => {
        setLoading(show);
    }

    const isLoading = () => {
        return loading;
    }

    const isLoadingFromSession = () => {
        return context.values.isLoading;
    }

    useEffect(() => {
        setLoading(initialState);
    }, []);

    useEffect(() => {
        // set global AppContext variable 'isLoading' to show/hide animation on UI
        context.setValues({ ...context.values, isLoading: loading });
    }, [loading]);

    //const context = useContext<IAppContext>(AppContext);

    // const showLoading = (show: boolean) => {
    //     context.setSession({ ...context.session, isLoading: show});
    // }

    // const isLoading = () => {
    //     return context.session.isLoading;
    // }

    // useEffect(() => {
    //     setLoading(context.session.isLoading);
    // }, [context.session.isLoading]);

    return { showLoading, isLoading, isLoadingFromSession }
}

export default useAppLoader;