import React, { useEffect, useRef, useState } from 'react';

import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';

import { apiClient } from '../../../../../api/apiClient';
import { WORKPLANS_GET_WORKPLANS } from '../../../../../api/apiEndpoints';
import WorkPlanListResponseDto from '../../../../../models/dto/WorkPlans/Responses/WorkPlanListResponseDto';
import NotificationEnum from '../../../../../models/enums/NotificationEnum';
import DropdownItem from '../../../../../models/local/Dropdown/DropdownItem';
import UserWorkPlan from '../../../../../models/local/Employees/UserWorkPlan';
import { mapper } from '../../../../../models/mapper';
import useAppLoader from '../../../../../services/AppLoader';
import useAppNotifications from '../../../../../services/AppNotifications';
import { requiredValidator } from '../../../../../validators/CommonValidators';
import { FormDatePicker, FormDropDownList } from '../../../../Kendo/form-components';
import EditToolbar from '../PersonalFiles/LeftSideTabs/Edit/EditToolbar';

const EmployeeWorkPlansAddEdit = (props: {
  entity: UserWorkPlan,
  onSave: (entity: UserWorkPlan) => void,
  onCancel: () => void,
  setDisableParentSubmit: (value: boolean) => void
}) => {
  const [availableWorkplans, setAvailableWorkplans] = useState<DropdownItem[] | undefined>(undefined);
  const [entity, setEntity] = useState<UserWorkPlan>(props.entity);

  const formRef = useRef<Form>(null);
  const notifications = useAppNotifications();
  const loader = useAppLoader(true);

  const handleDropdownChange = (e: any) => {
    var workplan = findWorkplanById(e.value.id);
    setEntity({ ...entity, workPlanId: workplan.id, name: workplan.text });
    props.setDisableParentSubmit(true);
  };

  const handleChange = (e: any) => {
    props.setDisableParentSubmit(true);
    setEntity({ ...entity, validFromDate: e.value });
  };

  const getAvailableWorkplans = async () => {
    loader.showLoading(true);

    await apiClient({
      method: "GET",
      url: WORKPLANS_GET_WORKPLANS,
      data: {},
    }).then((resp) => {
      loader.showLoading(false);

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        const mappedAvailableWorkPlans: DropdownItem[] =
          mapper.mapArray(
            (resp?.data?.result as WorkPlanListResponseDto[]),
            DropdownItem,
            WorkPlanListResponseDto);
        setAvailableWorkplans(mappedAvailableWorkPlans);
      }
    });
  };

  const findWorkplanById = (id: string): DropdownItem => availableWorkplans!.find(e => e.id === id) as DropdownItem;

  useEffect(() => {
    getAvailableWorkplans();
  }, []);

  return (
    <>
      {entity && availableWorkplans &&
        <Form
          ref={formRef}
          initialValues={{
            ...entity,
            workPlanId: entity.isNew ?
              undefined :
              { id: entity.workPlanId, text: findWorkplanById(props.entity.workPlanId).text }
          }}
          render={(formRenderProps: FormRenderProps) => (
            <>
              <FormElement
                style={{
                  width: 400,
                }}
              >

                <div className="mainToolbar">
                  <div className="itemHeader">{entity.isNew ? "Przypisywanie nowego planu pracy" : "Edycja informacji o przypisanym planie pracy"}</div>
                  <EditToolbar
                    onCancel={props.onCancel}
                    onSave={() => {
                      if (formRef?.current?.isValid()) {
                        props.onSave(entity);
                        notifications.showNotification("Zmiany wprowadzone. Pamiętaj o ich zapisaniu.", NotificationEnum.Info);
                      }
                    }}
                  ></EditToolbar>
                </div>

                <Field
                  label={"Nazwa planu pracy"}
                  id={"workPlanId"}
                  name={"workPlanId"}
                  component={FormDropDownList}
                  data={availableWorkplans}
                  validator={requiredValidator}
                  textField="text"
                  dataItemKey="id"
                  // valueMap={(item: any) => { console.log("ITEM->", item); return item && item.id }}
                  onChange={handleDropdownChange}
                />

                <Field
                  id={"validFromDate"}
                  name={"validFromDate"}
                  label={"Obowiązuje od daty"}
                  component={FormDatePicker}
                  validator={requiredValidator}
                  onChange={handleChange}
                  disabled={(entity?.validFromDate as Date)?.getFullYear() === 1}
                />
              </FormElement>
            </>
          )}
        />}
    </>
  );
};

export default EmployeeWorkPlansAddEdit;