import React, { useEffect, useState } from 'react';

import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import {
    ListBox, ListBoxDragEvent, ListBoxItemClickEvent, ListBoxToolbar, ListBoxToolbarClickEvent,
    processListBoxData, processListBoxDragAndDrop
} from '@progress/kendo-react-listbox';

import { apiClient } from '../../../api/apiClient';
import {
    CALENDAR_GET_calendars_users, CALENDAR_PUT_users_calendar
} from '../../../api/apiEndpoints';
import AssignCalendarToUsersRequestDto from '../../../models/dto/Calendars/Requests/AssignCalendarToUsersRequestDto';
import UserWithCalendarResponseDto from '../../../models/dto/Calendars/Responses/UserWithCalendarResponseDto';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import Calendar from '../../../models/local/Calendars/Calendar';
import CalendarUsersListboxItem from '../../../models/local/Calendars/CalendarUsers';
import { mapper } from '../../../models/mapper';
import Loading from '../../../pages/Loading';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';

const SELECTED_FIELD = "selected";

const localStyle = {
  column: {
    padding: "10px",
    width: "50%",

  },
  listboxHeader: {
    paddingTop: "10px",
    paddingBottom: "10px"
  }
}

const EmployeesAssign = (props: {
  calendar: Calendar,
  onAssign: () => void
  onCancel: () => void
}) => {
  const notifications = useAppNotifications();
  const loader = useAppLoader(true);
  const [state, setState] = React.useState<{
    unassigned: CalendarUsersListboxItem[],
    assigned: CalendarUsersListboxItem[],
    draggedItem: {}
  }>({
    unassigned: [],
    assigned: [],
    draggedItem: {},
  });

  const handleItemClick = (event: any, sourceListClicked: boolean) => {
    if (sourceListClicked) {
      setState({
        ...state,
        unassigned: state.unassigned.map((item: any) => {
          if (item.userId === event.dataItem.userId) {
            item[SELECTED_FIELD] = !item[SELECTED_FIELD];
          } else if (!event.nativeEvent.ctrlKey) {
            item[SELECTED_FIELD] = false;
          }
          return item;
        }),
        assigned: state.assigned.map((item: any) => {
          item[SELECTED_FIELD] = false;
          return item;
        }),
      });
    } else {
      setState({
        ...state,
        assigned: state.assigned.map((item: any) => {
          if (item.userId === event.dataItem.userId) {
            item[SELECTED_FIELD] = !item[SELECTED_FIELD];
          } else if (!event.nativeEvent.ctrlKey) {
            item[SELECTED_FIELD] = false;
          }
          return item;
        }),
        unassigned: state.unassigned.map((item: any) => {
          item[SELECTED_FIELD] = false;
          return item;
        }),
      });
    }
  };

  const handleToolBarClick = (e: ListBoxToolbarClickEvent) => {
    let toolName: string = e.toolName || "";
    let result: any = processListBoxData(
      state.unassigned,
      state.assigned,
      toolName,
      SELECTED_FIELD
    );
    setState({
      ...state,
      unassigned: result.listBoxOneData,
      assigned: result.listBoxTwoData,
    });
  };

  const handleDragStart = (e: ListBoxDragEvent) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const handleDrop = (e: ListBoxDragEvent) => {
    let result: any = processListBoxDragAndDrop(
      state.unassigned,
      state.assigned,
      state.draggedItem,
      e.dataItem,
      "userId"
    );
    setState({
      ...state,
      unassigned: result.listBoxOneData,
      assigned: result.listBoxTwoData,
    });
  };

  // const asyncSleep = (ms: number) => new Promise(r => setTimeout(r, ms));

  const reloadUsersData = () => {
    loader.showLoading(true);
    apiClient({
      method: "GET",
      url: CALENDAR_GET_calendars_users(props.calendar.id),
      data: {},
    }).then((resp) => {
      const mappedResultAssigned: CalendarUsersListboxItem[] =
        mapper.mapArray(
          (resp?.data?.result as UserWithCalendarResponseDto[]).filter(u => u.isAssigned),
          CalendarUsersListboxItem,
          UserWithCalendarResponseDto);

      const mappedResultUnassigned: CalendarUsersListboxItem[] =
        mapper.mapArray(
          (resp?.data?.result as UserWithCalendarResponseDto[]).filter(u => !u.isAssigned),
          CalendarUsersListboxItem,
          UserWithCalendarResponseDto);

      setState({
        ...state,
        unassigned: mappedResultUnassigned,
        assigned: mappedResultAssigned
      });

      loader.showLoading(false);

    });
  };

  const onAssignClick = () => {
    loader.showLoading(true);

    const dtoToSend: AssignCalendarToUsersRequestDto = new AssignCalendarToUsersRequestDto();
    dtoToSend.calendarId = props.calendar.id;
    dtoToSend.usersIds = state.assigned.map((item) => item.userId);

    apiClient({
      method: "PUT",
      url: CALENDAR_PUT_users_calendar,
      data: dtoToSend,
    }).then((resp) => {
      loader.showLoading(false);
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        props.onAssign();
      }
    });
  };

  useEffect(() => {
    reloadUsersData();
  }, []);

  const calendarUnassignedUserItem = (localProps: any) => {
    let { dataItem, selected, ...others } = localProps;
    return (
      <li {...others}>
        <div>
          <span style={{ fontWeight: "bold" }}>{(localProps.dataItem as CalendarUsersListboxItem).fullName}</span>
          <br />
          {/* <span style={{ fontSize: "0.8em" }}>Przypisany do działu: {(props.dataItem as CalendarUsersListboxItem).departmentsNames?.join(', ') ?? "-"}</span><br /> */}
          {
            (localProps.dataItem as CalendarUsersListboxItem).calendarId === props.calendar.id ?
              <span style={{ fontSize: "0.8em" }}>Przypisany kalendarz: brak</span> :
              <span style={{ fontSize: "0.8em" }}>Przypisany kalendarz: {(localProps.dataItem as CalendarUsersListboxItem).calendarName ?? "-"}</span>
          }
        </div>
      </li >
    );
  };

  const calendarAssignedUserItem = (props: any) => {
    let { dataItem, selected, ...others } = props;
    return (
      <li {...others}>
        <div>
          <span style={{ fontWeight: "bold" }}>{(props.dataItem as CalendarUsersListboxItem).fullName}</span><br />
        </div>
      </li >
    );
  };

  return (
    <>
      <p>
        <Toolbar>
          <ToolbarItem>
            <Button fillMode="outline" icon="save" onClick={() => { onAssignClick(); }}>
              Zapisz
            </Button>&nbsp;
          </ToolbarItem>
          <ToolbarItem>
            <Button fillMode="outline" icon="undo" onClick={props.onCancel}>
              Anuluj
            </Button>&nbsp;
          </ToolbarItem>
        </Toolbar>
      </p>

      <p>
        Przypisywanie kalendarza "{props.calendar.name}" do pracowników
      </p>

      {!loader.isLoading() && (
        <div className="container">
          <div className="row justify-content-center" style={{ display: "flex" }}>

            <div style={localStyle.column}>
              <div style={localStyle.listboxHeader}>Wszyscy pracownicy firmy</div>
              <ListBox
                style={{ height: 400, width: "100%" }}
                data={state.unassigned}
                textField="userId"
                selectedField={SELECTED_FIELD}
                onItemClick={(e: ListBoxItemClickEvent) =>
                  handleItemClick(e, true)
                }
                onDragStart={handleDragStart}
                onDrop={handleDrop}
                item={calendarUnassignedUserItem}
                toolbar={() => {
                  return (
                    <ListBoxToolbar
                      tools={[
                        "transferTo",
                        "transferFrom",
                      ]}
                      data={state.unassigned}
                      dataConnected={state.assigned}
                      onToolClick={handleToolBarClick}
                    />
                  );
                }}
              />
            </div>

            <div style={localStyle.column}>
              <div style={localStyle.listboxHeader}>Pracownicy przypisani do kalendarza "{props?.calendar.name}"</div>
              <ListBox
                style={{ height: 400, width: "100%" }}
                data={state.assigned}
                textField="userId"
                selectedField={SELECTED_FIELD}
                onItemClick={(e: ListBoxItemClickEvent) =>
                  handleItemClick(e, false)
                }
                onDragStart={handleDragStart}
                onDrop={handleDrop}
                item={calendarAssignedUserItem}
              />
            </div>

          </div>
        </div>
      )}
    </>

  );
};

export default EmployeesAssign;
