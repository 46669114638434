import '../../../styles/_dashboard.scss';

import React from 'react';

function Contact() {
  return (
    <div id="contactWrapper" className="singleContactBrick brickframe">
      <img src="https://cdn-icons-png.flaticon.com/512/942/942802.png" className="bcgrImg" alt="Contact us" />
      <b>Masz pytania?</b> Potrzebujesz pomocy?
      <br />
      Chętnie udzielimy Ci odpowiedzi.
      <br />
      <br />
      Pomorski Park Naukowo-Technologiczny
      <br />
      Casco Sp. z o.o.
      <br />
      Al. Zwycięstwa 96/98
      <br />
      81-451 Gdynia
      <br />
      <br />
      Telefon: 58 732 85 85
      <br />
      E-mail: biuro@synerdi.pl
    </div>
  );
}

export default Contact;
