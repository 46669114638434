import React, { useEffect, useState } from 'react';

import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';

import { apiClient } from '../../../api/apiClient';
import { SETTINGS_GET, SETTINGS_PUT } from '../../../api/apiEndpoints';
import SettingsParametersRequest from '../../../models/dto/Settings/Requests/SettingsParametersRequest';
import SettingsParametersResponse from '../../../models/dto/Settings/Responses/SettingsParametersResponse';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import SettingsParameters from '../../../models/local/Settings/Responses/SettingsParameters';
import { mapper } from '../../../models/mapper';
import Loading from '../../../pages/Loading';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';
import EditToolbar from '../../Common/EditToolbar';
import ErrorMessage from '../../Common/ErrorMessage';
import { FormCheckbox } from '../../Kendo/form-components';

const ParametersComponent = () => {
  const notifications = useAppNotifications();

  const [settings, setSettings] = useState<SettingsParameters>();
  const loader = useAppLoader(true);


  const loadSettingsData = async () => {
    const getSettings = async () => {
      loader.showLoading(true);

      apiClient({
        method: "GET",
        url: SETTINGS_GET,
        data: {},
      }).then((resp) => {
        const mappedResult: SettingsParameters = mapper.map(resp?.data?.result, SettingsParameters, SettingsParametersResponse);

        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }

        setSettings(mappedResult);
        loader.showLoading(false);
      });
    };

    getSettings();
  }

  const onFormSubmit = (form: any) => {
    const dtoToSend: SettingsParametersRequest = mapper.map(form as SettingsParameters, SettingsParametersRequest, SettingsParameters);
    loader.showLoading(true);

    apiClient({
      method: "PUT",
      url: SETTINGS_PUT,
      data: dtoToSend,
    }).then(async (resp) => {
      loader.showLoading(false);

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      const mappedResult: SettingsParameters = mapper.map(resp?.data?.result, SettingsParameters, SettingsParametersResponse);
      setSettings(mappedResult);
      notifications.showNotification("Zmiany zostały poprawnie zapisane")
    });
  };

  useEffect(() => {
    loadSettingsData();
  }, []);

  return (
    <>

      {!loader.isLoading() && (
        <Form
          onSubmit={onFormSubmit}
          initialValues={settings}
          render={(formRenderProps: FormRenderProps) => (
            <>
              <div className="mainToolbar">
                <EditToolbar onSaveLabel='Zapisz zmiany'></EditToolbar>
              </div>
              <FormElement>
                <Field
                  id={"replacementRequired"}
                  name={"replacementRequired"}
                  label={"Podczas składania wniosku wskazanie zastępstwa jest obowiązkowe"}
                  component={FormCheckbox}
                  hint={"(jeżeli włączysz tą opcję to pracownik składając wniosek będzie musiał każdorazowo wskazać osobę, która będzie go zastępowała) "}
                />

                <Field
                  id={"userCanChangeAvatar"}
                  name={"userCanChangeAvatar"}
                  label={"Włącz możliwość zmiany własnego zdjęcia przez pracownika"}
                  component={FormCheckbox}
                  hint={"(włączenie tej opcji umożliwi każdemu pracownikowi podmianę własnego zdjęcia) "}
                />

                <Field
                  id={"welcomeEmailWhenBriefcastIsCreated"}
                  name={"welcomeEmailWhenBriefcastIsCreated"}
                  label={"Wyślij e-mail powitalny do pracownika po utworzeniu teczki osobowej "}
                  component={FormCheckbox}
                  hint={"(włączenie tej opcji powoduje, że po utworzeniu i zapisaniu nowej teczki osobowej pracownika system automatycznie wyśle do niego e-mail z informacją o tym, że założone zostało nowe konto) "}
                />
              </FormElement>
            </>
          )}
        />
      )}
    </>
  )
};

export default ParametersComponent;
