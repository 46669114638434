import React from 'react';

import UserLeaveStats from '../../models/local/Leaves/UserLeaveStats';
import AllUserLimits from '../../models/local/Limits/AllUserLimits';
import LimitsList from '../Employees/EmployeeEdit/Tabs/Limits/LimitsTab/LimitsList';

const UserLimits = (props: {
  userLimits: AllUserLimits,
  userLeaveStats: UserLeaveStats,
  year: number,
  onSetYear: (year: number) => void,
  yearReadonly: boolean
}) => {

  return <>
    <LimitsList
      year={props.year}
      setYear={props.onSetYear}
      globalLimits={props.userLimits.global}
      individualLimits={props.userLimits.individual}
      leaveStats={props.userLeaveStats}
      onAddItem={() => { }}
      onEditItem={() => { }}
      onDeleteItem={() => { }}
      onVisibilityChange={() => { }}
      readonly={true}
      yearReadonly={props.yearReadonly}
    />
  </>
};

export default UserLimits;