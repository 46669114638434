export default class GetTrainingsRequest {
    TrainingTypeId: string;
    DateFrom: Date;
    DateTo: Date;

    ValidityDateFrom: Date;
    ValidityDateTo: Date;

    Firstname: string;
    Lastname: string;
}