export default class ResourceItem {
    id: string;
    userId: string;
    resourceType: string;
    date: Date;
    returnUntilDate: Date;
    returnDate: Date;
    isReturned: boolean;
    firstName: string;
    lastName: string;
}
