import OfficeDto from "../Office/OfficeDto";
import PromoCodeDto from "../PromoCode/PromoCodeDto";

export default class LicenceDto {
    id: string;
    office: OfficeDto;
    validFrom: Date;
    validTo: Date;
    workersLimit: number;
    promoCode: PromoCodeDto;
  }
  