import LeaveResponseListItem from './LeaveResponseListItem';
import SummaryLeaveItem from './SummaryLeaveItem';
import TodayInformation from './TodayInformation';

export default class DashboardData {
  todayInformation: TodayInformation;
  plannedLeaves: SummaryLeaveItem[];
  actualLeaves: SummaryLeaveItem[];
  forConsiderationLeaves: LeaveResponseListItem[];
}
