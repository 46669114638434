import DropdownItemRequest from "../../Dropdown/DropdownItemRequest";
import StorageFile from "../../Storage/StorageFile";

export default class AddMedicalRequest {
    userId: string;
    medicalTestingType: DropdownItemRequest;
    date: Date;
    validityDate?: Date;
    note: string;
    files: StorageFile[];
}