import React, { useEffect, useState } from 'react';

import { apiClient } from '../api/apiClient';
import { LEAVE_GET_monthView } from '../api/apiEndpoints';
import CalendarToolbar from '../components/Calendar/CalendarToolbar';
import CalendarListView from '../components/Calendar/ListView/CalendarListView';
import CalendarMonthView from '../components/Calendar/MonthView/CalendarMonthView';
import { ViewTypeEnum } from '../components/Calendar/ViewTypeEnum';
import MonthViewItemResponseDto from '../models/dto/Leaves/Responses/MonthViewItemResponseDto';
import NotificationEnum from '../models/enums/NotificationEnum';
import MonthViewItem from '../models/local/Leaves/MonthViewItem';
import { mapper } from '../models/mapper';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';

const Calendar = () => {
  const notifications = useAppNotifications();
  const loader = useAppLoader(true);
  const [displayDate, setDisplayDate] = useState<Date>(new Date());

  const [calendarItems, setCalendarItems] = useState<MonthViewItem[]>([]);
  const [filteredCalendarItems, setFilteredCalendarItems] = useState<MonthViewItem[]>([]);


  const [currentView, setCurrentView] = useState<ViewTypeEnum>(ViewTypeEnum.Month);

  const [filters, setFilters] = useState<{ departmentId: string | undefined }>({ departmentId: undefined });

  const onChangeDate = (shift: number) => {
    var date = displayDate;
    date.setMonth(date.getMonth() + shift);

    loader.showLoading(true);
    setDisplayDate(new Date(date));
  }

  const onChangeView = (viewType: ViewTypeEnum) => {
    setCurrentView(viewType);
  }

  const filterCalendarItems = () => {
    if (filters?.departmentId !== undefined)
      setFilteredCalendarItems(calendarItems.filter(ci => ci.departmentsIds.includes(filters.departmentId ?? "")));
    else
      setFilteredCalendarItems(calendarItems);
  }

  const reloadCalendarData = () => {
    loader.showLoading(true);
    apiClient({
      method: "GET",
      url: `${LEAVE_GET_monthView}?year=${displayDate.getFullYear()}&month=${displayDate.getMonth() + 1}`
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        const mappedResponse: MonthViewItem[] = mapper.mapArray(resp?.data?.result as MonthViewItemResponseDto[], MonthViewItem, MonthViewItemResponseDto);
        setCalendarItems(mappedResponse);
      }
      loader.showLoading(false);
    });
  };

  useEffect(() => {
    reloadCalendarData();
  }, [displayDate]);

  useEffect(() => {
    filterCalendarItems();
  }, [filters, calendarItems]);

  return (
    <>
      <div className="fullPageContentWrapper">
        <div className="fullWidthColumn">

          <div className="mainToolbar">
            <CalendarToolbar
              date={displayDate}
              changeMonth={onChangeDate}
              changeView={onChangeView}
              filterByDepartmentId={(id: string | undefined) => { setFilters({ ...filters, departmentId: id }) }} />

            {!loader.isLoading() &&
              <div>
                {currentView === ViewTypeEnum.Month && <CalendarMonthView displayDate={displayDate} calendarItems={filteredCalendarItems} />}
                {currentView === ViewTypeEnum.List && <CalendarListView displayDate={displayDate} calendarItems={filteredCalendarItems} />}
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;