import * as React from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const InfoDialog = (props: {
  text: string,
  onClose: () => void
}) => {

  return (
    <Dialog title={"Informacja"} onClose={props.onClose} width="30%" >
      <p style={{ margin: "25px", textAlign: "left", }} dangerouslySetInnerHTML={{ __html: props.text }}>
      </p>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={props.onClose}
        >
          OK
        </button>

      </DialogActionsBar>
    </Dialog>
  );
};

export default InfoDialog;
