export default class PromoCodeList {
    id: string;
    code: string;
    validFromUtc: Date;
    validToUtc: Date;
    workersLimit: number;
    maxUsages: number;
    usages: number;
    validForDays: number;
    description: string;
}