import moment from 'moment';

export const getHoursDiff = (startTime: Date, endTime: Date | null) => {
    if (!startTime || !endTime) {
        return { hours: 0, mins: 0, asString: "00:00" };
    }

    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    const hours = duration.asHours();
    const minutes = duration.asMinutes() % 60;
    return {
        hours: Math.floor(hours),
        mins: minutes,
        asString: `${addLeadingZeros(Math.floor(hours))}:${addLeadingZeros(minutes)}`
    };
}

export const addLeadingZeros = (value: number) => value <= 9 ? `0${value.toString()}` : value.toString();

export const calculateTimeDifference = (hourFrom: Date, hourTo: Date | null): string => {
    if (!hourFrom || !hourTo) {
        return "00 godz. 00 minut";
    }

    const differenceInMilliseconds = hourTo.getTime() - hourFrom.getTime();
    const hoursDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutesDifference = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

    // Formatowanie wyników, aby mieć dwie cyfry dla godzin i minut
    const formattedHours = String(hoursDifference).padStart(2, '0');
    const formattedMinutes = String(minutesDifference).padStart(2, '0');

    return `${formattedHours} godz. ${formattedMinutes} minut`;
};
