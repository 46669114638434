import StorageFile from '../../dto/Storage/StorageFile';
import IsNewBaseMarker from './IsNewBaseMarker';

export default class Contract extends IsNewBaseMarker {
    id: string;
    contractType: string;
    contractPeriod: string;
    signDate: Date;
    startDate: Date;
    endDate?: Date;
    note: string;
    files: StorageFile[];
}