import React, { CSSProperties } from 'react';

import { Loader } from '@progress/kendo-react-indicators';

const containerStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backgroundColor: "white",
  zIndex: "1000",
  opacity: "0.75",
  position: "absolute",
  top: "0px",
  left: "0px"
};
const childStyle: CSSProperties = {
  width: "300px",
  height: "300px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const Loading = () => {
  return (
    <div style={containerStyle}>
      <div style={childStyle}>
        Trwa wczytywanie. Proszę czekać...
        <Loader size="large" type={"infinite-spinner"} />
      </div>
    </div>
  );
};

export default Loading;
