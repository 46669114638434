import React, { ReactNode } from 'react';

import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import {
    Grid, GridDataStateChangeEvent, GridSelectionChangeEvent
} from '@progress/kendo-react-grid';

import { apiClient } from '../../api/apiClient';
import Loading from '../../pages/Loading';
import useAppLoader from '../../services/AppLoader';

interface StatefullGridState {
    dataState: State,
    total: 0,
    data?: undefined
}

type CallbackMapping = (dtoData: any) => any;
type CallbackOnGridSelectionChange = (event: GridSelectionChangeEvent) => any;

export const StatefullGrid = (props: {
    style: any,
    children: ReactNode,
    url: string,
    filterable: boolean,
    sortable: boolean,
    filter: CompositeFilterDescriptor,
    sort: any,
    callbackMapping?: CallbackMapping,
    onSelectionChange?: CallbackOnGridSelectionChange
}) => {
    const loader = useAppLoader(true);
    const [state, setState] = React.useState<StatefullGridState>({ dataState: { skip: 0, take: 20, filter: props.filter }, total: 0, });

    const handleDataStateChange = (changeEvent: GridDataStateChangeEvent) => {
        console.log("handleDataStateChange", state.dataState.filter)
        setState({ ...state, dataState: changeEvent.dataState });
        fetchData(changeEvent.dataState);
    }
    //const [selectedState, setSelectedState] = React.useState<{[id: string]: boolean | number[];}>({});
    //const singleMode: GridSelectableMode = "single"; 

    const fetchData = (dataState: any) => {
        loader.showLoading(true);
        apiClient({
            method: "POST",
            url: props.url,
            data: dataState
        }).then((resp: any) => {
            if (props.callbackMapping && resp.data.result)
                resp.data.result.data = props.callbackMapping(resp.data.result.data);

            console.log("fetchData", state.dataState)

            setState({
                ...state,
                data: resp.data.result?.data,
                total: resp.data.result?.total,
                dataState
            });

            console.log("after fetchData", resp.data.result?.data)

            loader.showLoading(false);
        });
    };

    React.useEffect(() => {
        console.log("useEffect fetchData", state.dataState)
        fetchData(state.dataState);
    }, []);

    React.useEffect(() => {
        console.log("CHANGED", state.dataState.filter)
    }, [state.dataState]);

    return (
        <>
            <Grid
                pageable={{ pageSizes: true }}
                // selectable = {{
                //     enabled: true,
                //     cell: false,
                //     drag: false,
                //     mode: singleMode
                // }}
                {...props}
                total={state.total}
                data={state.data}
                skip={state.dataState.skip}
                pageSize={state.dataState.take}
                filter={state.dataState.filter}
                sort={state.dataState.sort}
                onDataStateChange={handleDataStateChange}
            // onSelectionChange={props.onSelectionChange}
            >
                {/* {props.children} */}
            </Grid>
        </>
    );
}