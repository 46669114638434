import StorageFileDto from "../StorageFileDto";


export default class WorkExperienceDto {
    id: string;
    name: string;
    date: Date;
    validityDate: Date;
    note: string;
    files: StorageFileDto[] = [];
}