import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ClientLicenceInfo from "../components/ClientLicence/ClientLicenceInfo";

const ClientLicence = () => {
    return (
        <>
        <div className="fullPageContentWrapper">
            <div className="fullWidthColumn">
              <ClientLicenceInfo></ClientLicenceInfo>
            </div>
        </div >
    </>
  )
}

export default ClientLicence;