
import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";

export default class CompetencyEditForm {
    id: string;
    competencyType: DictItem;
    date: Date;
    validityDate: Date;
    note: string;
    files: StorageFile[];
}