import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';

import { apiClient } from '../api/apiClient';
import { BRIEFCASES_GET_TRAININGS } from '../api/apiEndpoints';
import TrainingsList from '../components/Briefcases/TrainingsList';
import ErrorMessage from '../components/Common/ErrorMessage';
import GetTrainingsRequest from '../models/dto/Briefcases/Requests/GetTrainingsRequest';
import TrainingItemResponse from '../models/dto/Briefcases/Responses/TrainingItemResponse';
import NotificationEnum from '../models/enums/NotificationEnum';
import TrainingItem from '../models/local/Briefcases/TrainingItem';
import { mapper } from '../models/mapper';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';
import Loading from './Loading';

const BcTrainings = () => {
  const [gridItems, setGridItems] = useState<TrainingItem[]>([]);
  const loader = useAppLoader(true);
  const navigate = useNavigate();
  const notifications = useAppNotifications();

  const refreshGridItems = () => {
    loader.showLoading(true);
    let request: GetTrainingsRequest = new GetTrainingsRequest();

    apiClient({
      method: "GET",
      url: BRIEFCASES_GET_TRAININGS,
      data: request,
    }).then((resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        loader.showLoading(false);
        return;
      }

      loader.showLoading(false);

      const mappedResult: TrainingItem[] = mapper.mapArray(resp?.data?.result, TrainingItem, TrainingItemResponse);
      setGridItems(mappedResult);
    });

  }

  useEffect(() => {
    refreshGridItems();
  }, []);

  return (
    <>
      {!loader.isLoading() &&
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn">
            <div className="mainToolbar" style={{ minHeight: "3em", marginBottom: "10px" }}>
              <Toolbar>
                <ToolbarItem>
                  <Button
                    icon="plus"
                    fillMode="outline"
                    onClick={() => {
                      navigate(`/briefcases/trainings/add`);
                    }}
                  >
                    Dodaj szkolenie
                  </Button>
                </ToolbarItem>
              </Toolbar>
            </div>

            <TrainingsList gridItems={gridItems} ></TrainingsList>
          </div>
        </div >
      }

    </>
  )
};

export default BcTrainings;
