import React from 'react';

import { Button, Toolbar, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';
import { FormElement } from '@progress/kendo-react-form';

const EditToolbar = (props: {
  onCancelClick?: () => void
  onSubmitClick?: (e: any) => void
  onSaveLabel?: string
  useSpacer?: boolean
  useColors?: boolean
}) => {

  return (
    <Toolbar>
      <ToolbarItem>
        <FormElement>
          <Button
            icon="save"
            id="submit"
            type={props.onSubmitClick ? "button" : "submit"}
            className="k-button"
            themeColor={props.useColors ? "success" : "base"}
            fillMode={props.useColors ? "solid" : "outline"}
            onClick={(e: any) => {
              if (props.onSubmitClick)
                props.onSubmitClick(e);
            }}
          >
            {props.onSaveLabel ?? "Zapisz"}
          </Button>
        </FormElement>
      </ToolbarItem>

      {props.useSpacer && <ToolbarSpacer />}

      {
        props.onCancelClick !== undefined && (
          <ToolbarItem>
            <Button
              icon="close"
              themeColor={props.useColors ? "error" : "base"}
              fillMode={props.useColors ? "solid" : "outline"}
              onClick={props.onCancelClick}
            >
              Anuluj
            </Button>
          </ToolbarItem>
        )
      }
    </Toolbar >
  );
};

export default EditToolbar;
