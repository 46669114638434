import React, { useEffect } from 'react';

import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import {
    ListBox, ListBoxDragEvent, ListBoxItemClickEvent, ListBoxToolbar, ListBoxToolbarClickEvent,
    processListBoxData, processListBoxDragAndDrop
} from '@progress/kendo-react-listbox';

import { apiClient } from '../../api/apiClient';
import { DEPARTMENTS_GET_usersAssignedUnassigned } from '../../api/apiEndpoints';
import AssignedUnassignedUsersResponseDto from '../../models/dto/Departments/Responses/AssignedUnassignedUsersResponseDto';
import DepartmentUserListboxItem from '../../models/local/Departments/DepartmentUserListboxItem';
import { mapper } from '../../models/mapper';
import Loading from '../../pages/Loading';
import useAppLoader from '../../services/AppLoader';

const localStyle = {
  column: {
    padding: "10px",
    width: "50%",

  },
  listboxHeader: {
    paddingTop: "10px",
    paddingBottom: "10px"
  }
}

const SELECTED_FIELD = "selected";

const EmployeesManage = (props: {
  departmentName: string,
  departmentId: string,
  onSave: (items: DepartmentUserListboxItem[]) => void,
  onCancel: () => void,
}) => {
  const loader = useAppLoader(true);

  const [state, setState] = React.useState<{
    unassigned: DepartmentUserListboxItem[],
    assigned: DepartmentUserListboxItem[],
    draggedItem: {}
  }>({
    unassigned: [],
    assigned: [],
    draggedItem: {},
  });

  const handleItemClick = (event: any, sourceListClicked: boolean) => {
    if (sourceListClicked) {
      setState({
        ...state,
        unassigned: state.unassigned.map((item: any) => {
          if (item.id === event.dataItem.id) {
            item[SELECTED_FIELD] = !item[SELECTED_FIELD];
          } else if (!event.nativeEvent.ctrlKey) {
            item[SELECTED_FIELD] = false;
          }
          return item;
        }),
        assigned: state.assigned.map((item: any) => {
          item[SELECTED_FIELD] = false;
          return item;
        }),
      });
    } else {
      setState({
        ...state,
        assigned: state.assigned.map((item: any) => {
          if (item.id === event.dataItem.id) {
            item[SELECTED_FIELD] = !item[SELECTED_FIELD];
          } else if (!event.nativeEvent.ctrlKey) {
            item[SELECTED_FIELD] = false;
          }
          return item;
        }),
        unassigned: state.unassigned.map((item: any) => {
          item[SELECTED_FIELD] = false;
          return item;
        }),
      });
    }
  };

  const handleToolBarClick = (e: ListBoxToolbarClickEvent) => {
    let toolName: string = e.toolName || "";
    let result: any = processListBoxData(
      state.unassigned,
      state.assigned,
      toolName,
      SELECTED_FIELD
    );
    setState({
      ...state,
      unassigned: result.listBoxOneData,
      assigned: result.listBoxTwoData,
    });
  };

  const handleDragStart = (e: ListBoxDragEvent) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const handleDrop = (e: ListBoxDragEvent) => {
    let result: any = processListBoxDragAndDrop(
      state.unassigned,
      state.assigned,
      state.draggedItem,
      e.dataItem,
      "name"
    );
    setState({
      ...state,
      unassigned: result.listBoxOneData,
      assigned: result.listBoxTwoData,
    });
  };

  const reloadUsersData = async () => {
    loader.showLoading(true);
    apiClient({
      method: "GET",
      url: DEPARTMENTS_GET_usersAssignedUnassigned(props.departmentId),
      data: {},
    }).then((resp) => {
      const mappedResultAssigned: DepartmentUserListboxItem[] =
        mapper.mapArray(
          (resp?.data?.result as AssignedUnassignedUsersResponseDto[]).filter(u => u.isAssigned),
          DepartmentUserListboxItem,
          AssignedUnassignedUsersResponseDto);

      const mappedResultUnassigned: DepartmentUserListboxItem[] =
        mapper.mapArray(
          (resp?.data?.result as AssignedUnassignedUsersResponseDto[]).filter(u => !u.isAssigned),
          DepartmentUserListboxItem,
          AssignedUnassignedUsersResponseDto);

      setState({
        ...state,
        unassigned: mappedResultUnassigned,
        assigned: mappedResultAssigned
      });

      loader.showLoading(false);

    });
  };

  useEffect(() => {
    reloadUsersData();
  }, []);

  return (
    <div className="mainToolbar">
      <div className="itemHeader">
        Zarządzanie listą pracowników "{props?.departmentName}"
      </div>

      <Toolbar>
        <ToolbarItem>
          <Button
            fillMode="outline"
            icon="save"
            id="submit"
            onClick={() => { props.onSave(state.assigned) }}
            className="k-button"
          >
            Zapisz
          </Button>
        </ToolbarItem>

        <ToolbarItem>
          <Button
            icon="close"
            fillMode="outline"
            onClick={props.onCancel}
          >
            Anuluj
          </Button>
        </ToolbarItem>
      </Toolbar>

      {!loader.isLoading() &&
        <div className="container">
          <div className="row justify-content-center" style={{ display: "flex" }}>
            <div style={localStyle.column}>
              <div style={localStyle.listboxHeader}>Wszyscy pracownicy firmy</div>
              <ListBox
                style={{ height: 400, width: "100%" }}
                data={state.unassigned}
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e: ListBoxItemClickEvent) =>
                  handleItemClick(e, true)
                }
                onDragStart={handleDragStart}
                onDrop={handleDrop}
                toolbar={() => {
                  return (
                    <ListBoxToolbar
                      tools={[
                        "transferTo",
                        "transferFrom",
                      ]}
                      data={state.unassigned}
                      dataConnected={state.assigned}
                      onToolClick={handleToolBarClick}
                    />
                  );
                }}
              />
            </div>

            <div style={localStyle.column}>
              <div style={localStyle.listboxHeader}>Pracownicy przypisani do działu "{props?.departmentName}"</div>
              <ListBox
                style={{ height: 400, width: "100%" }}
                data={state.assigned}
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e: ListBoxItemClickEvent) =>
                  handleItemClick(e, false)
                }
                onDragStart={handleDragStart}
                onDrop={handleDrop}
              />
            </div>
          </div>
        </div>}

    </div>
  );
};

export default EmployeesManage;
