import StorageFile from '../../dto/Storage/StorageFile';
import IsNewBaseMarker from './IsNewBaseMarker';

export default class Notification extends IsNewBaseMarker {
    id: string;
    notificationType: string;
    date: Date;
    validityDate: Date;
    note: string;
    notifyHR: boolean;
    notifyEmployee: boolean;
    files: StorageFile[];
}