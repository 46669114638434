import * as React from 'react';

import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';

import { apiClientDownload } from '../api/apiClient';
import {
    EXPORT_Enova, EXPORT_ERPOptima, EXPORT_Symfonia, EXPORT_WFGang, LEAVE_GET_exportData
} from '../api/apiEndpoints';
import ErrorMessage from '../components/Common/ErrorMessage';
import ExportDataList from '../components/ExportData/ExportDataList';
import ExportDataListItemDto from '../models/dto/Export/ExportDataListItemDto';
import GetExportDataRequest from '../models/dto/Export/GetExportDataRequest';
import ExportTypeEnum from '../models/enums/ExportTypeEnum';
import LeaveStatusEnum from '../models/enums/LeaveStatusEnum';
import ExportDataListItem from '../models/local/ExportData/ExportDataListItem';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';

const ExportData = () => {
  const loader = useAppLoader(true);
  const notifications = useAppNotifications();
  const [selectedIndex, setSelected] = React.useState(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const getUrl = (exportType: ExportTypeEnum) => {
    switch (exportType) {
      case ExportTypeEnum.ERPOptima: return EXPORT_ERPOptima;
      case ExportTypeEnum.Enova: return EXPORT_Enova;
      case ExportTypeEnum.Symfonia: return EXPORT_Symfonia;
      case ExportTypeEnum.WFGang: return EXPORT_WFGang;
      default: return "";
    }
  };

  const getExportType = (index: number) => {
    switch (index) {
      case 0: return ExportTypeEnum.Symfonia;
      case 1: return ExportTypeEnum.Enova;
      case 2: return ExportTypeEnum.ERPOptima;
      case 3: return ExportTypeEnum.WFGang;
    }
  };

  const getFileName = (type: ExportTypeEnum) => {
    switch (type) {
      case ExportTypeEnum.Symfonia: return "symfonia.xml";
      case ExportTypeEnum.Enova: return "enova.xlsx";
      case ExportTypeEnum.ERPOptima: return "erpoptima.xlsx";
      case ExportTypeEnum.WFGang: return "wfgang.xlsx";
      default: return "unknown.export";
    }
  };

  const getContentType = (type: ExportTypeEnum) => {
    switch (type) {
      case ExportTypeEnum.Symfonia: return "text/xml";
      case ExportTypeEnum.Enova: return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case ExportTypeEnum.ERPOptima: return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case ExportTypeEnum.WFGang: return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      default: return "application/octet-stream";
    }
  };

  // download file
  const exportData = async () => {
    var exportType: ExportTypeEnum = ExportTypeEnum[getExportType(selectedIndex) as keyof typeof ExportTypeEnum];
    const getLeaves = async () => {
      loader.showLoading(true);

      await apiClientDownload({
        path: getUrl(exportType),
        fileName: getFileName(exportType),
        contentType: getContentType(exportType),
        data: {
        }
      });
    };

    Promise.all([getLeaves()]).then(() => {
      loader.showLoading(false);
    });
  }

  React.useEffect(() => {

  }, []);

  return (
    <>
      <div className="fullPageContentWrapper">
        <div className="fullWidthColumn">
          <div className="mainToolbar" style={{ minHeight: "3em", marginBottom: "10px" }}>
            <Toolbar>
              {/* <ToolbarItem>
              <Button
                icon="search"
                fillMode="outline"
                onClick={() => {
                  loadData();
                }}
              >
                Pokaż
              </Button>
            </ToolbarItem> */}
              <ToolbarItem>
                <Button
                  icon="undo"
                  fillMode="outline"
                  onClick={() => {
                    exportData();
                  }}
                >
                  Eksport do pliku
                </Button>
              </ToolbarItem>
            </Toolbar>
          </div>
          <TabStrip selected={selectedIndex} onSelect={handleSelect}>
            {/* Warning: changing tab order force to some changes in getExportType() method */}
            <TabStripTab title="Symfonia">
              <ExportDataList exportType={ExportTypeEnum.Symfonia} url={LEAVE_GET_exportData} ></ExportDataList>
            </TabStripTab>
            <TabStripTab title="Enova">
              <ExportDataList exportType={ExportTypeEnum.Enova} url={LEAVE_GET_exportData} ></ExportDataList>
            </TabStripTab>
            <TabStripTab title="ERP Optima">
              <ExportDataList exportType={ExportTypeEnum.ERPOptima} url={LEAVE_GET_exportData} ></ExportDataList>
            </TabStripTab>
            <TabStripTab title="WFGang">
              <ExportDataList exportType={ExportTypeEnum.WFGang} url={LEAVE_GET_exportData} ></ExportDataList>
            </TabStripTab>
          </TabStrip>
        </div>
      </div >

    </>
  )
};

export default ExportData;
