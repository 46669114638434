import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Field, Form, FormRenderProps } from '@progress/kendo-react-form';

import { apiClient } from '../../api/apiClient';
import { PROMOCODE_GET_BY_ID, PROMOCODE_POST, PROMOCODE_PUT } from '../../api/apiEndpoints';
import PromoCodeDto from '../../models/dto/PromoCode/PromoCodeDto';
import PromoCodeEditRequest from '../../models/dto/PromoCode/PromoCodeEditRequest';
import NotificationEnum from '../../models/enums/NotificationEnum';
import PromoCode from '../../models/local/PromoCode/PromoCode';
import { mapper } from '../../models/mapper';
import Loading from '../../pages/Loading';
import useAppNotifications from '../../services/AppNotifications';
import { codeValidator } from '../../validators/PromoCodeValiddators';
import ErrorMessage from '../Common/ErrorMessage';
import {
    FormDatePicker, FormInput, FormNumericTextBox, FormTextArea
} from '../Kendo/form-components';
import PromoCodeEditToolbar from './PromoCodeEditToolbar';

type PromoCodeEditParams = {
    id: string;
};

const PromoCodeEdit = (props: {}) => {
    const formRef = useRef<Form>(null);
    const navigate = useNavigate();
    const notifications = useAppNotifications();
    const promoCodeId = useParams<PromoCodeEditParams>()?.id;

    const [viewState, setViewState] = React.useState<{
        isLoading: boolean,
        promoCodeData: PromoCode | undefined
    }>(
        {
            isLoading: true,
            promoCodeData: new PromoCode()
        }
    );

    const getPromoCodeData = async () => {
        if (promoCodeId != null) {
            const resp = await apiClient({
                method: "GET",
                url: PROMOCODE_GET_BY_ID(promoCodeId),
                data: {},
            });

            if (resp.status !== 200) {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error);
                return;
            }

            const result: PromoCodeDto = resp.data.result as PromoCodeDto;
            const mappedResult: PromoCode = mapper.map(result, PromoCode, PromoCodeDto);

            return mappedResult;

        } else {
            let fromDate = new Date();
            fromDate.setHours(0, 0, 0, 0);
            let toDate = new Date(fromDate.getFullYear() + 1, fromDate.getMonth(), fromDate.getDate())
            const emptyPromoCode: PromoCode = mapper.map({ validFromUtc: fromDate, validToUtc: toDate, workersLimit: 0 } as PromoCodeDto, PromoCode, PromoCodeDto);
            return emptyPromoCode;
        }
    }

    useEffect(() => {
        Promise.all([getPromoCodeData()]).then(([resPromoCode]) => {
            setViewState({ ...viewState, isLoading: false, promoCodeData: resPromoCode });
        });
    },
        []);

    const submitForm = (form: any) => {
        const dtoToSend: PromoCodeEditRequest = mapper.map(form as PromoCode, PromoCodeEditRequest, PromoCode);

        apiClient({
            method: dtoToSend.id ? "PUT" : "POST",
            url: dtoToSend.id ? PROMOCODE_PUT : PROMOCODE_POST,
            data: dtoToSend,
        }).then(async (resp) => {
            if (resp.status !== 200) {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error);
                return;
            } else {
                navigate("/promocodes/edit/" + resp.data.result.id);
                notifications.showNotification("Dane zostały zapisane");
            }
        });
    };

    const onSubmitButtonClick = (formProps: FormRenderProps, e: any) => {
        // data was not changed in edit mode - go back to list
        if (promoCodeId !== null && formRef?.current != null && Object.keys(formRef.current.modified).length === 0)
            navigate("/promocodes/list");

        // try submit if form is valid
        if (!formRef.current?.isValid()) {
            formProps.onSubmit(e);
            // formRef.current?.onSubmit(formRef.current.context);
            notifications.showNotification("Zapisanie danych nie udało się ponieważ formularz zawiera błędy.", NotificationEnum.Error);
        }

        formProps.onSubmit(e);
    };

    return (
        <div className="fullPageContentWrapper">
            <div className="fullWidthColumn">
                {viewState.isLoading === false && (
                    <Form
                        onSubmit={submitForm}
                        initialValues={viewState.promoCodeData}
                        //initialValues={licenceData}
                        //key={JSON.stringify(viewState.licenceData)}
                        ref={formRef}
                        render={(formRenderProps: FormRenderProps) => (
                            <>
                                <div className="mainToolbar">
                                    <div className="itemHeader">
                                        {promoCodeId === undefined
                                            ? "Dodawanie nowego kodu promocyjnego"
                                            : `Edycja kodu promocyjnego "${viewState.promoCodeData?.code}"`}
                                    </div>
                                    <PromoCodeEditToolbar onSubmitClick={(e: any) => { onSubmitButtonClick(formRenderProps, e) }} />

                                </div>
                                <Field
                                    id={"code"}
                                    name={"code"}
                                    autoComplete="off"
                                    label={"Kod promocyjny"}
                                    component={FormInput}
                                    validator={codeValidator}
                                />
                                <Field
                                    id={"description"}
                                    name={"description"}
                                    label={"Opis"}
                                    component={FormTextArea}
                                    hint={
                                        "(pole opcjonalne, np przeznaczenie kodu)"
                                    }
                                />
                                <Field
                                    id={"validFromUtc"}
                                    name={"validFromUtc"}
                                    autoComplete="off"
                                    label={"Ważny od"}
                                    component={FormDatePicker} />
                                <Field
                                    id={"validToUtc"}
                                    name={"validToUtc"}
                                    autoComplete="off"
                                    label={"Ważny do"}
                                    component={FormDatePicker} />

                                <Field
                                    id={"workersLimit"}
                                    name={"workersLimit"}
                                    label={"Limit pracowników"}
                                    component={FormNumericTextBox} />

                                <Field
                                    id={"maxUsages"}
                                    name={"maxUsages"}
                                    label={"Max liczba użyć"}
                                    component={FormNumericTextBox} />

                                <Field
                                    id={"validForDays"}
                                    name={"validForDays"}
                                    label={"Ważność w dniach"}
                                    component={FormNumericTextBox} />
                            </>
                        )}
                    />)}

                {viewState.isLoading && <Loading />}
            </div>
        </div>
    );
}
export default PromoCodeEdit;