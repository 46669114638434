import DropdownItem from '../Dropdown/DropdownItem';
import LimitTypeOverride from '../Employees/LimitTypeOverride';

export default class AddLeaveForm {
    id: string;
    limitType: LimitTypeOverride;
    startDate: Date;
    endDate: Date;
    comment: string;
    hrComment: string;
    ownerName: string;
    sendNotification: boolean;
    onBehalfOf: DropdownItem;

    replacementUsers: DropdownItem[];
}