import React from 'react';

import TypesOfLeaveComponent from '../components/Settings/TypesOfLeaves/TypesOfLeaveComponent';

const SettingsTypesOfLeaves = () => {
  return (
    <div className="fullPageContentWrapper">
      <div className="fullWidthColumn">
        <div className="mainToolbar" style={{ minHeight: "3em" }}>
          <TypesOfLeaveComponent></TypesOfLeaveComponent>
        </div>
      </div>
    </div >
  );
};

export default SettingsTypesOfLeaves;
