import moment from 'moment';
import React from 'react';

import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';

import CalendarItem from '../../../models/local/Calendars/CalendarItem';

const MemoizedCalendarComponent = (props: {
  year: number,
  calendarItems: CalendarItem[],
  toggleInfoDialog: (data: CalendarItem | null, visible: boolean) => void,
  toggleAddDialog: (date: Date | null, visible: boolean) => void,
}) => {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const dayNames = ["N", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"];
  const numberToDayName = (no: number) => dayNames[no];
  const monthsNames = [
    { name: "Styczeń" },
    { name: "Luty" },
    { name: "Marzec" },
    { name: "Kwiecień" },
    { name: "Maj" },
    { name: "Czerwiec" },
    { name: "Lipiec" },
    { name: "Sierpień" },
    { name: "Wrzesień" },
    { name: "Październik" },
    { name: "Listopad" },
    { name: "Grudzień" }];

  const CustomCalendarCellRenderer = (localProps: GridCellProps) => {
    const day = localProps.columnIndex as number;
    const month = localProps.dataIndex + 1;
    const date = moment(`${props.year}-${month}-${day}`);

    const currentItemIdx = props.calendarItems?.findIndex(
      ci => moment(ci.date).utcOffset(0, true).toISOString() === date.utcOffset(0, true).toISOString());
    const isItHoliday = currentItemIdx !== -1;
    const isItOutOfIndexDay = date.month() === month;
    const isStatutory = props.calendarItems[currentItemIdx]?.hasParent;

    const localStyle = {
      holiday: {
        height: "50px", backgroundColor: "#ffb900", display: "flex", flexDirection: 'column' as 'column', justifyContent: "center", cursor: "pointer"
      },
      statutoryHoliday: {
        height: "50px", backgroundColor: "#f2502299", display: "flex", flexDirection: 'column' as 'column', justifyContent: "center", cursor: "pointer"
      },
      workday: {
        height: "50px", backgroundColor: "#7fba0033", display: "flex", flexDirection: 'column' as 'column', justifyContent: "center", cursor: "pointer"
      },
      outOfIndexDay: {
        height: "50px", backgroundColor: "#ecf0f1", display: "flex", flexDirection: 'column' as 'column', justifyContent: "center", cursor: "pointer"
      }
    }


    return (
      <td style={{ fontSize: "1em", padding: "2px", textAlign: "center" }}>

        {!isItOutOfIndexDay && (
          <>
            {isItHoliday && !isStatutory && (
              <div onClick={() => { props.toggleInfoDialog(props.calendarItems[currentItemIdx], true) }} style={localStyle.holiday}>
                {numberToDayName(date.day())}
                <div><span className="k-icon k-i-information" /></div>
              </div>
            )}

            {isItHoliday && isStatutory && (
              <div onClick={() => { props.toggleInfoDialog(props.calendarItems[currentItemIdx], true) }} style={localStyle.statutoryHoliday}>
                {numberToDayName(date.day())}
                <div><span className="k-icon k-i-information" /></div>
              </div>
            )}

            {!isItHoliday && (
              <div onClick={() => { props.toggleAddDialog(date.toDate(), true) }} style={localStyle.workday}>
                {numberToDayName(date.day())}
                <div><span className="k-icon k-i-plus" /></div>
              </div>
            )}
          </>
        )}

        {isItOutOfIndexDay && (<div style={localStyle.outOfIndexDay} />)}

      </td >
    );
  };

  React.useEffect(() => {
    console.log(`${new Date().toISOString()}CalendarComponent rerendered.`)
  }, []);

  return (
    <Grid style={{ height: "100%" }} data={monthsNames} >
      <Column field="name" title="Miesiąc" width={"100px"} key="months" />
      {
        days.map((value, idx) => {
          return <Column
            field="name"
            title={value.toString()}
            cell={CustomCalendarCellRenderer}
            id={`${value}_${idx} `}
            key={`${value}_${idx} `} />
        })
      }
    </Grid>
  );
};

export default React.memo(MemoizedCalendarComponent);
