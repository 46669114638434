import moment from 'moment';
import React, { CSSProperties, useEffect } from 'react';

import { TimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { Field } from '@progress/kendo-react-form';

import { getHoursDiff } from '../../../../helpers/timeUtils';
import { DaysEnumTranslation } from '../../../../models/enums/DaysEnum';
import WorkPlanItem from '../../../../models/local/WorkPlan/WorkPlanItem';
import { requiredNoLabelValidator } from '../../../../validators/CommonValidators';
import { FormInput, FormTimePicker } from '../../../Kendo/form-components';

const localStyle: { [key: string]: CSSProperties } =
{
  itemRow: {
    width: "750px",
    height: "2.5em",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"
  },
}

const DayHoursComponentItem = (props: {
  workPlanItem: WorkPlanItem,
  onUpdateWorkPlanItem: (workPlanItem: WorkPlanItem) => void,
  readonly: boolean
}) => {

  const handlehourFromChange = (event: TimePickerChangeEvent) => {
    const diff = getHoursDiff(event.value! as Date, props.workPlanItem.hourTo);
    props.onUpdateWorkPlanItem({ ...props.workPlanItem, hourFrom: event.value!, hoursSum: diff.hours > 0 ? diff.asString : "" });
  };

  const handlehourToChange = (event: TimePickerChangeEvent) => {
    const updatedItem: WorkPlanItem = { ...props.workPlanItem, hourTo: event.value! }
    props.onUpdateWorkPlanItem(updatedItem);
  };

  useEffect(() => {
    console.log("DayHoursComponentItem", props)
  }, [props]);

  return (
    <div style={localStyle.itemRow}>
      <div style={{ width: "200px" }}>{DaysEnumTranslation(props.workPlanItem.day)}</div>
      <div style={{ width: "400px" }}>

        <Field
          id={`hourFrom${props.workPlanItem.day.toUpperCase()}`}
          name={`hourFrom${props.workPlanItem.day.toUpperCase()}`}
          component={FormTimePicker}

          width={130}
          onChange={handlehourFromChange}
          disabled={props.readonly || !props.workPlanItem.enabled}

          label="od&nbsp;"
          wrapperStyle={{ display: "inline-flex", alignItems: "center" }}

          validator={requiredNoLabelValidator}
        />

        <Field
          id={`hourTo${props.workPlanItem.day.toUpperCase()}`}
          name={`hourTo${props.workPlanItem.day.toUpperCase()}`}
          component={FormTimePicker}

          width={130}
          onChange={handlehourToChange}
          disabled={props.readonly || !props.workPlanItem.enabled}

          label="&nbsp;do&nbsp;"
          wrapperStyle={{ display: "inline-flex", alignItems: "center" }}

          validator={requiredNoLabelValidator}
        />
      </div>

      <div style={{ width: "120px" }}>
        {props.workPlanItem.enabled ?
          <span>
            <Field
              id={`hoursSum${props.workPlanItem.day.toUpperCase()}`}
              name={`hoursSum${props.workPlanItem.day.toUpperCase()}`}
              component={FormInput}

              disabled={true}

              wrapperStyle={{ display: "inline-flex", alignItems: "center" }}

              label="&nbsp;"
              validator={requiredNoLabelValidator}
            />
          </span> : <span>---</span>
        }
      </div>

    </div>
  );
};
export default DayHoursComponentItem;

