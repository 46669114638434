import React from 'react';
import { useNavigate } from 'react-router';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { FormElement } from '@progress/kendo-react-form';

const EmployeeEditToolbar = (props:
  {
    onSubmitClick: (e: any) => void,
    disableSubmitBtn: boolean
  }
) => {
  const navigate = useNavigate();

  return (
    <Toolbar>
      <ToolbarItem>
        <FormElement>
          <Button
            fillMode="outline"
            icon="save"
            id="submit"
            type={"button"}
            className="k-button"
            onClick={(e: any) => {
              props.onSubmitClick(e);
            }}
            disabled={props.disableSubmitBtn}
          >
            Zapisz
          </Button>
        </FormElement>
      </ToolbarItem>

      <ToolbarItem>
        <Button
          icon="close"
          fillMode="outline"
          onClick={() => {
            navigate(-1);
          }}
        >
          Anuluj
        </Button>
      </ToolbarItem>

      <ToolbarSeparator />
    </Toolbar>
  );
};

export default EmployeeEditToolbar;
