import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";
import DropdownItem from "../Dropdown/DropdownItem";

export default class AddDocForm {
    user: DropdownItem;
    name: string;
    internalId: string;
    date: Date;
    note: string;
    files: StorageFile[];
}