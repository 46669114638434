import './App.scss';
import 'hammerjs';

import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import caGregorian from 'cldr-dates-full/main/pl/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/pl/dateFields.json';
import localCurrency from 'cldr-numbers-full/main/pl/currencies.json';
import numbers from 'cldr-numbers-full/main/pl/numbers.json';
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { IntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';

import DrawerRouterContainer from './components/Drawer/DrawerRouterContainer';
import EmployeeEdit from './components/Employees/EmployeeEdit';
import polishTranslation from './components/Kendo/messages/pl-PL/pl-PL.json';
import ApplicationDetail from './components/Leaves/ApplicationDetail/ApplicationDetail';
import ApplicationConsider from './components/Leaves/ApplicationsList/ApplicationConsider';
import LicenceEdit from './components/Licences/LicenceEdit';
import PromoCodeEdit from './components/PromoCode/PromoCodeEdit';
import WorkTimePersonalTimeLogGrid from './components/WorkTime/MonthlyLog/Personal/WorkTimePersonalTimeLogGrid';
import WorkTimeDetail1UsersGrid from './components/WorkTime/MonthlyLog/Summary/WorkTimeDetail1UsersGrid';
import UserRoleEnum from './models/enums/UserRoleEnum';
import ActivateAccount from './pages/ActivateAccount.jsx';
import AddApp from './pages/AddApp';
import AppsList from './pages/AppsList';
import ArchivedEmployees from './pages/ArchivedEmployees';
import Bc from './pages/Bc';
import BcCompetencies from './pages/BcCompetencies';
import BcCompetenciesAdd from './pages/BcCompetenciesAdd';
import BcContracts from './pages/BcContracts';
import BcContractsAdd from './pages/BcContractsAdd';
import BcDocs from './pages/BcDocs';
import BcDocsAdd from './pages/BcDocsAdd';
import BcMedicals from './pages/BcMedicals';
import BcMedicalsAdd from './pages/BcMedicalsAdd';
import BcNotifications from './pages/BcNotifications';
import BcNotificationsAdd from './pages/BcNotificationsAdd';
import BcResources from './pages/BcResources';
import BcResourcesAdd from './pages/BcResourcesAdd';
import BcTrainings from './pages/BcTrainings';
import BcTrainingsAdd from './pages/BcTrainingsAdd';
import BcWorkExperience from './pages/BcWorkExperience';
import BcWorkexperienceAdd from './pages/BcWorkExperienceAdd';
import Calendar from './pages/Calendar';
import ClientLicence from './pages/ClientLicence';
// import Dashboard from './pages/Dashboard';
import Dashboard from './pages/Dashboard2';
import Departments from './pages/Departments';
import Employees from './pages/Employees';
import ErrorPage from './pages/ErrorPage';
import ExportData from './pages/ExportData';
import Licences from './pages/Licences';
import Loading from './pages/Loading';
import Login from './pages/Login';
import LoginQrCode from './pages/LoginQrCode';
import MyProfile from './pages/MyProfile';
import NotFoundPage from './pages/NotFoundPage';
import PromoCodes from './pages/PromoCodes';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import SettingsCalendars from './pages/SettingsCalendars';
import SettingsDictionaries from './pages/SettingsDictionaries';
import SettingsParameters from './pages/SettingsParameters';
import SettingsTypesOfLeaves from './pages/SettingsTypesOfLeaves';
import UserLimitsView from './pages/UserLimitsView';
import WorkPlans from './pages/WorkPlans';
import WorkTimeRegisterPage from './pages/WorkTimeRegisterPage';
import WorkTimeReportsBrowser from './pages/WorkTimeReportsBrowser';
import * as secure from './permissions/permissions';
import { AppContext, IAppContext } from './services/AppContext';
import useSessionWrapper from './services/SessionWrapper';

// translations
load(likelySubtags, currencyData, weekData, localCurrency, numbers, caGregorian, dateFields);

loadMessages(polishTranslation, "pl-PL");

const App = () => {
  const context = React.useContext<IAppContext>(AppContext);

  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);

  const sessionWrapper = useSessionWrapper();

  React.useEffect(() => {
    console.log(`(!) App.tsx: Page refreshed! UseEffect({},[]) | isLoggedIn sessionWrapper: ${sessionWrapper.isLoggedIn()} | isLoggedIn context: ${context.values.loggedIn}`)

    console.log("(!) App.tsx: setting setAllDataLoaded=false ")
    setAllDataLoaded(false);

    const getDataAfterPageRefresh = async () => {
      await sessionWrapper.localStorageToContextCopy();
    }

    getDataAfterPageRefresh();
  }, []);

  React.useEffect(() => {
    let isDataLoaded = (context.values.loggedIn && context.values.dicts?.length > 0) || localStorage?.length === 2 || localStorage?.length === 0;
    // console.log(`(!) App.tsx: context.values.loggedIn is ${context.values.loggedIn}/${sessionWrapper.isLoggedIn()} -> Setting requiredDataLoaded: from ${allDataLoaded} -> to ${isDataLoaded}; context:`, context.values)
    console.log(`(!) App.tsx/[context.values.loggedIn]: ${localStorage?.length}`)

    setAllDataLoaded(isDataLoaded);

  }, [context.values.loggedIn]);

  React.useEffect(() => {
    if (context.values.notification === undefined) return;

    let notificationTimer = setTimeout(() => context.setValues({ ...context.values, notification: undefined }), 6000);

    return () => {
      clearTimeout(notificationTimer);
    };
  }, [context.values.notification]);

  React.useEffect(() => {
    console.log("(!) App.tsx: window.location.href changed!", window.location.href);
    console.log("App.tsx: window.location.href session:", context.values);
  }, [window.location.href]);



  return (
    <div className="App">

      {allDataLoaded && <>

        <LocalizationProvider language="pl-PL">
          <IntlProvider locale="pl-PL">
            <>
              {context.values.notification !== undefined && (
                <div onClick={() => { document.querySelectorAll('.k-notification-group').forEach(el => (el as HTMLElement).style.display = 'none'); }}>
                  <NotificationGroup >
                    <Notification key="success" type={{ style: context.values.notificationStyle }} style={{ fontSize: "1em" }}>
                      {context.values.notification}
                    </Notification>
                  </NotificationGroup>
                </div>
              )}

              {context.values.isLoading && <Loading />}

              <DrawerRouterContainer>
                <Routes>
                  {context.values.loggedIn === false && (
                    <>
                      <Route path="/" element={<Login />} />
                      <Route path="*" element={<NotFoundPage></NotFoundPage>} />
                    </>
                  )}

                  <Route path="/register" element={<Register />} />
                  <Route path="/resetPasswordRequest" element={<ResetPasswordRequest />} />
                  <Route path="/resetPassword/:token" element={<ResetPassword />} />
                  <Route path="/activateAccount/:activationCode" element={<ActivateAccount />} />

                  <Route path="/login-qrcode" element={<LoginQrCode />} />

                  {context.values.loggedIn === true && context.values.role === UserRoleEnum.ADMINISTRATOR && (
                    <>
                      <Route
                        path="/"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Licences}>
                            <Licences />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/licences/edit"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.LicenceEdit}>
                            <LicenceEdit />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/licences/edit/:id"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.LicenceEdit}>
                            <LicenceEdit />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/promocodes/list"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.PromoCodes}>
                            <PromoCodes />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/promocodes/edit"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.PromoCodeEdit}>
                            <PromoCodeEdit />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/promocodes/edit/:id"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.PromoCodeEdit}>
                            <PromoCodeEdit />
                          </secure.PermissionsGate>
                        }
                      />
                    </>
                  )}

                  {context.values.loggedIn === true && (
                    <>
                      <Route
                        path="/"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Dashboard}>
                            <Dashboard />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/addapp"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.AddApp}>
                            <AddApp />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/userlimits"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.UserLimits}>
                            <UserLimitsView />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/calendar"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Calendar}>
                            <Calendar />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/worktime"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.WorkTimeRegister}>
                            <WorkTimeRegisterPage />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/worktimeBrowse"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.WorkTimeBrowse}>
                            <WorkTimeReportsBrowser />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/worktimeBrowse/:id"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.WorkTimeBrowse}>
                            <WorkTimePersonalTimeLogGrid forCurrentUser={false} isIndividualView={true} cachedOnBehalfUser={undefined} />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/appslist"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.AppsList}>
                            <AppsList />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/appslist/:id"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.ApplicationDetail}>
                            <ApplicationDetail />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/appslist/:id/consider"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.ApplicationConsider}>
                            <ApplicationConsider />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/exportdata"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.ExportData}>
                            <ExportData />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/employees"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Employees}>
                            <Employees />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/archivedemployees"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.ArchivedEmployees}>
                            <ArchivedEmployees />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={undefined} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={undefined} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id/contracts"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={0} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id/medicals"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={1} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id/trainings"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={2} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id/notifications"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={3} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id/workexperiences"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={4} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id/competencies"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={5} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id/resources"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={6} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id/docs"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={7} />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/employees/edit/:id/notes"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.EmployeeEdit}>
                            <EmployeeEdit forceSwitchToPersonalFilesTab={8} />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/briefcases"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <Bc />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/competencies"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcCompetencies />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/competencies/add"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcCompetenciesAdd />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/contracts"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcContracts />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/contracts/add"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcContractsAdd />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/docs"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcDocs />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/docs/add"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcDocsAdd />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/medicals"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcMedicals />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/medicals/add"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcMedicalsAdd />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/notifications"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcNotifications />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/notifications/add"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcNotificationsAdd />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/resources"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcResources />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/resources/add"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcResourcesAdd />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/trainings"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcTrainings />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/trainings/add"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcTrainingsAdd />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/workexperience"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcWorkExperience />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/briefcases/workexperience/add"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Bc}>
                            <BcWorkexperienceAdd />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/departments"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Departments}>
                            <Departments />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/settingsTypesOfLeaves"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Settings}>
                            <SettingsTypesOfLeaves />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/settingsParameters"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Settings}>
                            <SettingsParameters />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/settingsDictionaries"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Settings}>
                            <SettingsDictionaries />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/settingsLicence"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.ClientLicence}>
                            <ClientLicence />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/settingsCalendars"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Settings}>
                            <SettingsCalendars />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/workPlans"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Settings}>
                            <WorkPlans />
                          </secure.PermissionsGate>
                        }
                      />
                      <Route
                        path="/workPlans/edit/:id"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.Settings}>
                            <WorkPlans />
                          </secure.PermissionsGate>
                        }
                      />

                      <Route
                        path="/userinfo"
                        element={
                          <secure.PermissionsGate scope={secure.Scopes.MyProfile}>
                            <MyProfile />
                          </secure.PermissionsGate>
                        }
                      />
                    </>
                  )}

                  <Route path="/error" element={<ErrorPage />} />
                </Routes>
              </DrawerRouterContainer>
            </>
          </IntlProvider>
        </LocalizationProvider>

      </>
      }

    </div>
  );
};

export default App;


