

import PersonalDataDto from './Tabs/PersonalDataDto';
import UserBriefcaseBaseDto from './UserBriefcaseBaseDto';

export default class UserListDto {
  id: string;
  email: string;
  personalData: PersonalDataDto;
  userBriefcase: UserBriefcaseBaseDto;

  supervisorsNames: string;
  accountCreateDate: Date;
  currentDepartmentName: string;

  role: string;

  isActive: boolean;
  isBlocked: boolean;
}


