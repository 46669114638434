import React, { useState } from 'react';

import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';

import UserRoleEnum from '../../../models/enums/UserRoleEnum';
import DropdownItem from '../../../models/local/Dropdown/DropdownItem';
import { AppContext, IAppContext } from '../../../services/AppContext';
import useAppLoader from '../../../services/AppLoader';
import WorkTimeRegisterForm from './WorkTimeRegisterForm';

const WorkTimeRegisterFormWrapper = (
    props: {
        onBehalfUserChanged: (behalfUserId: DropdownItem | undefined) => void;
        onTabIdxChange: (tabIdx: number) => void,
        onDataSaved: () => void,
        cachedOnBehalfUser: DropdownItem | undefined
    }) => {
    const context = React.useContext<IAppContext>(AppContext);
    const [selectedIndex, setSelectedIndex] = useState<TabStripSelectEventArguments>()

    const loader = useAppLoader(false);

    React.useEffect(() => {
    }, []);

    return (
        <>
            {!loader.isLoading() && (
                <TabStrip onSelect={(e) => { setSelectedIndex(e); props.onTabIdxChange(e.selected.valueOf()); }} selected={selectedIndex?.selected ?? 0}>
                    <TabStripTab title="Zarejestruj swój czas pracy">
                        <WorkTimeRegisterForm onBehalfUserChanged={(behalfUser: DropdownItem | undefined) => { props.onBehalfUserChanged(behalfUser); }} onDataSaved={props.onDataSaved} onBehalf={false} behalfUser={undefined} />
                    </TabStripTab>
                    {context.values.role === UserRoleEnum.HR && (
                        <TabStripTab title="Wprowadź za innego pracownika">
                            <WorkTimeRegisterForm onBehalfUserChanged={(behalfUser: DropdownItem | undefined) => { props.onBehalfUserChanged(behalfUser); }} onDataSaved={props.onDataSaved} onBehalf={true} behalfUser={props.cachedOnBehalfUser} />
                        </TabStripTab>)}
                </TabStrip>
            )}
        </>)
}

export default WorkTimeRegisterFormWrapper;