import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Button, Toolbar, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';

import { apiClient } from '../api/apiClient';
import { USER_GET } from '../api/apiEndpoints';
import EmployeesList from '../components/Employees/EmployeesList';
import UserListDto from '../models/dto/Employees/UserListDto';
import UserRoleEnum from '../models/enums/UserRoleEnum';
import UserListItem from '../models/local/Employees/UserListItem';
import { mapper } from '../models/mapper';
import { AppContext, IAppContext } from '../services/AppContext';
import useAppLoader from '../services/AppLoader';

const Employees = () => {
  const loader = useAppLoader(true);
  const [users, setUsers] = useState<UserListItem[] | undefined>();
  const navigate = useNavigate();
  const context = useContext<IAppContext>(AppContext);

  const onReloadData = () => {
    loader.showLoading(true);

    apiClient({
      method: "GET",
      url: USER_GET,
      data: {},
    }).then((resp) => {
      loader.showLoading(false);
      // map dto to local
      const mappedResult: UserListItem[] = mapper.mapArray(resp?.data?.result as UserListDto[], UserListItem, UserListDto);
      setUsers(mappedResult);
    });
  };

  useEffect(() => {
    //breadcrumb.levelUp('employees', 'Pracownicy', '/employees');
    onReloadData();
  }, []);


  return (
    <div className="fullPageContentWrapper">
      <div className="fullWidthColumn">
        {context.values.role === UserRoleEnum.HR &&
          (
            <>
              <div className="mainToolbar">
                <Toolbar>

                  <ToolbarItem>
                    <Button
                      id="btnNewEmployee"
                      icon="plus"
                      fillMode="outline"
                      onClick={() => {
                        navigate(`/employees/edit`);
                      }}
                    >
                      Nowy pracownik
                    </Button>
                  </ToolbarItem>
                  <ToolbarSpacer></ToolbarSpacer>

                  <ToolbarItem>
                    <Button
                      fillMode="outline"
                      icon="page-properties"
                      onClick={() => {
                        navigate(`/archivedemployees`);
                      }}>
                      Wyświetl archiwum
                    </Button>
                  </ToolbarItem>
                </Toolbar>
              </div>
            </>)}

        {!loader.isLoading() && <EmployeesList users={users} reloadData={() => { onReloadData(); }} />}
      </div>
    </div>
  );
};

export default Employees;
