import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Upload } from '@progress/kendo-react-upload';

import { apiClient, baseUrl } from '../api/apiClient';
import {
  BRIEFCASES_POST_ADDCONTRACT, DROPDOWN_GET_ALLUSERS, STORAGE_REMOVE, STORAGE_UPLOAD_contract
} from '../api/apiEndpoints';
import EditToolbar from '../components/Common/EditToolbar';
import ErrorMessage from '../components/Common/ErrorMessage';
import {
  FormDatePicker, FormDropDownList, FormTextArea
} from '../components/Kendo/form-components';
import useUploadUtils from '../helpers/uploadUtils';
import AddContractRequest from '../models/dto/Briefcases/Requests/AddContractRequest';
import DropdownItemResponse from '../models/dto/Dropdown/DropdownItemResponse';
import NotificationEnum from '../models/enums/NotificationEnum';
import AddContractForm from '../models/local/Briefcases/AddContractForm';
import DropdownItem from '../models/local/Dropdown/DropdownItem';
import { mapper } from '../models/mapper';
import { DictByType, DictTypes } from '../scripts/dictProvider';
import { AppContext, IAppContext } from '../services/AppContext';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';
import { requiredValidator } from '../validators/CommonValidators';

const BcContractsAdd = () => {
  const navigate = useNavigate();
  const context = React.useContext<IAppContext>(AppContext);
  const notifications = useAppNotifications();

  const [users, setUsers] = useState<DropdownItem[]>();
  const loader = useAppLoader(true);
  const uploadUtils = useUploadUtils();

  const onFormSubmit = (form: any) => {
    loader.showLoading(true);
    const dtoToSend: AddContractRequest = mapper.map(form as AddContractForm, AddContractRequest, AddContractForm);

    dtoToSend.files = uploadUtils.uploadedFiles;

    apiClient({
      method: "POST",
      url: BRIEFCASES_POST_ADDCONTRACT,
      data: dtoToSend,
    }).then((resp) => {
      if (resp.status !== 200) {
        loader.showLoading(false);
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        notifications.showNotification("Umowa została dodana", NotificationEnum.Success);
        navigate(-1);
      }
    });
  };

  const getDropdownUsers = () => {
    loader.showLoading(true);

    apiClient({
      method: "GET",
      url: DROPDOWN_GET_ALLUSERS,
    }).then((resp) => {
      loader.showLoading(false);
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        const response: DropdownItem[] = mapper.mapArray(resp?.data?.result as DropdownItemResponse[], DropdownItem, DropdownItemResponse);
        setUsers(response);
      }
    });
  };

  useEffect(() => {
    getDropdownUsers();
  }, []);

  return (
    <>
      {!loader.isLoading() &&
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn">

            <Form
              onSubmit={onFormSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <>
                  <div className="mainToolbar">
                    <EditToolbar onCancelClick={() => { navigate(-1); }} />
                  </div>

                  <FormElement
                    style={{
                      width: 400,
                    }}
                  >

                    <Field
                      id={"user"}
                      name={"user"}
                      label={"Imię i nazwisko"}
                      component={FormDropDownList}
                      data={users}
                      validator={requiredValidator}
                      textField="text"
                      dataItemKey="id"
                    />

                    <Field
                      id={"contractType"}
                      name={"contractType"}
                      label={"Rodzaj umowy"}
                      component={FormDropDownList}
                      data={DictByType(DictTypes.ContractTypes, context.values.dicts)}
                      validator={requiredValidator}
                      textField="name"
                      dataItemKey="id"
                    />

                    <Field
                      id={"contractPeriod"}
                      name={"contractPeriod"}
                      label={"Okres obowiązywania"}
                      component={FormDropDownList}
                      data={DictByType(DictTypes.ContractPeriods, context.values.dicts)}
                      validator={requiredValidator}
                      textField="name"
                      dataItemKey="id"
                    />

                    <Field
                      id={"signDate"}
                      name={"signDate"}
                      label={"Data podpisania"}
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />

                    <Field
                      id={"startDate"}
                      name={"startDate"}
                      label={"Data rozpoczęcia"}
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />

                    <Field
                      id={"endDate"}
                      name={"endDate"}
                      label={"Data zakończenia"}
                      component={FormDatePicker}
                    />

                    <Field
                      label={"Szczegółowe informacje"}
                      id={"note"}
                      name={"note"}
                      autoComplete="off"
                      component={FormTextArea}
                    />

                    <Upload
                      autoUpload={true}
                      multiple={true}
                      defaultFiles={[]}
                      withCredentials={false}
                      saveUrl={baseUrl + STORAGE_UPLOAD_contract}
                      removeUrl={baseUrl + STORAGE_REMOVE}
                      onBeforeRemove={uploadUtils.onBeforeRemove}
                      onStatusChange={uploadUtils.onStatusChange}
                      saveHeaders={uploadUtils.setHeaders()}
                      removeHeaders={uploadUtils.setHeaders()}
                    />

                  </FormElement>
                </>
              )}
            />

          </div>
        </div >
      }

    </>
  )
};

export default BcContractsAdd;