import CalculateAppItem from './CalculateAppItem';

export default class CalculateApp {
    allDaysSum: number;
    workingDaysSum: number;
    dates: CalculateAppItem[];
    isCalculated: boolean;

    public constructor(props?: any) {
        this.allDaysSum = 0;
        this.workingDaysSum = 0;
        this.dates = [];
        this.isCalculated = false;
    }
}