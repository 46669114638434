import StorageFileDto from "../StorageFileDto";


export default class MedicalDto {
    id: string;
    medicalTestingType: string;
    date: Date;
    validityDate: Date;
    note: string;
    files: StorageFileDto[] = [];
}