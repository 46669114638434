import * as Permissions from '../../permissions/permissions';

export enum MenuItemType {
    Basic,
    Nested,
    Separator,
    Avatar
};

export interface IMenuItem {
    name?: string;

    id?: number;
    parentid?: number;

    permission?: Permissions.Scopes;

    icon?: string;
    route?: string;

    menuItemType?: MenuItemType;
    avatar?: boolean; // special element

    separator?: boolean; // special element - space between items
    separatorLabel?: string; // separator text-label

    selected?: boolean; // highlight
    expanded?: boolean;
    hidden?: boolean; // render or not
}

export const MenuItems: IMenuItem[] = [
    {
        name: "UserInfo",
        avatar: true,
    },
    {
        name: "Pulpit",
        icon: "k-i-grid",
        selected: true,
        route: "/",
        permission: Permissions.Scopes.Dashboard
    },
    {
        name: "Licencje",
        icon: "k-i-grid",
        selected: true,
        route: "/",
        permission: Permissions.Scopes.Licences
    },
    {
        name: "Kody promocyjne",
        icon: "k-i-grid",
        selected: false,
        route: "/promocodes/list",
        permission: Permissions.Scopes.PromoCodes
    },
    { separator: true, separatorLabel: "Kartoteki", permission: Permissions.Scopes.Briefcases },
    {
        name: "Struktura firmy",
        icon: "k-i-categorize",
        route: "/departments",
        permission: Permissions.Scopes.Departments
    },
    {
        name: "Pracownicy",
        icon: "k-i-folder-more",
        route: "/employees",
        permission: Permissions.Scopes.Employees
    },
    {
        name: "Dokumenty ▼",
        id: 100,
        icon: "k-i-tell-a-friend",
        route: "/briefcases/contracts",
        permission: Permissions.Scopes.Bc
    },
    {
        name: "Umowy",
        id: 101,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/contracts",
        permission: Permissions.Scopes.Bc
    },
    {
        name: "Badania",
        id: 102,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/medicals",
        permission: Permissions.Scopes.Bc
    },
    {
        id: 103,
        parentid: 100,
        hidden: false,

        name: "Szkolenia",
        icon: "k-i-arrow-60-right",
        route: "/briefcases/trainings",
        permission: Permissions.Scopes.Bc
    },
    {
        name: "Powiadomienia",
        id: 104,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/notifications",
        permission: Permissions.Scopes.Bc
    },
    {
        name: "Doświadczenie",
        id: 105,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/workexperience",
        permission: Permissions.Scopes.Bc
    },
    {
        name: "Kompetencje",
        id: 106,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/competencies",
        permission: Permissions.Scopes.Bc
    },
    {
        name: "Zasoby",
        id: 107,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/resources",
        permission: Permissions.Scopes.Bc
    },
    {
        name: "Dokumenty",
        id: 108,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/docs",
        permission: Permissions.Scopes.Bc
    },
    { separator: true, separatorLabel: "Urlopy" },
    {
        name: "Złóż wniosek",
        icon: "k-i-plus-circle",
        route: "/addapp",
        permission: Permissions.Scopes.AddApp
    },
    {
        name: "Pozostały urlop",
        icon: "k-i-information",
        route: "/userlimits",
        permission: Permissions.Scopes.UserLimits
    },
    {
        name: "Lista wniosków",
        icon: "k-i-track-changes-enable",
        route: "/appslist",
        permission: Permissions.Scopes.AppsList
    },
    {
        name: "Kalendarz urlopów",
        icon: "k-i-calendar",
        route: "/calendar",
        permission: Permissions.Scopes.Calendar
    },
    { separator: true, separatorLabel: "Rejestracja pracy", permission: Permissions.Scopes.WorkTime },
    {
        name: "Rejestracja godzin",
        icon: "k-i-clock",
        route: "/worktime",
        permission: Permissions.Scopes.WorkTimeRegister
    },
    {
        name: "Karty pracy",
        icon: "k-i-table-align-middle-center",
        route: "/worktimeBrowse",
        permission: Permissions.Scopes.WorkTimeBrowse
    },
    { separator: true, separatorLabel: "Ustawienia", permission: Permissions.Scopes.Settings },
    {
        name: "Parametry ▼",
        id: 200,
        icon: "k-i-gears",
        route: "/settingsTypesOfLeaves",
        permission: Permissions.Scopes.Settings
    },
    {
        name: "Limity urlopowe",
        id: 201,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsTypesOfLeaves",
        permission: Permissions.Scopes.Settings
    },
    {
        name: "Kalendarze pracy",
        id: 202,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsCalendars",
        permission: Permissions.Scopes.Settings
    },
    {
        name: "Plany pracy",
        id: 203,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/workPlans",
        permission: Permissions.Scopes.Settings
    },
    {
        name: "Parametry",
        id: 204,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsParameters",
        permission: Permissions.Scopes.Settings
    },
    {
        name: "Słowniki",
        id: 205,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsDictionaries",
        permission: Permissions.Scopes.Settings
    },
    {
        name: "Licencja",
        id: 206,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsLicence",
        permission: Permissions.Scopes.ClientLicence
    },
    {
        name: "Eksport danych",
        icon: "k-i-redo",
        route: "/exportdata",
        permission: Permissions.Scopes.ExportData
    },
];