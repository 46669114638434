import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import {
  CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor
} from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import {
  Grid, GridColumn as Column, GridFilterChangeEvent, GridSortChangeEvent
} from '@progress/kendo-react-grid';

import WorkExperienceItem from '../../models/local/Briefcases/WorkExperienceItem';

const WorkExperienceList = (props: {
  gridItems: WorkExperienceItem[],
}) => {
  const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [
      { field: "firstName", operator: "startswith", value: "" },
      { field: "lastName", operator: "startswith", value: "" },
      { field: "contractType", operator: "contains", value: "" },
    ],
  };
  const [filter, setFilter] = useState(initialFilter);

  const initialSort: Array<SortDescriptor> = [
    { field: "lastName", dir: "asc" },
  ];
  const [sort, setSort] = useState(initialSort);

  const navigate = useNavigate();

  return (
    <Grid style={{ height: "100%" }}
      data={orderBy(filterBy(props.gridItems, filter), sort)}
      filterable={true}
      filter={filter}
      onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}

      sortable={true}
      sort={sort}
      onSortChange={(e: GridSortChangeEvent) => {
        setSort(e.sort);
      }}
    >
      <Column field="lastName" title="Nazwisko" />
      <Column field="firstName" title="Imię" />
      <Column field="name" title="Opis" />
      <Column field="date" title="Data" format="{0:d}" filter="date" />
      <Column field="validityDate" title="W okresie do" format="{0:d}" filter="date" />
      <Column
        title="Operacje"
        width="140px"
        filterable={false}
        cell={(v: any) => (
          <td>
            {
              <Button fillMode="outline" icon="zoom" onClick={() => {
                navigate(`/employees/edit/${v.dataItem.userId}/workexperiences`)
              }}> Podgląd</Button>}
          </td>
        )}
      />
    </Grid>
  );
};
export default WorkExperienceList;
