import { getter } from "@progress/kendo-react-common";
import {} from "./CommonValidators";

export const depNameValidator = (value) =>
  !value
    ? "Nazwa działu jest wymagana"
    : value.length < 2
    ? "Nazwa działu powinna mieć przynajmniej 2 znaki"
    : "";
const depNameGetter = getter("depName");

export const formValidator = (values) => {
  const depName = depNameGetter(values);

  if (depName) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: "Uzupełnij wszystkie wymagane pola.",
  };
};
