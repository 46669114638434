import StorageFileDto from "../StorageFileDto";


export default class DocDto {
    id: string;
    name: string;
    internalId: string;
    date: Date;
    note: string;
    files: StorageFileDto[] = [];
}
