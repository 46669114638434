enum ExportTypeEnum {
    ERPOptima = "ERPOptima",
    Enova = "Enova",
    WFGang = "WFGang",
    Symfonia = "Symfonia",
    UNKNOWN = "UNKNOWN"
  };

  
export const ExportTypeEnumTranslation = (value: ExportTypeEnum | undefined) => {
    let translation: string = "";
  
    switch (value) {
      case ExportTypeEnum.UNKNOWN || undefined:
        translation = "***ERROR***";
        break;
      case ExportTypeEnum.ERPOptima:
        translation = "ERP Optima";
        break;
      case ExportTypeEnum.Enova:
        translation = "Enova";
        break;
      case ExportTypeEnum.WFGang:
        translation = "WAPRO Ganng";
        break;
      case ExportTypeEnum.Symfonia:
        translation = "Symfonia";
        break;
    }
  
    return translation;
  }
  
  export default ExportTypeEnum;