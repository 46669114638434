import PromoCodeDto from "../PromoCode/PromoCodeDto";

export default class LicenceListDto {
    officeId: string;
    officeName: string;
    validFrom: Date;
    validTo: Date;
    workersLimit: number;
    id: string;
    promoCode: PromoCodeDto;
  }
  