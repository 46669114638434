import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";
import DropdownItem from "../Dropdown/DropdownItem";

export default class AddNotificationForm {
    user: DropdownItem;
    notificationType: DictItem;
    date: Date;
    validityDate?: Date;
    note: string;
    notifyHR: boolean;
    notifyEmployee: boolean;
    files: StorageFile[];
}