import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";

export default class MedicalEditForm {
    id: string;
    medicalTestingType: DictItem;
    date: Date;
    validityDate: Date;
    note: string;
    files: StorageFile[];
}