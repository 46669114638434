import { AutoMap } from "@automapper/classes";
import StorageFileDto from "../StorageFileDto";

export default class TrainingDto {
    id: string;
    trainingType: string;
    date: Date;
    validityDate: Date;
    note: string;
    files: StorageFileDto[] = [];
}
