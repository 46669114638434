import React from 'react';

import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QRCode } from '@progress/kendo-react-barcodes';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, FormElement } from '@progress/kendo-react-form';

import { apiClient } from '../../../../../api/apiClient';
import { USER_PUT_resendActivationEmail } from '../../../../../api/apiEndpoints';
import NotificationEnum from '../../../../../models/enums/NotificationEnum';
import User from '../../../../../models/local/Employees/User';
import useAppNotifications from '../../../../../services/AppNotifications';
import { EmptyGuid } from '../../../../../services/GuidHelper';
import InfoDialog from '../../../../Common/InfoDialog';
import { FormCheckbox, FormInput } from '../../../../Kendo/form-components';

const SystemConfigurationTab = (props: { data: User }) => {

  const [qrVisible, setQrVisible] = React.useState<boolean>();
  const [resendActivationEmailDialogVisible, setResendActivationEmailDialogVisible] = React.useState<boolean>();
  const notifications = useAppNotifications();

  const toggleQrVisible = () => setQrVisible(!qrVisible);
  const resendActivationEmail = () => {
    apiClient({
      method: "PUT",
      url: USER_PUT_resendActivationEmail(props.data.id),
      data: {},
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification("Wysłanie wiadomości e-mail nie powiodło się.", NotificationEnum.Error);
        return;
      }

      setResendActivationEmailDialogVisible(true);
    });
  }

  return (
    <>
      {qrVisible && (
        <Dialog title={"Kod QR pracownika"} onClose={toggleQrVisible} width={500} height={580} >
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            <QRCode
              value={props.data.specialId}
              errorCorrection="H"
              size={400}
              renderAs='canvas'
              encoding='UTF_8'
            />
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleQrVisible}
            >
              Zamknij
            </button>
          </DialogActionsBar>
        </Dialog >
      )}

      {resendActivationEmailDialogVisible === true && (
        <InfoDialog
          text={`E-mail aktywacyjny został wysłany na adres:<br/><b>${props.data.email}</b><p>Wiadomość ta zawiera przycisk z linkiem aktywacyjnym, który umożliwi pracownikowi aktywację konta i nadanie hasła.</p>`}
          onClose={() => { setResendActivationEmailDialogVisible(false) }}
        />
      )}

      <div>

        {props.data.id !== EmptyGuid &&
          <>
            {props.data.isActive === false && <div style={{ color: "red" }}><FontAwesomeIcon icon={faCircleXmark} /> To konto nie zostało jeszcze aktywowane przez pracownika.</div>}
            {props.data.isActive === true && <div style={{ color: "green" }}><FontAwesomeIcon icon={faCheckCircle} /> Konto zostało aktywowane przez pracownika.</div>}

            {props.data.isActive === false &&
              <p><Button icon='k-icon k-i-hyperlink-email' onClick={resendActivationEmail}>Wyślij ponownie e-mail powitalny (aktywacyjny)</Button></p>
            }

            <p><Button icon='k-icon k-i-borders-show-hide' onClick={toggleQrVisible}>Pokaż kod QR pracownika</Button></p>
          </>
        }

        <FormElement
          style={{
            width: 400,
          }}
        >
          <Field
            id={"jobPosition"}
            name={"jobPosition"}
            autoComplete="off"
            label={"Nazwa stanowiska"}
            component={FormInput}
            hint={
              "(opis stanowiska)"
            }
          />

          <Field
            id={"departmentNames"}
            name={"departmentNames"}
            autoComplete="off"
            label={"Nazwa przypisanego działu"}
            component={FormInput}
            readOnly={true}
            hint={
              "(zmiana możliwa jest w module Struktura firmy; nazwa działu, do którego przypisany jest użytkownik)"
            }
          />

          <Field
            id={"supervisorsNames"}
            name={"supervisorsNames"}
            autoComplete="off"
            label={"Przełożeni"}
            component={FormInput}
            readOnly={true}
            hint={
              "(na liście pojawiają się nazwiska kierowników działów, do których przypisany jest pracownik; kierowników przypisuje się na stronie \"Struktura firmy\" podczas edycji konkretnego działu)"
            }
          />

          <Field
            id={"canBeManager"}
            name={"canBeManager"}
            label={"Może pełnić funkcję kierownika"}
            component={FormCheckbox}
            hint={
              "(jeżeli zaznaczono, to pracownik ten będzie mógł być wskazany jako kierownik działu czyli osoba akceptująca wnioski)"
            }
          />

          <Field
            id={"isHR"}
            name={"isHR"}
            label={"Jest pracownikiem działu kadr (HR)"}
            component={FormCheckbox}
            hint={
              "(tak oznaczony pracownik uzyskuje dodatkowe uprawnienia i dostęp do zaawansowanych funkcji systemu takich jak definicja planów pracy, edycja kalendarzy, kartotek osobowych itp.)"
            }
          />

          {/* <Field
          id={"allowImpersonate"}
          name={"allowImpersonate"}
          label={"Może wystawiać wnioski za podwładnych"}
          component={FormCheckbox}
          hint={
            "(po włączeniu opcji pracownik ten w funkcji kierownika działu będzie mógł wystawić wniosek w imieniu podwładnego)"
          }
        /> */}

          <Field
            id={"isBlocked"}
            name={"isBlocked"}
            label={"Blokada konta"}
            component={FormCheckbox}
            hint={
              "(konto nie zostanie usunięte, a jedynie zablokowane - pracownik ten nie będzie mógł zalogować się do systemu)"
            }
          />


          <br />


          <Field
            id={"accountCreateDate"}
            name={"accountCreateDate"}
            autoComplete="off"
            label={"Data utworzenia konta"}
            component={DatePicker}
            readOnly={true}
            disabled={true}
          />

          {!props.data.lastLoginDate || props.data.lastLoginDate.getFullYear() === 1
            ? <FormInput label="Data ostatniego logowania" readOnly={true} disabled={true} value="użytkownik jeszcze nie logował się" />
            : <Field
              id={"lastLoginDate"}
              name={"lastLoginDate"}
              autoComplete="off"
              label={"Data ostatniego logowania"}
              component={DatePicker}
              readOnly={true}
              disabled={true}
            />}
        </FormElement>
      </div>
    </>
  )
};

export default SystemConfigurationTab;
