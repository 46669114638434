import React from 'react';

import { DataResult, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import User from '../../../../../models/local/Employees/User';
import UserWorkPlan from '../../../../../models/local/Employees/UserWorkPlan';
import { DateToDateAsString } from '../../../../../models/mapper';
import PromptDialog from '../../../../Common/PromptDialog';
import { EmployeeSubViewsTypeEnum } from '../../../EmployeeEdit';
import EmployeeWorkPlansAddEdit from './EmployeeWorkPlansAddEdit';
import EmployeeWorkPlansListToolbar from './EmployeeWorkPlansListToolbar';

enum EditState {
  PlansList,
  PlanEdit
}

const EmployeeWorkPlansList = (props: {
  data: User,
  OnSave: (entity: any, entityId: string, entityType: EmployeeSubViewsTypeEnum) => void,
  onDeleteItem: (item: UserWorkPlan) => void,
  setDisableParentSubmit: (value: boolean) => void
}) => {
  const [viewState, setViewState] = React.useState<{
    editState: EditState,
    selectedItem?: UserWorkPlan,
    confirmDeleteDialogVisible: boolean,
    isNew: boolean
  }>(
    {
      editState: EditState.PlansList,
      selectedItem: undefined,
      confirmDeleteDialogVisible: false,
      isNew: false
    });

  const onDeleteItem = (item: UserWorkPlan) => {
    props.onDeleteItem(item)
    setViewState({ ...viewState, selectedItem: undefined, confirmDeleteDialogVisible: false });
  }

  const onEditItem = (item: UserWorkPlan) => {
    setViewState({ ...viewState, selectedItem: item, editState: EditState.PlanEdit, isNew: false });
    props.setDisableParentSubmit(true);
  }

  const onCancel = () => {
    setViewState({ ...viewState, selectedItem: undefined, editState: EditState.PlansList, isNew: false })
    props.setDisableParentSubmit(false);
  }

  const onNew = () => {
    props.setDisableParentSubmit(true);
    setViewState({ ...viewState, editState: EditState.PlanEdit, isNew: true });
  };

  const onSaveItem = (item: UserWorkPlan) => {
    setViewState({ ...viewState, selectedItem: undefined, editState: EditState.PlansList, isNew: false })
    props.setDisableParentSubmit(false);
    props.OnSave(item, item.id, EmployeeSubViewsTypeEnum.USERWORKPLANS);
  }

  const gridData = (): any[] | DataResult => {

    if (props?.data?.userWorkPlans != null)
      return process(props?.data?.userWorkPlans, { sort: [{ field: "validFromDate", dir: "desc" }] })

    let result: UserWorkPlan[] = [];
    return result;
  }

  return (
    <>
      {viewState.confirmDeleteDialogVisible && (
        <PromptDialog
          text={`<p>Czy na pewno chcesz usunąć tą pozycję ?</p>` +
            `Każda zmiana przypisanego pracownikowi planu pracy może spowodować zmianę ilości przysługującego limitu urlopowego w roku.`}
          onYes={() => { onDeleteItem(viewState.selectedItem!); }}
          onNo={() => { setViewState({ ...viewState, selectedItem: undefined, confirmDeleteDialogVisible: false }) }}
        />
      )}

      {props.data && viewState.editState === EditState.PlansList &&
        <>
          <p style={{ fontWeight: 400 }}>
            Lista dziennych planów pracy pracownika
          </p>
          <EmployeeWorkPlansListToolbar onAdd={onNew} ></EmployeeWorkPlansListToolbar>
          <Grid style={{ height: "100%" }}
            data={gridData()}
            sort={[{ field: "validFromDate", dir: "desc" }]}
            sortable={true}
          >
            <Column field="name" title="Nazwa planu pracy" />
            <Column field="validFromDate" title="Obowiązuje od" cell={(v: any) => <td>{DateToDateAsString(v.dataItem.validFromDate as Date).replace("0001-01-01", "(od początku)")}</td>} />
            <Column
              title="Operacje"
              width="210px"
              cell={(v: any) => {
                return (
                  <td style={{ textAlign: "right" }}>
                    <Button fillMode="outline" icon="pencil" onClick={() => { onEditItem(v.dataItem); }} >Edytuj</Button>
                    &nbsp;
                    {
                      <Button fillMode="outline" icon="trash"
                        onClick={() => { setViewState({ ...viewState, selectedItem: (v.dataItem) as UserWorkPlan, confirmDeleteDialogVisible: true }) }}
                        disabled={((v.dataItem.validFromDate as Date).getFullYear() === 1)} >Usuń</Button>
                    }
                  </td>
                )
              }}
            />
          </Grid>

        </>}

      {props.data && viewState.editState === EditState.PlanEdit &&
        <EmployeeWorkPlansAddEdit
          entity={viewState.isNew ? new UserWorkPlan(true) : viewState.selectedItem!}
          onCancel={onCancel}
          onSave={onSaveItem}
          setDisableParentSubmit={props.setDisableParentSubmit} />
      }

    </>
  );
};

export default EmployeeWorkPlansList;
