import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Button, Toolbar, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';

import { apiClient } from '../api/apiClient';
import { USERS_ARCHIVED_GET } from '../api/apiEndpoints';
import ArchivedEmployeesList from '../components/Employees/ArchivedEmployeesList';
import ArchivedUserListDto from '../models/dto/Employees/ArchivedUserListDto';
import ArchivedUserListItem from '../models/local/Employees/ArchivedUserListItem';
import { mapper } from '../models/mapper';
import useAppLoader from '../services/AppLoader';

const ArchivedEmployees = () => {
  const [archivedUsers, setArchivedUsers] = useState<ArchivedUserListItem[] | undefined>();
  const navigate = useNavigate();
  const loader = useAppLoader(false);

  const onReloadData = () => {
    loader.showLoading(true);

    apiClient({
      method: "GET",
      url: USERS_ARCHIVED_GET,
      data: {},
    }).then((resp: any) => {
      loader.showLoading(false);

      // map dto to local
      const mappedResult: ArchivedUserListItem[] = mapper.mapArray(resp?.data?.result as ArchivedUserListDto[], ArchivedUserListItem, ArchivedUserListDto);
      setArchivedUsers(mappedResult);
    });
  };

  useEffect(() => {
    onReloadData();
  }, []);

  return (
    <>

      <div className="fullPageContentWrapper">
        <div className="fullWidthColumn">
          <div className="mainToolbar">
            <div className="itemHeader">Kartoteki archiwalne pracowników</div>
            <Toolbar>
              <ToolbarSpacer></ToolbarSpacer>
              <ToolbarItem>
                <Button
                  fillMode="outline"
                  icon="page-properties"
                  onClick={() => {
                    navigate(`/employees`);
                  }}
                >
                  Wyświetl listę pracowników
                </Button>
              </ToolbarItem>
            </Toolbar>
          </div>
          <ArchivedEmployeesList users={archivedUsers} reloadData={() => { onReloadData(); }}></ArchivedEmployeesList>
        </div>
      </div>
    </>
  );
};

export default ArchivedEmployees;
