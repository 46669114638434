import React, { useEffect, useRef, useState } from 'react';

import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';

import DaysEnum from '../../../../models/enums/DaysEnum';
import NotificationEnum from '../../../../models/enums/NotificationEnum';
import WorkPlanTypeEnum, {
  WorkPlanTypeEnumTranslation
} from '../../../../models/enums/WorkPlanTypeEnum';
import WorkPlan from '../../../../models/local/WorkPlan/WorkPlan';
import WorkPlanItem from '../../../../models/local/WorkPlan/WorkPlanItem';
import { requiredMinLength6Validator } from '../../../../validators/CommonValidators';
import EditToolbar from '../../../Common/EditToolbar';
import { FormCheckbox, FormInput, FormRadioGroup } from '../../../Kendo/form-components';
import DayCheckboxComponent from './DayCheckboxComponent';
import DayHoursComponent from './DayHoursComponent';

const WorkPlanAddEdit = (props: {
  data: WorkPlan | null,
  onSave: (entity: WorkPlan) => void,
  onCancel: () => void,
}) => {
  const formRef = useRef<Form>(null);
  const [currentWorkPlan, setCurrentWorkPlan] = useState<WorkPlan | undefined>(props.data as WorkPlan);

  const handleOnSubmitClick = (data: any) => {
    const mergedDtoToSend: WorkPlan = { ...currentWorkPlan, name: data.values.name, isDefault: data.values.isDefault, type: data.values.type } as WorkPlan;

    if (data.isValid)
      props.onSave(mergedDtoToSend);
  };

  const onHourItemUpdate = (workPlanItem: WorkPlanItem) => {
    currentWorkPlan!.items[currentWorkPlan!.getItemIndexByDay(workPlanItem.day)] = workPlanItem;
    setCurrentWorkPlan({ ...currentWorkPlan } as WorkPlan);

    const refreshHoursSumField = (day: DaysEnum) => {
      formRef.current!.valueSetter(`hoursSum${day.toUpperCase()}`, workPlanItem.hoursSum);
    }
    refreshHoursSumField(workPlanItem.day);
  }

  const refreshDayCheckboxes = (day?: DaysEnum, value?: boolean) => {
    if (day !== undefined) {
      currentWorkPlan!.getItemByDay(day!).enabled = value!;
      setCurrentWorkPlan({ ...currentWorkPlan as WorkPlan })
    }
  };

  const formInitialValues = (): { [name: string]: any } => {
    const values: { [name: string]: any } = {
      name: currentWorkPlan!.name,
      type: WorkPlanTypeEnum.NORMALIZED,
      isDefault: currentWorkPlan!.isDefault,
    };

    (Object.keys(DaysEnum) as Array<keyof typeof DaysEnum>).map((key) => {
      values[`hourFrom${key.toUpperCase()}`] = currentWorkPlan!.getItemByDay(DaysEnum[key]).hourFrom;
      values[`hourTo${key.toUpperCase()}`] = currentWorkPlan!.getItemByDay(DaysEnum[key]).hourTo;
      values[`hoursSum${key.toUpperCase()}`] = currentWorkPlan!.getItemByDay(DaysEnum[key]).hoursSum;
    });

    return values;
  };

  useEffect(() => {
    refreshDayCheckboxes();
  }, []);

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={formInitialValues()}
      ref={formRef}
      render={(formProps: FormRenderProps) => (
        <>
          <p>
            {props.data === null ? "Tworzenie nowego planu pracy" : "Edycja planu pracy"}
          </p>

          <p>
            <EditToolbar onCancelClick={props.onCancel} />
          </p>

          <FormElement
            style={{
              width: 400,
            }}
          >
            {/* Name */}
            <Field
              id={"name"}
              name={"name"}
              autoComplete="off"
              label={"Nazwa"}
              component={FormInput}
              hint={"(np. Pełny etat lub Druga zmiana)"}
              validator={requiredMinLength6Validator}
            />

            {/* Turn on/off working days (checkboxes) */}
            <div style={{ marginTop: "20px", marginBottom: "0px" }} className="highlighted">Praca w dni tygodnia:</div>
            {currentWorkPlan?.items &&
              <div style={{ padding: "15px" }}>
                {Object.values(DaysEnum).map(day =>
                  <DayCheckboxComponent day={day} value={currentWorkPlan!.getItemByDay(day).enabled} onChange={refreshDayCheckboxes} key={day} />)}
              </div>
            }

            {/* Choose work plan type */}
            <div style={{ marginTop: "20px", marginBottom: "0px" }} className="highlighted">Model pracy:</div>
            <Field
              id="type"
              name="type"
              data={[
                {
                  label: WorkPlanTypeEnumTranslation(WorkPlanTypeEnum.NORMALIZED),
                  value: WorkPlanTypeEnum.NORMALIZED,
                },
              ]}
              component={FormRadioGroup}
            />

            {/* Set working hours from-to */}
            <div style={{ marginTop: "20px", marginBottom: "0px" }} className="highlighted">Godziny pracy:</div>
            {currentWorkPlan?.items && <DayHoursComponent workPlan={currentWorkPlan} onItemUpdate={onHourItemUpdate} readonly={false} />}

            {/* Is workplan default ? */}
            <div style={{ marginTop: "20px", marginBottom: "0px" }} className="highlighted">Ustawienia dodatkowe:</div>
            <Field
              id={"isDefault"}
              name={"isDefault"}
              label={"Domyślny"}
              component={FormCheckbox}
              hint={"(domyślny plan przypisywany jest nowym pracownikom)"}
            />
          </FormElement>
        </>
      )}
    />
  );
};
export default WorkPlanAddEdit;
