import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { faHomeUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Slide } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { useTour } from '@reactour/tour';

import SynerdiLogoImg from '../assets/synerdi-logo-dark.png';
import useAppBreadcrumb from '../services/AppBreadcrumb';
import useSessionWrapper from '../services/SessionWrapper';
import { BreadcrumbComp } from './BreadcrumbComp';

export const Header = (props: { onHamburgerClick: () => void }) => {
  const navigate = useNavigate();
  const sessionWrapper = useSessionWrapper();
  const breadcrumb = useAppBreadcrumb();
  const location = useLocation();
  const { setIsOpen, setCurrentStep } = useTour();

  const handleLogout = () => {
    sessionWrapper.logout();
    console.log("clearing session and local storage");
  };

  const handleMyProfil = () => {
    navigate("/userinfo");
  };

  const handleSettings = () => {
    navigate("/settingsParameters");
  };

  const handleTutorial = () => {
    let url = location.pathname;

    if (url.startsWith("/employees/edit")) setCurrentStep(4);
    else if (url.startsWith("/employees")) setCurrentStep(3);
    else setCurrentStep(0);

    setIsOpen(true);
  };

  React.useEffect(() => {
    breadcrumb.refresh();
  }, [location]);

  React.useEffect(() => {
    const handleResize = () => {
      const mobileBreakpointStr = getComputedStyle(document.documentElement)
        .getPropertyValue('--mobileBreakpoint').trim().replace("px", "");
      const mobileBreakpoint = parseInt(mobileBreakpointStr, 10);
      localStorage.setItem("mobileBreakpointPx", mobileBreakpointStr)

      if (window.innerWidth < mobileBreakpoint) {
        setTimeout(() => { props.onHamburgerClick() }, 1000);
        localStorage.setItem("isInMobileView", "true")
      } else
        localStorage.setItem("isInMobileView", "false")

      sessionWrapper.localStorageMobileViewToContextCopy();
    }

    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);


  return (
    <header className="header" >
      <div className="nav-container">
        <div className="title" style={{ fontFamily: "Montserrat", fontSize: "1.5em", cursor: "pointer" }}>
          <Button id="hamburgerMenu" fillMode="flat" icon="menu" onClick={(e) => { props.onHamburgerClick(); }} />
          <img alt="Synerdi.pl" src={SynerdiLogoImg} className={"SynerdiLogo"} onClick={() => navigate("/")} />
        </div>

        <BreadcrumbComp />

        <Slide direction="left" appear={true} className="searchPanel" style={{ minWidth: "75px" }}>
          <div className="settings">
            {/* <Input style={{ marginRight: "30px" }} placeholder="Wyszukaj..." /> */}
            {/* <FontAwesomeIcon icon={faBell} className="fa-xl marginRight15px" /> */}

            {/* <span className="marginRight15px headerButton hideOnMobile" onClick={handleTutorial}>
              <FontAwesomeIcon icon={faCircleQuestion} className="fa-xl  " /> <span>Pomoc</span>
            </span> */}

            {/* <FontAwesomeIcon icon={faGear} className="fa-xl marginRight15px headerButton" onClick={handleSettings} /> */}

            <span className="marginRight15px  headerButton" onClick={handleMyProfil}>
              <FontAwesomeIcon icon={faHomeUser} className="fa-xl " /> <span className='hideOnMobile'>Profil</span>
            </span>

            <span className="marginRight15px logoutButton" onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} className="fa-xl  " /> <span className='hideOnMobile'>Wyloguj</span>
            </span>
          </div>
        </Slide>
      </div>
    </header>
  );
};
