export default class UserWithCalendarResponseDto {
  userId: string;
  fullName: string;

  calendarId: string | null;
  calendarName: string;

  departmentsNames: string[];

  isAssigned: boolean;
}