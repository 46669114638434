import React from 'react';

import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import { LimitTypeScopeEnum } from '../../../models/enums/LimitTypeScopeEnum';
import LimitType from '../../../models/local/Employees/LimitType';
import { CellDictToName, DictTypes } from '../../../scripts/dictProvider';
import YearSelector from '../../Common/YearSelector';

const TypesOfLeaveList = (props: {
  limitTypes: LimitType[],
  onEdit: (entityId: string) => void,
  onYearChange: (year: number) => void,
}) => {
  const limitScopeToIcon = (limitScope: string) => {
    let iconName: string = "";
    switch (limitScope) {
      case LimitTypeScopeEnum.NoLimit:
        iconName = "k-icon k-i-aggregate-fields";
        break;
      case LimitTypeScopeEnum.Global:
        iconName = "k-icon k-i-globe-outline";
        break;
      case LimitTypeScopeEnum.Individual:
        iconName = "k-icon k-i-user";
        break;
    }

    return <span className={iconName} style={{ float: "left", marginRight: "5px" }}></span>
  }

  const limitScopeToColor = (limitScope: string): string => {
    let color: string = "";
    switch (limitScope) {
      case LimitTypeScopeEnum.NoLimit:
        color = "black";
        break;
      case LimitTypeScopeEnum.Global:
        color = "red";
        break;
      case LimitTypeScopeEnum.Individual:
        color = "blue";
        break;
    }
    return color;
  }

  return (
    <>
      <p style={{ fontWeight: 400 }}>Rodzaje wniosków dostępne dla pracowników w roku <YearSelector initialYear={new Date().getFullYear()} onValueChanged={props.onYearChange} readonly={false} /></p>

      <Grid style={{ height: "100%" }} data={props.limitTypes}>
        <Column field="name" title="Nazwa" />
        <Column
          field="limitScope"
          title="Rodzaj limitu"
          cell={(v: any) => {
            return <td style={{ color: limitScopeToColor(v.dataItem.limitScope) }}>{limitScopeToIcon(v.dataItem.limitScope)}{CellDictToName(v, DictTypes.LimitTypeScopeEnum, "", true)}</td>;
          }}
        />
        <Column field="limit" title="Wartość limitu" width="150px" />
        <Column
          field="limitUnit"
          title="Okres"
          cell={(v: any) => CellDictToName(v, DictTypes.LimitUnitEnum)}
        />
        <Column field="visible" title="Aktualny stan" cell=
          {
            (v: any) =>
              <td>
                {v.dataItem.visible === true ?
                  <span style={{ color: "green", fontWeight: 500 }}>Dostępny dla pracowników</span> : <span style={{ color: "red", fontWeight: 500 }}>Ukryty</span>}
              </td>
          } />
        <Column
          title="Operacje"
          width="120px"
          cell={(v: any) => (
            <td>
              {
                <Button fillMode="outline" icon="pencil" onClick={() => {
                  props.onEdit(v.dataItem.id);
                }} >Edytuj</Button>}
            </td>
          )}
        />
      </Grid>
    </>
  );
};
export default TypesOfLeaveList;
