import '../../../../styles/pdfExportUnicodeSupport.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { DateInput } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';

import { apiClient } from '../../../../api/apiClient';
import {
    DROPDOWN_GET_ALLOWEDDEPARTMENTSTREE, WORKTIME_GET_DEPARTMENT
} from '../../../../api/apiEndpoints';
import { getCurrentMonthBounds } from '../../../../helpers/dateUtils';
import DropdownTreeItemResponse from '../../../../models/dto/Dropdown/DropdownTreeItemResponse';
import WorkTimeGroupItemResponseDto from '../../../../models/dto/WorkTime/Responses/Summary/WorkTimeGroupItemResponseDto';
import NotificationEnum from '../../../../models/enums/NotificationEnum';
import DropdownTreeItem from '../../../../models/local/Dropdown/DropdownITreetem';
import WorkTimeSummaryGrid from '../../../../models/local/WorkTime/Summary/WorkTimeSummaryGrid';
import WorkTimeSummaryViewState from '../../../../models/local/WorkTime/Summary/WorkTimeSummaryViewState';
import { mapper } from '../../../../models/mapper';
import useAppLoader from '../../../../services/AppLoader';
import useAppNotifications from '../../../../services/AppNotifications';
import WorkTimeMonthlyTimeLogGridToolbar from '../WorkTimeGridSimpleToolbar';
import WorkTimeDetail1UsersGrid from './WorkTimeDetail1UsersGrid';

const WorkTimeGroupTimeLogGrid = (props: { showFilters: boolean }) => {
    const loader = useAppLoader(false);
    const notifications = useAppNotifications();
    const [departments, setDepartments] = React.useState<DropdownTreeItem[]>([]);
    const navigate = useNavigate();

    const [viewState, setViewState] = useState<WorkTimeSummaryViewState>(new WorkTimeSummaryViewState());

    // actions
    const onChangeDate = (shift: number) => {
        const date = new Date(viewState.Date);
        date.setMonth(date.getMonth() + shift);
        date.setDate(1); // first day

        setViewState(prevState => ({ ...prevState, Date: new Date(date) }));
    }

    const onResetDate = () => {
        const date = new Date();
        date.setDate(1); // first day
        setViewState(prevState => ({ ...prevState, Date: new Date(date) }));
    }

    const onExportPDF = () => {
        exportGridToPDF();
    }

    const onClickPrintAttendanceList = () => {
        toggleAttendanceListDateDialogVisibility();
    }

    const toggleAttendanceListDateDialogVisibility = () => {
        (async () => {
            if (!viewState.attendanceListDateDialogVisible)
                await getDepartmentsWhereUserIsManager();

            setViewState(prevState => ({ ...prevState, attendanceListDateDialogVisible: !prevState.attendanceListDateDialogVisible }));
        })();
    };

    const handlePrintAttendanceList = () => {
        toggleAttendanceListDateDialogVisibility();
        exportAttGridToPDF();
    };


    const goToPersonalDetails = (id: string) => {
        navigate(`/worktimeBrowse/${id}`);
    }

    // api
    const getSummary = async () => {
        loader.showLoading(true);

        const monthBounds = getCurrentMonthBounds(viewState.Date);

        await apiClient({
            method: "GET",
            url: WORKTIME_GET_DEPARTMENT(null, monthBounds.start, monthBounds.end),
        }).then(async (resp) => {
            if (resp.status !== 200) {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error);
                return;
            } else {
                const mappedResult: WorkTimeSummaryGrid[] = mapper.mapArray(resp?.data?.result.items, WorkTimeSummaryGrid, WorkTimeGroupItemResponseDto);
                console.log(mappedResult);
                setViewState(prevState => ({ ...prevState, GridItems: mappedResult }));
            }
        });
        loader.showLoading(false);
    }

    const getDepartmentsWhereUserIsManager = () => {
        loader.showLoading(true);

        apiClient({
            method: "GET",
            url: DROPDOWN_GET_ALLOWEDDEPARTMENTSTREE,
        }).then(async (resp) => {
            loader.showLoading(false);
            if (resp.status !== 200) {
                return;
            } else {
                const mappedResponse: DropdownTreeItem[] = mapper.mapArray(resp?.data?.result as DropdownTreeItemResponse[], DropdownTreeItem, DropdownTreeItemResponse);
                setDepartments(mappedResponse);
            }
        });
    };

    const gridLocalComponent = (isForPrint: boolean) => {
        return <Grid
            data={viewState.GridItems}
            detail={(items) => { return WorkTimeDetail1UsersGrid({ data: items, goToPersonalDetails: goToPersonalDetails, isForPrint }) }}
            expandField='expanded'
            className='pdfExportFonts'
            headerCellRender={(txt) => { return <tr style={{ textTransform: 'uppercase' }}>{txt}</tr> }}
        >
            <GridColumn field="CategoryID" title="ID" width="1px" className='pdfExportFonts' />
            <GridColumn field="departmentName" title="Zarejestrowane godziny pracy" className='pdfExportFonts pdfExportImportant' />
        </Grid>;
    }

    // print pdf

    let mainPDFGrid: GridPDFExport;
    const exportGridToPDF = () => {
        if (mainPDFGrid !== null) {
            mainPDFGrid.save();
        }
    };

    let attPDFGrid: GridPDFExport;
    const exportAttGridToPDF = () => {
        if (attPDFGrid !== null) {
            attPDFGrid.save();
        }
    };

    // effects
    useEffect(() => {
        getSummary();
    }, [viewState.Date]);

    return (
        <>
            {!loader.isLoading() && (
                <>
                    <WorkTimeMonthlyTimeLogGridToolbar
                        date={viewState.Date}
                        changeMonth={onChangeDate}
                        resetMonth={onResetDate}
                        exportPDF={onExportPDF}
                        exportAttendanceList={onClickPrintAttendanceList}
                        showFilters={props.showFilters}
                        showPrintTemplateButton={true}
                        showBackToListButton={false}
                    />

                    <div id="itemsGridWrapper">
                        {gridLocalComponent(false)}
                    </div>

                    {viewState.attendanceListDateDialogVisible && (
                        <Dialog title={"Wydruk pustej listy obecności"} onClose={toggleAttendanceListDateDialogVisibility}>
                            <p
                                style={{
                                    margin: "25px",
                                    textAlign: "left",
                                }}
                            >
                                Rok i miesiąc generowanej listy obecności:
                                <p>
                                    <DateInput format="yyyy/MM" width={150} spinners={true} defaultValue={new Date()} required={true} />
                                </p>
                                Dział:
                                <p>
                                    <DropDownTree
                                        id="department"
                                        // ref={dropdownDepartment}
                                        style={{ width: "300px" }}
                                        data={departments}

                                        placeholder="Wybierz..."
                                        dataItemKey={"id"}
                                        textField={"text"}

                                        expandField={"expanded"}
                                        loading={loader.isLoading()}

                                        popupSettings={{ animate: false }}

                                        // onChange={onChangeDepartment}
                                        defaultValue={departments[0]}
                                    />
                                </p>
                            </p>
                            <DialogActionsBar>
                                <button
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                    onClick={handlePrintAttendanceList}
                                >
                                    Przygotuj wydruk
                                </button>
                                <button
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                    onClick={toggleAttendanceListDateDialogVisibility}
                                >
                                    Anuluj
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}

                    <GridPDFExport
                        ref={(e: GridPDFExport) => (mainPDFGrid = e)}
                        paperSize="A4"
                        scale={0.5}
                        margin={{ top: "25mm", right: "15mm", left: "10mm", bottom: "15mm" }}
                        pageTemplate={(props: any) => {
                            return (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "60px",
                                        left: "40px",
                                    }}

                                    className='pdfExportFonts'
                                >
                                    <p>MIESIĘCZNA KARTA EWIDENCJI CZASU PRACY<br />
                                        {viewState.Date.toISOString().slice(0, 7)}
                                    </p>
                                </div>
                            )
                        }}
                    >
                        {gridLocalComponent(true)}
                    </GridPDFExport >

                    <GridPDFExport
                        ref={(e: GridPDFExport) => (attPDFGrid = e)}
                        paperSize="A4"
                        scale={0.5}
                        margin={{ top: "25mm", right: "15mm", left: "10mm", bottom: "15mm" }}
                        pageTemplate={(props: any) => {
                            return (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "60px",
                                        left: "40px",
                                    }}

                                    className='pdfExportFonts'
                                >
                                    <p>LISTA OBECNOŚCI<br />
                                    </p>
                                </div>
                            )
                        }}
                    >
                        {/* {gridLocalComponent(true)} */}

                        {/* 1. pobrac listę działów lub wszystkich pracowników
                        2. przygotować listę */}
                    </GridPDFExport >
                </>
            )}
        </>)
};

export default WorkTimeGroupTimeLogGrid;