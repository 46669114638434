enum StorageTypeEnum {
    StorageCompetency = "StorageCompetency", 
    StorageContract = "StorageContract", 
    StorageDoc = "StorageDoc", 
    StorageMedical  = "StorageMedical", 
    StorageNotification = "StorageNotification",
    StorageResource = "StorageResource",
    StorageTraining = "StorageTraining",
    StorageWorkExperience = "StorageWorkExperience",
    StorageNote = "StorageNote"
  };
  
  export default StorageTypeEnum;