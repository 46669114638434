import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { apiClientDownload, baseUrl } from "../../../../../../../api/apiClient";
import { STORAGE_DOWNLOAD } from "../../../../../../../api/apiEndpoints";
import FilePanelData from "../../../../../../../models/dto/Storage/FilePanelData";
import StorageTypeEnum from "../../../../../../../models/enums/StorageTypeEnum";

const FilesPanel = (props: FilePanelData) => {

    const download = async(fileId: string, fileName: string, storageType: StorageTypeEnum) => {        
        await apiClientDownload({
            path: STORAGE_DOWNLOAD,
            fileName: fileName,
            data: {
                fileId: fileId,
                storageType: storageType
            }
          });
          return false;
    }
    
    return (
        <>
            <section>
                {props?.files?.map((item, index) => {
                    return <div key={item.fileId}>
                                <a href="#" onClick={(e) => { e.preventDefault(); return download(item.fileId, item.fileName, props.storageType)} }>{item.fileName}</a>
                                &nbsp;<Button 
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" 
                                onClick={(e) => props.onRemove(item.fileId)}
                                icon="close">
                                </Button>
                           </div>
                })}  
            </section>
        </>
    )
}

export default FilesPanel;