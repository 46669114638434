import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';

interface ImgWithJWTTokenProps {
  src: string;
  alt: string;
  className?: string;
  width?: string;
  height?: string;
  base64?: boolean;
}

const useImageFromSrcWithJWT = (src: string, base64?: boolean) => {
  const [imageSrc, setImageSrc] = useState<string>("");
  const token = localStorage.getItem("access-token");

  useEffect(() => {
    if (!token) return;

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: base64 ? "json" : "blob",
    };

    const blobToBase64 = (blob: Blob): Promise<string> => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
      });
    };

    const getImage = async () => {
      try {
        const response = await axios.get(src, config);
        if (base64) {
          setImageSrc(response.data.avatar);
        } else {
          blobToBase64(response.data).then(setImageSrc);
        }
      } catch (error) {
        console.error("Error fetching the image:", error);
      }
    };
    getImage();
  }, [src, token, base64]);

  return imageSrc;
};

const ImgWithJWTToken: React.FC<ImgWithJWTTokenProps> = (props) => {
  const { src, alt, className, width, height, base64 } = props;
  const imageSrc = useImageFromSrcWithJWT(src, base64);

  return <img src={imageSrc} alt={alt} className={className} height={height} width={width} />;
}

export default ImgWithJWTToken;
