

import React from 'react';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';

const EmployeeWorkPlansListToolbar = (props: {
  onAdd: () => void
}) => {
  return (
    <Toolbar>
      <ToolbarItem>
        <Button
          fillMode="outline"
          icon="save"
          id="submit"
          className="k-button"
          onClick={() => {
            props.onAdd();
          }}
        >
          Przypisz plan pracy
        </Button>
      </ToolbarItem>
      <ToolbarSeparator />
    </Toolbar>
  );
};

export default EmployeeWorkPlansListToolbar;
