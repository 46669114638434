import DaysEnum from '../../enums/DaysEnum';
import WorkPlanTypeEnum from '../../enums/WorkPlanTypeEnum';
import WorkPlanItem from '../WorkPlan/WorkPlanItem';
import IsNewBaseMarker from './IsNewBaseMarker';

export default class UserWorkPlan extends IsNewBaseMarker {
    id: string;

    name: string;
    workPlanId: string;

    validFromDate: Date;
    userId: string;

    type: WorkPlanTypeEnum;
    items: WorkPlanItem[];

    public constructor(isNew: boolean | any) { // | any -> workaround for automapper casting problem
        super();
        this.isNew = true;
    }

    getItemByDay = (day: DaysEnum): WorkPlanItem => { return this.items.filter(item => item.day.toLowerCase() === day.toLowerCase())[0] }


}