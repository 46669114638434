import React, { useEffect } from 'react';

import { Field, Form, FormElement } from '@progress/kendo-react-form';

import DictResponse from '../../../models/dto/Dict/Responses/DictResponse';
import { requiredMinLength3Validator } from '../../../validators/CommonValidators';
import EditToolbar from '../../Common/EditToolbar';
import { FormInput } from '../../Kendo/form-components';

const AddEditDictionaryItem = (props: {
  parentDict: [string, string],
  data?: DictResponse | null | undefined,
  onSave: (entity: DictResponse) => void,
  onCancel: () => void,
}) => {

  const handleSubmit = (dataItem: any) => {
    props.onSave(dataItem);
  };

  useEffect(() => {
    // console.log("DATA --->", props.data);
  }, []);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        ignoreModified={true}
        initialValues={{
          id: props?.data?.id,
          name: props?.data?.name,
        }}
        render={(formProps) => (
          <>
            <div className="mainToolbar">
              <div className="itemHeader">{props.data === undefined ? `Tworzenie nowej pozycji słownika ${props.parentDict[0]}` : `Edycja pozycji słownika ${props.parentDict[0]}`}</div>
              <EditToolbar onCancelClick={props.onCancel} ></EditToolbar>
            </div>

            <FormElement
              style={{
                width: 400,
              }}
            >
              <Field
                id={"name"}
                name={"name"}
                autoComplete="off"
                label={"Nazwa pozycji słownika"}
                component={FormInput}
                hint={"(nazwa elementu słownika, np. dla słownika Typ umowy wprowadź Umowa o pracę. Pozycja ta będzie mogła być użyta na innych widokach programu, np. w kartotekach pracowników)"}
                validator={requiredMinLength3Validator}
              />
            </FormElement>
          </>
        )
        }
      />
    </>
  );
};
export default AddEditDictionaryItem;
