import AddressDto from './Tabs/AddressDto';
import CorrAddressDto from './Tabs/CorrAddressDto';

export default class UserBriefcaseBaseDto {
    id: string;
    address: AddressDto;
    correspondenceAddress: CorrAddressDto;
    corAddressSameAsAddress: boolean;
    canBeManager: boolean;
    isHR: boolean;
    jobPosition: string;
}