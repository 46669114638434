import React, { useEffect } from 'react';

import { faAddressCard, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';

import WorkTimeGroupUser from '../../../../models/local/WorkTime/Summary/WorkTimeGroupUser';

const WorkTimeDetail1UsersGrid = (props: { data: GridDetailRowProps, goToPersonalDetails: (id: string) => void, isForPrint: boolean }) => {
    const data = props.data.dataItem.userWorkSummaries as WorkTimeGroupUser[];

    const goToDetailsView = (id: string) => {
        props.goToPersonalDetails(id);
    }

    useEffect(() => {
    }, []);

    return (
        <Grid
            data={data}
            expandField='expanded'
            className='pdfExportFonts'
        >
            <GridColumn field="userName" title="Imię i nazwisko" />
            <GridColumn field="hours" title="Zarejestrowany czas" cell={(d: GridCellProps) => {
                const item = (d.dataItem as WorkTimeGroupUser);
                return <td>
                    <FontAwesomeIcon icon={faPencil} title='Wpisy zarejestrowane w programie' color='darkblue' /> {item.hours} godz. {item.minutes} min.
                    <br />
                    <FontAwesomeIcon icon={faAddressCard} title='Wpisy zarejestrowane z użyciem identyfikatora' color='darkblue' /> {item.hoursQr} godz. {item.minutesQr} min.
                </td>
            }}
            />
            {(props.isForPrint ? <></> : <GridColumn field="Opcje" width={140}
                cell={(item: any) => (
                    <td>
                        <Button
                            fillMode="outline"
                            icon="zoom"
                            onClick={() => {

                                goToDetailsView((item.dataItem as WorkTimeGroupUser).userId)
                            }}
                        >Szczegóły</Button>
                    </td>
                )}
            />)}
        </Grid>
    );
}

export default WorkTimeDetail1UsersGrid;