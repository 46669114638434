enum DaysEnum {
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
  SUNDAY = "Sunday"
};

export const DaysEnumTranslation = (value: DaysEnum | undefined) => {
  let translation: string = "";

  switch (value) {
    case undefined:
      translation = "***ERROR***";
      break;
    case DaysEnum.MONDAY:
      translation = "poniedziałek";
      break;
    case DaysEnum.TUESDAY:
      translation = "wtorek";
      break;
    case DaysEnum.WEDNESDAY:
      translation = "środa";
      break;
    case DaysEnum.THURSDAY:
      translation = "czwartek";
      break;
    case DaysEnum.FRIDAY:
      translation = "piątek";
      break;
    case DaysEnum.SATURDAY:
      translation = "sobota";
      break;
    case DaysEnum.SUNDAY:
      translation = "niedziela";
      break;
  }

  return translation;
}

export default DaysEnum;