enum LeaveStatusEnum {
  UNKNOWN = "Unknown",
  AWAITING = "Awaiting",
  ACCEPTED = "Accepted",
  REFUSED = "Refused",
  WITHDRAWN = "Withdrawn"
};

export const LeaveStatusEnumTranslation = (value: LeaveStatusEnum | undefined) => {
  let translation: string = "";

  switch (value) {
    case LeaveStatusEnum.UNKNOWN || undefined:
      translation = "***ERROR***";
      break;
    case LeaveStatusEnum.AWAITING:
      translation = "oczekujący";
      break;
    case LeaveStatusEnum.REFUSED:
      translation = "odrzucony";
      break;
    case LeaveStatusEnum.ACCEPTED:
      translation = "zaakceptowany";
      break;
    case LeaveStatusEnum.WITHDRAWN:
      translation = "wycofany";
      break;
  }

  return translation;
}

export const LeaveStatusEnumToColor = (value: LeaveStatusEnum | undefined) => {
  switch (value as LeaveStatusEnum) {
    case LeaveStatusEnum.REFUSED:
      return "red"
    case LeaveStatusEnum.AWAITING:
      return "black"
    case LeaveStatusEnum.ACCEPTED:
      return "darkgreen"
    case LeaveStatusEnum.WITHDRAWN:
      return "gray"

    default:
      return "#000"
  }
}

export default LeaveStatusEnum;