import { EmptyGuid } from '../../../services/GuidHelper';
import UserWorkPlanResponseDto from './Responses/UserWorkPlan/UserWorkPlanResponseDto';
import StorageFileDto from './StorageFileDto';
import LimitTypeDto from './Tabs/LimitTypeDto';
import LimitTypeOverrideDto from './Tabs/LimitTypeOverrideDto';
import PersonalDataDto from './Tabs/PersonalDataDto';
import UserBriefcaseDto from './Tabs/UserBriefcaseDto';

export default class UserDto {
  id: string;
  email: string;
  personalData: PersonalDataDto;
  userBriefcase: UserBriefcaseDto;
  avatarId?: string;

  supervisorsNames: string;
  departmentNames: string;

  accountCreateDate: Date;
  lastLoginDate: Date;

  limitTypes: LimitTypeDto[];
  limitTypesOverrides: LimitTypeOverrideDto[];

  userWorkPlans: UserWorkPlanResponseDto[];

  specialId: string;
  isBlocked: boolean;
  isActive: boolean;

  isNewUser = (): boolean => { return this.id === (null || undefined || EmptyGuid) }
}