import * as React from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormCheckbox, FormRadioGroup } from "../../Kendo/form-components";
import { requiredValidator } from "../../../validators/CommonValidators";

const SetDaysDialog = (props: {
  onCancel: () => void
  onSave: (days: number[], operation: string) => void
}) => {

  const handleSubmit = (event: any) => {
    const days: number[] = [];

    for (let i = 1; i <= 7; i++) {
      if (event[`d${i}`] === true)
        days.push(i);
    }

    props.onSave(days, event.operation);
  };

  return (
    <Dialog title={"Hurtowe definiowanie dni wolnych"} onClose={props.onCancel} minWidth="400px">
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement style={{ width: 400 }}>
            <fieldset className={"k-form-fieldset"}>
              <p>Oznacz wskazane dni tygodnia w całym roku:</p>
              <Field
                id={"d1"}
                name={"d1"}
                label={"Poniedziałek"}
                component={FormCheckbox}
              />

              <Field
                id={"d2"}
                name={"d2"}
                label={"Wtorek"}
                component={FormCheckbox}
              />

              <Field
                id={"d3"}
                name={"d3"}
                label={"Środa"}
                component={FormCheckbox}
              />

              <Field
                id={"d4"}
                name={"d4"}
                label={"Czwartek"}
                component={FormCheckbox}
              />

              <Field
                id={"d5"}
                name={"d5"}
                label={"Piątek"}
                component={FormCheckbox}
              />

              <Field
                id={"d6"}
                name={"d6"}
                label={"Sobota"}
                component={FormCheckbox}
              />

              <Field
                id={"d7"}
                name={"d7"}
                label={"Niedziela"}
                component={FormCheckbox}
              />

              <p>jako dni:</p>

              <Field
                id={"operation"}
                name={"operation"}
                component={FormRadioGroup}
                data={[
                  { label: "dni wolne od pracy", value: "add" },
                  { label: "dni pracujące", value: "remove" },
                ]}
                validator={requiredValidator}
              />


              <br />
              <div style={{ textAlign: "justify", fontStyle: "italic" }}>
                Wskazówka: Zmiana dni wolnych ma wpływ na dni ustawowo wolne od pracy i dni wprowadzone do kalendarza przez użytkownika.
              </div>

              <DialogActionsBar layout={"center"} >
                <Button
                  fillMode="outline"
                  icon="close"
                  type={"submit"}
                  disabled={!formRenderProps.allowSubmit}

                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                >
                  Ustaw
                </Button>

                <Button
                  fillMode="outline"
                  icon="check"
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={props.onCancel}
                >
                  Anuluj
                </Button>
              </DialogActionsBar>
            </fieldset>
          </FormElement>
        )
        }
      />



    </Dialog >
  );
};

export default SetDaysDialog;
