


export default class AddressDto {
    id: string;
    addressName: string;
    city: string;
    street: string;
    houseNumber: string;
    streetNumber: string;
    postalCode: string;
}
