import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import { TourProvider } from '@reactour/tour';

import App from './App';
import AppContextProvider from './services/AppContextProvider';

// React 18
const rootElement = document.getElementById("root");
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

root.render(
  <HashRouter>
    <TourProvider
      showDots={false}
      startAt={0}
      className="tutorial"
      styles={{
        popover: (base) => ({
          ...base,
          maxWidth: "500px"
        }),
        maskWrapper: (base) => ({
          ...base,
          opacity: 0.5,
        }),
        badge: (base) => ({
          ...base,
        }),
      }}
      steps={[]}
    >
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </TourProvider>
  </HashRouter>
);
