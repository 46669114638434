import React, { useEffect } from 'react';

import { Field } from '@progress/kendo-react-form';

import DaysEnum, { DaysEnumTranslation } from '../../../../models/enums/DaysEnum';
import { FormCheckbox } from '../../../Kendo/form-components';

const DayCheckboxComponent = (props: { day: DaysEnum, value: boolean, onChange: (day: DaysEnum, value: boolean) => void }) => {

  useEffect(() => {
  }, []);

  return (
    <Field
      name={`cbDay_${props.day}`.toLocaleLowerCase()}
      label={DaysEnumTranslation(props.day)}
      component={FormCheckbox}
      checked={props.value}
      onChange={() => { props.onChange(props.day, !props.value) }}
    />
  );
};
export default DayCheckboxComponent;
