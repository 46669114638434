import React from 'react';
import { useNavigate } from 'react-router';

import { Breadcrumb, BreadcrumbLinkMouseEvent } from '@progress/kendo-react-layout';

import BreadcrumbItem from '../models/local/Breadcrumb/BreadcrumbItem';
import useAppBreadcrumb from '../services/AppBreadcrumb';

export const BreadcrumbComp = () => {
    const appBreadcrumb = useAppBreadcrumb();
    const navigate = useNavigate();

    const handleItemSelect = (event: BreadcrumbLinkMouseEvent) => {
        const itemIndex: number = appBreadcrumb.getData().findIndex(
            (curValue) => curValue.id === event.id
        );
        const newData: BreadcrumbItem[] = appBreadcrumb.getData().slice(0, itemIndex + 1);

        appBreadcrumb.setMenu(newData);
        navigate(newData[newData.length - 1].url);
    };


    return (
        <div id="breadcrumb">
            <Breadcrumb
                data={appBreadcrumb.getData()}
                onItemSelect={handleItemSelect}
            />
        </div>
    )
}
