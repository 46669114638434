import React, { useContext } from 'react';

import { DrawerItem, DrawerItemProps } from '@progress/kendo-react-layout';

import UserRoleEnum, { UserRoleEnumTranslation } from '../../models/enums/UserRoleEnum';
import { AppContext, IAppContext } from '../../services/AppContext';

const DrawerItemRenderer = (props: DrawerItemProps) => {
  const context = useContext<IAppContext>(AppContext);
  const arrowDir = props["data-expanded"] ? "k-i-arrow-chevron-down" : "k-i-arrow-chevron-right";

  //element is not visible
  if (props.hidden) return null;

  //element is avatar
  if (props.avatar) {
    return (
      <DrawerItem style={{ justifyContent: "center", pointerEvents: "none", padding: "10px 0px 10px 0px", margin: "0px" }}>
        <div className="avatarContainer">
          <div className="avatarBackground"></div>
          <div className="avatarName">{context.values.userName}</div>
          <div className="avatarPosition">{UserRoleEnumTranslation(context.values.role as UserRoleEnum)}</div>
        </div>
      </DrawerItem>
    );
  }

  // non clickable item (parent for nested items)
  if (props.route === "#")
    return (
      <div className="k-drawer-item" style={{ cursor: "default" }}>
        <span className={`k-icon itemIcon ${props.icon}`}></span>
        <div className="itemText">{props.text}</div>
      </div>
    );

  // header for items
  if (props.separator)
    return (
      <div style={{ borderBottom: "1px solid rgba(255, 255, 255, .5)", textAlign: "left", marginTop: "10px", marginBottom: "10px" }}>
        <div style={{ padding: "3px", textTransform: "uppercase", fontWeight: '500' }}>{props.separatorLabel ?? "---"}</div>
      </div>
    );

  // nested item with arrow
  if (props.parentid !== undefined)
    return (
      <DrawerItem {...props} className="nestedDrawerItem" >
        <span className={`k-icon itemIcon ${props.icon}`}></span>
        <div className="itemText">{props.text}</div>
      </DrawerItem >
    );

  //normal item
  return (
    <DrawerItem {...props}>
      <span className={`k-icon itemIcon ${props.icon}`}></span>
      <div className="itemText">{props.text}</div>

      {props.expanded !== undefined && <span className={`k-icon ${arrowDir}`} />}
    </DrawerItem>
  );
};

export default DrawerItemRenderer;
