import StorageFileDto from "../StorageFileDto";


export default class CompetencyDto {
    id: string;
    competencyType: string;
    date: Date;
    validityDate: Date;
    note: string;
    files: StorageFileDto[] = [];
}
