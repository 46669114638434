import React from 'react';

import DaysEnum from '../../../../models/enums/DaysEnum';
import WorkPlan from '../../../../models/local/WorkPlan/WorkPlan';
import WorkPlanItem from '../../../../models/local/WorkPlan/WorkPlanItem';
import DayHoursComponentItem from './DayHoursComponentItem';

const localStyle: { [key: string]: React.CSSProperties } =
{
  wrapper: {
    width: "750px",
    display: "flex",
    flexDirection: "column",
    padding: "15px"
  },
  headerRow: {
    width: "750px",
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    marginBottom: "1em",
    borderBottom: "1px solid black"
  },
}

const DayHoursComponent = (props: {
  workPlan: WorkPlan,
  onItemUpdate: (updatedItem: WorkPlanItem) => void,
  readonly: boolean
}) => {

  return (
    <div style={localStyle.wrapper}>
      <div style={localStyle.headerRow}>
        <div style={{ width: "200px" }}>Dzień tygodnia</div>
        <div style={{ width: "400px" }}>Godziny</div>
        <div style={{ width: "100px" }}>Łącznie</div>
      </div>

      {Object.values(DaysEnum).map(day =>
        <DayHoursComponentItem workPlanItem={props.workPlan.getItemByDay(day)} onUpdateWorkPlanItem={props.onItemUpdate} key={day} readonly={props.readonly} />
      )}
    </div>
  );
};
export default DayHoursComponent;
