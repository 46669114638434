import { useState } from 'react';

import {
  UploadFileStatus, UploadHttpHeaders, UploadOnAddEvent, UploadOnBeforeRemoveEvent,
  UploadOnStatusChangeEvent
} from '@progress/kendo-react-upload';

import StorageFile from '../models/dto/Storage/StorageFile';

const useUploadUtils = () => {
  const [uploadedFiles, setUploadedFiles] = useState<StorageFile[]>([]);

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    if (event.newState.find(x => x.status == UploadFileStatus.Uploaded) != null) {
      let data = event?.response?.response?.result as StorageFile[];
      if (data != null && data.length > 0) {
        data.forEach(e => {
          uploadedFiles.push(e);
        });
        setUploadedFiles(uploadedFiles);
      }
    }
  }

  const onBeforeRemove = (event: UploadOnBeforeRemoveEvent) => {
    event.additionalData.files = [];
    event.files.forEach(f => {
      if (f.status == UploadFileStatus.Removing) {
        let toDelete = uploadedFiles.filter(x => x.fileLength == f.size && x.fileName == f.name);
        if (toDelete) {
          event.additionalData.files = toDelete.map((x) => x.fileId);
          // remove from currently uploaded files
          toDelete.forEach(tDel => {
            setUploadedFiles(uploadedFiles.filter(x => x.fileId != tDel.fileId));
          });
        }
      }
    });
  };

  const setHeaders = (): UploadHttpHeaders => {
    return { 'Authorization': `Bearer ${localStorage.getItem("access-token")}` };
  };

  const showOnAdd = (event: UploadOnAddEvent) => {
    event.affectedFiles.forEach(f => {
      var preview = document.querySelector('.avatar-preview');

      if (f) {
        var file = f.getRawFile;
        if (file) {
          var reader = new FileReader();
          reader.onloadend = function () {
            if (preview)
              preview.setAttribute('src', this.result != null ? this.result.toString() : '');
          };
          reader.readAsDataURL(file());
        }
      }
    });
  }

  return { onStatusChange, onBeforeRemove, setHeaders, uploadedFiles, showOnAdd }
}

export default useUploadUtils;

