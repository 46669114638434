import DaysEnum from '../../enums/DaysEnum';
import WorkPlanTypeEnum from '../../enums/WorkPlanTypeEnum';
import WorkPlanItem from './WorkPlanItem';

export default class WorkPlan {
  id: string;
  name: string;
  isDefault: boolean;
  type: WorkPlanTypeEnum;
  items: WorkPlanItem[];

  public constructor() {
    this.items = [
      new WorkPlanItem(DaysEnum.MONDAY, true),
      new WorkPlanItem(DaysEnum.TUESDAY, true),
      new WorkPlanItem(DaysEnum.WEDNESDAY, true),
      new WorkPlanItem(DaysEnum.THURSDAY, true),
      new WorkPlanItem(DaysEnum.FRIDAY, true),
      new WorkPlanItem(DaysEnum.SATURDAY, false),
      new WorkPlanItem(DaysEnum.SUNDAY, false),
    ];
    this.name = "";
    this.type = WorkPlanTypeEnum.NORMALIZED;
    this.isDefault = false;
  }

  getItemByDay = (day: DaysEnum): WorkPlanItem => { return this.items.filter(item => item.day.toLowerCase() === day.toLowerCase())[0] }
  getItemIndexByDay = (day: DaysEnum): number => { return this.items.findIndex(item => item.day.toLowerCase() === day.toLowerCase()) }
}