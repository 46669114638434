import DropdownItem from '../../../local/Dropdown/DropdownItem';

export default class AddLeaveRequestDto {
  onBehalfOfUserId?: string;
  limitTypeId: string;

  startDate: string;
  endDate: string;

  comment: string;
  hrComment: string;

  sendNotification: boolean;
  replacementUsers: DropdownItem[];
}