export default class SummaryLeaveItemDto {
    id: string;

    userId: string;
    firstName: string;
    lastName: string;
  
    limitType: string;
  
    startDate: Date;
    endDate: Date;
  
    status: string;    
}