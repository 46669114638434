import React, { useEffect } from 'react';

import { ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { DropDownTree, DropDownTreeChangeEvent } from '@progress/kendo-react-dropdowns';
import { FormElement } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';

import { apiClient } from '../../../api/apiClient';
import { DROPDOWN_GET_ALLOWEDDEPARTMENTSTREE } from '../../../api/apiEndpoints';
import DropdownTreeItemResponse from '../../../models/dto/Dropdown/DropdownTreeItemResponse';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import DropdownTreeItem from '../../../models/local/Dropdown/DropdownITreetem';
import { mapper } from '../../../models/mapper';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';

const WorkTimeGridDepartmentFilter = (props: {
  filterParentByDepartmentId: (departmentId: string | undefined) => void
}) => {
  const loader = useAppLoader(true);
  const [departments, setDepartments] = React.useState<DropdownTreeItem[]>([]);
  // const [users, setUsers] = React.useState<DropdownTreeItem[]>([]);

  const [selectedDepartment, setSelectedDepartment] = React.useState<string | undefined>();
  // const [selectedUser, setSelectedUser] = React.useState<DropdownTreeItem>();

  const notifications = useAppNotifications();

  const onChangeDepartment = (event: DropDownTreeChangeEvent) => {

    if (event.level.length === 1)
      setSelectedDepartment(undefined);
    else setSelectedDepartment((event.value as DropdownTreeItem).id);
  }

  // const onChangeUser = (event: DropDownTreeChangeEvent) => {
  //   notifications.showNotification("user changed " + event.value.id)
  //   setSelectedUser(event.value as DropdownTreeItem);
  // }

  const getDepartments = () => {
    loader.showLoading(true);

    apiClient({
      method: "GET",
      url: DROPDOWN_GET_ALLOWEDDEPARTMENTSTREE,
    }).then(async (resp) => {
      loader.showLoading(false);

      if (resp.status !== 200) {
        notifications.showNotification("Wystąpił błąd podczas ładowania danych o działach.", NotificationEnum.Error);
        return;
      } else {
        const mappedResponse: DropdownTreeItem[] = mapper.mapArray(resp?.data?.result as DropdownTreeItemResponse[], DropdownTreeItem, DropdownTreeItemResponse);
        setDepartments(mappedResponse);
      }
    });
  };

  // const getUsers = async (departmentId: string | undefined) => {
  //   apiClient({
  //     method: "GET",
  //     url: DROPDOWN_GET_USERS(departmentId),
  //     data: {},
  //   }).then((resp) => {
  //     if (resp.status !== 200) {
  //       notifications.showNotification("Wystąpił błąd podczas ładowania danych o pracownikach.", NotificationEnum.Error);
  //     } else {
  //       const mappedResult: DropdownTreeItem[] = mapper.mapArray(resp?.data?.result as DropdownTreeItemResponse[], DropdownTreeItem, DropdownTreeItemResponse);
  //       setUsers(mappedResult);
  //     }
  //   });

  //   loader.showLoading(false);
  // }

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    // setSelectedUser(new DropdownTreeItem());
    // getUsers(selectedDepartment);

    props.filterParentByDepartmentId(selectedDepartment);
  }, [selectedDepartment]);

  return (
    <>
      <ToolbarItem>
        <FormElement>
          <Label editorId="department">Dział:&nbsp;</Label>

          <DropDownTree
            id="department"
            style={{ width: "300px" }}
            data={departments}

            placeholder="Wybierz..."
            dataItemKey={"id"}
            textField={"text"}

            expandField={"expanded"}
            loading={loader.isLoading()}

            popupSettings={{ animate: false }}

            onChange={onChangeDepartment}
            defaultValue={departments[0]}
          />
        </FormElement>

        {/* <FormElement>
          &nbsp;<Label editorId="user">Pracownik:&nbsp;</Label>

          <DropDownTree
            id="user"
            style={{ width: "300px" }}
            data={users}

            placeholder="Wybierz..."
            dataItemKey={"id"}
            textField={"text"}

            expandField={"expanded"}
            loading={loader.isLoading()}

            value={selectedUser}

            popupSettings={{ animate: false }}

            onChange={onChangeUser}
          // defaultValue={departments[0]}
          />
        </FormElement> */}
      </ToolbarItem>

      <ToolbarSeparator></ToolbarSeparator>

    </ >
  );
};

export default WorkTimeGridDepartmentFilter;