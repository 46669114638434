import { getter } from "@progress/kendo-react-common";

// REGEX's
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const number0to100 = new RegExp(/^[0-9][0-9]?$|^100$/);

// COMMON VALIDATORS
export const termsValidator = (value) =>
  value ? "" : "Zgadzam się na warunki licencji";
export const emailValidator = (value) =>
  !value
    ? "Adres e-mail jest wymagany"
    : emailRegex.test(value)
      ? ""
      : "Adres e-mail ma nieprawidłowy format";
export const nameValidator = (value) =>
  !value
    ? "Imię jest wymagane"
    : value.length < 2
      ? "Imię powinno mieć przynajmniej 2 znaki"
      : "";
export const requiredMinLength6Validator = (value) =>
  !value
    ? "To pole jest wymagane"
    : value.length < 6
      ? "Wprowadzony tekst mieć minimum 6 znaków"
      : "";
export const requiredMinLength3Validator = (value) =>
  !value
    ? "To pole jest wymagane"
    : value.length < 3
      ? "Wprowadzony tekst mieć minimum 3 znaków"
      : "";
export const nipValidator = (value) =>
  !value
    ? "Numer NIP jest wymagany"
    : value.length !== 10
      ? "Wprowadzono niepoprawny numer NIP"
      : "";
export const lastNameValidator = (value) =>
  !value
    ? "Nazwisko jest wymagane"
    : value.length < 2
      ? "Nazwisko powinno mieć przynajmniej 2 znaki"
      : "";
export const userNameValidator = (value) =>
  !value
    ? "Nazwa użytkownika jest wymagana"
    : value.length < 5
      ? "Nazwa użytkownika powinna mieć minimum 5 znaków"
      : "";
export const passwordValidator = (value) =>
  value && value.length > 8 ? "" : "Hasło musi mieć minimum 8 znaków";
export const phoneValidator = (value) =>
  !value
    ? "Numer telefonu jest wymagany"
    : phoneRegex.test(value)
      ? ""
      : "To nie jest poprawny numer telefonu";
export const number0to100Validator = (value) =>
  !value
    ? "Wprowadź wartość od 0 do 100"
    : number0to100.test(value)
      ? ""
      : "Wartość poza zakresem od 0 do 100";
export const requiredValidator = (value) =>
  value ? "" : "To pole jest wymagane";
export const requiredNoLabelValidator = (value) =>
  value && value !== "" ? "" : " ";
// GETTERS
export const nameGetter = getter("name");
export const userNameGetter = getter("username");
export const emailGetter = getter("email");
