import DropdownItemRequest from "../../Dropdown/DropdownItemRequest";
import StorageFile from "../../Storage/StorageFile";

export default class AddCompetencyRequest {
    userId: string;
    competencyType: DropdownItemRequest;
    date: Date;
    validityDate?: Date;
    note: string;
    files: StorageFile[];
}