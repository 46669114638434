export default class GetResourcesRequest {
    ResourceTypeId: string;
    DateFrom: Date;
    DateTo: Date;

    ReturnDateFrom: Date;
    ReturnDateTo: Date;

    IsReturned: Date;

    Firstname: string;
    Lastname: string;
}
