import React, { ReactNode, useState } from 'react';

import { AppContext, AppContextModel, defaultValues } from './AppContext';

interface InputProps {
  children: ReactNode;
}

//https://www.youtube.com/watch?v=HYKDUF8X3qI
const AppContextProvider = ({ children }: InputProps) => {
  const [currentAppContextValues, setCurrentAppContextValues] = React.useState<AppContextModel>(defaultValues.values);

  return (
    <AppContext.Provider value={{ values: currentAppContextValues, setValues: setCurrentAppContextValues }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;