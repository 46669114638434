import { Field, FormElement } from '@progress/kendo-react-form';
import React, { useState } from 'react';
import { lastNameValidator, nameValidator } from '../../../../../validators/CommonValidators';
import { FormCheckbox, FormInput } from '../../../../Kendo/form-components';


const PersonalContactTab = (props: any) => {
  const [corAddressVisible, setCorAddressVisible] =
    useState<boolean>(props.data.corAddressSameAsAddress !== true);

  const onCorAddressSameAsAddressChange = (event: any) => {
    setCorAddressVisible(!corAddressVisible);
  };

  return (
    <div>
      <FormElement
        style={{
          width: 400,
        }}
      >
        <div id="mainAddr">
          <p>Adres zameldowania</p>
          <Field
            id={"addrCity"}
            name={"addrCity"}
            autoComplete="off"
            label={"Miasto"}
            component={FormInput}
          />
          <Field
            id={"addrStreet"}
            name={"addrStreet"}
            autoComplete="off"
            label={"Ulica"}
            component={FormInput}
          />
          <Field
            id={"addrStreetNumber"}
            name={"addrStreetNumber"}
            autoComplete="off"
            label={"Numer ulicy"}
            component={FormInput}
          />
          <Field
            id={"addrHouseNumber"}
            name={"addrHouseNumber"}
            autoComplete="off"
            label={"Numer domu"}
            component={FormInput}
          />
          <Field
            id={"addrPostalCode"}
            name={"addrPostalCode"}
            autoComplete="off"
            label={"Kod pocztowy"}
            component={FormInput}
          />
          <Field
            id={"addrCountry"}
            name={"addrCountry"}
            autoComplete="off"
            label={"Kraj"}
            readOnly={true}
            disabled={true}
            component={FormInput}
          />
          <Field
            id={"corAddressSameAsAddress"}
            name={"corAddressSameAsAddress"}
            label={"Adres korespondencyjny taki sam jak zameldowania"}
            onChange={onCorAddressSameAsAddressChange}
            component={FormCheckbox}
          />
        </div>

        {corAddressVisible && (
          <div id="corespAddr">
            <p>Adres korespondencyjny</p>
            <Field
              id={"addrCorespCity"}
              name={"addrCorespCity"}
              autoComplete="off"
              label={"Miasto"}
              component={FormInput}
            />
            <Field
              id={"addrCorespStreet"}
              name={"addrCorespStreet"}
              autoComplete="off"
              label={"Ulica"}
              component={FormInput}
            />
            <Field
              id={"addrCorespStreetNumber"}
              name={"addrCorespStreetNumber"}
              autoComplete="off"
              label={"Numer ulicy"}
              component={FormInput}
            />
            <Field
              id={"addrCorespHouseNumber"}
              name={"addrCorespHouseNumber"}
              autoComplete="off"
              label={"Numer domu"}
              component={FormInput}
            />
            <Field
              id={"addrCorespPostalCode"}
              name={"addrCorespPostalCode"}
              autoComplete="off"
              label={"Kod pocztowy"}
              component={FormInput}
            />
            <Field
              id={"addrCorespCountry"}
              name={"addrCorespCountry"}
              autoComplete="off"
              label={"Kraj"}
              readOnly={true}
              disabled={true}
              component={FormInput}
            />
          </div>
        )}
      </FormElement>
    </div>
  );
};

export default PersonalContactTab;
