class BreadCrumbItem {
  name: string;
  icon?: string;
}

const useRoutesBreadcrumb = () => {
  const menu = new Map<string, BreadCrumbItem>([
    ["/", { name: "Pulpit", icon: "k-i-grid" }],
    ["/licences/edit", { name: "Licencja" }],
    ["/licences/edit/:id", { name: "Licencja" }],
    ["/promocodes/list", { name: "Lista kodów" }],
    ["/promocodes/edit", { name: "Edycja kodu" }],
    ["/promocodes/edit/:id", { name: "Edycja kodu" }],
    ["/promocodes/edit", { name: "Edycja kodu" }],
    ["/addapp", { name: "Złóż wniosek", icon: "k-i-plus-circle" }],
    ["/userlimits", { name: "Pozostały urlop", icon: "k-i-information" }],
    ["/calendar", { name: "Kalendarz urlopów", icon: "k-i-calendar" }],
    ["/worktime", { name: "Rejestracja godzin pracy", icon: "k-i-clock" }],
    ["/appslist", { name: "Lista wniosków", icon: "k-i-track-changes-enable" }],
    ["/appslist/:id", { name: "Szczegóły wniosku" }],
    ["/appslist/:id/consider", { name: "Rozpatrywanie wniosku" }],
    ["/exportdata", { name: "Eksport danych do systemów K-P" }],
    ["/archivedemployees", { name: "Archiwum pracowników" }],

    ["/employees", { name: "Lista pracowników", icon: "k-i-folder-more" }],
    ["/employees/edit", { name: "Edycja pracownika" }],
    ["/employees/edit/:id", { name: "Edycja pracownika" }],
    ["/employees/edit/:id/contracts", { name: "Umowy" }],
    ["/employees/edit/:id/medicals", { name: "Badania" }],
    ["/employees/edit/:id/trainings", { name: "Szkolenia" }],
    ["/employees/edit/:id/notifications", { name: "Powiadomienia" }],
    ["/employees/edit/:id/workexperiences", { name: "Doświaddczenie" }],
    ["/employees/edit/:id/competencies", { name: "Kompetencje" }],
    ["/employees/edit/:id/resources", { name: "Zasoby" }],
    ["/employees/edit/:id/docs", { name: "Dokumenty" }],
    ["/employees/edit/:id/notes", { name: "Notatki" }],

    ["/briefcases", { name: "Kartoteki", icon: "k-i-tell-a-friend" }],
    ["/briefcases/competencies", { name: "Kompetencje", icon: "k-i-arrow-60-right" }],
    ["/briefcases/competencies/add", { name: "Dodawanie" }],
    ["/briefcases/contracts", { name: "Umowy", icon: "k-i-arrow-60-right" }],
    ["/briefcases/contracts/add", { name: "Dodawanie" }],
    ["/briefcases/docs", { name: "Dokumenty", icon: "k-i-arrow-60-right" }],
    ["/briefcases/docs/add", { name: "Dodawanie" }],
    ["/briefcases/medicals", { name: "Badania", icon: "k-i-arrow-60-right" }],
    ["/briefcases/medicals/add", { name: "Dodawanie" }],
    ["/briefcases/notifications", { name: "Powiadomienia", icon: "k-i-arrow-60-right" }],
    ["/briefcases/notifications/add", { name: "Dodawanie" }],
    ["/briefcases/resources", { name: "Zasoby", icon: "k-i-arrow-60-right" }],
    ["/briefcases/resources/add", { name: "Dodawanie" }],
    ["/briefcases/trainings", { name: "Szkolenia", icon: "k-i-arrow-60-right" }],
    ["/briefcases/trainings/add", { name: "Dodawanie" }],
    ["/briefcases/workexperience", { name: "Doświaddczenie", icon: "k-i-arrow-60-right" }],
    ["/briefcases/workexperience/add", { name: "Dodawanie" }],

    ["/departments", { name: "Struktura firmy", icon: "k-i-categorize" }],
    ["/settings", { name: "Ustawienia" }],
    ["/settingsTypesOfLeaves", { name: "Rodzaje wniosków" }],
    ["/settingsParameters", { name: "Parametry systemowe" }],
    ["/settingsDictionaries", { name: "Słowniki" }],
    ["/settingsLicence", { name: "Informacje o licencji" }],
    ["/settingsCalendars", { name: "Kalendarze pracy" }],
    ["/workPlans", { name: "Plan pracy" }],
    ["/workPlans/edit/:id", { name: "Edycja" }],
    ["/userinfo", { name: "Profil użytkownika" }],
  ]);

  const getName = (path: string): string => {
    if (menu.has(path)) {
      return (menu.get(path) || { name: "" }).name;
    } else return "";
  };

  const getItem = (path: string): BreadCrumbItem => {
    if (menu.has(path)) {
      return menu.get(path) || null!;
    } else return null!;
  };

  return { getName, getItem };
};

export default useRoutesBreadcrumb;
