import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { Button, Toolbar, ToolbarItem, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { GridFilterChangeEvent, GridSortChangeEvent, GridColumn as Column, GridCellProps, GridSelectionChangeEvent, getSelectedState } from "@progress/kendo-react-grid";
import React, { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { apiClient } from "../../api/apiClient";
import { PROMOCODE_DELETE } from "../../api/apiEndpoints";
import PromoCodeListDto from "../../models/dto/PromoCode/PromoCodeListDto";
import PromoCodeList from "../../models/local/PromoCode/PromoCodeList";
import { mapper } from "../../models/mapper";
import useAppNotifications from "../../services/AppNotifications";
import PromptDialog from "../Common/PromptDialog";
import { StatefullGrid } from "../Kendo/StatefullGrid";

const PromoCodesList = (props: { 
    url: string
   }) => {

    const navigate = useNavigate();
    const notifications = useAppNotifications();
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
          { field: "officeName", operator: "startswith", value: "" }
        ],
      };
      const initialSort: Array<SortDescriptor> = [
        { field: "officeName", dir: "asc" },
      ];

    const [viewState, setViewState] = React.useState<{ 
        promptDialogVisible: boolean,
        selectedId: string | null, 
        selectedName: string | null,
        errorMessage: string | null,
        filter: CompositeFilterDescriptor,
        sort: Array<SortDescriptor>}>(
      {
        promptDialogVisible: false,
        selectedId: null,
        selectedName: null,
        errorMessage: null,
        filter: initialFilter,
        sort: initialSort
      }
    );

    const onDeleteButtonClick = (id: string, name: string) => {
      setViewState({ ...viewState, selectedId: id, selectedName: name, promptDialogVisible: true })
    }

    const callbackMapping = (dtoData: PromoCodeListDto[]) => {
      return mapper.mapArray(dtoData, PromoCodeList, PromoCodeListDto);
    };

    const onDeletePromoCode = () => {
        apiClient({
          method: "DELETE",
          url: `${PROMOCODE_DELETE(viewState.selectedId)}`,
          data: {},
        }).then(async (resp) => {
          setViewState({ ...viewState, promptDialogVisible: false })
          if (resp.status !== 200) {
            setViewState({ ...viewState, errorMessage: resp.data.errors })
            return;
          } else {
            notifications.showNotification("Kod promocyjny został usunięty.");
            navigate("/");
          }
        });
    };

    return (
        <>    
        {viewState.promptDialogVisible === true && (
          <PromptDialog
            text={`<p>Czy na pewno chcesz usunąć kod promocyjny <b>"${viewState.selectedName}"</b> ?</p>`}
            onYes={() => { onDeletePromoCode(); }}
            onNo={() => { setViewState({ ...viewState, promptDialogVisible: false }) }}
          />
        )}

        <Toolbar>
            <ToolbarItem>
                <Button
                    icon="plus"
                    fillMode="outline"
                    onClick={() => {
                        navigate(`/promocodes/edit`);
                    }}
                >
                    Dodaj
                </Button>
            </ToolbarItem>            
        </Toolbar>

        <StatefullGrid style={{ height: "100%" }}
            url = {props.url}
            filterable={true}
            filter={viewState.filter}
            sortable={true}
            sort={viewState.sort}
            callbackMapping = {callbackMapping}
            //onSelectionChange = {onSelectionChange}
            >
            <Column field="code" title="Kod promocyjny" />
            <Column field="workersLimit" title="Limit pracowników" />
            <Column field="validFromUtc" title="Ważny od" format="{0:d}" filter="date" />
            <Column field="validToUtc" title="Ważny do" format="{0:d}" filter="date" />
            <Column field="usages" title="Użycia" filter="numeric" />
            <Column field="maxUsages" title="Limit użyć" filter="numeric" />
            <Column field="validForDays" title="Ważność w dniach" filter="numeric" />
            <Column title="Operacje"
                filterable={false}
                sortable={false}
                width="100px"
                cell={(props: any) => (
                    <td>
                    <Button
                        fillMode="outline"
                        icon="edit"
                        onClick={() => {
                        navigate(`/promocodes/edit/${props.dataItem.id}`);
                        }}
                    />{" "}
                    <Button
                        fillMode="outline"
                        icon="trash"
                        themeColor="error"
                        onClick={() => {
                            onDeleteButtonClick(props.dataItem.id, props.dataItem.code);
                        }}
                    />
                    </td>
                )}
            />
        </StatefullGrid>
        </>
        );
      };
export default PromoCodesList;