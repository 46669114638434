import StorageFileDto from "../StorageFileDto";


export default class NotificationDto {
    id: string;
    notificationType: string;
    date: Date;
    validityDate: Date;
    note: string;
    notifyHR: boolean;
    notifyEmployee: boolean;
    files: StorageFileDto[] = [];
}