import { Field, Form, FormRenderProps } from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { apiClient } from "../../api/apiClient";
import { DROPDOWN_GET_ALLOFFICES, LICENCE_GET, LICENCE_GET_BY_ID, LICENCE_POST, LICENCE_PUT } from "../../api/apiEndpoints";
import DropdownItemResponse from "../../models/dto/Dropdown/DropdownItemResponse";
import LicenceDto from "../../models/dto/Licence/LicenceDto";
import LicenceEditRequest from "../../models/dto/Licence/LicenceEditRequest";
import NotificationEnum from "../../models/enums/NotificationEnum";
import DropdownItem from "../../models/local/Dropdown/DropdownItem";
import Licence from "../../models/local/Licences/Licence";
import { mapper } from "../../models/mapper";
import Loading from "../../pages/Loading";
import useAppNotifications from "../../services/AppNotifications";
import { requiredValidator } from "../../validators/CommonValidators";
import ErrorMessage from "../Common/ErrorMessage";
import { FormDatePicker, FormDropDownList, FormNumericTextBox } from "../Kendo/form-components";
import LicenceEditToolbar from "./LicenceEditToolbar";

type LicenceEditParams = {
    id: string;
  };

const LicenceEdit = (props: {  }) => {
    const formRef = useRef<Form>(null);
    const navigate = useNavigate();
    const notifications = useAppNotifications();
    const licenceId = useParams<LicenceEditParams>()?.id;

    const [viewState, setViewState] = React.useState<{
      isLoading: boolean, 
      licenceData: Licence | undefined,
      offices: DropdownItem[] | undefined
    }>(
    {
      isLoading: true,
      licenceData: new Licence(),
      offices: new Array<DropdownItem>()
    }
  );

    const getDropdownOffices = async ()  => {

      const resp = await apiClient({
        method: "GET",
        url: DROPDOWN_GET_ALLOFFICES,
        data: {},
      });

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      const response: DropdownItem[] = mapper.mapArray(resp?.data?.result as DropdownItemResponse[], DropdownItem, DropdownItemResponse);    
      return response;  
    };

    const getLicenceData = async () => {
      if (licenceId != null) {
        const resp = await apiClient({
          method: "GET",
          url: LICENCE_GET_BY_ID(licenceId),
          data: {},
        });

        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }

        const result: LicenceDto = resp.data.result as LicenceDto;
        const mappedResult: Licence = mapper.map(result, Licence, LicenceDto);

        return mappedResult;

      } else {
        let fromDate = new Date();
        fromDate.setHours(0,0,0,0);
        let toDate = new Date(fromDate.getFullYear() + 1, fromDate.getMonth(), fromDate.getDate())
        const emptyLicence: Licence = mapper.map({ validFrom: fromDate, validTo: toDate, workersLimit: 0  } as LicenceDto, Licence, LicenceDto);
        return emptyLicence;
      }

    }

    useEffect(() => {
        Promise.all([getDropdownOffices(), getLicenceData()]).then(([resOffices, resLicence]) => { 
            setViewState({ ...viewState, isLoading: false, offices: resOffices, licenceData: resLicence });
        });
      }, []);
      
    const submitForm = (form: any) => {
        const dtoToSend: LicenceEditRequest = mapper.map(form as Licence, LicenceEditRequest, Licence);

        apiClient({
          method: dtoToSend.id ? "PUT" : "POST",
          url: dtoToSend.id ? LICENCE_PUT : LICENCE_POST,
          data: dtoToSend,
        }).then(async (resp) => {
          if (resp.status !== 200) {
            notifications.showNotification(resp.data.errors, NotificationEnum.Error);
            return;
          } else {
            navigate("/licences/edit/"+resp.data.result.id);
            notifications.showNotification(dtoToSend.id ? "Dane zostały zmienione" : "Licencja została dodana", NotificationEnum.Success);
          }
        });
      };

      const onSubmitButtonClick = (formProps: FormRenderProps, e: any) => {
        // data was not changed in edit mode - go back to list
        if (licenceId !== null && formRef?.current != null && Object.keys(formRef.current.modified).length === 0)
          navigate("/");
              
        // try submit if form is valid
        if (!formRef.current?.isValid()) {
          formProps.onSubmit(e);
          // formRef.current?.onSubmit(formRef.current.context);
          notifications.showNotification("Zapisanie danych nie udało się ponieważ formularz zawiera błędy.", NotificationEnum.Error);
        }
    
        formProps.onSubmit(e);
      };      

    return (
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn">
          {viewState.isLoading === false && (
              <Form
                onSubmit={submitForm}
                initialValues={viewState.licenceData}
                //initialValues={licenceData}
                //key={JSON.stringify(viewState.licenceData)}
                ref={formRef}
                render={(formRenderProps: FormRenderProps) => (
                  <>
                    <div className="mainToolbar">
                      <div className="itemHeader">
                        {licenceId === undefined
                          ? "Tworzenie nowej licencji"
                          : `Edycja danych licencji dla ${viewState.licenceData?.office?.text}`}
                      </div>
                      <LicenceEditToolbar onSubmitClick={(e: any) => { onSubmitButtonClick(formRenderProps, e) }} />
    
                    </div>
                    <Field
                      id={"office"}
                      name={"office"}
                      label={"Biuro"}
                      component={FormDropDownList}
                      //data={viewState.offices}
                      data={viewState.offices}
                      validator={requiredValidator}
                      textField="text"
                      dataItemKey="id"
                    />
                    <Field
                      id={"validFrom"}
                      name={"validFrom"}
                      autoComplete="off"
                      label={"Ważna od"}
                      component={FormDatePicker}
                    />
                    <Field
                      id={"validTo"}
                      name={"validTo"}
                      autoComplete="off"
                      label={"Ważna do"}
                      component={FormDatePicker}
                    />
                    <Field
                      id={"workersLimit"}
                      name={"workersLimit"}
                      autoComplete="off"
                      label={"Limit pracowników"}
                      component={FormNumericTextBox}
                    />                                 
                  </>
                )}
              />)}
    
            {viewState.isLoading && <Loading />}
          </div>
        </div>
      );
}

export default LicenceEdit;