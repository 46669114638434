export enum UserRoleEnum {
  HR = "HR",
  EMPLOYEE = "EMPLOYEE",
  MANAGER = "MANAGER",
  ADMINISTRATOR = "ADMINISTRATOR",
  UNKNOWN = "UNKNOWN",
};

export const UserRoleEnumTranslation = (value: UserRoleEnum | undefined) => {

  switch (value) {
    case UserRoleEnum.UNKNOWN || undefined:
      return "***ERROR***";
    case UserRoleEnum.ADMINISTRATOR:
      return "ADMIN";
    case UserRoleEnum.HR:
      return "kadry";
    case UserRoleEnum.EMPLOYEE:
      return "pracownik";
    case UserRoleEnum.MANAGER:
      return "kierownik";
  }
}

export default UserRoleEnum;