import * as React from "react";

import { DictTypes, CellDictToName } from "../../../../../../scripts/dictProvider";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

import PersonalFilesToolbar from "../PersonalFilesToolbar";

import ContractDto from "../../../../../../models/dto/Employees/Tabs/ContractDto";

const ContractsTab = (props: any) => {
  return (
    <>
      <PersonalFilesToolbar
        onNewItem={() => {
          props.onNew();
        }}
      />
      <Grid style={{ height: "100%" }} data={props.entities as ContractDto[]}>
        <Column field="signDate" title="Data zawarcia" format="{0:d}" filter="date" />
        <Column field="startDate" title="Obowiązuje od" format="{0:d}" />
        <Column field="endDate" title="Obowiązuje do" format="{0:d}" />
        <Column field="contractType" title="Rodzaj umowy"
          cell={(v: any) => CellDictToName(v, DictTypes.ContractTypes)} />
        <Column field="contractPeriod" title="Czas trwania"
          cell={(v: any) => CellDictToName(v, DictTypes.ContractPeriods)} />

        <Column
          title="Operacje"
          width="100px"
          cell={(c: any) => (
            <td>
              <Button
                fillMode="outline"
                icon="edit"

                onClick={() => {
                  props.onEdit(c.dataItem.id);
                }}
              ></Button>{" "}
              <Button
                fillMode="outline"
                icon="trash"
                onClick={() => {
                  props.onDelete(c.dataItem.id);
                }}
              ></Button>
            </td>
          )}
        />
      </Grid>
    </>
  );
};

export default ContractsTab;
