import React, { useState } from 'react';

import DropdownItem from '../../../models/local/Dropdown/DropdownItem';
import LimitType from '../../../models/local/Employees/LimitType';
import LimitTypeOverride from '../../../models/local/Employees/LimitTypeOverride';
import LeavesGroupedByType from '../../../models/local/Leaves/LeavesGroupedByType';
import UserLeaveStats from '../../../models/local/Leaves/UserLeaveStats';
import AllUserLimits from '../../../models/local/Limits/AllUserLimits';
import CustomProgressBar from '../../_common/CustomProgressBar/CustomProgressBar';

export const AppAppFormExplanations = (props: {
  workingDaysSum: number,
  allDaysSum: number,
  selectedLimitType: DropdownItem | undefined,

  userLimits: AllUserLimits,
  userLeaveStats: UserLeaveStats,
}) => {

  const [viewState, setViewState] = useState<{ used: number | null, all: number | null, isLimitExceeded: boolean }>({
    used: null,
    all: null,
    isLimitExceeded: false
  });

  const coversAnyWorkingDay = props?.workingDaysSum !== 0;
  const backgroundColor = coversAnyWorkingDay ? "#282456" : "#e9282e";

  React.useEffect(() => {
    console.log("child PROPS ------->", props);

    if (props.selectedLimitType?.id != null) {
      const usedDays = getUserLeaveStatsItemByLeaveTypeId(props.userLeaveStats.leavesGroupedByType, props.selectedLimitType.id);
      const sumOfAllDays = getLimitsSum(props.userLimits.individual, props.userLimits.global, props.selectedLimitType.id);

      if (usedDays != null) {
        setViewState((prev) => ({ ...prev, used: usedDays.count, all: sumOfAllDays, isLimitExceeded: false }));
      } else {
        setViewState(prev => ({ ...prev, used: null, all: sumOfAllDays, isLimitExceeded: false }));
      }
    } else {
      setViewState({ all: null, used: null, isLimitExceeded: false });
    }

  }, [props.selectedLimitType]);

  React.useEffect(() => {
    const isLimitExceeded = (viewState.used ?? 0) + props.workingDaysSum > (viewState.all ?? 0);

    setViewState((prev) => ({ ...prev, isLimitExceeded: isLimitExceeded }));
    console.log("exceedingLimit", { "vs.u": viewState.used, "p.workingDaysSum:": props.workingDaysSum, "vs.a": viewState.all, exceedingLimit: isLimitExceeded });

  }, [viewState.used, viewState.all, props.workingDaysSum]);

  return <><div style={{ marginTop: "10px", marginBottom: "35px", padding: "20px", paddingBottom: "20px", backgroundColor, color: "white", borderRadius: "5px" }}>
    <div style={{ width: "100%", textAlign: "justify" }}>

      {!coversAnyWorkingDay ?
        <span>
          <span className="k-icon k-i-warning"></span>
          &nbsp;Nie można złożyć wniosku ponieważ wskazany zakres dat <b>nie obejmuje dni pracujących</b> wynikających z przypisanego do pracownika kalendarza pracy i planu pracy.<br />
          <b>Upewnij się, że wprowadzone daty rozpoczęcia i zakończenia są poprawne.</b>
        </span>
        :
        <span>
          <span className="k-icon k-i-information"></span>&nbsp;Wniosek zostanie wystawiony na <b>{declineDays(props.workingDaysSum, "robocz")}</b> ({declineDays(props.allDaysSum, "kalendarzow")}).<br />

          {(viewState.used != null && props.selectedLimitType != null) &&
            <span>
              <span className="k-icon k-i-calendar"></span>&nbsp;{getDaysString(viewState.all, viewState.used, props.selectedLimitType!.text)}.
              <CustomProgressBar value={viewState.used!} predicted={props.workingDaysSum} max={viewState.all} />
            </span>}

          {props.selectedLimitType != null && viewState.used == null &&
            <span>
              <span className="k-icon k-i-calendar"></span>&nbsp; Jest to pierwszy wniosek dla wskazanego typu nieobecności. Dostępna ilość dni: {viewState.all == null ? "bez ograniczeń" : viewState.all}.
            </span>}
        </span>}

    </div>
  </div>
  </>
}

const getUserLeaveStatsItemByLeaveTypeId = (leavesGroupedByType: LeavesGroupedByType[], leaveTypeId: string): LeavesGroupedByType | undefined => {
  return leavesGroupedByType.find(item => item.leaveTypeId === leaveTypeId);
};

const getLimitsSum = (individual: LimitTypeOverride[], global: LimitType[], id: string): number | null => {
  console.log("CALCULATION", { individual, global, id })

  const findInArray = <T extends LimitTypeOverride | LimitType>(arr: T[]): number | null => {
    for (const item of arr) {
      if ((item instanceof LimitTypeOverride && item.parentId === id) || item.id === id) {
        return item.sum;
      }
    }
    return null;
  }

  const individualSum = findInArray(individual);
  const globalSum = findInArray(global);

  return individualSum && globalSum ? individualSum! + globalSum! : individualSum || globalSum || null;
}

const getDaysString = (days: number | null, used: number, leaveType: string): string => {
  switch (days) {
    case null:
      return leaveType + " (ilość dostępnych dni bez limitu)";
    default:
      return leaveType + ": wykorzystano " + used + " z " + days + " dostępnych dni";
  }
}

export const declineDays = (days: number, word: string): string => {
  if (days === 1) {
    return `1 dzień ${word}y`;
  } else if (days > 1 && days < 5) {
    return `${days} dni ${word}e`;
  } else {
    return `${days} dni ${word}ych`;
  }
}

export default AppAppFormExplanations;