import DropdownItemRequest from "../../Dropdown/DropdownItemRequest";
import StorageFile from "../../Storage/StorageFile";

export default class AddResourceRequest {
    userId: string;
    resourceType: DropdownItemRequest;
    date: Date;
    returnUntilDate?: Date;
    returnDate?: Date;
    isReturned: boolean;
    note: string;
    files: StorageFile[];
}