import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { PROMOCODE_GET } from "../api/apiEndpoints";
import PromoCodesList from "../components/PromoCode/PromoCodesList";

const PromoCodes = () => {
    return (
        <>
        <div className="fullPageContentWrapper">
            <div className="fullWidthColumn">
              <div className="itemHeader"><FontAwesomeIcon icon={faFileExport} />&nbsp;Licencje</div>
              <PromoCodesList url = {PROMOCODE_GET} ></PromoCodesList>
            </div>
        </div >
    </>
  )
}

export default PromoCodes;