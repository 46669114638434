
import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";

export default class DocEditForm {
    id: string;
    name: string;
    internalId: string;
    date: Date;
    note: string;
    files: StorageFile[];
}