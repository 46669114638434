

export default class DepartmentDto {
  id: string;
  name: string;
  description: string;
  managers: string[];
  assistantManagers: string[];
}


