export default class PromoCodeListDto {
    id: string;
    code: string;
    description: string;
    validFromUtc: Date;
    validToUtc: Date;
    maxUsages: number;
    usages: number;
    validForDays: number;
    workersLimit: number;
}