import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import { apiClient } from '../api/apiClient';
import { LEAVE_GET_all, LEAVE_GET_forConsideration, LEAVE_GET_own } from '../api/apiEndpoints';
import ApplicationGridTab from '../components/Leaves/ApplicationsList/ApplicationGridTab';
import LeaveListItemDto from '../models/dto/Leaves/LeaveListItemDto';
import LeaveStatusEnum from '../models/enums/LeaveStatusEnum';
import UserRoleEnum from '../models/enums/UserRoleEnum';
import LeaveListItem from '../models/local/Leaves/LeaveListItem';
import { mapper } from '../models/mapper';
import { AppContext, IAppContext } from '../services/AppContext';
import useAppLoader from '../services/AppLoader';

const AppsList = () => {
  const [selected, setSelected] = React.useState(0);
  const context = useContext<IAppContext>(AppContext);
  const navigate = useNavigate();

  const [ownLeavesList, setOwnLeavesList] = React.useState<LeaveListItem[]>();
  const [notOwnLeavesList, setNotOwnLeavesList] = React.useState<LeaveListItem[]>();
  const loader = useAppLoader(true);
  const [allLeavesList, setAllLeavesList] = React.useState<LeaveListItem[]>();

  const canViewNotOwnedLeaves: boolean = context.values.role === UserRoleEnum.MANAGER || context.values.role === UserRoleEnum.HR;
  const canViewAllLeaves: boolean = context.values.role === UserRoleEnum.HR;


  const fetchOwnLeaves = async () => {
    await apiClient({
      method: "GET",
      url: LEAVE_GET_own,
      data: {},
    }).then((resp) => {
      const mappedResult: LeaveListItem[] = mapper.mapArray(resp?.data?.result, LeaveListItem, LeaveListItemDto);
      setOwnLeavesList(mappedResult);
    });
  }

  const fetchAllLeaves = async () => {
    if (!canViewAllLeaves)
      return;

    await apiClient({
      method: "GET",
      url: LEAVE_GET_all,
      data: {},
    }).then((resp) => {
      const mappedResult: LeaveListItem[] = mapper.mapArray(resp?.data?.result, LeaveListItem, LeaveListItemDto);
      setAllLeavesList(mappedResult);
    });
  }

  const fetchForeignLeaves = async () => {
    if (!canViewNotOwnedLeaves)
      return;

    await apiClient({
      method: "GET",
      url: LEAVE_GET_forConsideration,
      data: {},
    }).then((resp) => {
      const mappedResult: LeaveListItem[] = mapper.mapArray(resp?.data?.result, LeaveListItem, LeaveListItemDto);
      setNotOwnLeavesList(mappedResult);
    });
  }

  const reloadData = () => {
    loader.showLoading(true);
    Promise.all([fetchOwnLeaves(), fetchForeignLeaves(), fetchAllLeaves()]).then((r1) => {
      loader.showLoading(false);
    })
  }

  const goToAppChangeStatus = (id: string) => {
    navigate(`/appslist/${id}/consider`)
  };

  const goToAppDetails = (id: string) => {
    navigate(`/appslist/${id}`)
  };

  useEffect(() => {
    reloadData();
  }, []);

  useEffect(() => {
    if (loader.isLoading()) return;
    let awaitingList = notOwnLeavesList?.filter(l => l.status as LeaveStatusEnum === LeaveStatusEnum.AWAITING) ?? [];
    if (awaitingList.length > 0) {
      let elements = document.querySelectorAll('div.k-tabstrip-items-wrapper > ul > li:nth-child(1)');
      if (elements?.length > 0)
        elements[0].setAttribute('hasData', 'true');
    }
  }, [loader.isLoading()]);

  return (
    <>
      {!loader.isLoading() &&
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn" id="leaveList">
            <TabStrip selected={selected} onSelect={(e) => setSelected(e.selected)}>

              {canViewNotOwnedLeaves && (
                <TabStripTab title="Oczekujące na Twoją decyzję">
                  <ApplicationGridTab data={notOwnLeavesList?.filter(l => l.status as LeaveStatusEnum === LeaveStatusEnum.AWAITING)} buttonAction={goToAppChangeStatus} icon="" buttonLabel="Rozpatrz"></ApplicationGridTab>
                </TabStripTab>
              )}

              <TabStripTab title="Złożone przez Ciebie wnioski" >
                <ApplicationGridTab data={ownLeavesList} buttonAction={goToAppDetails} buttonLabel="Szczegóły" icon=""></ApplicationGridTab>
              </TabStripTab>

              {canViewNotOwnedLeaves && !canViewAllLeaves && (
                <TabStripTab title="Rozpatrzone wnioski (w działach kierownika)">
                  <ApplicationGridTab data={notOwnLeavesList?.filter(l => l.status as LeaveStatusEnum !== LeaveStatusEnum.AWAITING)} buttonAction={goToAppDetails} icon="" buttonLabel="Szczegóły"></ApplicationGridTab>
                </TabStripTab>
              )}

              {canViewAllLeaves && (
                <TabStripTab title="Raport HR (wszystkie wnioski)">
                  <ApplicationGridTab data={allLeavesList} buttonAction={goToAppDetails} icon="" buttonLabel="Szczegóły"></ApplicationGridTab>
                </TabStripTab>
              )}
            </TabStrip>
          </div>
        </div>
      }
    </>
  );
};

export default AppsList;
