import React, { useEffect, useRef, useState } from 'react';

import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import {
  Upload, UploadFileStatus, UploadOnBeforeRemoveEvent, UploadOnStatusChangeEvent
} from '@progress/kendo-react-upload';

import { baseUrl } from '../../../../../../../api/apiClient';
import { STORAGE_REMOVE, STORAGE_UPLOAD_competency } from '../../../../../../../api/apiEndpoints';
import useUploadUtils from '../../../../../../../helpers/uploadUtils';
import StorageFile from '../../../../../../../models/dto/Storage/StorageFile';
import NotificationEnum from '../../../../../../../models/enums/NotificationEnum';
import StorageTypeEnum from '../../../../../../../models/enums/StorageTypeEnum';
import CompetencyEditForm from '../../../../../../../models/local/Briefcases/CompetencyEditForm';
import Competency from '../../../../../../../models/local/Employees/Competency';
import { mapper } from '../../../../../../../models/mapper';
import { DictByType, DictTypes } from '../../../../../../../scripts/dictProvider';
import { AppContext, IAppContext } from '../../../../../../../services/AppContext';
import useAppNotifications from '../../../../../../../services/AppNotifications';
import { requiredValidator } from '../../../../../../../validators/CommonValidators';
import {
  FormDatePicker, FormDropDownList, FormTextArea
} from '../../../../../../Kendo/form-components';
import EditToolbar from './EditToolbar';
import FilesPanel from './FilesPanel';

const CompetenciesEdit = (props: any) => {
  const context = React.useContext<IAppContext>(AppContext);
  const [entity, setEntity] = useState<Competency>(props.entity);
  const formRef = useRef<Form>(null);
  const notifications = useAppNotifications();
  const uploadUtils = useUploadUtils();
  // list of currently uploaded files
  const [uploadedFiles, setUploadedFiles] = useState<StorageFile[]>([]);
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);

  const onBeforeRemove = (event: UploadOnBeforeRemoveEvent) => {
    event.additionalData.files = [];
    event.files.forEach(f => {
      if (f.status == UploadFileStatus.Removing) {
        let toDelete = uploadedFiles.filter(x => x.fileLength == f.size && x.fileName == f.name);
        if (toDelete) {
          event.additionalData.files = toDelete.map((x) => x.fileId);
          // remove from currently uploaded files
          toDelete.forEach(tDel => {
            entity.files = entity.files.filter(x => x.fileId != tDel.fileId);
            setUploadedFiles(uploadedFiles.filter(x => x.fileId != tDel.fileId));
          });
        }
      }
    });
  };

  const onRemove = (fileId: string) => {
    removedFiles.push(fileId);
    setRemovedFiles(removedFiles);
  }

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    if (event.newState.find(x => x.status == UploadFileStatus.Uploaded) != null) {
      let data = event?.response?.response?.result as StorageFile[];
      if (data != null && data.length > 0) {
        data.forEach(f => {
          let file = { fileId: f.fileId, fileLength: f.fileLength, fileName: f.fileName };
          uploadedFiles.push(file);
          setUploadedFiles(uploadedFiles);
        });
        setEntity(entity);
      }
    }
  }

  const initValue: CompetencyEditForm = mapper.map(props.entity as Competency, CompetencyEditForm, Competency);

  const handleChange = (e: any) => {
    if (e.value?.id) //todo: ech...
      e.value = e.value.id;

    props.setDisableParentSubmit(true);

    setEntity({ ...entity, [e.target.name as string]: e.value });
  };

  // hooks
  useEffect(() => { }, []);

  return (
    <>
      <Form
        ref={formRef}
        initialValues={initValue}
        render={(formRenderProps: FormRenderProps) => (
          <>
            <FormElement
              style={{
                width: 400,
              }}
            >

              <div className="mainToolbar">
                <div className="itemHeader">Kompetencje</div>
                <EditToolbar
                  onCancel={props.onCancel}
                  onSave={() => {
                    if (formRef?.current?.isValid()) {
                      // update files
                      uploadedFiles.forEach(e => {
                        entity.files.push(e);
                      });
                      entity.files = entity?.files?.filter(x => !removedFiles.includes(x.fileId));

                      props.onSave(entity);
                      notifications.showNotification("Zmiany wprowadzone. Pamiętaj o ich zapisaniu.", NotificationEnum.Info);
                    }
                  }}
                ></EditToolbar>
              </div>

              <Field
                label={"Typ kompetencji"}
                id={"competencyType"}
                name={"competencyType"}
                component={FormDropDownList}
                data={DictByType(DictTypes.CompetencyTypes, context.values.dicts)}
                validator={requiredValidator}
                textField="name"
                dataItemKey="id"
                onChange={handleChange}
              />

              <Field
                label={"Ważne od"}
                id={"date"}
                name={"date"}
                component={FormDatePicker}
                validator={requiredValidator}
                onChange={handleChange}
              />

              <Field
                label={"Ważne do"}
                id={"validityDate"}
                name={"validityDate"}
                component={FormDatePicker}
                validator={requiredValidator}
                onChange={handleChange}
              />

              <Field
                label={"Szczegółowe informacje"}
                id={"note"}
                name={"note"}
                autoComplete="off"
                component={FormTextArea}
                onChange={handleChange}
              />

              <Upload
                autoUpload={true}
                multiple={true}
                defaultFiles={[]}
                withCredentials={false}
                saveUrl={baseUrl + STORAGE_UPLOAD_competency}
                removeUrl={baseUrl + STORAGE_REMOVE}
                onBeforeRemove={onBeforeRemove}
                onStatusChange={onStatusChange}
                saveHeaders={uploadUtils.setHeaders()}
                removeHeaders={uploadUtils.setHeaders()}
                showFileList={true}
              />

              <FilesPanel
                storageType={StorageTypeEnum.StorageCompetency}
                files={entity?.files?.filter((x) => !uploadedFiles.some(u => u.fileId === x.fileId) && !removedFiles.some(r => r == x.fileId))}
                onRemove={onRemove}
              />

            </FormElement>
          </>
        )}
      />
    </>
  );
};

export default CompetenciesEdit;
