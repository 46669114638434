import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";
import DropdownItem from "../Dropdown/DropdownItem";

export default class AddResourceForm {
    user: DropdownItem;
    resourceType: DropdownItem;
    date: Date;
    returnUntilDate?: Date;
    returnDate?: Date;
    isReturned: boolean;
    note: string;
    files: StorageFile[];
}