import * as React from 'react';
import { useNavigate } from 'react-router';

import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';

import { LeaveStatusEnumTranslation } from '../../../models/enums/LeaveStatusEnum';
import MonthViewItem from '../../../models/local/Leaves/MonthViewItem';

const colorBlockStyle = (color: string): React.CSSProperties => {
  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid transparent",
    height: "3em",
    justifyContent: "center",
    borderRadius: "10px",
    color: "white",
    backgroundColor: color
  };

  return style;
}

const CalendarListView = (props: {
  displayDate: Date,
  calendarItems: MonthViewItem[]
}) => {
  const navigate = useNavigate();

  const goToDetailsView = (id: string) => {
    navigate(`/appslist/${id}`);
  }

  return (
    <>
      <Grid style={{ maxHeight: "30%" }} data={props.calendarItems}>
        <GridColumn field="id" title="Nr wniosku"
          cell={(d: GridCellProps) => (<td><div style={colorBlockStyle((d.dataItem as MonthViewItem).color)} >{(d.dataItem as MonthViewItem).id.substr(0, 8)}</div></td>)} width="100px" />
        <GridColumn title="Składający" cell={(d: GridCellProps) => (<td>{d.dataItem.firstName} {d.dataItem.lastName}</td>)} />
        {/* <GridColumn field="replacement" title="Zastępstwo" cell={(d: GridCellProps) => (<td>{d.dataItem.replacementUsers.map((u: DropdownItem) => u.text).join(", ")}</td>)} /> */}
        <GridColumn field="limitType" title="Nieobecność" />
        <GridColumn field="department" title="Działy" cell={(d: GridCellProps) => (<td>{(d.dataItem as MonthViewItem).departments.join(", ")}</td>)} />
        <GridColumn field="startDate" title="Rozpoczęcie" format="{0:d}" width="110px" />
        <GridColumn field="endDate" title="Zakończenie" format="{0:d}" width="110px" />
        { }
        <GridColumn field="status" title="Status" width="140px" cell={(d: GridCellProps) => (<td>{LeaveStatusEnumTranslation(d.dataItem.status)}</td>)} />
        <GridColumn
          title="Operacje"
          width="140px"
          cell={(item: any) => (
            <td>
              <Button
                fillMode="outline"
                icon="check"
                onClick={() => {
                  goToDetailsView(item.dataItem.id)
                }}
              >Szczegóły</Button>
            </td>
          )}
        />
      </Grid>
    </>
  );
};

export default CalendarListView;
