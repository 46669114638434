import * as React from 'react';
import { useNavigate } from 'react-router';

const Bc = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/briefcases/competencies");
  }, []);

  return <></>
};

export default Bc;
