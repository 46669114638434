import UserBriefcaseBaseDto from '../UserBriefcaseBaseDto';
import CompetencyDto from './CompetencyDto';
import ContractDto from './ContractDto';
import DocDto from './DocDto';
import MedicalDto from './MedicalDto';
import NoteDto from './NoteDto';
import NotificationDto from './NotificationDto';
import ResourceDto from './ResourceDto';
import TrainingDto from './TrainingDto';
import WorkExperienceDto from './WorkExperienceDto';

export default class UserBriefcaseDto extends UserBriefcaseBaseDto {
    contracts: ContractDto[];
    medicals: MedicalDto[];
    trainings: TrainingDto[];
    notifications: NotificationDto[];
    workExperiences: WorkExperienceDto[];
    competencies: CompetencyDto[];
    resources: ResourceDto[];
    docs: DocDto[];
    notes: NoteDto[];
}
