enum WorkPlanTypeEnum {
  UNKNOWN = "Unknown",
  NORMALIZED = "Normalized",
  DYNAMIC = "Dynamic",
  HOURLY = "Hourly",
  CUSTOM = "Custom"
};

export const WorkPlanTypeEnumTranslation = (value: WorkPlanTypeEnum | undefined) => {
  let translation: string = "";

  switch (value) {
    case WorkPlanTypeEnum.UNKNOWN || undefined:
      translation = "***ERROR***";
      break;
    case WorkPlanTypeEnum.NORMALIZED:
      translation = "normowany";
      break;
    case WorkPlanTypeEnum.DYNAMIC:
      translation = "dynamiczny";
      break;
    case WorkPlanTypeEnum.HOURLY:
      translation = "godzinowy";
      break;
    case WorkPlanTypeEnum.CUSTOM:
      translation = "własny";
      break;
  }

  return translation;
}

export default WorkPlanTypeEnum;