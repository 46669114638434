import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";
import DropdownItem from "../Dropdown/DropdownItem";

export default class AddContractForm {
    user: DropdownItem;
    contractType: DictItem;
    contractPeriod: DictItem;
    signDate: Date;
    startDate: Date;
    endDate?: Date;
    note: string;
    files: StorageFile[];
}