import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { apiClient } from '../api/apiClient';
import {
  WORKPLANS_DELETE_WORKPLANS, WORKPLANS_GET_WORKPLAN, WORKPLANS_GET_WORKPLANS,
  WORKPLANS_POST_WORKPLANS, WORKPLANS_PUT_WORKPLANS
} from '../api/apiEndpoints';
import WorkPlanAddEdit from '../components/Settings/WorkPlan/WorkPlanAddEdit/WorkPlanAddEdit';
import WorkPlansList from '../components/Settings/WorkPlan/WorkPlansList';
import WorkPlanRequestDto from '../models/dto/WorkPlans/Requests/WorkPlanRequestDto';
import WorkPlanListResponseDto from '../models/dto/WorkPlans/Responses/WorkPlanListResponseDto';
import WorkPlanResponseDto from '../models/dto/WorkPlans/Responses/WorkPlanResponseDto';
import NotificationEnum from '../models/enums/NotificationEnum';
import WorkPlan from '../models/local/WorkPlan/WorkPlan';
import WorkPlanListItem from '../models/local/WorkPlan/WorkPlanListItem';
import { mapper } from '../models/mapper';
import useAppNotifications from '../services/AppNotifications';
import Loading from './Loading';

enum EditState {
  WorkPlanList,
  AddWorkPlan,
  EditWorkPlan,
}

type WorkPlanEditParams = {
  id: string;
};

const WorkPlans = () => {
  const { id } = useParams<WorkPlanEditParams>();
  const navigate = useNavigate();
  const notifications = useAppNotifications();

  const [viewState, setViewState] = React.useState<{
    editState: EditState,
    isLoading: boolean,
    errorMessage: string | null,

    listItems: WorkPlanListItem[]

    editedEntity: WorkPlan | null,
    editedEntityId?: string,
  }>(
    {
      editState: EditState.WorkPlanList,
      isLoading: true,
      errorMessage: null,

      listItems: [],

      editedEntity: null,
      editedEntityId: id,
    }
  );

  // -- API

  const getWorkPlans = async () => {
    const getWorkPlans = async () => {
      setViewState({ ...viewState, isLoading: true });
      apiClient({
        method: "GET",
        url: WORKPLANS_GET_WORKPLANS,
        data: {},
      }).then((resp) => {
        const mapperWorkPlans: WorkPlanListItem[] =
          mapper.mapArray(
            (resp?.data?.result as WorkPlanListResponseDto[]),
            WorkPlanListItem,
            WorkPlanListResponseDto);

        setViewState({ ...viewState, isLoading: false, editState: EditState.WorkPlanList, editedEntity: null, listItems: mapperWorkPlans });
      });
    };

    await getWorkPlans();
  }

  const getWorkPlanById = async (entityId: string) => {
    const getWorkPlan = async (entityId: string) => {
      setViewState({ ...viewState, isLoading: true });
      apiClient({
        method: "GET",
        url: WORKPLANS_GET_WORKPLAN(entityId),
        data: {},
      }).then((resp) => {
        const response = resp?.data?.result as WorkPlanResponseDto;
        const mappedResult: WorkPlan = mapper.map(response, WorkPlan, WorkPlanResponseDto);

        setViewState({ ...viewState, isLoading: false, editState: EditState.EditWorkPlan, editedEntity: mappedResult });
      });
    };

    await getWorkPlan(entityId);
  }

  // --

  const addWorkPlan = async () => {
    navigate(`/workPlans/edit/add`);
  }

  const deleteWorkPlan = async (entityId: string) => {
    apiClient({
      method: "DELETE",
      url: WORKPLANS_DELETE_WORKPLANS(entityId),
      data: {
        userId: entityId
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error)
        return;
      }

      notifications.showNotification("Wskazana pozycja została usunięta.")
      getWorkPlans();
    });
  }

  const onSaveWorkPlan = async (entity: WorkPlan) => {
    const dtoToSend: WorkPlanRequestDto = mapper.map(entity, WorkPlanRequestDto, WorkPlan);

    apiClient({
      method: viewState.editState === EditState.EditWorkPlan ? "PUT" : "POST",
      url: viewState.editState === EditState.EditWorkPlan ? WORKPLANS_PUT_WORKPLANS : WORKPLANS_POST_WORKPLANS,
      data: dtoToSend,
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error)
        return;
      }

      notifications.showNotification("Dane zapisano poprawnie")
      navigate(`/workPlans`);
    });
  }

  const cancelAddEdit = () => {
    navigate(`/workPlans`);
  }

  useEffect(() => {
    if (id === "add")
      setViewState({ ...viewState, isLoading: false, editState: EditState.AddWorkPlan, editedEntity: null });
    else if (id)
      getWorkPlanById(id)
    else getWorkPlans();
  }, [id]);


  useEffect(() => {
  }, []);

  return (
    <>
      {viewState.isLoading && <Loading />}

      {viewState.isLoading === false &&
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn">
            <div className="mainToolbar" style={{ minHeight: "3em" }}>

              {viewState.editState === EditState.WorkPlanList &&
                <WorkPlansList
                  workPlans={viewState.listItems}
                  onAdd={addWorkPlan}
                  onDelete={deleteWorkPlan}
                  onPreview={() => { }}
                  onEmployeesAssign={() => { }}
                />}

              {viewState.editState === EditState.AddWorkPlan &&
                <WorkPlanAddEdit
                  data={new WorkPlan()}
                  onSave={onSaveWorkPlan}
                  onCancel={cancelAddEdit} />}

              {viewState.editState === EditState.EditWorkPlan &&
                <WorkPlanAddEdit
                  data={viewState.editedEntity}
                  onSave={onSaveWorkPlan}
                  onCancel={cancelAddEdit} />}

            </div>
          </div>
        </div >
      }
    </>
  );
};

export default WorkPlans;