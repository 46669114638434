import preval from 'preval.macro';
import React, { useEffect, useState } from 'react';

import { apiClient } from '../../api/apiClient';
import { UTILS_POST_buildDetails } from '../../api/apiEndpoints';
import moduleStyle from '../../styles/LoginRegister.module.scss';

const PromoBanner = (props: { minHeight?: number }) => {
  const [apiBuildDate, setApiBuildDate] = useState<string | null>(null);
  const uiBuildDate = preval`module.exports = new Date().toLocaleString('pl-PL');`;

  useEffect(() => {
    apiClient({
      method: "POST",
      url: UTILS_POST_buildDetails,
      data: {},
    }).then((resp) => {
      setApiBuildDate(
        new Date(resp?.data?.result?.dateTime).toLocaleString("pl-PL")
      );
    });
  }, []);

  return (
    <div className={moduleStyle.productDescColumn} style={{ minHeight: props.minHeight }}>
      <div className={moduleStyle.sloganWrapper}>
        <div className={moduleStyle.sloganContentText}>
          <span className={moduleStyle.bigOrnament} />
          <span className={moduleStyle.smallOrnament} />
          <span className={moduleStyle.slogan}>
            {/* Nowoczesny HR w Twojej firmie. */}
            Urlopy to nasza specjalność.
          </span>
          <br />
          <p className={moduleStyle.description}>
            Zarządzanie nieobecnościami jeszcze nigdy nie było tak proste.
            Przejrzysty grafik, wygodna obsługa, szczegółowe informacje oraz
            pełna automatyzacja dla Ciebie, działu HR i pracowników. Bez względu
            na to gdzie jesteś.
          </p>
          <small style={{ opacity: "0.4" }}>
            API:{apiBuildDate} UI:{uiBuildDate}
          </small>
        </div>
      </div>
    </div>
  );
};
export default PromoBanner;
