import React, { useContext } from 'react';
import { useNavigate } from 'react-router';

import UserRoleEnum from '../models/enums/UserRoleEnum';
import { Dict, GetAllDictsAndEnums } from '../scripts/dictProvider';
import { AppContext, AppContextModel, IAppContext } from './AppContext';

const useSessionWrapper = () => {
    const context = React.useContext<IAppContext>(AppContext);

    const navigate = useNavigate();

    const login = (accessToken: string, userName: string, userId: string, role: string, licenceExpirationDays: number, avatarId?: string) => {
        console.log("SessionWrapper: clearing and setting localStorage...")
        localStorage.clear();

        localStorage.setItem("access-token", accessToken);
        localStorage.setItem("userName", userName);
        localStorage.setItem("avatarId", avatarId ?? '');
        localStorage.setItem("userId", userId);
        localStorage.setItem("role", role.toUpperCase());
        localStorage.setItem("licenceExpirationDays", licenceExpirationDays?.toString());

        localStorage.setItem("isInMobileView", "false");
        localStorage.setItem("mobileBreakpointPx", "600");

        localStorageToContextCopy();

        if (licenceExpirationDays < 0 && role !== UserRoleEnum.ADMINISTRATOR) {
            navigate("/settingsLicence");
        }
        else {
            navigate("/");
        }
    }

    const localStorageToContextCopy = (dontRedirect: boolean = false) => {
        if (localStorage.getItem("access-token")) {
            console.log("localStorageToContextCopy:  -> access-token exists; do job.")

            let dictionaries: Dict[] = [];
            const getAllDicts = async () => {
                dictionaries = await GetAllDictsAndEnums();
            }
            getAllDicts().then(() => {

                context.setValues({
                    ...context.values,
                    loggedIn: localStorage.getItem("access-token") ? true : false,
                    userName: localStorage.getItem("userName")!,
                    userId: localStorage.getItem("userId")!,
                    role: UserRoleEnum[(localStorage.getItem("role") as keyof typeof UserRoleEnum)],
                    avatarId: localStorage.getItem("avatarId"),
                    dicts: dictionaries,

                    isInMobileView: localStorage.getItem("isInMobileView") === "true",
                    mobileBreakpointPx: parseInt(localStorage.getItem("mobileBreakpointPx")!),
                });
            });
        } else {
            console.log("localStorageToContextCopy:  -> access-token not found. do logout");
            if (isLoggedIn())
                logout(dontRedirect);
        }
    }

    const localStorageMobileViewToContextCopy = () => {
        context.setValues({
            ...context.values, isInMobileView: localStorage.getItem("isInMobileView") === "true",
            mobileBreakpointPx: parseInt(localStorage.getItem("mobileBreakpointPx")!),
        });
    }

    const logout = (dontRedirect: boolean = false) => {
        console.log("SessionWrapper:  -> logout.")

        localStorage.clear();
        context.setValues({ ...{} as AppContextModel, loggedIn: false });
        if (!dontRedirect)
            navigate("/");
    }

    const isLoggedIn = () => {
        return context.values.loggedIn;
    }

    return { logout, login, isLoggedIn, localStorageToContextCopy, localStorageMobileViewToContextCopy }
}

export default useSessionWrapper;