import '../../../styles/_common.scss';

import * as React from 'react';

import {
  Grid, GridColumn as Column, GridFooterCellProps, GridRowProps
} from '@progress/kendo-react-grid';

import LeaveDaySourceEnum, {
  LeaveDaySourceEnumTranslation
} from '../../../models/enums/LeaveDaySourceEnum';
import CalculateAppItem from '../../../models/local/AddLeave/CalculateAppItem';

const rowRenderer = (
  trElement: React.ReactElement<HTMLTableRowElement>,
  rowProps: GridRowProps
) => {
  const isWorkingDay = ((rowProps.dataItem) as CalculateAppItem).source === LeaveDaySourceEnum.WORKINGDAY;
  const trProps: any = { style: !isWorkingDay ? { color: "#848884" } : {} };

  return React.cloneElement(trElement, { ...trProps }, trElement.props.children as any);
};

const AddAppRangeDetails = (props: {
  items: CalculateAppItem[]
}) => {

  const gridFooter = (cellProps: GridFooterCellProps) =>
    <td colSpan={3} style={{ fontWeight: "normal" }}>
      Ilość dni pracujących (dni urlopu): {props.items.length > 0 ? Math.max(...props.items.map(i => i.counter)) : "---"}
    </td>;

  const gridFooterEmpty = (cellProps: GridFooterCellProps) => <></>;

  React.useEffect(() => {
  }, []);

  return (
    <div id="itemsGridWrapper">
      <Grid data={props?.items} className="contentWrapperFullHeight" rowRender={rowRenderer}>
        <Column field="date" filter="date" title="Data" format="{0:d}" width="100px" className="flexItemsRight" footerCell={gridFooter} />
        <Column field="counter" title="Nr" width="50px" cell={(v: any) => <td style={{ textAlign: "right" }}>{(v.dataItem as CalculateAppItem).counter ?? "-"}</td>} footerCell={gridFooterEmpty} />
        <Column field="source" title="Typ" width="190px" cell={(v: any) => <td>{LeaveDaySourceEnumTranslation((v.dataItem as CalculateAppItem).source)}</td>} footerCell={gridFooterEmpty} />
        <Column field="description" title="Opis" footerCell={gridFooterEmpty} />
      </Grid>
    </div>
  );
}

export default AddAppRangeDetails;