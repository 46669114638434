import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";
import DropdownItem from "../Dropdown/DropdownItem";

export default class AddMedicalForm {
    user: DropdownItem;
    medicalTestingType: DictItem;
    date: Date;
    validityDate?: Date;
    note: string;
    files: StorageFile[];
}