import { DictItem } from "../../../scripts/dictProvider";
import StorageFile from "../../dto/Storage/StorageFile";

export default class ResourceEditForm {
    id: string;
    resourceType: DictItem;
    date: Date;
    returnUntilDate: Date;
    returnDate?: Date;
    isReturned: boolean;
    note: string;
    files: StorageFile[];
}