import React, { useEffect } from 'react';

import {
  Button, Toolbar, ToolbarItem, ToolbarSeparator, ToolbarSpacer
} from '@progress/kendo-react-buttons';
import { DropDownTree, DropDownTreeChangeEvent } from '@progress/kendo-react-dropdowns';
import { FormElement } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';

import { apiClient } from '../../api/apiClient';
import { DROPDOWN_GET_ALLOWEDDEPARTMENTSTREE } from '../../api/apiEndpoints';
import DropdownTreeItemResponse from '../../models/dto/Dropdown/DropdownTreeItemResponse';
import DropdownTreeItem from '../../models/local/Dropdown/DropdownITreetem';
import { mapper } from '../../models/mapper';
import useAppLoader from '../../services/AppLoader';
import { ViewTypeEnum } from './ViewTypeEnum';

const CalendarToolbar = (props: {
  date: Date,
  changeMonth: (shift: number) => void,
  changeView: (viewType: ViewTypeEnum) => void,
  filterByDepartmentId: (departmentId: string | undefined) => void
}) => {
  const [viewType, setViewType] = React.useState<ViewTypeEnum>(ViewTypeEnum.Month);
  const loader = useAppLoader(true);
  const [departments, setDepartments] = React.useState<DropdownTreeItem[]>([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState<string | undefined>();
  const dropdownDepartment = React.useRef(null);

  const onChangeView = (viewType: ViewTypeEnum) => {
    setViewType(viewType)
    props.changeView(viewType);
  }

  const onChangeDepartment = (event: DropDownTreeChangeEvent) => {

    if (event.level.length === 1)
      setSelectedDepartment(undefined);
    else setSelectedDepartment((event.value as DropdownTreeItem).id);
  }

  const getDepartments = () => {
    loader.showLoading(true);

    apiClient({
      method: "GET",
      url: DROPDOWN_GET_ALLOWEDDEPARTMENTSTREE,
    }).then(async (resp) => {
      loader.showLoading(false);
      if (resp.status !== 200) {
        return;
      } else {
        const mappedResponse: DropdownTreeItem[] = mapper.mapArray(resp?.data?.result as DropdownTreeItemResponse[], DropdownTreeItem, DropdownTreeItemResponse);
        setDepartments(mappedResponse);
      }
    });
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    props.filterByDepartmentId(selectedDepartment);
  }, [selectedDepartment]);

  return (
    <Toolbar>
      <ToolbarItem>
        <FormElement>
          <Button
            fillMode="outline"
            icon="arrow-chevron-left"
            className="k-button"
            onClick={() => { props.changeMonth(-1) }}
            type="button"
          >
            poprzedni
          </Button>
        </FormElement>
        <FormElement>
          <div style={{ paddingLeft: "10px", paddingRight: "10px", marginTop: "0.4em", fontWeight: "bold" }}>
            {new Intl.DateTimeFormat("pl-PL", { month: 'long', year: 'numeric' }).format(props.date)}
          </div>
        </FormElement>
        <FormElement>
          <Button
            fillMode="outline"
            icon="arrow-chevron-right"
            className="k-button"
            onClick={() => { props.changeMonth(1) }}
            type="button"
          >
            następny
          </Button>
        </FormElement>
      </ToolbarItem>
      <ToolbarSeparator></ToolbarSeparator>

      <Label editorId="department">Dział:&nbsp;</Label>


      <DropDownTree
        id="department"
        ref={dropdownDepartment}
        style={{ width: "300px" }}
        data={departments}

        placeholder="Wybierz..."
        dataItemKey={"id"}
        textField={"text"}

        expandField={"expanded"}
        loading={loader.isLoading()}

        popupSettings={{ animate: false }}

        onChange={onChangeDepartment}
        defaultValue={departments[0]}
      />
      <ToolbarSeparator></ToolbarSeparator>

      <ToolbarSpacer></ToolbarSpacer>
      <ToolbarItem>
        <Button
          fillMode="outline"
          icon="calendar"
          className="k-button"
          togglable={true} onClick={() => onChangeView(ViewTypeEnum.Month)} selected={viewType === ViewTypeEnum.Month}
        >
          Widok kalendarza
        </Button>
        &nbsp;
        <Button
          fillMode="outline"
          icon="align-justify"
          className="k-button"
          togglable={true} onClick={() => onChangeView(ViewTypeEnum.List)} selected={viewType === ViewTypeEnum.List}
        >
          Widok listy
        </Button>
      </ToolbarItem>
    </Toolbar >
  );
};

export default CalendarToolbar;
